<template>
	<div class="login-container">
		<!-- 登录表单 -->
		<el-form class="login-box">
			<div class="login-title">账号登录</div>
			<!-- 账号密码登录 -->
			<div v-show="loginType == 'pwd'">
				<el-form-item class="login-item">
					<el-input v-model="loginName" placeholder="请输入用户名"></el-input>
				</el-form-item>
				<el-form-item class="login-item" style="text-align: right;">
					<el-input v-model="loginPwd" placeholder="请输入密码" show-password></el-input>
					<el-button type="text" @click="forgetPwd">忘记密码？</el-button>
				</el-form-item>
				<el-form-item class="login-item">
					<el-button class="btn-login" type="danger" @click="loginByPwd" :loading="loginClicked">登
						录</el-button>
				</el-form-item>
				<el-form-item class="login-item" style="margin-top: 10px;">
					<el-button class="btn-login btn-change-type" @click="loginType = 'tel';forgetClicked = false;">使用手机号登录</el-button>
				</el-form-item>
			</div>
			<!-- 手机号验证码登录 -->
			<div v-show="loginType == 'tel'">
				<el-form-item class="login-item">
					<el-input v-model="loginTel" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item class="login-item">
					<el-input class="input-vcode" v-model="loginCode" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入验证码"></el-input>
					<el-button class="btn-vcode" type="danger" @click="getVerificationCode">{{vcodeBtnTxt}}</el-button>
				</el-form-item>
				<el-form-item class="login-item" style="margin-top: 60px;">
					<el-button class="btn-login" type="danger" @click="loginCheck" :loading="loginClicked">登
						录</el-button>
				</el-form-item>
				<el-form-item class="login-item" style="margin-top: 10px;">
					<el-button class="btn-login btn-change-type" @click="loginType = 'pwd';forgetClicked = false;">使用用户名密码登录</el-button>
				</el-form-item>
			</div>
		</el-form>
		<!-- 完善用户名密码信息弹窗 -->
		<name-pwd-dialog :isShow="showCompleteInfo" :oldName="oldLoginName" @success="completeSuccess"></name-pwd-dialog>
	</div>
</template>

<script>
	import NamePwdDialog from '../../components/common/NamePwdDialog.vue';
	import { getCustomerInfo } from '../../utils/api-utils/api-customer.js';
	import { getParam } from '../../utils/api-utils/api-params.js'
	import '../../theme/element/theme/self.css'
	var interval;
	export default {
		name: 'Login',
		components: {
			'name-pwd-dialog': NamePwdDialog
		},
		data() {
			return {
				loginTel: "", // 用户输入的手机号
				loginCode: "", // 用户输入的验证码
				loginPhone: "", // 用户输入的手机号
				loginPassword: "", // 用户输入的密码
				sysLoginCode: "", // 系统发送的验证码，用作校验
				vcodeBtnTxt: "获取验证码", // 获取验证码按钮文字
				vcodeIntervalTime: 60, // 验证码发送间隔 
				loginClicked: false, // 按钮已点击
				loginType: "pwd", // 登录方式 tel:手机号验证码, pwd:登录名密码
				loginName: "", //登录名
				loginPwd: "", //登录密码
				showCompleteInfo: false, //完善信息弹窗（用户名密码）
				forgetClicked: false, //点击了忘记密码
				oldLoginName: "", //原登录名
				restrictLoginText: "仅限指定会员登录，请联系商家开通"
			}
		},
		created: function() {
			this.loadParam(); // 获取参数
		},
		methods: {
			/**
			 * 获取验证码
			 */
			getVerificationCode() {
				let _this = this;

				//关闭所有警告
				_this.$message.closeAll();

				// 检测是否可以再次获取验证码
				if (_this.vcodeBtnTxt != "获取验证码") {
					return false;
				}

				// 检测验证码输入是否正确
				if (!_this.loginTel) {
					return _this.$message.error('请输入手机号');
				}

				// 检测手机号格式是否正确
				if (!_this.$utils.checkTel(_this.loginTel)) {
					return _this.$message.error('手机号格式不正确');
				}

				// 发送请求获取验证码
				_this.$request.jackyunPost({
					method: "jmall.sms.sendverificationcode",
					jsonStr: JSON.stringify({
						loginTel: _this.loginTel,
					})
				}, (data) => {

					// 检测验证码发送是否正确
					if (data.code != 200) {
						return _this.$message.error(data.msg);
					}

					// 开始倒计时
					_this.countdown();

				});
			},

			/**
			 * 倒计时
			 */
			countdown() {
				let _this = this;
				interval = setInterval(function() {
					_this.vcodeBtnTxt = _this.vcodeIntervalTime + "秒";
					_this.vcodeIntervalTime = _this.vcodeIntervalTime - 1;
					if (_this.vcodeIntervalTime < 0) {
						_this.vcodeBtnTxt = "获取验证码";
						_this.vcodeIntervalTime = 60;
						clearInterval(interval);
					}
				}, 1000);
			},

			/**
			 * 登录验证
			 */
			async loginCheck() {
				let _this = this;

				//关闭所有警告
				_this.$message.closeAll();

				// 检测手机号是否输入
				if (!_this.loginTel) {
					return _this.$message.error('请输入手机号');
				}

				// 检测手机号格式是否正确
				if (!_this.$utils.checkTel(_this.loginTel)) {
					return _this.$message.error('手机号格式不正确');
				}

				// 检测验证码输入是否正确
				if (!_this.loginCode) {
					return _this.$message.error('请输入验证码');
				}

				// 获取用户信息
				_this._getCustomerInfoByTel();
			},

			/**
			 * 获取用户信息
			 * @param {Object} isSkipRegister 是否跳过注册（如果没有获取到会员信息是否自动注册标记）
			 */
			async _getCustomerInfoByTel(isSkipRegister) {
				let _this = this;
				if (_this.loginClicked) {
					return _this.$message.error('请勿重复点击');
				}
				_this.loginClicked = true;

				// 手机号登录
				let customer = await getCustomerInfo({ tel: _this.loginTel, verificationCode: _this.loginCode }, true);
				_this.loginClicked = false;
				if (!customer.customerId) {
					return _this.openAlterMessageBox(customer.error);
				}

				// 判断是否有用户名密码，没有的话，提示设置（忘记密码也需要弹窗重置）
				if (!customer.loginName || this.forgetClicked) {
					this.oldLoginName = customer.loginName;
					this.showCompleteInfo = true;
				} else {
					// 跳转到首页
					_this.$router.replace({
						name: 'home'
					})
				}
			},

			/**
			 * 用户名密码登录
			 */
			loginByPwd() {
				let _this = this;

				//关闭所有警告
				_this.$message.closeAll();

				// 检测用户名是否输入
				if (!_this.loginName) {
					return _this.$message.error('请输入用户名');
				}

				// 检测密码是否输入
				if (!_this.loginPwd) {
					return _this.$message.error('请输入密码');
				}

				// 获取用户信息
				_this.getCustomerByPwd();
			},

			/**
			 * 根据用户名密码，获取用户信息
			 */
			async getCustomerByPwd() {
				let _this = this;
				if (_this.loginClicked) {
					return _this.$message.error('请勿重复点击');
				}
				_this.loginClicked = true;

				//用户名密码登录
				let customer = await getCustomerInfo({ loginName: _this.loginName, loginPwd: btoa(encodeURIComponent(_this.loginPwd)) }, true)
				_this.loginClicked = false;
				if (!customer.customerId) {
					return _this.openAlterMessageBox(customer.error);
				}

				// 跳转到首页
				_this.$router.replace({
					name: 'home'
				})
			},

			/**
			 * 用户名密码设置完成
			 */
			completeSuccess() {
				// 跳转到首页
				this.$router.replace({
					name: 'home'
				})
			},

			/**
			 * 加载参数
			 */
			async loadParam() {
				let params = await getParam("pc_restrictLoginText");
				if (!!params.pc_restrictLoginText) {
					this.restrictLoginText = params.pc_restrictLoginText.replaceAll('\n', '<br>');
				}
			},

			/**
			 * 忘记密码（先用手机号登录，再设置密码）
			 */
			forgetPwd() {
				this.loginType = 'tel';
				this.forgetClicked = true;
			},

			// 打开限制登入弹窗
			openAlterMessageBox(text) {
				if (text == "非内部人员无法登录") {
					let restrictLoginText = this.restrictLoginText
					this.$alert(restrictLoginText, '提示', {
						confirmButtonText: '确定',
						dangerouslyUseHTMLString: true,
						callback: action => {
							// this.$message({
							// 	type: 'info',
							// 	message: `action: ${ action }`
							// });
						}
					});
				} else {
					this.$message.error(text);
				}

			}
		}
	}
</script>

<style scoped="scoped">
	.login-box {
		padding: 30px 50px;
		width: 400px;
		margin: 100px auto 0;
	}

	.login-title {
		text-align: center;
		font-size: 26px;
		margin-bottom: 50px;
	}

	.login-item {
		margin-bottom: 20px;
	}

	.input-vcode {
		width: 278px;
	}

	.btn-vcode {
		margin-left: 10px;
		width: 112px;
	}

	.btn-login {
		width: 100%;
	}

	.btn-change-type {
		border: 1px solid #f56c6c;
		color: #f56c6c;
	}

	/deep/.el-tabs__item {
		padding-left: 68px !important;
		padding-right: 68px !important;
		margin-bottom: 8px !important;
	}

	/deep/.el-tabs__content {
		margin-top: 30px !important;
	}
</style>