<template>
	<div class="address-list">
		<el-row :gutter="20">
			<el-col :span="8" class="el-col">
				<div class="add-box flex-row flex-center" @click="addAddress">
					<div class="add-icon"> <i class="el-icon-circle-plus"></i></div>
					<div>添加地址</div>
				</div>
			</el-col>
			<el-col :span="8" v-for="(item,index) in addresslist" :key="item.addressId" class="el-col">
				<address-detail :address="item"
					:isselect="selectId == item.addressId || selectIds.includes(item.addressId)" @edit="editAddress"
					@remove="deleteAddress" @select="selectAddr" @multiselect="multiSelectAddr"></address-detail>
			</el-col>
		</el-row>
		<address-edit :addressinfo="currentAddr" :title="dialogTitle" :isCreate="createEditDialog"
			:isShow="showEditDialog" @close="closeEditDialog" @success="editSuccess"></address-edit>
	</div>
</template>

<script>
	import addressDetail from './AddressDetail.vue'
	import addressEdit from './AddressEdit.vue'
	export default {
		name: "address-list",
		components: {
			addressDetail,
			addressEdit
		},
		props: {
			addresslist: {
				type: Array,
				default: function() {
					return [];
				}
			},
			selectid: {
				type: String,
				default: ''
			},
			triggerSelectEvent: {
				type: Boolean,
				default: true
			}, //是否触发选择事件，我的地址列表，不需要
			triggerMultiSelectEvent: {
				type: Boolean,
				default: false
			}, //是否触发多选选择事件(我的地址列表)
		},
		watch: {
			//监听父组件传值
			selectid(newValue, oldValue) {
				this.selectId = newValue;
			}
		},
		data() {
			return {
				dialogTitle: '',
				currentAddr: {},
				createEditDialog: false,
				showEditDialog: false,
				selectId: this.selectid,
				selectIds: [],
			}
		},
		methods: {
			addAddress() {
				this.dialogTitle = "添加地址";
				this.createEditDialog = true;
				setTimeout(() => {
					this.showEditDialog = true;
					this.currentAddr = {};
				}, 100);
			},
			editAddress(e) {
				this.dialogTitle = "修改地址";
				this.createEditDialog = true;
				let _this = this;
				//为了避免页面抖动，添加延时操作
				setTimeout(() => {
					_this.showEditDialog = true;
					_this.currentAddr = _this.addresslist.find(val => val.addressId == e);
				}, 100);
			},
			deleteAddress(e) {
				let that = this;
				let list = that.addresslist;
				that.$confirm("确定删除地址吗？", {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {

					this.$request.jackyunPost({
						method: "jmall.address.deleteaddress",
						addressIdList: e
					}, (res) => {
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功'
							});

							setTimeout(() => {
								let index = list.findIndex(val => val.addressId == e);
								index !== -1 && list.splice(index, 1);
							}, 500);
							this.$emit('refresh');
						} else {
							this.$message.error(res.msg || "删除失败");
						}
					});
				});
			},
			closeEditDialog() {
				this.currentAddr = {};
				this.createEditDialog = false;
				this.showEditDialog = false;
			},
			editSuccess(obj) {
				this.closeEditDialog();
				this.$emit('refresh');
				this.$emit('success', obj);
			},
			selectAddr(value) {
				if (this.triggerSelectEvent) {
					this.selectId = value;
					this.$emit('change', value);
				}
			},
			multiSelectAddr(value) {
				if (this.triggerMultiSelectEvent) {
					let selectIds = this.selectIds;
					if (selectIds.includes(value)) {
						selectIds = selectIds.filter(item => {
							return item != value;
						});
					} else {
						selectIds.push(value);
					}
					this.selectIds = selectIds;
					this.$emit('multiSelect', selectIds);
				}
			}

		}
	}
</script>

<style scoped="scoped">
	.address-list {
		max-height: 400px;
		overflow: hidden;
		overflow-y: scroll;
	}

	.address-list::-webkit-scrollbar {
		display: none;
	}

	.add-box {
		height: 135px;
		padding: 20px;
		color: #999;
		margin-bottom: 20px;
		font-size: 18px;
		border: 1px solid #dedede;
		cursor: pointer;
	}

	.add-icon {
		font-size: 30px;
		margin-right: 15px;
	}
</style>