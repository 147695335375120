import { Message } from 'element-ui';
const request = require('../request-utils.js');

/**
 * 创建退款申请
 */
const createSellbackOrder = (sellbackInfo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.createsellbackorder",
			jsonStr: JSON.stringify(sellbackInfo)
		}, (res) => {
			let data = res.code == 200 && res.result.data;
			let sellbackTypeName = sellbackInfo.sellbackType == 1 ? "退款" : "退货";
			if (data) {
				Message.success(`${sellbackTypeName}申请创建成功`);
			} else {
				console.log("创建退款申请失败：", res);
				Message.error(res.msg || `${sellbackTypeName}申请创建失败`);
			}
			resolve(data);
		});
	});
}

/**
 * 获取已经申请了退款的商品列表
 */
const getRefundedGoodsList = (jackyunTradeNo) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.getrefundedgoodslistbyjackyuntradeno",
			jsonStr: JSON.stringify({
				jackyunTradeNo
			})
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data;
			} else {
				console.log("获取已经申请了退款的商品列表失败：", res);
			}
			resolve(data);
		});
	});
}

/**
 * 根据吉客云销售单号获取退款单状态
 */
const getRefundStatusByJackyunTradeNo = (jackyunTradeNoList) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.trade.getrefundstatusbyjackyuntradeno",
			jsonStr: JSON.stringify({
				jackyunTradeNoList
			})
		}, (res) => {
			let data = [];
			if (res.code == 200) {
				data = res.result.data || [];
			} else {
				console.log("获取退款单状态失败：", res);
			}
			resolve(data);
		});
	});
}

export default {
	createSellbackOrder,
	getRefundedGoodsList,
	getRefundStatusByJackyunTradeNo
}