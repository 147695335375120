import { constant } from '../../config.js'
import commonUtils from '../common-utlis.js'
import requestUtil from '../request-utils.js'
import { getTimelinesData, setTimelinesData } from './data-refresh.js'
import { getParam } from './api-params.js'

class Sys {
	sysTimestamp
	// constructor(){
	// 	this.getSysTime()
	// }
	async getSysTime(isRefresh = false) {
		let needRefreshJudge = getTimelinesData(constant.DATA_KEY_SYS_TIMESTAMP, true);
		// 如果需要获取最新数据，则不读取缓存
		if (!!this.sysTimestamp &&!!needRefreshJudge && !isRefresh) {
			// 如果数据存在则直接返回，否则调用接口获取最新数据
			return this.sysTimeStamp;
		}
		this.sysTimeStamp = await this.getSysTimeFromOrigion();
		
		setTimelinesData(constant.DATA_KEY_SYS_TIMESTAMP, this.sysTimeStamp, true);
		
		try{
			window.clearInterval(timeInterval)
		}catch(e){
			//TODO handle the exception
		} 
		
		let timeInterval = setInterval(() => {
			this.sysTimestamp += 1000
		}, 1000)
		
		// 返回系统时间
		return this.sysTimeStamp;
	}
	getSysTimeFromOrigion() {
		return new Promise((resolve, reject) => {
			requestUtil.jackyunPost({
				method: "sys.getsystimestamp",
			}, function(data) {
				if (data.code == 200) {
					resolve(data.msg)
				}else{
					console.log('系统时间戳获取失败：' + data.msg);
					return resolve(0);
				}
				
			})
		})
	}

}

var system = new Sys()

//可以被外部引用的方法
export {
	system
}