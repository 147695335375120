<!-- 我的地址列表 -->
<template>
	<personal-layout cur-menu="addresslist">
		<div class="flex-between">
			<div class="address-list-wrap">
				<div class="address-list-title">收货地址</div>
			</div>
			<div class="flex-row" style="margin-right: 10px;">
				<div style="margin-right: 10px;width:350px">
					<el-input clearable @clear="clearFn" placeholder="请输入姓名/电话/地址" v-model="addressSearchValue" @keyup.13.native="getAddressList">
						<el-button slot="append" icon="el-icon-search" class="danger-button" @click="getAddressList"></el-button>
					</el-input>
				</div>
				<el-button icon="export-button" plain @click="outExcel">
					导出
				</el-button>
				<el-button plain icon="el-icon-delete" @click="deleteAddressMulti">删除</el-button>
			</div>
		</div>
		<div style="margin: 0 10px;">
			<address-list v-loading="addressLoading" :addresslist="addressList" @success="editAddrSuccess" @refresh="getAddressList" @multiSelect="selectAddrIds" :trigger-select-event="false" :trigger-multi-select-event="true">
			</address-list>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="currentTotal">
			</el-pagination>
		</div>
	</personal-layout>
</template>

<script>
	import personalLayout from '../../components/mine/PersonalLayout.vue';
	import addressList from '../../components/trade/AddressList.vue';
	import exportUtils from '../../utils/api-utils/api-export.js';
	import addressUtils from '../../utils/api-utils/api-address.js';

	export default {
		name: "mine-address",
		components: {
			personalLayout,
			addressList
		},
		data() {
			return {
				addressList: [],
				selectAddressId: [],
				addressLoading: false,
				listHander: {
					name: '收货人',
					tel: "收货电话",
					address: "收货地址"
				},
				pageSize: 10, // 当前页大小
				currentPage: 1, // 当前index
				addressSearchValue: "", //搜索值
				currentTotal: 10,
			}
		},
		created() {
			//获取用户地址
			this.getAddressList();
		},
		mounted() {
			let customerId = localStorage.getItem("pc_customerId");
			if (!customerId) {
				this.$eventBus.$emit("showLoginDialog", true, false); //显示登录弹窗,不可关闭
			}
		},
		destroyed() {
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		methods: {
			handleSizeChange(val) {
				this.pageSize = val;
				this.getAddressList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getAddressList();
			},
			//获取用户地址
			async getAddressList() {
				// 获取用户地址列表
				this.addressList = await addressUtils.getAddressList(this.addressSearchValue, this.pageSize, this.currentPage - 1);
				
				// 获取用户地址列表 
				addressUtils.getAddressListCount(this.addressSearchValue).then((count) => {
					this.currentTotal = count;
				});

				// 关闭加载遮罩层
				this.addressLoading = false;
			},

			//编辑地址保存成功
			editAddrSuccess() {
				this.getAddressList();
			},

			// 导出excel
			outExcel() {
				let addressJson = this.addressList.map(item => {
					return {
						name: item.name,
						tel: item.tel,
						address: item.country + item.province + item.city + item.district + item.address
					}
				})
				exportUtils.xlsx(addressJson, this.listHander, '收货人信息')
			},
			// 获取选择的地址
			selectAddrIds(value) {
				this.selectAddressId = value;
			},
			// 清空
			clearFn() {
				this.addressSearchValue = "";
			},

			// 批量删除地址
			deleteAddressMulti() {
				let that = this;
				// 获取选取的地址
				let selectAddressId = this.selectAddressId;
				// 删除地址
				if (selectAddressId.length == 0) {
					that.$message.error({
						message: "请选择需要删除的地址"
					});
					return;
				} else {
					that.$confirm("确定删除选中的地址吗？", {
						confirmButtonText: '确定',
						cancelButtonText: '取消'
					}).then(() => {
						that.addressList = that.addressList.filter(item => !selectAddressId.includes(item.addressId));

						// 删除地址
						addressUtils.deleteAddress(selectAddressId.toString()).then((deleteRes) => {
							if (deleteRes) {
								this.$message.success("删除成功");
							} else {
								this.$message.error("删除失败");
							}
						});
					})

				}
			}
		}
	}
</script>

<style scoped="scoped">
	.address-list-title {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.address-list-wrap {
		padding: 30px;
		height: calc(100% - 60px);
	}

	/deep/.address-list {
		max-height: calc(100% - 50px);
	}

	.danger-button {
		color: #FFF !important;
		background-color: #F56C6C !important;
		border-color: #F56C6C !important;
		border-radius: 0;
	}
	
	.export-button {
		position: absolute;
		top: 0px;
		right: 0px;
		line-height: 8px;
	}
</style>