import md5 from './md5.js'
import aes from './aes.js'
import axios from 'axios'
import utils from './common-utlis.js'
const {
	config
} = require('../config.js')

// 签名密钥
const signKey = 'differcxztymylch'; //CECC95A6E3DDD6818E0E1713CF31466A

// 用户信息串
let memberEncryptStr = '';
// 吉客号
let memberName = '';
// 子商城账号
let subMemberName = '';

// 版本时间戳，用作前端强制刷新（当前版本：2022-11-24）  获取方式：new Date('2022-07-01').getTime()/1000
const versionTimestamp = 1691625600;

/**
 * 获取吉客号
 */
function getUserName() {
	// 解析url传参
	if (window.location.search) {
		memberEncryptStr = window.location.search.substr(1);
		let strCode = window.decodeURIComponent(window.atob(memberEncryptStr));
		let userInfoStr = strCode ? strCode.substring(strCode.indexOf("=") + 1) : '';

		// 用户信息串：吉客号_子账号
		if (userInfoStr.includes("_")) {
			let userInfoArr = userInfoStr.split("_");
			memberName = userInfoArr[0];
			subMemberName = userInfoArr[1];
		} else {
			memberName = userInfoStr;
			subMemberName = "";
		}
	}

	// 本地默认情况处理
	if (!memberName && window.location.hostname == "localhost") {
		memberName = '420001'; //集成环境默认吉客号
		subMemberName = '';
	}

	// 比较吉客号与缓存中是否相同，不同则清除缓存
	if (memberName != localStorage.getItem("JackyunUserName") || (localStorage.getItem("SubMemberName") && subMemberName != localStorage.getItem("SubMemberName"))) {
		localStorage.clear();
	}

	// 将吉客号信息记录到缓存
	localStorage.setItem("memberEncryptStr", memberEncryptStr);
	localStorage.setItem("JackyunUserName", memberName);
	localStorage.setItem("SubMemberName", subMemberName);

	return memberName;
}

/**
 * 获取时间戳
 */
function timestamp() {
	return parseInt(new Date().getTime() / 1000);
}

/**
 * 获取验证签名
 * @param {Object} data
 */
function sign(data) {
	// 将对象根据key进行升序排序 
	let arrKey = new Array();
	for (let key in data) {
		arrKey.push(key);
	}
	arrKey.sort();

	// 获取MD5加密前的字符串，格式:signKey+key+value+...+key+value+signKey，key升序大写
	let signStr = '';
	for (let i = 0; i < arrKey.length; i++) {
		// 数据为空或者null则不进行签名计算
		if (data[arrKey[i]]) {
			// 大写key+value
			signStr += arrKey[i].toUpperCase() + data[arrKey[i]];
		}
	}
	signStr = signKey + signStr + signKey;

	// MD5加密并返回结果，结果大写
	return md5.md5(signStr).toUpperCase();
}

/**
 * 转换表情为字符串
 */
function utf16toEntities(str) {
	var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则  
	str = str.replace(patt, function(char) {
		var H, L, code;
		if (char.length === 2) {
			H = char.charCodeAt(0); // 取出高位  
			L = char.charCodeAt(1); // 取出低位  
			code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法  
			return "&#" + code + ";";
		} else {
			return char;
		}
	});
	return str;
}

/**
 * 转换字符串为表情 
 */
function decodeHtmlEntity(str) {
	let nstr = str.replace(/&#(\d+);/g, function(match, dec) {
		return String.fromCharCode(dec);
	});
	return nstr;
}

/**
 * 完善请求数据
 * @param {Object} data
 */
function completeData(data) {
	// 特殊字符编码
	data = JSON.parse(utils.htmlEncode(JSON.stringify(data)));
	// 补充会员名
	data.user_name = memberName;
	data.account_name = subMemberName;
	// 补充时间戳
	data.timestamp = timestamp();
	// 接口数据格式
	data.format = 'json';
	//处理表情
	for (let key in data) {
		data[key] = utf16toEntities(data[key] + '');
		data[key] = encodeURIComponent(data[key]);
	}
	// 补充签名
	data.sign = sign(data);
	return data;
}

/**
 * 请求接口数据
 * @param {Object} data 请求数据
 * @param {Object} callback 回调方法
 */
function postData(data, callback) {
	// 版本时间戳，用作前端强制刷新（当前版本：2022-11-24）  获取方式：new Date('2022-07-01').getTime()/1000
	data.version_timestamp = versionTimestamp;

	// 完善请求数据
	data = completeData(data);

	// 请求接口
	axios.post(config.jmallApiUrl, data).then((response) => {
		// console.info(response);
		if (response.status == 200) {
			// 特殊符号解码
			response.data = JSON.parse(JSON.stringify(response.data));
			callback && callback(response.data)
		}
	}).catch((error) => {
		console.info(error);
	});
}

/**
 * 请求接口数据（同步请求）
 * @param {Object} data 请求数据
 */
const postDataSync = (data) => {
	return new Promise((resolve, reject) => {
		// 完善请求数据
		data = completeData(data);
		// 请求接口		
		axios.post(config.jmallApiUrl, data).then((response) => {
			if (response.status == 200) {
				// 特殊符号解码
				response.data = JSON.parse(utils.htmlDecode(JSON.stringify(response.data)));
				resolve(response.data)
			}
		}).catch((error) => {
			console.info(error);
		});
	})
}

/**
 * 请求吉客云接口数据
 * @param {Object} data 请求数据
 * @param {Object} callback 回调方法
 */
function jackyunPost(data, callback) {
	// 版本时间戳，用作前端强制刷新（当前版本：2022-11-24）  获取方式：new Date('2022-07-01').getTime()/1000
	// data.version_timestamp = 1714089600;
	//设置吉客云会员名
	data.target_member_name = memberName;
	data.sub_member_name = subMemberName;
	// 客户端名称
	data.client_name = "pc";
	// 用于验证版本的时间戳
	data.version_timestamp = versionTimestamp;
	// 用于验证版本的时间戳
	data.timestamp = timestamp();
	// //处理表情
	// for (let key in data) {
	// 	data[key] = utf16toEntities(data[key] + '');
	// 	data[key] = encodeURIComponent(data[key]);
	// }
	//生成内部验证串
	data.innerSign = getInnerSign(data);
	// 请求接口
	axios.post(config.jackyunApiUrl, data).then((response) => {
		// console.info(response);
		if (response.status == 200) {
			callback && callback(response.data)
		}

		try {
			// 签名异常导致的请求，打印错误日志
			if (response.data.code != 200 && response.data.msg.includes("签名")) {
				console.error(`签名验证失败（${data.method}）:`, data, response.data);
			}
		} catch (e) {}
	}).catch((error) => {
		console.info(error);
	});
}

/**
 * 获取内部验证串
 */
const getInnerSign = (data) => {
	// 将对象根据key进行升序排序
	let arrKey = new Array();
	for (let key in data) {
		arrKey.push(key);
	}
	arrKey.sort();

	// 获取MD5加密前的字符串，格式:signKey+key+value+...+key+value+signKey，key升序大写
	let signStr = '';
	for (let i = 0; i < arrKey.length; i++) {
		// 数据为空或者null则不进行签名计算（时间戳不参与计算，前端时间戳不准）
		if (arrKey[i] != "timestamp" && ![null, undefined, ""].includes(data[arrKey[i]])) {
			// 大写key+大写value（防止布尔值前后端大小写不一致情况）
			try {
				signStr += arrKey[i].toString().toUpperCase() + data[arrKey[i]].toString().toUpperCase();
			} catch (e) {
				console.log(data[arrKey[i]])
			}
		}
	}
	signStr = signKey + signStr + signKey;

	// MD5加密并返回结果，结果大写
	return md5.md5(signStr).toUpperCase();
}

/**
 * 获取配置表参数
 */
function getParam(strParamkeys, callback) {
	postData({
		method: "common.cfg.getparam",
		param_key: strParamkeys
	}, (data) => {
		if (data.error_code == "0") {
			if (data.error_info) {
				let paramObj = JSON.parse(data.error_info.replace(/\\\n/g, '\\n').replace(/\\\r/g, '\\r'));
				callback && callback(paramObj);
			} else {
				callback && callback(null);
			}
		} else {
			callback && callback(null);
		}
	});
}

/**
 * 通用数据请求接口
 * @param {Object} url 请求地址
 * @param {Object} data 请求数据
 * @param {Object} callback 回调方法
 */
function commonPost(url, data, callback) {
	data.memberName = memberName;
	data.accountName = subMemberName;

	// 请求接口
	axios.post(url, data).then((res) => {
		callback && callback(res);
	}).catch((error) => {
		console.info(error);
	});
}

export default {
	postData,
	postDataSync,
	jackyunPost,
	getParam,
	commonPost,
	getUserName,
}