// 公共请求
import request from './request-utils.js'
import utils from './common-utlis.js'
import md5 from './md5.js'
import { getChannelInfo } from './api-utils/api-channel.js';

/**
 * 获取用户预存款余额
 * @param {Object} customerId
 * @param {Object} channelId
 */
function getCustomerBalance(customerId, channelId) {
	// 未传递则默认获取缓存中的数据
	customerId = customerId || localStorage.getItem("pc_customerId") || "";
	channelId = channelId || localStorage.getItem("pc_channelId") || "";

	return new Promise(async (resolve, reject) => {
		// 参数不正确则不请求接口
		if (!customerId || !channelId) {
			resolve(0);
			return;
		}
		// 获取渠道详情
		let channelInfo = await getChannelInfo(channelId);
		if (!channelInfo) {
			resolve(0);
			return;
		}

		// 缓存中没有信息，则调用接口获取最新数据
		request.jackyunPost({
			method: 'customer.realbalancenew',
			customerId,
			channelId,
			companyIds: channelInfo.companyId
		}, (res => {
			resolve(res.data || 0);
		}));
	});
}




export default {
	getCustomerBalance,
}