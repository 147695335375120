import { constant } from '../../config.js'
import commonUtli from '../common-utlis.js'

/**
 * 获取时效性数据（存在且在有效期内）
 * @param {*} key 
 * @param {boolean} isString 存储类型是否是字符串
 */
const getTimelinesData = (key, isString = false) => {
	// 获取当前数据和刷新时间差
	let val = isString ? localStorage.getItem(key) : JSON.parse(localStorage.getItem(key) || "{}");
	let refreshTimeObj = JSON.parse(localStorage.getItem("refreshTimeObj") || "{}")
	let refreshTime = refreshTimeObj[key] || 0;
	let differSecond = parseInt((new Date().getTime() - refreshTime) / 1000);

	// 检测数据是否存在，不存在则直接返回null
	if (commonUtli.isNullOrEmpty(val)) {
		return null;
	}

	// 检测时间是否到期
	switch (key) {
		// 系统时间戳
		case constant.DATA_KEY_SYS_TIMESTAMP:
			if (differSecond > constant.REFRESH_SECOND_SYS_TIMESTAMP) {
				return null;
			}
			break;
			// 系统配置
		case constant.DATA_KEY_SYS_SETTING:
			if (differSecond > constant.REFRESH_SECOND_SYS_SETTING) {
				return null;
			}
			break;
			// 商城账号信息
		case constant.DATA_KEY_MALL_ACCOUNT_INFO:
			if (differSecond > constant.REFRESH_SECOND_MALL_ACCOUNT_INFO) {
				return null;
			}
			break;
			// 用户配置
		case constant.DATA_KEY_USER_SETTING:
			if (differSecond > constant.REFRESH_SECOND_USER_SETTING) {
				return null;
			}
			break;
			// 客户档案
		case constant.DATA_KEY_CUSTOMER:
			if (differSecond > constant.REFRESH_SECOND_CUSTOMER) {
				return null;
			}
			break;
			// openId
		case constant.DATA_KEY_OPENID:
			if (differSecond > constant.REFRESH_SECOND_OPENID) {
				return null;
			}
			break;
			// 手机号对应的所有客户档案ID
		case constant.DATA_KEY_ALL_CUSTOMER_ID:
			if (differSecond > constant.REFRESH_SECOND_ALL_CUSTOMER_ID) {
				return null;
			}
			break;
		case constant.DATA_KEY_CART_CACHE:
			if (differSecond > constant.REFRESH_SECOND_CART_CACHE) {
				return null;
			}
			break;
	}

	return val;
}

/**
 * 设置时效性数据
 * @param {*} key 
 * @param {*} val 
 */
const setTimelinesData = (key, val, isString = false) => {
	// 获取上一次刷新时间
	let refreshTimeObj = JSON.parse(localStorage.getItem("refreshTimeObj") || "{}");
	let refreshTime = refreshTimeObj[key] || 0;

	// 数据存在或者初次获取，将数据保存起来
	if (!commonUtli.isNullOrEmpty(val) || !refreshTime || refreshTime <= 0) {
		// 保存当前数据和刷新时间		
		refreshTimeObj[key] = new Date().getTime();
		localStorage.setItem(key, isString ? val : JSON.stringify(val));
		localStorage.setItem("refreshTimeObj", JSON.stringify(refreshTimeObj));
	}
}

/**
 * 更新时效性数据 (供getParam使用)
 * @param {*} key 
 * @param {*} refreshSecondKey 
 * @param {*} newVal 
 */
const updateTimelinesData = (key, refreshSecondKey, newVal) => {
	// 差量更新缓存中的数据
	let oldVal = JSON.parse(localStorage.getItem(key) || "{}");
	Object.assign(oldVal, newVal)
	// 获取上一次刷新时间
	let refreshTimeObj = JSON.parse(localStorage.getItem("refreshTimeObj") || "{}");
	let refreshTime = refreshTimeObj[key] || 0;

	// 数据存在或者初次获取，将数据保存起来
	if (!commonUtli.isNullOrEmpty(oldVal) || refreshTime <= 0) {
		// 保存当前数据和刷新时间		
		let differSecond = parseInt((new Date().getTime() - refreshTime) / 1000);
		refreshTimeObj[key] = differSecond < constant[refreshSecondKey] ? refreshTimeObj[key] : new Date().getTime();
		localStorage.setItem(key, JSON.stringify(oldVal));
		localStorage.setItem("refreshTimeObj", JSON.stringify(refreshTimeObj));
	}
	// 返回更新后的数据
	return oldVal
}

//可以被外部引用的方法
export {
	getTimelinesData,
	setTimelinesData,
	updateTimelinesData
}
