<template>
	<div class="flex-row goods-cate" @mouseleave="closeGoodsCate">
		<!--父分类列表-->
		<div class="parent-cate-list flex-col">
			<div class="parent-cate flex-row" :class="{selected : selectedPCateId == 0, hovered:hoverPId == 0}" :data-index="-1" data-cateid="0" @mouseover="mouseOverPCate" @click="selectPCategory">
				<img class="cate-img" :src="$utils.setImg(allCateImg)" onerror="javascript:this.src='https://jmall.jackyun.com/jmc/Images/MobileUse/default.png';" />
				<span class="cate-name text-center">全部</span>
			</div>
			<div class="parent-cate flex-row" v-for="(item,index) in categoryList" :class="{selected : selectfCateId== item.categoryId || selectedPCateId == item.categoryId || selectedCateId == item.categoryId , hovered:hoverPId == item.categoryId}" :data-index="index" :data-cateid="item.categoryId" @mouseover="mouseOverPCate" @click="selectPCategory">
				<img class="cate-img" :src="$utils.setImg(item.picUrl)" onerror="javascript:this.src='https://jmall.jackyun.com/jmc/Images/MobileUse/default.png';" />
				<span class="cate-name text-center">{{item.categoryName}}</span>
			</div>

		</div>
		<div v-if="subCategoryList.length > 0">
			<div class="son-cate-list" v-if="!isThreeCate">
				<!--子分类列表-->
				<div v-for="(subItem,subIndex) in subCategoryList">
					<div class="son-cate" :class="{selected:selectedCateId==subItem.categoryId || selectedPCateId ==subItem.categoryId,hovered:hoverSonCateId==subItem.categoryId}" :data-pid="currentCateId" :data-subindex="subIndex" :data-cateid="subItem.categoryId" :data-catename="subItem.categoryName" @mouseover="mouseOverSCate" @click="selectCategory">
						<div class="cate-name text-right" :title="subItem.categoryName">{{subItem.categoryName}}</div>
						<div class="arrow-right"></div>
					</div>
				</div>
			</div>
			<div class="three-cate-list" v-else>
				<!--子分类列表-->
				<div v-for="(subItem,subIndex) in subCategoryList" class="flex-row">
					<div class="son-cate" :class="{selected:selectedCateId==subItem.categoryId || selectedPCateId ==subItem.categoryId,hovered:hoverSonCateId==subItem.categoryId}" :data-pid="currentCateId" :data-subindex="subIndex" :data-cateid="subItem.categoryId" :data-catename="subItem.categoryName" @mouseover="mouseOverSCate" @click="selectCategory">
						<div class="cate-name text-right" :title="subItem.categoryName">{{subItem.categoryName}}</div>
						<div class="arrow-right"></div>
					</div>
					<div class="flex-wrap three-cate" v-for="(item,index) in subItem.categoryList" :data-pid="subItem.categoryId" :data-subindex="index" :data-cateid="item.categoryId" :data-catename="item.categoryName" :class="{selected:selectedCateId==item.categoryId,hovered:hoverThreeCateId==item.categoryId}" v-on:mouseover="mouseOverthreeCate" @click="selectCategory">
						<div class="three-cate-text" :title="item.categoryName">{{item.categoryName}}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import { getCustomerInfo } from "../../utils/api-utils/api-customer.js"
	export default {
		name: 'goods-cate',
		props: {
			pCateId: {
				type: Number,
				default: 0
			}, //父组件传入选中的父分类Id
			cateId: {
				type: Number,
				default: 0
			}, //父组件传入选中的分类Id
			fCateId: {
				type: Number,
				default: 0
			} // 父组件传入的选中的一级分类Id
		},
		data() {
			return {
				allCateImg: "", //全部分类的图标
				categoryList: [],
				subCategoryList: [],
				selectedPCateId: this.pCateId, //选中的父分类Id
				selectedCateId: this.cateId, //选中的分类Id
				selectfCateId: this.fCateId, // 选中的一级分类Id
				hoverPId: -1, //当前鼠标停留的父分类Id
				hoverSonCateId: 0, //当前鼠标停留的子分类Id
				isThreeCate: false, // 是否为三级分类
				hoverThreeCateId: 0, // 当前鼠标停留的三级分类
				currentCateId: 0, // 当前选中的一级id
			}
		},
		watch: {
			hoverPId(newValue, oldValue) {
				if (newValue == -1) {
					return;
				}
				let cateInfo = this.categoryList.find(item => item.categoryId == newValue);
				if (cateInfo) {
					this.subCategoryList = cateInfo.categoryList || [];
					this.isThreeCate = cateInfo.isThreeCate;
					this.currentCateId = cateInfo.categoryId;
				}
			},
		},
		created: function() {
			//获取商品分类
			this.getCategoryList();
		},
		methods: {
			mouseOverthreeCate(e) {
				var sCateId = e.currentTarget.dataset.cateid;
				var pCateId = e.currentTarget.dataset.pid;
				this.hoverSonCateId = parseInt(pCateId);
				this.hoverThreeCateId = parseInt(sCateId);
			},
			//获取商品分类列表
			async getCategoryList() {
				// 获取缓存中信息
				let customerInfo = await getCustomerInfo();
				let viplevel = customerInfo && customerInfo.vipLevel ? customerInfo.vipLevel : "";
				this.$request.jackyunPost({
					method: "jmall.goods.getgoodscategorylistv2",
					memberGrade: viplevel
				}, (res) => {
					if (res.code == 200) {
						let cateList = res.result.data;
						if (cateList[0].categoryName == "全部分类") {
							this.allCateImg = cateList[0].picUrl;
							cateList = cateList.splice(1, cateList.length - 1);
						}
						cateList.forEach(item => {
							if (item.categoryList && item.categoryList.length > 0) {
								item.isThreeCate = !!item.categoryList.find(f => f.categoryList.length >
									0);
							}
						})
						if (this.fCateId > 0) {
							var cateInfo = cateList.find(item => item.categoryId == this.fCateId);
							this.isThreeCate = cateInfo.isThreeCate;
							this.subCategoryList = cateInfo.categoryList;
						}

						this.categoryList = cateList;
					}
				})
			},
			//鼠标移到父分类，展示子分类
			mouseOverPCate(e) {
				var pId = e.currentTarget.dataset.cateid;
				this.hoverPId = parseInt(pId);
				this.hoverSonCateId = 0;
				this.hoverThreeCateId = 0;
				this.selectedCateId = 0;
				this.selectedPCateId = -1;
				this.selectfCateId = -1;
			},
			//鼠标移出分类区域，重置hoverPId，关闭分类
			closeGoodsCate() {
				this.hoverPId = -1;
				this.hoverSonCateId = 0;
				this.$emit('close-goods-cate');
			},
			//鼠标移到子分类
			mouseOverSCate(e) {
				var sCateId = e.currentTarget.dataset.cateid;
				this.hoverSonCateId = parseInt(sCateId);
				this.hoverThreeCateId = 0;
			},
			//选中父分类
			selectPCategory(e) {
				var index = parseInt(e.currentTarget.dataset.index);
				var cateId = parseInt(e.currentTarget.dataset.cateid);
				var cateName = "全部";
				if (index != -1) {
					cateName = this.categoryList[index].categoryName;
				}
				this.selectedCateId = cateId;
				this.selectedPCateId = cateId;
				this.$emit('select-cate', {
					cateId: cateId,
					pCateId: cateId,
					cateName: cateName
				});
			},
			//选中分类
			selectCategory(e) {
				var pCateId = parseInt(e.currentTarget.dataset.pid);
				var cateId = parseInt(e.currentTarget.dataset.cateid);
				var cateName = e.currentTarget.dataset.catename;
				this.selectedCateId = cateId;
				this.selectedPCateId = pCateId;
				this.$emit('select-cate', {
					cateId: cateId,
					pCateId: pCateId,
					cateName: cateName,
					fcate_id: this.currentCateId
				});
			}
		}
	}
</script>

<style scoped="scoped">
	/**
	 * 三级分类弹窗宽度
	 */
	.three-cate-list {
		width: 750px;
		padding: 10px 20px;
		max-height: 50vh;
		overflow-y: auto;
	}

	.three-cate-text {
		font-size: 14px;
		margin: 14px 14px 0 14px;
	}

	.son-cate .cate-name {
		font-weight: 800;
	}

	.goods-cate {
		position: absolute;
		border: solid 1px #ccc;
		z-index: 999;
		background-color: #fff;
		line-height: 20px;
		top: 70px;
		border-radius: 6px;
	}

	.goods-cate:before {
		content: "";
		position: absolute;
		border-style: solid;
		border-width: 0 10px 10px;
		border-color: #fff transparent;
		/* 		border: 1px solid #ccc; */
		display: block;
		width: 0;
		z-index: 1;
		top: -10px;
		left: 30%;
		margin-left: -10px;
	}

	.arrow-right {
		width: 8px;
		height: 8px;
		border-top: 2px solid #999;
		border-right: 2px solid #999;
		transform: rotate(45deg);
	}

	.flex-row {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
	}



	.goods-cate:after {
		content: "";
		position: absolute;
		border-style: solid;
		border-width: 0 10px 10px;
		border-color: #000 transparent;
		display: block;
		width: 0;
		z-index: 0;
		top: -10px;
		left: 30%;
		margin-left: -10px;
	}

	.parent-cate-list {
		width: 160px;
		border-right: solid 1px #ccc;
		padding: 10px;
		max-height: 50vh;
		overflow-y: auto;
	}

	.parent-cate {
		margin-top: 16px;
		margin-left: 16px;
		margin-bottom: 4px;
		align-items: center;
		cursor: pointer;
		font-size: 15px;
		white-space: nowrap;
		overflow: hidden;
		min-height: 28px;
		text-overflow: ellipsis;
	}

	.parent-cate.selected,
	.parent-cate.hovered {
		color: #f56c6b;
	}

	.son-cate-list {
		width: 120px;
		padding: 10px 10px;
		max-height: 50vh;
		overflow-y: auto;
	}

	.son-cate-list>div {
		flex-wrap: wrap;
	}

	.son-cate {
		height: 50px;
		align-items: center;
		cursor: pointer;
		font-size: 14px;
		display: flex;
	}

	.son-cate.selected,
	.son-cate.hovered {
		color: #f56c6b;
	}

	.three-cate.selected,
	.three-cate.hovered {
		color: #f56c6b;
	}

	.cate-img {
		width: 30px;
		height: 30px;
		margin-right: 15px;
	}

	.cate-name {
		width: 120px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 6px;
	}

	.text-center {
		text-align: center;
	}

	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}
</style>