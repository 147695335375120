<!-- 登录弹窗 -->
<template>
	<div>
		<!-- 完善用户名密码信息弹窗 -->
		<el-dialog width="400px" :modal-append-to-body='false' :show-close='false' :close-on-click-modal='false' :visible.sync="isShow">
			<el-form class="login-box">
				<div class="login-title">{{oldName != '' ? '重置密码' : '完善信息'}}</div>
				<el-form-item class="login-item">
					<el-input v-model="setLoginName" placeholder="请输入用户名" :disabled="oldName != ''"></el-input>
				</el-form-item>
				<el-form-item class="login-item">
					<el-input v-model="setLoginPwd" minlength="6" placeholder="请输入密码" show-password></el-input>
				</el-form-item>
				<el-form-item class="login-item">
					<el-input v-model="resetLoginPwd" minlength="6" placeholder="请输入确认密码" show-password></el-input>
				</el-form-item>
				<el-form-item class="login-item" style="margin-top: 60px;">
					<el-button class="btn-login" type="danger" @click="setLoginNamePwd" :loading="confirmClicked">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import utils from '../../utils/common-utlis.js'
	import { getCustomerInfo, updateCustomerNameAndPwd } from '../../utils/api-utils/api-customer.js';
	export default {
		name: 'name-pwd-dialog',
		props: {
			isShow: {
				type: Boolean,
				default: false
			},
			oldName: {
				type: String,
				default: ''
			},
			tel: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				showCompleteInfo: false, //完善信息弹窗（用户名密码）
				confirmClicked: false, //完善信息按钮已点击
				setLoginName: "", //登录名
				setLoginPwd: "", //登录密码
				resetLoginPwd: "", //登录密码
				phone: ''
			}
		},
		mounted() {},
		watch: {
			oldName() {
				this.setLoginName = this.oldName;
			},
			tel() {
				this.phone = this.tel;
			}
		},
		methods: {
			/**
			 * 完善信息
			 */
			async setLoginNamePwd() {
				let _this = this;

				//关闭所有警告
				_this.$message.closeAll();

				// 检测用户名是否输入
				if (!_this.setLoginName) {
					return _this.$message.error('请输入用户名');
				}

				// 检测密码是否输入
				if (!_this.setLoginPwd) {
					return _this.$message.error('请输入密码');
				}
				if (_this.setLoginPwd.length < 6) {
					return _this.$message.error('请至少输入6位数密码');
				}

				// 检测密码是否输入
				if (!_this.resetLoginPwd) {
					return _this.$message.error('请输入确认密码');
				}

				// 检测密码是否相同
				if (_this.resetLoginPwd != _this.setLoginPwd) {
					return _this.$message.error('两次密码不相同');
				}

				// 更新用户用户名密码
				_this.confirmClicked = true;
				let customerInfo = await getCustomerInfo();
				let result;
				if (!!customerInfo.customerId) {
					result = await updateCustomerNameAndPwd({ customerId: customerInfo.customerId, loginName: _this.setLoginName, loginPwd: btoa(encodeURIComponent(_this.setLoginPwd))});
					_this.confirmClicked = false;
					if (result !== true) {
						return _this.$message.error(result);
					}
				}

				// 保存成功
				this.$emit('success');
			},
		}
	}
</script>

<style scoped="scoped">
	.login-box {
		padding: 0 50px 30px 50px;
	}

	.login-title {
		text-align: center;
		font-size: 26px;
		margin-bottom: 50px;
	}

	.login-item {
		margin-bottom: 20px;
	}

	.input-vcode {
		width: 138px;
	}

	.btn-vcode {
		margin-left: 10px;
		width: 112px;
	}

	.btn-login {
		width: 100%;
	}

	.btn-change-type {
		border: 1px solid #f56c6c;
		color: #f56c6c;
	}
</style>