<template>
	<div class="i-content flex-row">
		<input class="i-input" v-model="currentValue" @input="handleInput"></input>
		<div class="flex-col">
			<span class="i-span" @click="increase"> <i class="el-icon-plus"></i></span>
			<span class="i-span" @click="decrease"> <i class="el-icon-minus"></i></span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'input-number',
		props: {
			max: {
				type: Number,
				default: Infinity
			},
			min: {
				type: Number,
				default: -Infinity
			},
			step: {
				type: Number,
				default: 1
			},
			curval: {
				type: Number,
				default: 0
			}
		},
		watch: {
			//监听父组件传值
			curval(newValue, oldValue) {
				this.currentValue = newValue;
			},
			step(newValue, oldValue) {
				this.step = newValue;
			}
		},
		data() {
			return {
				currentValue: this.curval
			}
		},
		methods: {
			increase() {
				let newVal = this.currentValue + this.step;
				this.setCurrentValue(newVal);
			},
			decrease() {
				let newVal = this.currentValue - this.step;
				this.setCurrentValue(newVal);
			},
			handleInput(e) {
				let value = e.target.value;
				let newVal = value.replace(/[^\d]/g, "");
				if (!newVal) {
					this.currentValue = this.min;
				} else if ((newVal - this.min) % this.step > 0) {
					this.currentValue = this.min;
					this.$message.error("购买数量必须为单次购买量" + this.step + "的倍数加最小起购量")
				} else {
					this.setCurrentValue(newVal);
				}
			},
			setCurrentValue(newVal) {
				newVal = parseInt(newVal);
				if (newVal >= this.max) newVal = this.max;
				if (newVal <= this.min) newVal = this.min;
				this.currentValue = newVal;
				this.$emit('change', newVal);
			}
		}
	}
</script>

<style scoped="scoped">
	.i-content {
		align-items: center;
		width: 87px;
		height: 30px;
		border: 1px solid #DCDFE6;
		border-radius: 6px;
		margin: 9px 20px 9px 20px;
	}

	/* 	.i-span {
		margin-left: 9px;
		font-size: 8px;
		border: 1px solid #DCDFE6;
	} */

	.i-span {
		font-size: 12px;
		border: 1px solid #dcdfe6;
		padding: 2px;
		cursor: pointer;
		margin: 1px 0 1px 9px;
		line-height: 12px;
		text-align: center;
		height: 12px;
		width: 12px;
		border-radius: 2px;
	}

	.i-input {
		height: 26px;
		width: 81px;
		border: none;
		text-align: center;
		font-size: 15px;
	}

	.i-input:focus {
		outline: none;
	}
</style>
