/**
 * 规格选择组件，只展示属性规格，无图片展示，数量选择等
 */
<template>
	<div>
		<!-- <div>规格选择</div> -->
		<div v-if="classType == 'new' && noAttrSpecList.length>0 && (isMulti || !isMulti && showSingleSpec)" class="attr-div flex-row">
			<div class='attr-name spec-txt ' >规格</div>
			<div class='attr-flex '>
				<div v-for="(item, attrIndex) in noAttrSpecList" :key="attrIndex" class='attr-box' v-bind:class='{attrselected: selectedSpecID == item.specId, attrdisabled: noAttrSpecDisabled}' :data-name='item.specName'
				 :data-index='attrIndex' :data-specid='item.specId' @click='specTap'>
					<div class='attr-nametext' :title="item.specName && item.specName.length > 20 ? item.specName : '' ">{{item.specName}}</div>
				</div>
			</div>			
		</div>
		<div class="attr-div"  v-else-if="noAttrSpecList.length>0 && (isMulti || !isMulti && showSingleSpec)">
			<div class='attr-name'>规格</div>
			<div class='attr-flex '>
				<div v-for="(item, attrIndex) in noAttrSpecList" :key="attrIndex" class='attr-box' v-bind:class='{attrselected: selectedSpecID == item.specId, attrdisabled: noAttrSpecDisabled}' :data-name='item.specName'
				 :data-index='attrIndex' :data-specid='item.specId' @click='specTap'>
					<div class='attr-nametext' :title="item.specName && item.specName.length > 20 ? item.specName : '' ">{{item.specName}}</div>
				</div>
			</div>			
		</div>
		<div v-for="(item, attrIndex) in attrList" :key="attrIndex">
			<div class='attr-name'>{{item.attrName}}</div>
			<div class='attr-flex '>
				<div v-for="(subItem, skuIndex) in item.attrValue" :key="skuIndex">
					<div class='attr-box' v-bind:class='{attrselected: skuIndex == item.selected, attrdisabled: subItem.disabled}' :data-name='subItem.value'
					 :data-attrindex='attrIndex' :data-skuindex='skuIndex' @click='attrTap'>
						<el-image v-if="item.attrName=='颜色' && colorImgMap.get(subItem.value)" class="color-img" fit='scale-down' :title='subItem.value' :alt='subItem.value' :src="$utils.setImg(colorImgMap.get(subItem.value))"></el-image>
						<div v-else class='attr-nametext' :title="subItem.value && subItem.value.length > 20 ? subItem.value : ''">{{subItem.value}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			goodsSpec: Array,
			selectedID: String,
			showSingleSpec:{
				type:Boolean,
				default:true
			},
			isMulti:{
				type:Boolean,
				default:true
			},
			// 是否为套餐商品
			isPackageGood: {
				type: Boolean,
				default: false
			},
			goodsSelectionActionType:{
				type:String,
				default:"normal"
			},
			classType:{
				type:String,
				default:""
			}
		},
		watch:{
			goodsSpec(newValue, oldValue){
				this.specList = newValue;
				this.noAttrSpecDisabled = false;
				this.initData();
			}
		},
		data() {
			return {
				specList:[], //父组件传递的数据
				attrList: [], // 属性集合，用于前端选项展示
				noAttrSpecList: [], // 没有属性的自定义规格列表
				noAttrSpecDisabled: false, // 没有属性的自定义规格是否可选
				selectedSpecID: 0, // 选中的自定义规格ID
				colorImgMap: new Map(), // 颜色图片键值Map
				specAttrList: [],
			};
		},
		created() {			
			this.initData();
		},
		methods: {
			initData(){
				var specList = this.specList;
				var attrList = []; //属性集合
				var objAttrMap = new Map(); //属性键值Map
				var specAttrList = []; // 当前商品规格的属性信息 mapSpecAttr 的集合，使用map是为了防止出现某个属性没有值的规格 [{"颜色":"红","尺寸":"S"}]
				var specAttrCodeList = []; // 当前商品规格的属性信息索引 [[0,0],[0,1]]
				var noAttrSpecList = []; // 没有属性的自定义规格列表
				var selectedID = this.selectedID; // 父页面指定选中规格ID
				var selectedAttrMap = new Map(); // 父页面指定选中规格属性键值对
				var colorSet = new Set(), colorMap = new Map(); // 颜色值
				// 单规格默认选中，多规格拆分属性
				if (specList.length == 1) {
					//只有一个规格，不分属性，默认选中，并返回父页面
					noAttrSpecList.push(specList[0]);
					this.selectedSpecID = specList[0].specId;
					this.$emit('selectSpec', {
						specIndex: 0,
						error: ""
					})
				} else if (specList.length > 1) {
				//} else if (specList.length > 1 && (this.goodsSelectionActionType != "normal" || this.isPackageGood)) {
					// 遍历所有规格，获取属性名及属性值
					for (var index = 0; index < specList.length; index++) {
						var specItem = specList[index];
						var mapSpecAttr = new Map(); // 当前规格属性信息 {"颜色":"红","尺寸":"S"}
						if ((specItem.specAttr && specItem.specAttr != "{}")|| specItem.detailSpecStr) {
							// 有规格属性的规格，获取属性名及属性值
							var jsonAttr
							if (specItem.detailSpecStr) {
								jsonAttr = JSON.parse(specItem.detailSpecStr).specAttr; // 规格的属性列表
							} else {
								jsonAttr = JSON.parse(specItem.specAttr); // 规格的属性列表
							}
										  
							for (let attrKey in jsonAttr) {
								var attrValue = jsonAttr[attrKey];
								mapSpecAttr.set(attrKey, attrValue);
								// 过滤重复属性名
								if (!objAttrMap.has(attrKey)) {
									objAttrMap.set(attrKey, attrValue); // 新增属性名
								} else if ((',' + objAttrMap.get(attrKey) + ',').indexOf((',' + attrValue + ',')) == -1) {
									var newValue = objAttrMap.get(attrKey) ? objAttrMap.get(attrKey) + "," + attrValue : attrValue;
									objAttrMap.set(attrKey, newValue); // 没有该属性值，则新增属性值
								}
								// 颜色图片键值对
								if (attrKey == "颜色" && !colorSet.has(attrValue)) {
									colorSet.add(attrValue);
									colorMap.set(attrValue, specItem.colorPic);
								}
							}
							mapSpecAttr.set("specIndex", index); // 当前规格在全部规格列表中的索引
							specAttrList.push(mapSpecAttr);
				
							// 父页面指定选中某个自定义规格
							if (selectedID && selectedID > 0 && selectedID == specItem.specId) {
								selectedAttrMap = mapSpecAttr;
							}
						} else {
							// 无规格属性的自定义规格
							noAttrSpecList.push(specItem);
							// 父页面指定选中某个自定义规格
							if (selectedID && selectedID > 0 && selectedID == specItem.specId) {
								this.selectedSpecID = selectedID;
							}
						}
					}
				
					// 组装属性名称及选项，用户前端展示
					for (let item of objAttrMap) {
						let name = item[0];
						let attrValue = [];
						let arrValue = item[1].split(',');
						for (let value of arrValue) {
							attrValue.push({
								value: value,
								disabled: false
							});
						}
						var attrItem = {
							attrName: name,
							attrValue: attrValue,
							selected: -1
						};
						attrList.push(attrItem);
					}
				
					// 已有规格对应的属性索引 列表
					for (let specItem of specAttrList) {
						var attrCode = [];
						attrList.forEach(function(attr, i) {
							if (specItem.has(attr.attrName)) {
								var find = false;
								for (var j = 0; j < attr.attrValue.length; j++) {
									if (specItem.get(attr.attrName) == attr.attrValue[j].value) {
										attrCode.push(j);
										find = true;
										break;
									}
								}
								if (!find) {
									attrCode.push(-1);
								}
							} else {
								attrCode.push(-1);
							}
						})
						specAttrCodeList.push(attrCode);
					}
				}
				
				this.attrList = attrList;
				this.specAttrList = specAttrList; // 所有规格属性信息 列表
				this.specAttrCodeList = specAttrCodeList; // 所有规格属性信息 索引列表
				this.noAttrSpecList = noAttrSpecList; // 没有属性自定义的规格列表
				this.colorImgMap = colorMap; // 颜色图片键值对
				
				// 如果有传入选中规格，属性selected
				if (selectedAttrMap.size > 0) {
					this.setAttrSelected(selectedAttrMap);
				}
			},
			// 父页面传入的选中规格，选中属性 {0: {"尺寸" => "M"}
			setAttrSelected: function(selectedAttrMap, attrList) {
				var attrList = this.attrList;
				var attrIndexList = []; // 规格属性选择列表 [0,0,-1]
				var selectedAttrLength = 0; // 已选属性的个数
				// 遍历所有属性，选中当前规格属性
				for (let attr of attrList) {
					var attrName = attr.attrName;
					if (selectedAttrMap.has(attrName)) {
						for (var valueIndex = 0; valueIndex < attr.attrValue.length; valueIndex++) {
							var attrValue = attr.attrValue[valueIndex].value;
							if (selectedAttrMap.get(attrName) == attrValue) {
								attr.selected = valueIndex; // 选中此属性选项
								selectedAttrLength++;
								break;
							}
						}
					}
					attrIndexList.push(attr.selected);
				}

				// 有属性选择时，判断剩余属性是否可选，不可选则置灰，否则保持
				if (selectedAttrLength > 0) {
					this.setAttrCanChooseOrNot(attrIndexList, selectedAttrLength);
				}

				this.attrList = attrList;
			},

			// 自定义规格 点击
			specTap: function(e) {	
				if (!this.noAttrSpecDisabled) {
					let specId = e.currentTarget.dataset.specid; // 当前选中值
					if (this.selectedSpecID == specId) {
						// 取消选择，属性全部置为可选
						this.selectedSpecID = 0;
						this.setSpecAttrDisabled(false);
						// 数据返回父页面
						this.$emit('selectSpec', {
							specIndex: -1,
							error: "请选择规格"
						})
					} else {
						// 选择自定义规格，属性全部置为不可选
						this.selectedSpecID = specId;
						this.setSpecAttrDisabled(true);
						// 将选择的规格信息返回父页面
						this.returnSelectedSpec(specId);
					}
				}
			},

			// 规格属性设置是否可选
			setSpecAttrDisabled: function(disabled) {
				var attrList = this.attrList;
				attrList.forEach(function(attr, index) {
					for (var j = 0; j < attr.attrValue.length; j++) {
						attr.attrValue[j].disabled = disabled;
					}
				})
				this.attrList = attrList;
			},

			// 将选择的规格信息返回父页面
			returnSelectedSpec: function(specId) {
				// 找到当前选中的规格索引
				for (var i = 0; i < this.specList.length; i++) {
					if (this.specList[i].specId == specId) {
						this.$emit('selectSpec', {
							specIndex: i,
							error: ""
						})
						break;
					}
				}
			},

			// 规格属性 点击
			attrTap: function(e) {
				var attrList = this.attrList; // 规格属性集合
				let attrindex = e.currentTarget.dataset.attrindex; // 当前点击的属性索引
				let skuindex = e.currentTarget.dataset.skuindex; // 当前点击的属性值索引
				let disabled = attrList[attrindex].attrValue[skuindex].disabled; //判断是否可选
				if (!disabled) {
					// 选中或取消选中当前点击选项
					attrList[attrindex].selected = (attrList[attrindex].selected == skuindex) ? -1 : skuindex;

					// 获取所有已选属性值，并将所有规格属性选项置为可选
					var attrList = this.attrList;
					var attrIndexList = []; // 规格属性选择列表 [0,0,-1]
					var selectedAttrLength = 0; // 已选属性的个数
					attrList.forEach(function(attr, index) {
						// 所有规格属性置为可选
						for (var j = 0; j < attr.attrValue.length; j++) {
							attr.attrValue[j].disabled = false;
						}
						if (attr.selected != -1) {
							selectedAttrLength++;
						}
						attrIndexList.push(attr.selected);
					})

					// 判断自定义规格是否可选
					this.setNoAttrSpecDisabled(selectedAttrLength > 0);

					// 有属性选择时，判断剩余属性是否可选，不可选则置灰，否则保持
					if (selectedAttrLength > 0) {
						this.setAttrCanChooseOrNot(attrIndexList, selectedAttrLength);
					}

					this.attrList = attrList;

					// 返回当前规格选择
					this.returnSelectedAttrSpec(selectedAttrLength);
				}
			},

			// 自定义规格设置是否可选
			setNoAttrSpecDisabled: function(disabled) {
				this.noAttrSpecDisabled = disabled;
			},

			// 有属性选择时，判断剩余属性是否可选
			setAttrCanChooseOrNot: function(attrIndexList, selectedAttrLength) {
				var attrList = this.attrList;
				for (var index = 0; index < attrList.length; index++) {
					var attr = attrList[index];
					if (attr.selected == -1) {
						// 未选择的属性，所有属性值与所有已选择的属性匹配，匹配到则可选
						for (var valueIndex = 0; valueIndex < attr.attrValue.length; valueIndex++) {
							this.filterSpecCanChoose(index, valueIndex, attrIndexList);
						}
					} else if (selectedAttrLength > 1) {
						// 如果有多个已选属性，需要判断选择过的属性 其他值是否可选
						// 已选择的属性，所有值（除了当前选中）与其他已选择的属性匹配，匹配到则可选
						for (var valueIndex = 0; valueIndex < attr.attrValue.length; valueIndex++) {
							if (valueIndex != attr.selected) {
								this.filterSpecCanChoose(index, valueIndex, attrIndexList);
							}
						}
					}
				}
			},

			// 过滤可选的属性值
			filterSpecCanChoose: function(attrIndex, valueIndex, attrIndexList) {
				var attrList = this.attrList; // 属性及选择项列表
				var specList = this.specAttrCodeList; // 所有规格属性信息 索引列表

				//当前判断的属性项先设置为不可选
				attrList[attrIndex].attrValue[valueIndex].disabled = true;

				//遍历所有规格，判断当前属性是否可选
				for (var i = 0; i < specList.length; i++) {
					var specItem = specList[i];
					var selectedSize = 0; // 已选中的属性数
					var findSize = 0; // 当前规格匹配的属性数
					for (var j = 0; j < attrIndexList.length; j++) {
						if (j != attrIndex && attrIndexList[j] != -1) {
							if (specItem[j] == attrIndexList[j]) {
								findSize++;
							}
							selectedSize++;
						}
					}
					if (findSize == selectedSize && specItem[attrIndex] == valueIndex) {
						// 当前规格与所有已选的属性匹配 并且 与当前属性也匹配，则表示当前属性项可选
						attrList[attrIndex].attrValue[valueIndex].disabled = false;
					}
				}
			},

			// 判断当前所有已选规格属性组合，是否有对应的skuID,有则将结果返回父页面，无则返回0
			returnSelectedAttrSpec: function(selectedAttrLength) {
				//没有选中任何属性，直接返回
				if (selectedAttrLength > 0) {
					var specAttrList = this.specAttrList; // 所有有属性的规格 属性值键值对[{0: {"尺寸" => "M"} 1: {"specIndex" => 0}}]
					var attrList = this.attrList; // 属性列表
					var selectedAttrMap = new Map(); // 当前选中的所有属性键值对 {"尺寸" => "M"}
					var unSelAttr = []; // 没有选择的属性名
					for (var attr of attrList) {
						var valueIndex = attr.selected;
						if (valueIndex != -1) {
							selectedAttrMap.set(attr.attrName, attr.attrValue[valueIndex].value);
						} else {
							unSelAttr.push(attr.attrName);
						}
					}

					var haveMatched = false; // 当前选中的属性是否已经匹配到某规格
					// 遍历所有规格及属性，匹配规格
					for (var specItem of specAttrList) {
						// 除去specItem中的specIndex键值对，如果和已选的键值对相同则匹配
						if (selectedAttrMap.size == (specItem.size - 1)) {
							var findIndex = 0; // 匹配到的属性数量
							for (var attrItem of selectedAttrMap) {
								let attrKey = attrItem[0];
								let arrValue = attrItem[1].split(',');
								if (specItem.has(attrKey) && specItem.get(attrKey) == arrValue) {
									findIndex++;
								} else {
									break;
								}
							}
							// 所有已选属性都相同，则匹配，返回规格索引
							if (findIndex == selectedAttrMap.size) {
								this.$emit('selectSpec', {
									specIndex: specItem.get("specIndex"),
									error: ""
								});
								haveMatched = true;
								break;
							}
						}
					}

					// 没有匹配的规格，直接返回
					if (!haveMatched) {
						this.$emit('selectSpec', {
							specIndex: -1,
							error: unSelAttr.length > 0 ? ("请选择" + unSelAttr.join(',')) : "请选择规格"
						});
					}
				} else {
					this.$emit('selectSpec', {
						specIndex: -1,
						error: "请选择规格"
					});
				}
			},
		
		    // 父页面传入的选中规格，选中属性 {0: {"尺寸" => "M"}
		    setAttrSelected:function (selectedAttrMap,attrList) {
		      var attrList =this.attrList;
		      var attrIndexList = []; // 规格属性选择列表 [0,0,-1]
		      var selectedAttrLength = 0; // 已选属性的个数
		      // 遍历所有属性，选中当前规格属性
		      for(let attr of attrList){
		        var attrName = attr.attrName;
		        if(selectedAttrMap.has(attrName)){
		          for(var valueIndex =0;valueIndex<attr.attrValue.length;valueIndex++){
		            var attrValue = attr.attrValue[valueIndex].value;
		            if(selectedAttrMap.get(attrName) == attrValue){
		              attr.selected = valueIndex; // 选中此属性选项
		              selectedAttrLength ++;
		              break;
		            }
		          }
		        }
		        attrIndexList.push(attr.selected);
		      }
		
		      // 有属性选择时，判断剩余属性是否可选，不可选则置灰，否则保持
		      if(selectedAttrLength > 0){
		        this.setAttrCanChooseOrNot(attrIndexList,selectedAttrLength);
		      }
		
		      this.attrList = attrList;
		    }
		}
	}
</script>

<style>
	.attr-div {
		background-color: #fff;		
	}

	.attr-flex {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.attr-box {
		height: 35px;
		min-width: 30px;	
		border-radius: 4px;
		margin: 10px 20px 10px 0;
		padding: 0 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: solid 1px #999;
		cursor: pointer;
	}
	
	.attr-box span{
		margin: 0 15px;
	}

	.attrselected {
		background-color: #FFF1F1 !important;
		border: solid 1px #FF5757;
		color: #FF5757;
	}

	.attrdisabled {
		background-color: #fff !important;
		border: dashed 1px #aaa;
		color: #aaa;
	}

	.color-img {
		width: 30px;
		height: 30px;
	}
	
	.spec-txt {
		color: #999;
		margin: 4px 20px;
		display: flex;
		flex-direction: row;
	}
	
	.attr-nametext {
		max-width: 400px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	   
</style>

