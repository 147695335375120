import requestUtil from '../request-utils.js'
import commonUtlis from '../common-utlis.js'
import {
	constant,
	allUserSettingKeyList
} from '../../config.js'
import {
	getTimelinesData,
	setTimelinesData,
	updateTimelinesData
} from './data-refresh.js'

/**
 * 获取全部配置表参数
 * @param {string} keys  
 */
const getAllParam = (keys) => {
	return new Promise(async (resolve, reject) => {
		// 优先获取已有数据
		let param = await __getParamFromOrigin(allUserSettingKeyList.join(",")) // 从服务器获取用户配置数据

		// 保存更新数据
		param = updateTimelinesData(constant.DATA_KEY_USER_SETTING, constant.REFRESH_SECOND_USER_SETTING, param);

		// 返回查询配置信息
		let paramResult = {};
		keys.split(",").map(t => {
			paramResult[t] = param[t];
		});
		resolve(paramResult);
	});
}

/**
 * 获取配置表参数
 * @param {string} keys  
 */
const getParam = (keys, isRefresh = false) => {
	return new Promise(async (resolve, reject) => {
		// 优先获取已有数据
		let param = getTimelinesData(constant.DATA_KEY_USER_SETTING, false) || {};

		// 判断缓存中是否有需要的数据
		let paramKeyList = []
		for (let i in param) {
			paramKeyList.push(i);
		}
		let newParamKeyList = isRefresh ? keys.split(",") : keys.split(",").filter(item => !paramKeyList.includes(item))

		// 如果数据存在则直接返回，否则调用接口获取最新数据
		if (!isRefresh && !commonUtlis.isNullOrEmpty(param) && newParamKeyList.length == 0) {
			return resolve(param);
		}
		
		if(isRefresh || commonUtlis.isNullOrEmpty(param)){
			// 重新保存渠道配置
			getAndSetChannelInfo();
		}

		// 从服务器获取用户配置数据
		param = await __getParamFromOrigin(newParamKeyList.join(","));

		// 保存更新数据
		param = updateTimelinesData(constant.DATA_KEY_USER_SETTING, constant.REFRESH_SECOND_USER_SETTING, param);

		// 返回查询配置信息
		let paramResult = {};
		keys.split(",").map(t => {
			paramResult[t] = param[t];
		});
		resolve(paramResult);

		// 返回全部配置信息
		//resolve(param);
	});
}

/**
 * 获取缓存中的配置表参数（只获取缓存，适用于部分不方便使用异步方法的页面）
 * @param {string} keys  
 */
const getParamByKeysFromCache = (keys) => {
	// 优先获取已有数据
	let param = JSON.parse(localStorage.getItem(constant.DATA_KEY_USER_SETTING) || "{}");

	// 返回查询配置信息
	let paramResult = {};
	keys.split(",").map(t => {
		paramResult[t] = param[t];
	});

	// 返回结果
	return paramResult;
}

/**
 * 重新保存渠道配置
 */
const getAndSetChannelInfo = async () => {
	// 从服务器获取用户配置数据
	let paramList = await __getParamFromOrigin("bindSaleChannelId,bindSaleChannelName,bindWarehouseId");
	
	if (paramList.bindSaleChannelId) {
		localStorage.setItem("pc_channelId", paramList.bindSaleChannelId);
	}
	// 绑定的吉客云渠道名称
	if (paramList.bindSaleChannelName) {
		localStorage.setItem("pc_channelName", paramList.bindSaleChannelName);
	}
	
	// 默认仓库ID
	if (paramList.bindWarehouseId) {
		localStorage.setItem("pc_warehouseId", paramList.bindWarehouseId);
	}
}

/**
 * 从服务器获取用户配置数据
 */
const __getParamFromOrigin = async (keys) => {
	return new Promise((resolve, reject) => {
		// 检测当前参数在缓存中是否已经存在
		requestUtil.jackyunPost({
			method: "jmall.param.getparam",
			paramKeys: keys
		}, (res) => {
			// 检测获取结果，失败则返回null
			if (res.code != 200 || !(res.result.data && res.result.data.length > 0)) {
				return resolve({});
			}

			// 生成结果对象并返回
			let paramResult = {};
			res.result.data.map(t => {
				paramResult[t.paramKey] = t.paramValue;
			});
			resolve(paramResult);
		});
	})
}

/**
 * 检测区域可购
 * @param {*} logisticType 
 * @param {*} addressInfo 
 */
const checkCanOrderAreasByParams = async (logisticType, addressInfo) => {
	// 自提模式不检测
	if (logisticType == 2) {
		return true;
	}

	let paramList = await getParam("isOpenCityBuyLimit,buyLimitCities");
	let canOrderAreaList = JSON.parse(paramList.buyLimitCities || "[]");
	let isOpenCityBuyLimit = paramList.isOpenCityBuyLimit == "1";

	// 未开启或者未设置限购地区，则不做展示
	if (!isOpenCityBuyLimit || !(canOrderAreaList.length > 0)) {
		return true;
	}

	// 获取当前地址的省市区信息
	let {
		province = "", city = "", district = ""
	} = addressInfo;
	let adrProvince = province.substring(0, 2) || "";
	let adrCity = city.replace("市", "") || "";
	let adrDistrict = district.replace("县", "").replace("区", "") || "";

	// 处理省份信息
	canOrderAreaList.forEach(t => {
		t.province = t.province.substring(0, 2) || "";
		t.city = t.city.replace("市", "") || "";
		t.town = t.town.replace("县", "").replace("区", "") || "";
	});

	// 检测是否可以下单
	let canOrder = canOrderAreaList.findIndex(t =>
		t.province == "全部" ||
		(t.province == adrProvince && t.city == "全部") ||
		(t.province == adrProvince && t.city == adrCity && t.town == "全部") ||
		(t.province == adrProvince && t.city == adrCity && t.town == adrDistrict)
	) >= 0;

	return canOrder
}

//可以被外部引用的方法
export {
	getParam,
	checkCanOrderAreasByParams,
	getAllParam,
	getParamByKeysFromCache
}