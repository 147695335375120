<template>
	<div class="address-box" :class="{addrselected : isselect}" @click="selectAddr">
		<el-row class="address-info" :gutter="20">
			<el-col class="flex-row" :span="18">
				<div class="address-name">{{address.name}}</div>
				<div v-show="address.isDefault" class="default-tip">默认</div>
			</el-col>
			<el-col :span="3">
				<div class="handle-icon" @click.stop="edit"> <i class="el-icon-edit-outline"></i></div>
			</el-col>
			<el-col :span="3">
				<div class="handle-icon" @click.stop="remove"> <i class="el-icon-delete"></i></div>
			</el-col>
		</el-row>
		<div class="address-tel">{{$utils.regTel(address.tel)}}</div>
		<div v-if="address.country=='中国'">{{address.province}} &nbsp;{{address.city}} &nbsp;{{address.district}} &nbsp;{{address.town}}</div>
		<div v-else>{{address.country}}</div>
		<div class="address-detail">{{address.address}}</div>
	</div>
</template>

<script>
	export default {
		name: "address-detail",
		props: {
			address: {
				type: Object,
				default: function(){
					return {};
				}
			},
			isselect: {
				type: Boolean,
				default: false
			}
		},		
		methods: {
			edit() {
				this.$emit('edit', this.address.addressId);
			},
			remove() {
				this.$emit('remove', this.address.addressId);
			},
			selectAddr() {
				this.$emit('select', this.address.addressId);
				this.$emit('multiselect',this.address.addressId);
			}
		}
	}
</script>

<style scoped="scoped">
	.address-box {
		height: 135px;
		border: 1px solid #dedede;
		padding: 20px;
		color: #999;
		font-size: 16px;
		margin-bottom: 20px;
		font-size: 14px;
		overflow: hidden;
		overflow-y: scroll;
	}

	.address-box::-webkit-scrollbar {
		display: none;
	}

	.address-info {
		align-items: center;
		color: #000;
		font-size: 18px;
	}

	.address-name {
		flex-shrink: 1;
	}

	.address-tel {
		margin: 15px 0;
	}

	.default-tip {
		background-color: #FF5757;
		color: #fff;
		font-size: 14px;
		padding: 0 6px;
		border-radius: 6px;
		margin-left: 15px;
		line-height: 23px;
		flex-shrink: 0;
		height: 23px;
	}

	.handle-icon {
		font-size: 25px;
		cursor: pointer;
	}

	.address-detail {
		line-height: 16px;
		margin-top: 4px;
	}

	.addrselected {
		border: 1px solid #FF5757 !important;
	}
</style>
