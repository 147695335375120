<!-- 商品列表 -->
<template>
	<div>
		<div class="container  container-min-height" id="goodsListWrap">
			<div class="flex-col">
				<div class="container search-box">
					<el-input clearable @clear="clearFn" v-model="goodsSearchValue" @keyup.13.native="searchGoods" placeholder="请输入活动商品名称">
						<el-button type="danger" slot="append" @click="searchGoods">搜索</el-button>
					</el-input>
				</div>
				<timed-list ref="timedlist" :isShowMoreMenu="false" :pageIndex="pageIndex" @getTotal="getTotalCount" @openLoading="openLoading" @closeLoading="closeLoading" @refreshPageIndex="refreshPageIndex"></timed-list>
				<div class="flex-center">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex+1" :page-size="pageSize" :page-sizes="[8, 16]" layout="sizes, prev, pager, next, jumper" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>

		<!-- 右下角浮动按钮 -->
		<float-btns scroll-target="goodsListWrap"></float-btns>
	</div>

</template>

<script>
	import GoodsCate from '../../components/goods/GoodsCate.vue';
	import FloatBtns from '../../components/common/FloatBtns.vue';
	import timedList from '../../components/goods/TimedGoodsList.vue';
	export default {
		name: 'goodslist',
		components: {
			'goods-cate': GoodsCate,
			'float-btns': FloatBtns,
			'timed-list': timedList
		},
		data() {
			return {
				totalCount: 4,
				pageIndex: 0,
				pageSize: 8,
				goodsSearchValue: '',
				loadingLayer: '',
			}
		},
		created() {

		},
		activated() {
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		methods: {
			handleSizeChange(val) {
				this.pageSize = val;
				this.$refs.timedlist._changePage(this.pageIndex, this.pageSize);
			},
			handleCurrentChange(val) {
				this.pageIndex = val - 1;
				this.$refs.timedlist._changePage(this.pageIndex, this.pageSize);
			},
			getTotalCount(value) {
				this.totalCount = value;
			},
			
			refreshPageIndex(){
				this.pageIndex = 0;
			},
			
			searchGoods() {
				this.pageIndex = 0;
				this.$refs.timedlist._getTimedSaleGoodsList(this.goodsSearchValue, 0, 100);
			},
			openLoading() {
				this.loadingLayer = this.$loading({
					lock: true,
					text: '加载中...',
					background: 'rgba(255, 255, 255, 0.2)'
				});
			},
			closeLoading() {
				this.loadingLayer.close();
			},
			// 清空
			clearFn() {
				this.goodsSearchValue = "";
				this.pageIndex = 0;
				this.$refs.timedlist._getTimedSaleGoodsList(this.goodsSearchValue, 0, 100);
			},
		}
	}
</script>

<style scoped="scoped">
	.container {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.container-min-height {
		min-height: calc(100vh - 200px);
	}

	.search-box {
		width: 865px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	/deep/ .el-input-group__append button.el-button {
		width: 104px;
		height: 40px;
		background: #f63e3e;
		color: #ffffff;
		border-radius: 0;
	}
</style>