const request = require('../request-utils.js');
import commonUtils from '../common-utlis.js'

/**
 * 获取货品价格列表
 * @param {*} goodsIdArr 
 * @param {*} isRefresh 
 * 
 */
const getGoodsPriceList = async (goodsIdArr) => {
	// 调用接口获取最新的货品价格
	let originPriceList = await _getPriceFromOrigin(goodsIdArr.join(','));

	// 返回最终价格列表
	return originPriceList;
}

/**
 * 获取货品最低价格列表
 * @param {*} goodsIdArr 
 * @param {*} isRefresh 
 */
const getGoodsMinPriceList = async (goodsIdArr) => {
	// 查询商品中不含普通商品直接返回
	if(goodsIdArr.length == 0){
		return [];
	}
	return new Promise((resolve, reject) => {
		let customerId = localStorage.getItem("pc_customerId") || "";
		let channelId = localStorage.getItem("pc_channelId");
		let customerInfoStr = localStorage.getItem("pc_customerInfo");
		let customerInfo = JSON.parse(customerInfoStr);
		let json = {
			method: "jmall.goods.listmingoodsprice",
			goodsIds: goodsIdArr.join(','),
			customerId: customerId,
			channelId: channelId,
		}
		if (customerInfo && customerInfo.customerId) {
			json.vipLevelId = customerInfo.vipLevel ? customerInfo.vipLevel : "empty";
		}
		request.default.jackyunPost(
		json, (res) => {
			if (res.code == 200) {
				let minPriceList = res.result.data.map(t => {
					// 未登录时展示游客展示价
					if(!customerId){
						t.minPrice = t.minPriceDetail;
					}
					return {
						goods_id: t.goodsId,
						price_member: t.minPrice,
						price_detail: t.minPriceDetail,
						score: t.score
					}
				});
				// 返回最终价格列表
				resolve(minPriceList);
				return;
			} else {
				resolve([]);
			}

			})

	})
}

/**
 * 获取货品最低价格列表(套餐商品)
 * @param {*} goodsIdArr 
 * @param {*} isRefresh 
 */
const getGoodsMinPriceListForPackage = async (goodsIdArr) => {
	// 查询商品中不含套餐商品直接返回
	if(goodsIdArr.length == 0){
		return [];
	}
	return new Promise((resolve, reject) => {
		let customerId = localStorage.getItem("pc_customerId") || "";
		let channelId = localStorage.getItem("pc_channelId");
		let customerInfoStr = localStorage.getItem("pc_customerInfo");
		let customerInfo = JSON.parse(customerInfoStr);
		let json = {
			goodsIds: goodsIdArr.join(','),
			customerId: customerId,
			channelId: channelId,
			method: "jmall.goods.listmingoodspriceforpackage"
		}
		if (customerInfo && customerInfo.customerId) {
			json.vipLevelId = customerInfo.vipLevel ? customerInfo.vipLevel : "empty";
		}
		request.default.jackyunPost(
			json, (res) => {
				if (res.code == 200) {
					let minPriceList = res.result.data.map(t => {
						return {
							goods_id: t.goodsId,
							price_member: t.minPrice,
							price_detail: t.minPriceDetail,
							score: t.score
						}
					});
					// 返回最终价格列表
					resolve(minPriceList);
					return;
				} else {
					resolve([]);
				}

			})

	})
}

/**
 * 获取SKU价格列表
 * @param {*} skuArr 
 */
const getSkuPriceList = async (skuArr) => {
	// 入参格式要求：[{goods_id,sku_id,unit_name},{goods_id,sku_id,unit_name}...]
	// 获取商品ID集合（需要去重）
	let goodsIdList = [...new Set(skuArr.map(t => t.goods_id))];
	// 获取货品ID对应的所有货品价格明细
	let goodsPriceList = await getGoodsPriceList(goodsIdList);

	// 补充价格数据
	skuArr.forEach(sku => {
		try {
			// 获取出当前sku对应的价格数据，补充到返回结果
			let skuPrice = goodsPriceList.find(t => t.goods_id == sku.goods_id).sku_list.find(t => t
				.sku_id == sku.sku_id && t.unit_name == sku.unit_name);
			sku.price_member = commonUtils.floatKeepDigit(skuPrice.price_member || 0);
			sku.price_detail = commonUtils.floatKeepDigit(skuPrice.price_detail || 0);
			sku.score = skuPrice.score;
		} catch (e) {
			// 异常情况都置为0
			sku.price_member = sku.price_detail = sku.score = 0;
		}
	});

	// 返回最终价格列表
	return skuArr;
}

/**
 * 调用接口获取货品价格
 * @param {*} goodsIds 
 */
const _getPriceFromOrigin = (goodsIds) => {
	let customerId = localStorage.getItem("pc_customerId");
	let channelId = localStorage.getItem("pc_channelId");
	return new Promise((resolve, reject) => {
		// 缺少参数的情况不请求接口
		if (!channelId || !customerId || !goodsIds) {
			return resolve([]);
		}
		// 调用接口获取最新的货品价格
		request.default.jackyunPost({
			method: "jmall.goods.listgoodsprice",
			channelId: channelId,
			customerId: customerId,
			goodsIds: goodsIds
		}, (res) => {
			if (res.code != 200) {
				return resolve([]);
			}
			let goodsPriceList = res.result.data;
			// 统一处理价格数据
			let goodsPriceListFitApp = goodsPriceList.map(item => {
				return {
					goods_id: item.goodsId,
					sku_list: item.skuList.map(m => {
						return {
							sku_id: m.skuId,
							unit_name: m.unitName,
							price_member: m.priceMember,
							price_detail: m.priceDetail,
							score: m.score
						}
					})
				}
			})
			resolve(goodsPriceListFitApp)
		})
	});
}

/**
 * 获取sku价格列表中价格最低的数据
 * @param {*} skuPriceList 
 */
const _getMinSkuPriceInfo = (skuPriceList) => {
	// 首先将没有设置价格/积分的数据过滤掉
	let filterPriceList = skuPriceList.filter(t => t.price_member > 0 || t.price_detail || t.score > 0);
	// 如果没有有效价格，直接返回默认结果
	if (!filterPriceList || filterPriceList.length <= 0) {
		return {
			price_detail: 0,
			price_member: 0,
			score: 0
		};
	}

	// 根据价格排序
	filterPriceList.sort((a, b) => {
		if (a.price_member == b.price_member) {
			return a.score - b.score;
		} else {
			return a.price_member - b.price_member;
		}
	});

	return filterPriceList[0];
}

/**
 * 获取sku价格列表中价格最低的数据
 * @param {*} goodsList 
 */
const getMinPriceFromSpec = (goodsList) => {
	// 获取最小价格
	let minPriceList = goodsList.map(t => {
		// 获取价格最小的SKU数据
		let minSkuPrice = _getMinSkuPriceInfo(t.GoodsSpecList);
		return {
			goods_id: t.goods_id,
			price_member: minSkuPrice.price_member,
			price_detail: minSkuPrice.price_detail,
			score: minSkuPrice.score
		}
	});
	// 返回最终价格列表
	return minPriceList;
}

export default {
	getGoodsPriceList,
	getGoodsMinPriceList,
	getGoodsMinPriceListForPackage
}