<!--右下角浮动按钮组：购物车，客服，回到顶部-->
<template>
	<div class="float-btns">
		<div class="float-btn-border">
			<div class="float-action-btn" @click="toShopCart">
				<el-badge :hidden="shopCartCount<=0" :value="shopCartCount" :max="99">
					<img src="../../assets/images/icon-cart.png" />
				</el-badge>
			</div>
			<div class="float-action-btn"><img src="../../assets/images/icon-contact.png" @click="toContact"/></div>
		</div>
		<!-- <el-backtop target=""></el-backtop> -->
		<div class="float-action-btn top-btn float-btn-border" v-show="topShow" @click="toTop"><img src="../../assets/images/icon-top.png" /></div>
	</div>
</template>

<script>
	import { getShoppingCartCount } from '../../utils/api-utils/api-goods-shoppingcart.js';
	export default {
		name: 'float-btns',
		props: {
			scrollTarget:{
				type: String,
				default: ""
			} // 监听触发滚动的对象Id，默认整个页面
		},
		data(){
			return{
				shopCartCount: 0, //购物车数量
				topShow: false, // 是否显示回到顶部按钮
			}
		},
		created(){
			//获取购物车数量
			this.countShopCart();
		},
		mounted(){
			//监听页面滚动距离，显示浮动按钮
			let scrollTargetView = this.scrollTarget ? document.getElementById(this.scrollTarget) : window;
			scrollTargetView.addEventListener("scroll", this.handleScroll);
			
			//监听购物车数量变化
			this.$eventBus.$on("updateFloatShopCartCount",(data)=>{
				this.shopCartCount = data ? data : 0;
			})
		},
		beforeDestroy(){
			//组件销毁前，取消监听滚动事件
			let scrollTargetView = document.getElementById(this.scrollTarget) || window;
			scrollTargetView.removeEventListener("scroll",this.handleScroll);
			
			//取消监听购物车数量变化
			this.$eventBus.$off("updateFloatShopCartCount");
		},
		methods:{
			//页面滚动监听事件
			handleScroll() {
				var scrollHeight = 0;
				if(this.scrollTarget){
					scrollHeight = document.getElementById(this.scrollTarget).scrollTop;
				}
				else{
					scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
				}
				if (scrollHeight > 100) {
					this.topShow = true;
				} else {
					this.topShow = false;
				}
			},
			//跳转购物车页面
			toShopCart(){
				// location.href = "#/goodslist";
				this.$router.push({name:'shopcart'});
			},
			//回到页面顶部
			toTop(){
				if(this.scrollTarget){
					document.getElementById(this.scrollTarget).scrollTop = 0;
				}
				else{
					document.documentElement.scrollTop = 0;
					document.body.scrollTop = 0;
				}
			},
			//获取购物车数量
			async countShopCart(){
				let shopCartCount = await getShoppingCartCount();
				this.shopCartCount = shopCartCount || 0;
			},
			toContact(){
				this.$router.push({name:'contactservice'});
			}
		}
	}
</script>

<style>
	.float-btns{
		position: fixed;
		bottom: 20px;
		right: 20px;
		padding: 10px;
		text-align: center;
		z-index: 990;
	}
	.float-action-btn{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70px;
		height: 70px;
		background-color: #fff;
		cursor: pointer;
	}
	.float-action-btn img{
		width: 30px;
		height: 30px;
	}
	.top-btn{
		margin-top: 10px;
	}
	.float-btn-border{
		border: solid 1px #f9f9f9;
		box-shadow: 2px 3px 5px 0px #d2d2d2;
		border-radius: 2px;
	}
</style>
