<template>
	<div class="ttrade-item flex-col">
		<div class="base-info flex-row">
			<div class="flex-row">
				<div>订单编号：{{tradeInfo.tradeOrder.tradeNo}}</div>
				<div class="copy-btn" v-clipboard:copy="tradeInfo.tradeOrder.tradeNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">复制</div>
			</div>
			<div> {{tradeInfo.tradeOrder.tradeTime}}</div>
		</div>
		<el-row class="tgoods-item" :gutter="30" @click.native="detail">
			<el-col :span="4">
				<el-image class="tgoods-img" fit='scale-down' :src="$utils.setImg(tradeInfo.tradeOrder.mainPic)"></el-image>
			</el-col>
			<el-col :span="11">
				<div>
					<span class="presale-style" v-show="tradeInfo.tradeOrder.tradeType == '3'">预售</span>
					<span class="tgoods-name">{{tradeInfo.tradeOrder.goodsName}}</span>
				</div>
				<div class="tgoods_count">共 {{$utils.amountFixed(tradeInfo.tradeOrder.tradeCount, 0)}} 件商品</div>

			</el-col>
			<el-col :span="5" class="tprice-box">
				<div class="tgoods-price">
					<span v-show="tradeInfo.tradeOrder.payment > 0 || tradeInfo.tradeOrder.pointTotal == 0">{{$currency.symbol}}{{$utils.amountFixed(tradeInfo.tradeOrder.payment, 2)}}</span>
					<span v-show="tradeInfo.tradeOrder.payment != 0 && tradeInfo.tradeOrder.pointTotal > 0"> + </span>
					<span v-show="tradeInfo.tradeOrder.pointTotal > 0">{{tradeInfo.tradeOrder.pointTotal}}</span>
					<span v-show="tradeInfo.tradeOrder.pointTotal > 0" class="sscore-text">积分</span>
				</div>
				<div class="ttrade-postage" v-if="tradeInfo.tradeOrder.receivedPostFee > 0">
					（含运费{{$currency.symbol}}{{$utils.amountFixed(tradeInfo.tradeOrder.receivedPostFee, 2)}}）</div>
			</el-col>
			<el-col :span="4">
				<div class="ttrade-status" v-if="tradeInfo.tradeOrder.tradeStatus =='5020'">原始单-被合并</div>
				<div class="ttrade-status" v-else-if="tradeInfo.tradeOrder.tradeStatus =='5030'">原始单-被拆分</div>
				<div class="ttrade-status" v-else>
					{{tradeInfo.tradeOrder.tradeStatusExplain.indexOf('代销发货') >= 0 ?'待发货' :tradeInfo.tradeOrder.tradeStatusExplain}}
				</div>
				<div class="ttrade-status" v-if="tradeInfo.tradeOrder.sellbackStatusExplain">{{tradeInfo.tradeOrder.sellbackStatusExplain}}</div>
			</el-col>
		</el-row>
		<div class='receiver-info' v-if="isShowTradeReceiver">
			<div class='receiver-item flew-row'>
				<span class='receiver-title'>收件人：</span><span>{{tradeInfo.tradeOrder.sendTo}}</span>
				<div class="flex-row">
					<span class='receiver-title m-left-30'>电话：</span>
					<div v-if="tradeInfo.tradeOrder.isLockPhone && isundecrypt" @click="decryptPhone"><img class="lock-img flew-row" src="../../assets/images/icon_lock.png" /></div><span>{{phone||tradeInfo.tradeOrder.mobile}}</span>
				</div>

				<span class='receiver-title m-left-30' v-if="tradeInfo.tradeOrder.tradeStatusExplain!='待结算'">物流单号：</span>
				<span class="post-no-text">{{tradeInfo.tradeOrder.mainPostid||''}} </span>
				<!-- 				<span v-if="tradeInfo.tradeOrder.mainPostid">
					|
					<span style="cursor: pointer;" @click.stop="displayTrajectory">查看物流</span>
				</span> -->
				<!-- 				<span class='copy-btn' v-clipboard:copy="tradeInfo.tradeOrder.mainPostid" v-clipboard:success="onCopySuccess"
					v-clipboard:error="onCopyError" v-if="tradeInfo.tradeOrder.mainPostid">复制</span> -->
			</div>
			<div class='receiver-item'>
				<span class='receiver-title'>地址：</span><span class='receiver-adr'>{{tradeInfo.tradeOrder.address}}</span>
			</div>
		</div>
		<div class="handle-box flex-row" v-if="tradeInfo.tradeOrder.tradeStatus && tradeInfo.tradeOrder.tradeStatus == 1">
			<el-button type="info" class="handle-btn size14" size="small" @click.stop="cancel">取消</el-button>
			<el-button type="danger" class="size14" size="small" @click.stop="pay">继续支付</el-button>
		</div>
		<div class="handle-box flex-row" v-if="tradeInfo.tradeOrder.tradeStatus == 19">
		</div>
		<div class="flex-col" v-if="tradeInfo.tradeOrder.tradeStatus == '16'">
			<div class="pay-begin-time">支付尾款时间：{{tradeInfo.tradeOrder.tradeFinalPayTime}} 起</div>
			<div class="handle-box">
				<div class="handle-box flew-row" v-if="Date.parse(tradeInfo.tradeOrder.tradeFinalPayTime) < new Date()">
					<el-button type="danger" class="size14" size="small" @click.stop="pay">支付尾款</el-button>
				</div>
				<div v-else class="handle-box flew-row">
					<el-button type="info" class="handle-btn size14" size="small">支付尾款</el-button>
				</div>
			</div>
		</div>
		<div class="handle-box flex-row" v-if="[0, 18].includes(tradeInfo.tradeOrder.tradeStatus)">
			<el-button class="size14" size="small" @click.stop="tradeAgain">
				再来一单</el-button>
		</div>
		<div class="handle-box flex-row" v-else-if="tradeInfo.tradeOrder.type == 'oms'">
			<el-button v-if="!tradeInfo.isMemberCouponTrade && !tradeInfo.isMemberTrade" class="size14" size="small" @click.stop="tradeAgain">
				再来一单</el-button>
			<el-button v-if="tradeInfo.tradeOrder.tradeStatus == 6000" type="danger" class="confirm-btn size14" size="small" @click.stop="confirmTrade">确认收货</el-button>
			<!-- <el-button v-if="tradeInfo.tradeOrder.logisticType == '2' && tradeInfo.tradeOrder.tradeStatusExplain != '已完成' && tradeInfo.tradeOrder.tradeStatusExplain != '待审核'"
			 plain class="handle-btn size14" size="small" @click.stop="openSelfpickCode">提货码</el-button> -->
		</div>

	</div>

</template>

<script>
	import tradeUtils from '../../utils/api-utils/api-trade.js'
	export default {
		name: "trade-item",
		props: {
			tradeInfo: {
				type: Object,
				default: function() {
					return {};
				}
			},
			
			// 是否开启展示收件人信息
			isShowTradeReceiver: {
				type: Boolean,
				default: function() {
					return false;
				}
			}
		},
		data() {
			return {
				webviewSrc: 'https://m.kuaidi100.com/result.jsp?nu=' + this.tradeInfo.tradeOrder.mainPostid,
				dialogVisible: false,
				isundecrypt: true,
				phone: "" // 订单手机号1
			}
		},
		methods: {
			onCopySuccess() {
				this.$message({
					message: "复制成功",
					type: 'success'
				});
			},
			onCopyError(e) {
				this.$message.error({
					message: "复制失败"
				});
			},
			cancel() {
				this.$emit("cancel", this.tradeInfo.tradeOrder.tradeId);
			},
			pay() {
				this.$emit("repay", this.tradeInfo.tradeOrder);
			},
			confirmTrade() {
				this.$emit("confirm", this.tradeInfo.tradeOrder);
			},
			tradeAgain() {
				this.$emit("tradeagain", this.tradeInfo);
			},
			detail() {
				this.$emit("detail", this.tradeInfo.tradeOrder);
			},
			displayTrajectory() {
				this.$emit("displayTrajectory", {
					dialogVisible: true,
					webviewSrc: this.webviewSrc
				});
			},
			async decryptPhone() {
				await tradeUtils.tradeDecrypt(this.tradeInfo);
				this.isundecrypt = false;
				this.phone = this.tradeInfo.tradeOrderReceiver.mobile;
			}

		}
	}
</script>

<style scoped="scoped">
	.ttrade-item {
		padding: 20px;
		font-size: 15px;
		border-bottom: 1px solid #dedede;
	}

	.base-info {
		width: 100%;
		justify-content: space-between;
		color: #999999;
		margin-bottom: 10px;
	}

	.tgoods-item {
		display: flex;
		align-items: center;
	}

	.tgoods-img {
		width: 100%;
		height: 100px;
	}

	.tgoods_count {
		margin-top: 20px;
		color: #999999;
	}

	.tscore-text {
		font-size: 15px;
		margin-left: 3px;
	}

	.ttrade-status {
		color: #FF5757;
	}

	.handle-box {
		display: flex;
		justify-content: flex-end;
	}

	.handle-btn {
		width: 80px;
		border: none;
		color: #333333;
		background-color: #F2F2F2;
	}

	.confirm-btn {
		border: 1px solid #FF5757;
		color: #FF5757;
		background-color: transparent;
	}

	.tprice-box {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.ttrade-postage {
		font-size: 13px;
		color: #999999;
	}

	.extra-info {
		color: #999999;
	}

	.tgoods-name {
		color: #333333;
	}

	.receiver-info {
		background: #f9f9f9;
		padding: 10px 20px;
		margin-bottom: 10px;
		margin-top: 5px;
		font-size: 14px;
		line-height: 30px;
		color: #333333;
	}

	.receiver-item span {
		display: inline-block;
		vertical-align: top;
		word-break: break-all;
	}

	.receiver-title {
		color: #999999;
	}

	.m-left-30 {
		margin-left: 30px;
	}

	.receiver-adr {
		width: calc(100% - 46px);
		line-height: 18px;
		padding: 6px 0;
	}

	.copy-btn {
		margin-left: 15px;
		border: 1px solid #bbb;
		padding: 3px 8px;
		border-radius: 2px;
		background: #f2f2f2;
		line-height: 15px;
		cursor: pointer;
		color: #777;
		font-size: 13px;
		width: 28px;
		text-align: center;
		vertical-align: unset !important;
	}

	.pay-begin-time {
		color: #F37A45;
		margin-bottom: 20rpx;
		text-align: right;
	}

	.post-no-text {
		margin-right: 4px;
	}

	.lock-img {
		height: 20px;
		width: 20px;
	}

	.flew-row {
		display: flex;
	}
</style>