const common = require('../common-utlis.js');
const request = require('../request-utils.js');
import { getParam } from "./api-params.js"

/**
 * 补充商品批次有效期信息
 */
const completeGoodsValidityDateInfo = async (goodsInfo) => {
	// 展示有效期相关配置(isShowGoodsValidityDate,goodsValidityDateFrom,skuCustomFieldName)
	let config = await _getValidityDateConfig();

	// 检测是否开启有效期展示
	if (config.isShowGoodsValidityDate != "1" || !config.goodsValidityDateFrom) {
		return;
	}

	// 获取所有skuId
	let skuIds = goodsInfo.GoodsSpecList.map(t => t.spec_id);
	// 获取有效期数据
	let skuValidityDateList = await _getSkuValidityDateList([goodsInfo.goods_id]);

	// 将有效期补充到规格数据中心
	goodsInfo.GoodsSpecList.forEach(spec => {
		// 获取当前sku的批次信息
		let item = skuValidityDateList.find(t => (t.goodsId == goodsInfo.goods_id || t.goodsId ==
			"0") && (t.skuId == spec.spec_id || t.skuId == "0"));
		if (item) {
			// 批次存在则记录到货品信息中
			spec.validity_date = item.validityDate;
		}
	});

	// 批次存在则记录到货品信息中
	goodsInfo.validity_date = goodsInfo.GoodsSpecList[0].validity_date;
}

/**
 * 补充规格批次有效期信息
 */
const completeSpecValidityDateInfo = async (specList) => {
	// 展示有效期相关配置(isShowGoodsValidityDate,goodsValidityDateFrom,skuCustomFieldName)
	let config = await _getValidityDateConfig();

	// 检测是否开启有效期展示
	if (config.isShowGoodsValidityDate != "1" || !config.goodsValidityDateFrom) {
		return;
	}

	// 获取保质期配置
	let paramList = await getParam("goodsValidityDateFrom,isShowValidateBySplitType");

	// 获取所有goodsId
	let goodsIds = [...new Set(specList.map(t => t.goodsId))];
	// 获取有效期数据 
	let skuValidityDateList = await common.default.getAllNeedInfoByConfigList(goodsIds, _getSkuValidityDateList);

	// 将有效期补充到规格数据中心
	specList.forEach(async spec => {
		// 获取当前sku的批次信息
		let item = skuValidityDateList.find(t => (t.goodsId == spec.goodsId || t.goodsId == "0") && (t
			.skuId == spec.specId || t.skuId == "0")) || {};

		// 补充配置信息
		item.validateFrom = paramList.goodsValidityDateFrom || "bathInfo";
		item.isShowValidateBySplitType = paramList.isShowValidateBySplitType == "1";
		if (item) {
			// 批次存在则记录到货品信息中
			item.validityDate = await common.default.getFormatValidate(item)
			spec.validityDate = item.validityDate;
		}
		spec.validityDateObj = item || {};
	});

}

/**
 * 获取有效期数据
 * @param {*} goodsIds 
 */
const _getSkuValidityDateList = async (goodsIds) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.goods.listvaliditydate",
			goodsIds: goodsIds.join(','),
			warehouseId: localStorage.getItem("pc_warehouseId")
		}, (res) => {
			if (res.code == 200) {
				return resolve(res.result.data);
			}
			resolve([]);
		});
	})
}

/**
 * 获取保质期
 */
const getValidityDateByMultiCondition = async (goodsIds) => {
	// 展示有效期相关配置(isShowGoodsValidityDate,goodsValidityDateFrom,skuCustomFieldName)
	let config = await _getValidityDateConfig();
	// 检测是否开启有效期展示
	if (config.isShowGoodsValidityDate != "1" || !config.goodsValidityDateFrom) {
		return [];
	}
	let skuValidityDateList = await _getSkuValidityDateList(goodsIds);
	return skuValidityDateList;
}

/**
 * 获取所有sku的批次信息
 */
const __getValidityDateFromBath = async (skuIds) => {
	let resultList = [];
	for (let i = 0; i < skuIds.length; i++) {
		let res = await __getSkuBathInfo__(skuIds[i]);
		if (res) {
			resultList.push(res);
		}
	}

	// 生成所需有效期结果集
	resultList = resultList.map(t => {
		let item = {
			goodsId: "0",
			skuId: t.skuId,
			validityDate: `${__formatDate__(t.productionDate)} 至 ${__formatDate__(t.expirationDate)}`
		};
		return item;
	});

	// 返回最终结果
	return resultList;
}

/**
 * 获取sku的批次信息，提取有效期信息
 */
const __getSkuBathInfo__ = (skuId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "goods.getbathlistbyskuid",
			skuId: skuId
		}, (res) => {
			if (res.code == 200) {
				try {
					// 获取包含有效期的批次属性，获取有效期最近的批次
					let bathItem = res.result.data.filter(t => !!t
						.expirationDate).sort((a, b) => a
						.expirationDate - b.expirationDate)[0];
					bathItem.skuId = skuId;
					return resolve(bathItem);
				} catch (e) {}
			}
			resolve(null);
		});
	});
}

/**
 * 从自定义字段中获取有效期信息
 * @param {*} goodsId 
 * @param {*} fieldName 
 */
const __getValidityDateFromSkuCustomField = async (goodsIds, fieldName, fieldExplain) => {
	// 有效期标题
	let title = fieldExplain || "有效期至";

	// 获取所有所有商品对应的有效期
	let resultList = [];
	for (let i = 0; i < goodsIds.length; i++) {
		let goodsId = goodsIds[i];

		// 获取所有SKU列表
		let skuList = await __getSkuList__(goodsId);

		// 生成所需有效期结果集
		let resultItem = skuList.map(t => {
			let dataStr = __stringToDate__(t[fieldName], false);
			let item = {
				goodsId: goodsId,
				skuId: t.skuId,
				validityDate: dataStr ? `${title}${dataStr}` : ""
			}
			return item;
		});
		resultList = resultList.concat(resultItem);
	}

	// 返回最终结果
	return resultList;
}

/**
 * 获取货品SKU列表
 */
const __getSkuList__ = (goodsId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "goods.skulistbygoodsid",
			goodsId: goodsId
		}, (res) => {
			if (res.code == 200) {
				return resolve(res.result.data);
			}
			resolve([]);
		});
	});
}

/**
 * 从货品自定义字段中获取有效期信息
 * @param {*} goodsIds 
 * @param {*} fieldName 
 * @param {*} fieldExplain
 */
const __getValidityDateFromGoodsCustomField = async (goodsIds, fieldName, fieldExplain) => {
	// 有效期标题
	let title = fieldExplain || "有效期至";

	// 获取所有所有商品对应的有效期
	let resultList = [];
	for (let i = 0; i < goodsIds.length; i++) {
		let goodsId = goodsIds[i];

		// 获取货品详情
		let goodsInfo = await __getGoodsInfo__(goodsId);

		// 获取有效期数据
		let validityDate = __stringToDate__(goodsInfo[fieldName], false);

		// 返回最终结果
		resultList.push({
			goodsId: goodsId,
			skuId: "0",
			validityDate: validityDate ? `${title}${validityDate}` : ""
		});
	}

	// 返回最终结果
	return resultList;
}

/**
 * 获取货品详情
 */
const __getGoodsInfo__ = (goodsId) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "goods.getbygoodsid",
			goodsId: goodsId
		}, (res) => {
			if (res.code == 200) {
				return resolve(res.result.data);
			}
			resolve({});
		});
	});
}

/**
 * 展示有效期相关配置
 */
const _getValidityDateConfig = async () => {
	return new Promise(async (resolve, reject) => {
		//门店信息
		let paramlist = await getParam('isShowGoodsValidityDate,goodsValidityDateFrom,skuCustomFieldName');
		resolve(paramlist);
	});
}

/**
 * 获取日期
 */
const __formatDate__ = (timestamp) => {
	return common.default.formatTime(timestamp, 'Y-M-D');
}

/**
 * 字符串尝试转为日期
 */
const __stringToDate__ = (timestamp) => {
	return common.default.stringToDate(timestamp, false);
}

export default {
	completeGoodsValidityDateInfo,
	completeSpecValidityDateInfo,
	getValidityDateByMultiCondition
}