<template>
	<personal-layout cur-menu="tradelist">
		<div class="right-box">
			<div class="top-box flex-row">
				<div>我的订单</div>
				<div class="top-box-r">
					<div class="search-box">
						<el-input clearable @clear="clearFn" placeholder="请输入内容" v-model="searchValue">
							<el-select v-model="selectSearchKey" slot="prepend" placeholder="请选择">
								<el-option label="商品名称" value="3"></el-option>
								<el-option label="订单编号" value="1"></el-option>
								<el-option label="手机号" value="2"></el-option>
								<el-option label="收件人" value="4"></el-option>
								<el-option label="收件地址" value="5"></el-option>
								<el-option label="物流单号" value="6"></el-option>
								<el-option label="原始单号" value="7"></el-option>
							</el-select>
							<el-button slot="append" type="danger" icon="el-icon-search" class="size18" @click="search">
							</el-button>
						</el-input>
						<div class="box-arrow" @click="showTimeFilter">
							筛选
							<i :class="isShowTimeFilter?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
						</div>
					</div>
					<div class="timefilter-box" v-if="isShowTimeFilter">
						<el-date-picker value-format="yyyy-MM-dd" placeholder="请选择下单时间(起始)" suffix-icon="el-icon-date" v-model="startFilterTime">
						</el-date-picker>
						<i class="el-icon-minus"></i>
						<el-date-picker value-format="yyyy-MM-dd" placeholder="请选择下单时间(结束)" suffix-icon="el-icon-date" v-model="endFilterTime">

						</el-date-picker>

					</div>
				</div>
			</div>
			<div style="position: relative;">
				<el-tabs class="tab-box" v-model="activeMenu" @tab-click="menuClick">
					<el-tab-pane v-for="(tabItem, tabIndex) in navList" :key="tabIndex.name" :name="tabItem.name">
						<span slot="label">{{tabItem.text}}
							<el-badge :value="tabItem.count" v-show="tabItem.count>0 && (tabIndex == 1 || tabIndex == 2)">
							</el-badge>
						</span>

						<div class="list-box" :style="{height: screenHeight+ 'px'}" v-infinite-scroll="loadTradeList" infinite-scroll-distance="600px" v-loading="tabItem.loadingType == 'loading' && tabItem.tradeList.length == 0" element-loading-text="加载中">
							<trade-item v-for="(item, index) in tabItem.tradeList" :key="index" :tradeInfo="item" @cancel="cancelTrade" @repay="repay" @confirm="confirmTrade" @tradeagain="tradeAgain" @detail="tradeDetail" @displayTrajectory="displayTrajectory" :isShowTradeReceiver="isShowTradeReceiver">
							</trade-item>
							<div class="load-tip" v-if="tabItem.loadingType == 'loading' && tabItem.tradeList.length > 0">
								正在加载更多</div>
							<div class="load-tip" v-else-if="tabItem.loadingType == 'nomore'">没有更多数据</div>
						</div>
					</el-tab-pane>

				</el-tabs>
				<el-button plain @click="outExcel" class="export-button">导出</el-button>
			</div>

		</div>
		<template #personalFooter>
			<div style="padding: unset; text-align: right; background-color: white; margin-top: 6px; padding: 5px  267px 5px 10px;">合计：<span>{{$currency.symbol}}{{tradePayment}}</span></div>
		</template>
		<trade-again-dialog :isShow="showTradeAgainDialog" :showClose="false" :goodsList="tradeAgainGoods" @close="showTradeAgainDialog = false"></trade-again-dialog>
		<div class="dialogBox">
			<el-dialog class="detailDialog" title="查看轨迹" :visible.sync="dialogVisible" style="margin-left: 28%;width:880px;">
				<iframe :src="webviewSrc" style="border: none; width: 402px; height: 628px;"></iframe>
			</el-dialog>
		</div>
	</personal-layout>
</template>

<script>
	import personalLayout from '../../components/mine/PersonalLayout.vue'
	import tradeItem from '../../components/trade/TradeItem.vue'
	import tradeAgainDialog from '../../components/trade/TradeAgainDialog.vue';
	import tradeUtils from '../../utils/api-utils/api-trade.js';
	import exportUtils from '../../utils/api-utils/api-export.js';
	import commonUtils from '../../utils/common-utlis.js';
	import { getParam } from '../../utils/api-utils/api-params.js';
	import { getPresaleInfoByGoodsId } from '../../utils/api-utils/api-activity.js';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import refundApi from '../../utils/api-utils/api-refund.js';
	export default {
		name: 'tradelist',
		components: {
			personalLayout,
			tradeItem,
			tradeAgainDialog
		},
		data() {
			return {
				isShowLogin: false,
				activeMenu: '',
				tabCurrentIndex: 0,
				isExported: false,
				screenHeight: 650,
				dialogVisible: false,
				webviewSrc: "",
				navList: [{
						state: 0,
						text: '全  部',
						name: 'allTrade',
						pageIndex: 0,
						loadingType: 'more',
						loaded: false,
						tradeList: [],
						// 2022/11/22 新增代销发货-待采购订单
						tradeStatus: ['1010', '1020', '1030', '1050', '2000', '2010', '2020', '2030', '2040', '3010',
							'4110', '4111',
							'4112', '4113', '4121', '4122',
							'4050', '4051', '4052',
							'4123', '4130', '4040', '4041', '4042', '5010', '5020', '5030', '6000', '9090'
						],
						count: 0,
						countLoaded: false,
						searchValue: ''
					},
					{
						state: 1,
						text: '待结算',
						name: 'waitPay',
						pageIndex: 0,
						loadingType: 'more',
						loaded: false,
						tradeList: [],
						count: 0,
						countLoaded: false,
						searchValue: ''
					},
					{
						state: 2,
						text: '待收货',
						name: 'waitTrade',
						pageIndex: 0,
						loadingType: 'more',
						loaded: false,
						tradeList: [],
						// 2022/11/22 新增代销发货-待采购订单
						tradeStatus: ["1010", "1020", "1050", "2000", "2010", "2020", "2030", "2040", "3010", "4110", "4111", "4112", "4113", "4121", "4122", "4123", "4130", "4040", "4041", "4042", "4050", "4051", "4052", "6000"],
						count: 0,
						countLoaded: false,
						searchValue: ''
					},
					{
						state: 3,
						text: '已完成',
						name: 'finishedTrade',
						pageIndex: 0,
						loadingType: 'more',
						loaded: false,
						tradeList: [],
						tradeStatus: ['9090'],
						count: 0,
						countLoaded: false,
						searchValue: ''
					},
					{
						state: 4,
						text: '交易关闭',
						name: 'cancelledTrade',
						pageIndex: 0,
						loadingType: 'more',
						loaded: false,
						tradeList: [],
						// 2022/11/22 新增代销发货-待采购订单
						tradeStatus: ["1010", "1020", "1050", "2000", "2010", "2020", "2030", "2040", "3010", "4110", "4111", "4112", "4113", "4121", "4122", "4123", "4130", "4040", "4041", "4042", "4050", "4051", "4052", "6000"],
						count: 0,
						countLoaded: false,
						searchValue: ''
					},
				],
				channelId: '',
				pageSize: 15,
				searchValue: "",
				selectSearchKey: "3",
				canConfrimTrade: true,
				initData: false,
				showTradeAgainDialog: false, // 是否显示再来一单提示弹窗
				tradeAgainGoods: [], // 再来一单，库存不足下架商品
				isShowTradeReceiver: false, // 是否开启收件人
				isShowTimeFilter: false, //是否展示是时间筛选
				startFilterTime: "", //建单筛选开始时间 
				endFilterTime: "", //建单筛选结束时间
				listHander: { // 表头
					tradeTime: '下单时间',
					tradeNo: "原始单号",
					tradeStatus: "订单状态",
					payTotal: "应收合计",
					goodsSummary: "货品摘要",
					sendTo: "收货人",
					tel: "收货电话",
					address: "收货地址",
					customerRemark: "客户备注"
				},
				tradePayment: 0, //应收合计
			}
		},
		mounted() {
			this.initData = true;
			if (document.body.clientHeight < 850) {
				this.screenHeight = 500;
			}
			this._initData();
		},
		beforeRouteEnter(to, from, next) {
			//进入页面之前，判断是否是订单详情进入，是则不刷新
			if (from.name == 'tradedetailjmc') {
				to.meta.refreshPage = true;
			} else if (from.name == 'tradedetail') {
				to.meta.refreshPage = from.meta.isEdit;
				to.meta.refreshTab = 1;
			} else if (from.name == 'pay') {
				to.meta.refreshPage = true;
				to.meta.refreshTab = 1;
			} else {
				to.meta.refreshPage = true;
				to.meta.refreshTab = 0;
			}
			next();
		},
		//在页面离开时记录滚动位置
		beforeRouteLeave(to, from, next) {
			// 获取div内部滚动距离
			let scrollTop = document.getElementsByClassName('list-box')[this.tabCurrentIndex].scrollTop;
			localStorage.setItem('scrollTop', scrollTop);
			next();
		},
		activated() {
			// 页面激活是要重置搜索选项(测试要求)
			this.selectSearchKey = "3";
			this.searchValue = "";

			//来源不是订单确认，刷新数据
			if (this.$route.meta.refreshPage && !this.initData) {

				this._initData();
			} else {
				let scrollTop = localStorage.getItem('scrollTop');
				localStorage.removeItem('scrollTop');
				document.getElementsByClassName('list-box')[this.tabCurrentIndex].scrollTo(0, scrollTop);
			}
		},
		deactivated() {
			this.initData = false;
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		destroyed() {
			this.initData = false;
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		methods: {
			/**
			 * 初始化数据
			 */
			async _initData() {
				let that = this;
				that.tabCurrentIndex = that.$route.params.tabIndex || that.$route.meta.refreshTab;
				that.activeMenu = that.navList[that.tabCurrentIndex].name;
				let customerId = localStorage.getItem("pc_customerId");

				// 提示登录
				if (!customerId) {
					that.$eventBus.$emit("showLoginDialog", true, false); //显示登录弹窗,不可关闭
					return
				}

				that.customerId = customerId;

				let paramlist = await getParam('bindSaleChannelId,isShowTradeReceiver')
				that.isShowTradeReceiver = paramlist.isShowTradeReceiver == "1";
				if (paramlist.bindSaleChannelId) {
					that.channelId = paramlist.bindSaleChannelId;
					let navList = this.navList;
					for (let i = 0; i < navList.length; i++) {
						navList[i].loadingType = 'more';
						navList[i].loaded = false;
						navList[i].tradeList = [];
						navList[i].count = 0,
							navList[i].pageIndex = 0,
							navList[i].countLoaded = false;
					}
					if (that.tabCurrentIndex != 1 && that.tabCurrentIndex != 4) {
						commonUtils.debounce(this._getTradeList())
					} else {
						that._getWaitTradeList();
					}
					that._getOmsTradeCount(navList[2].tradeStatus); //获取待收货订单数量
					// 吉商城待结算订单数量
					this._getJmallTradeCount("1,16,19");
				}
			},

			/**
			 * 切换tab
			 */
			menuClick(tab, event) {
				this.tabCurrentIndex = parseInt(tab.index);
				// if (this.navList[this.tabCurrentIndex].searchValue == this.searchValue && !this.searchValue) {
				// 	this.loadTradeList();
				// } else {
				// 	this._refreshTradeList();
				// }
				this._refreshTradeList();
			},

			/**
			 * 滚动加载
			 */
			loadTradeList() {
				(this.tabCurrentIndex == 1 || this.tabCurrentIndex == 4) ? this._getWaitTradeList(): commonUtils.debounce(this._getTradeList());
			},

			/**
			 * 点击搜索
			 */
			search() {
				this.$message.closeAll();
				if (this.searchValue && !this.selectSearchKey) {
					this.$message.error("请选择搜索类型");
					return;
				}
				if (this.navList[this.tabCurrentIndex].loadingType === 'loading') {
					this.$message.error("列表正在加载中，请稍候点击");
					return;
				}
				if ((this.startFilterTime && this.endFilterTime) && this.startFilterTime > this.endFilterTime) {
					this.$message.error("起始时间不得大于终止时间");
					return;
				}
				this._refreshTradeList();
			},

			/**
			 * 取消订单
			 */
			cancelTrade(value) {
				let that = this;
				that.$confirm("确定取消订单吗？", {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(async () => {
					let result = await tradeUtils.cancelTrade(value);
					if (result) {
						setTimeout(() => {
							//取消订单后删除待结算中该项
							let list = that.navList[1].tradeList;
							let index = list.findIndex(val => val.tradeId === value);
							index !== -1 && list.splice(index, 1);
							that.navList[1].count = that.navList[1].count - 1;
							that.$message({
								type: 'success',
								message: '取消成功'
							});
						}, 500);
					}
				});
			},

			/**
			 * 继续支付
			 */
			async repay(trade) {
				// 继续支付订单号检测，如果和PC冲突则会重新生成订单编号
				let tradeNo = await tradeUtils.getContinuePayTradeNo(trade.tradeNo);
				if (!!tradeNo.error) {
					this.$message.error(tradeNo.error);
					return;
				}
				if (trade.activityEndTime && Date.parse(trade.activityEndTime) < Date.now()) {
					this.$message.error("预售活动已到期，请重新下单购买");
					return;
				}
				this.$router.push({
					name: 'pay',
					params: {
						tradeNo: tradeNo
					}
				});
			},

			/**
			 * 确认收货
			 */
			confirmTrade(item) {
				let that = this;
				if (!that.canConfrimTrade) {
					return;
				}
				that.$confirm('确认收货？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(async () => {
					that.canConfrimTrade = false;

					let tradeDetail = await tradeUtils.getJmallTradeDetail(item.sourceTradeNo.split(",")[0])

					// 调用转发接口 arrivedJudge boolean
					let arrivedJudge = await tradeUtils.confirmTradeArrive(item.tradeId);
					that.canConfrimTrade = true;

					if (!arrivedJudge) {
						return
					}

					tradeUtils.addTradeConfirmArriveLog(tradeDetail.tradeId, tradeDetail.tradeNick);


					if (that.tabCurrentIndex == 2) {
						//确认收货后删除待收货中该项
						let list = that.navList[2].tradeList;
						let index = list.findIndex(val => val.tradeId === item.tradeId);
						index !== -1 && list.splice(index, 1);
						that.navList[2].count = that.navList[2].count - 1;
						//全部页面数据重新加载
						that.navList[0].pageIndex = 0;
						that.navList[0].loadingType = 'more';
						that.navList[0].tradeList = [];
						that.navList[0].loaded = false;

						//已完成页面数据重新加载	
						that.navList[3].pageIndex = 0;
						that.navList[3].loadingType = 'more';
						that.navList[3].tradeList = [];
						that.navList[3].loaded = false;
					} else {
						that._refreshTradeList();
					}
					that.$message({
						type: 'success',
						message: '收货成功'
					});
				});
			},

			/**
			 * 展示物流
			 */
			displayTrajectory(res) {
				this.dialogVisible = res.dialogVisible;
				this.webviewSrc = res.webviewSrc;
			},

			/**
			 * 再来一单
			 */
			async tradeAgain(trade) {
				//展示加载遮罩层
				let loadingLayer = this.$loading({
					lock: true,
					background: 'rgba(255, 255, 255, 0.2)'
				});

				// 获取原始单数据
				let jmallTradeNoList = trade.tradeOrder.type == "jmall" ? [trade.tradeNo] : trade.tradeOrder.sourceTradeNo && trade.tradeOrder.sourceTradeNo.split(',');
				if (!(jmallTradeNoList && jmallTradeNoList.length > 0)) {
					this.$message.error("未获取到原始单信息");
					loadingLayer.close(); //关闭加载遮罩层
					return;
				}

				// 获取吉商城下单的商品信息
				let jmallTradeGoodsList = [];
				for (let i = 0; i < jmallTradeNoList.length; i++) {
					// 获取订单详情
					let jmallTradeInfo = await tradeUtils.getJmallTradeDetail(jmallTradeNoList[i]);
					jmallTradeGoodsList = jmallTradeGoodsList.concat(jmallTradeInfo.tradeGoodsList);
				}

				// 如果是吉客云订单再来一单，则需要按照吉客云订单进行数量控制
				let needAddCartGoodsList = [];
				if (trade.tradeOrder.type == "jmall") {
					needAddCartGoodsList = jmallTradeGoodsList;
				} else {
					// 获取销售单详情
					let omsTradeInfo = await tradeUtils.getOmsTradeDetail(trade.tradeNo);
					omsTradeInfo.tradeOrderGoodsArr.forEach(omsGoods => {
						// 获取吉客云订单中对应的吉商城原始单货品信息
						let jmallTradeGoodsItem = jmallTradeGoodsList.find(t => t.goodsId == omsGoods.goodsId && t.specId == omsGoods.skuId && t.unit == omsGoods.unit);
						if (jmallTradeGoodsItem) {
							let needAddCartGoodsItem = JSON.parse(JSON.stringify(jmallTradeGoodsItem));
							needAddCartGoodsItem.sellCount = omsGoods.sellCount;
							needAddCartGoodsList.push(needAddCartGoodsItem);
						}
					});
				}

				// 检测是存在可加购商品
				if (!(needAddCartGoodsList && needAddCartGoodsList.length > 0)) {
					this.$message.error("无可加购商品");
					loadingLayer.close(); //关闭加载遮罩层
					return;
				}

				// 前置数据
				let preList = needAddCartGoodsList.map(t => {
					return {
						goodsId: t.goodsId,
						specId: t.specId,
						unit: t.unit,
						actId: t.actId,
						actType: t.actType,
						goodsCount: t.sellCount,
					}
				});

				// 加入购物车
				let cartIds = await goodsUtils.addTempShoppingCartBatch(preList);
				if (!cartIds || cartIds == 0) {
					this.$message.error("加购失败");
					loadingLayer.close(); //关闭加载遮罩层
					return;
				}

				this.$router.push({
					name: 'shopcart',
					params: {
						cartFrom: '再来一单'
					}
				});
			},

			/**
			 * 订单详情
			 */
			async tradeDetail(item) {
				if (this.tabCurrentIndex == 1 || this.tabCurrentIndex == 4) {
					// 继续支付订单号检测，如果和PC冲突则会重新生成订单编号
					let tradeNo = await tradeUtils.getContinuePayTradeNo(item.tradeNo);
					this.$router.push({
						name: 'tradedetail',
						query: {
							no: item.tradeStatus == "0" ? item.tradeNo : tradeNo
						}
					});
				} else {
					this.$router.push({
						name: 'tradedetailjmc',
						query: {
							no: item.tradeNo
						}
					});
				}

			},

			/**
			 * 加载吉客云订单列表
			 */
			async _getTradeList() {
				let that = this;
				//这里是将订单挂载到tab列表下
				let index = that.tabCurrentIndex;
				let navItem = that.navList[index];
				if (index == 1) {
					return;
				}

				if (!that.channelId) {
					// //未设置渠道不读订单
					// navItem.loadingType = 'nomore';
					// navItem.loaded = true;
					return;
				}
				if (navItem.loaded === true) {
					//只有第一次需要加载数据
					return;
				}
				if (navItem.loadingType === 'loading') {
					//防止重复加载
					return;
				}

				navItem.loadingType = 'loading';
				//搜索条件
				let jsonStr = {};

				// 订单状态
				if (that.tabCurrentIndex != 1 || that.tabCurrentIndex != 4) {
					jsonStr.tradeStatus = navItem.tradeStatus;
				}

				// 建单时间筛选
				jsonStr.tradeTimeBegin = this.startFilterTime ? this.startFilterTime + " 00:00:00" : "";
				jsonStr.tradeTimeEnd = this.endFilterTime ? this.endFilterTime + " 23:59:59" : "";

				//搜索内容
				let paymentLikeSelectDto = {};
				let searchValue = that.searchValue;
				if (searchValue) {
					switch (that.selectSearchKey) {
						case "1":
							jsonStr.tradeNo = searchValue;
							paymentLikeSelectDto = {
								keyword: searchValue,
							};
							break;
						case "2":
							// 直接手机号搜索不支持模糊，暂时弃用
							// jsonStr.mobile = searchValue;

							// 模糊搜索，文档中未开放，selectType:1 网店订单号, 2 客户账号, 3 手机, 4 物流单号, 5 客服备注, 6 客户备注, 7 收货人, 8 订单编号, 9 客户名称
							// 注意：selectType=3，目前也只支持精确搜索
							jsonStr.likeSelectDto = {
								keyword: searchValue,
								selectType: [3]
							};
							paymentLikeSelectDto = {
								mobile: searchValue,
							};
							break;
						case "3":
							jsonStr.goodslist = searchValue;
							break;
						case "4":
							jsonStr.receiverName = searchValue;
							paymentLikeSelectDto = {
								receiverName: searchValue,
							};
							break;
						case "5":
							jsonStr.address = searchValue;
							paymentLikeSelectDto = {
								address: searchValue,
							};
							break;
						case "6":
							jsonStr.mainPostid = searchValue;
							paymentLikeSelectDto = {
								mainPostid: searchValue,
							};
							break;
						case "7":
							jsonStr.sourceTradeNo = searchValue;
							paymentLikeSelectDto = {
								sourceTradeNo: searchValue,
							};
						default:
							break;
					}
				}

				// if (that.channelId) {
				// 	jsonStr.shopId = that.channelId;
				// }

				// 测试代码（开发环境暂时注释，需要恢复）
				// 添加子商城标识
				jsonStr.jmallAccountName = localStorage.getItem("SubMemberName") || localStorage.getItem("JackyunUserName");

				//获取订单总数目
				if (index == 2 && !navItem.countLoaded) {
					that._getOmsTradeCount(jsonStr.tradeStatus);
				}

				this._getAndSetOmsTradePayment(jsonStr, paymentLikeSelectDto)

				//分页信息
				let pageInfo = {};
				pageInfo.sortOrder = 'DESC';
				pageInfo.sortField = 'tradeTime';

				// 获取oms订单列表
				let omsTradeList = await tradeUtils.getOmsTradeList(jsonStr, pageInfo, navItem.pageIndex, that.pageSize);

				if (!omsTradeList || omsTradeList.length == 0) {
					navItem.loadingType = 'nomore';
					navItem.loaded = true;
					return
				}

				let picList = await tradeUtils.getPicByTradeNoList(omsTradeList.map(item => item.sourceTradeNo));

				// 订单数据处理
				omsTradeList.forEach(item => {
					// 订单状态处理，如：待发货-已递交，只显示待发货
					if (item.tradeOrder.tradeStatusExplain && item.tradeOrder.tradeStatusExplain.includes("取消")) {
						item.tradeOrder.isCancelled = true;
					} else {
						item.tradeOrder.tradeStatusExplain = item.tradeOrder.tradeStatusExplain.split('-')[0] || "";
					}
					item.tradeOrder.tradeStatusExplain = item.tradeOrder.tradeStatusExplain.includes("代销发货") ? "待发货" : item.tradeOrder.tradeStatusExplain;

					// 订单类型 
					item.tradeOrder.type = "oms"

					// 是否为权益券订单
					item.isMemberCouponTrade = !item.sourceTradeNo && item.tradeOrder.sellerMemo && item.tradeOrder.sellerMemo.startsWith('该订单为卡券兑换');
					// 判断是否为付费会员订单
					item.isMemberTrade = item.sourceTradeNo && item.sourceTradeNo.startsWith("HY");

					// 商品名称处理
					if (item.tradeOrder.goodslist) {
						try {
							// 如果是json传，直接反序列化，不是则拆分处理
							let goodsInfoList = item.tradeOrder.goodslist.includes('{"') ? JSON.parse(item.tradeOrder.goodslist).goodsInfoList : item.tradeOrder.goodslist.split(',');
							//获取示例商品,格式：(1)商品名称[规格]
							item.tradeOrder.goodsName = goodsInfoList[0] + (goodsInfoList.length > 1 ? '等' : '');
						} catch (e) {
							item.tradeOrder.goodsName = item.tradeOrder.goodslist;
						}
					}

					// 处理收件人手机号
					item.tradeOrder.isLockPhone = true;
					try {
						let mobile = this.parseTel(item.tradeOrderReceiver.mobile);
						item.tradeOrderReceiver.jackyunMobile = item.tradeOrderReceiver.mobile;
						item.tradeOrderReceiver.realTel = mobile;
						item.tradeOrder.realTel = mobile;
						let fuzzyMobile = this.getFuzzyTel(mobile);
						item.tradeOrderReceiver.mobile = fuzzyMobile;
						item.tradeOrder.mobile = fuzzyMobile;
						item.tradeOrder.address = item.tradeOrderReceiver.address;
						item.tradeOrder.sendTo = item.tradeOrderReceiver.receiverName
					} catch (e) {}

					// 图片
					item.tradeOrder.mainPic = picList.filter(picItem => item.sourceTradeNo == picItem.tradeNo).filter(item => !!item.picUrl)[0] && picList.filter(picItem => item.sourceTradeNo == picItem.tradeNo).filter(item => !!item.picUrl)[0].picUrl;
				})

				// 补充吉商城订单状态数据（退款单状态，评论状态等）
				await this.__completeTradeExtendedInfo(omsTradeList);

				let nowTradeList = navItem.tradeList;
				navItem.tradeList = nowTradeList.concat(omsTradeList);

				if (omsTradeList.length < that.pageSize) {
					navItem.loadingType = 'nomore';
					navItem.loaded = true;
				} else {
					navItem.pageIndex++;
					navItem.loadingType = 'more';
					navItem.loaded = false;
				}
			},

			/**
			 * 补充订单扩展信息（退款单状态，评论状态等）
			 */
			async __completeTradeExtendedInfo(omsTradeList) {
				return new Promise(async (resolve, reject) => {
					// 获取吉客云订单编号
					let jackyunTradeNoList = omsTradeList.map(t => t.tradeNo);

					// 执行集合
					let promiseList = [
						// 获取退款单状态
						refundApi.getRefundStatusByJackyunTradeNo(jackyunTradeNoList),
						// 获取订单评论状态
						// getTradeCommentStatus(jackyunTradeNoList),
					];

					// 等待所有接口执行完成
					Promise.all(promiseList).then((res) => {
						// 获取出返回数据
						let refundStatusList = res[0] || [];
						// let commentStatusList = res[1] || [];

						// 遍历订单，补充相关数据
						omsTradeList.forEach(trade => {
							// 补充退款单状态
							let curTradeRefundList = refundStatusList.filter(t => t.jackyunTradeNo == trade.tradeNo);
							// 处理退款单状态
							this.__processingTradeRefundStatus(curTradeRefundList, trade.tradeOrder);

							// // 补充订单评价状态
							// let commentStatusInfo = commentStatusList.find(t => t.jackyunTradeNo == trade.tradeNo);
							// trade.isCommented = commentStatusInfo?.commentStatus || false;
						});

						resolve(omsTradeList);
					});
				});
			},

			/**
			 * 订单退款单状态处理（一个订单可能对应多个退款单）
			 * @param {*} refundStatusList 
			 * @param {*} tradeInfo 
			 */
			__processingTradeRefundStatus(refundStatusList, trade = {}) {
				/**
				 * 退款状态（0待审核，1驳回，2审核通过）
				 * 退款类型（0退款，1退货）
				 */

				// 根据退款状态去重，多个相同状态的退款单做同样的状态处理
				refundStatusList = commonUtils.distinctArr(refundStatusList, "sellbackStatus");
				if (!refundStatusList || refundStatusList.length <= 0) {
					trade.sellbackStatus = -1;
					trade.sellbackType = -1;
					trade.sellbackStatusExplain = "";
					return trade;
				}

				// 当前订单退款状态，单一有效的状态数据
				let curTradeRefundInfo;
				if (refundStatusList.length == 1) {
					curTradeRefundInfo = refundStatusList[0];
				} else {
					// 去除“驳回”状态的单据，以当前生效的退款单为准
					let refundStatusNotRejectList = refundStatusList.filter(t => t.sellbackStatus != 1);
					if (refundStatusNotRejectList && refundStatusNotRejectList.length == 1) {
						curTradeRefundInfo = refundStatusNotRejectList[0];
					}
				}

				// 没有单一可判断的退款单状态，存在多条退款单状态的情况，显示“退款”或者“退货”
				if (!curTradeRefundInfo) {
					trade.sellbackStatus = refundStatusList[0].sellbackStatus;
					trade.sellbackType = refundStatusList[0].sellbackType;
					trade.sellbackStatusExplain = refundStatusList[0].sellbackType == 2 ? "退货" : "退款";
					return trade;
				}

				// 退款状态（0待审核，1驳回，2同意）
				trade.sellbackStatus = curTradeRefundInfo.sellbackStatus;
				// 退款类型（1退款，2退货）
				trade.sellbackType = curTradeRefundInfo.sellbackType;
				// 退款状态说明
				if (trade.sellbackType == 1 && trade.sellbackStatus == 0) {
					trade.sellbackStatusExplain = "待商家同意退款";
				} else if (trade.sellbackType == 1 && trade.sellbackStatus == 1) {
					trade.sellbackStatusExplain = "商家拒绝退款";
				} else if (trade.sellbackType == 1 && trade.sellbackStatus == 2) {
					trade.sellbackStatusExplain = "退款成功";
				} else if (trade.sellbackType == 2 && trade.sellbackStatus == 0) {
					trade.sellbackStatusExplain = "待商家同意退货";
				} else if (trade.sellbackType == 2 && trade.sellbackStatus == 1) {
					trade.sellbackStatusExplain = "商家拒绝退货";
				} else if (trade.sellbackType == 2 && trade.sellbackStatus == 2) {
					trade.sellbackStatusExplain = "退货成功";
				} else {
					trade.sellbackStatusExplain = "退款/退货";
				}
				return trade;
			},

			/**
			 * 加载待结算订单列表
			 */
			async _getWaitTradeList() {
				let that = this;
				//这里是将订单挂载到tab列表下				
				let navItem = this.tabCurrentIndex == 1 ? that.navList[1] : that.navList[4];

				if (!that.channelId) {
					//未设置渠道不读订单
					// navItem.loadingType = 'nomore';
					// navItem.loaded = true;
					return;
				}
				if (navItem.loaded === true) {
					//第一次需要加载数据
					return;
				}
				if (navItem.loadingType === 'loading') {
					//防止重复加载
					return;
				}

				navItem.loadingType = 'loading';

				// 待结算列表应收合计 条件参数
				let keyword = "",
					tradeNo = "",
					goodsName = "",
					tel = "",
					address = "",
					sendTo = "",
					beginTime = this.startFilterTime ? `${this.startFilterTime} 00:00:00` : "",
					endTime = this.endFilterTime ? `${this.endFilterTime} 23:59:59` : ""

				let searchkey = 0;
				if (that.searchValue) {
					// 根据搜索类型赋值不同的搜索条件
					switch (that.selectSearchKey) {
						case "1":
							keyword = this.searchValue;
							break;
						case "2":
							tel = this.searchValue;
							break;
						case "3":
							goodsName = this.searchValue;
						case "4":
							sendTo = this.searchValue;
							break;
						case "5":
							address = that.searchValue;
							break;
						case "7":
							tradeNo = this.searchValue;
							break;
						default:
							break;

					}
				}

				let tradeStatus = this.tabCurrentIndex == 1 ? "1,16,19" : "0,18"

				// 吉商城待结算订单数量
				this._getJmallTradeCount("1,16,19");

				// 吉商城待结算订单应收合计
				this._getAndSetJmallTradePayment(tradeStatus, tradeNo, goodsName, tel, address, this.startFilterTime, this.endFilterTime, sendTo);

				// 获取待结算订单列表
				let newTradeList = await tradeUtils.getJmallTradeList(navItem.pageIndex, that.pageSize, tradeStatus, keyword, tradeNo, goodsName, tel, address, beginTime, endTime, sendTo);
				if (newTradeList.length <= 0) {
					navItem.loadingType = 'nomore';
					navItem.loaded = true;
					navItem.count = navItem.tradeList.length;
					that.navList[this.tabCurrentIndex == 1 ? 1 : 4] = navItem;
					return
				}

				let picList = await tradeUtils.getPicByTradeNoList(newTradeList.map(item => item.tradeNo));

				// 将吉商城订单统一按照吉客云订单格式处理
				newTradeList.forEach((item, index) => {
					item.tradeOrder = {};
					item.tradeOrderReceiver = {};
					item.tradeOrder.tradeTime = item.tradeTime;
					item.tradeOrder.tradeType = item.tradeType;
					item.tradeOrder.tradeStatus = item.tradeStatus;
					item.tradeOrder.tradeFinalPayTime = item.tradeFinalPayTime;
					item.tradeOrder.type = "jmall"
					let arrGoods = item.TradeGoodsList;

					// 商品图片
					item.tradeOrder.mainPic = picList.filter(picItem => item.tradeNo == picItem.tradeNo).filter(item => !!item.picUrl)[0] && picList.filter(picItem => item.tradeNo == picItem.tradeNo).filter(item => !!item.picUrl)[0].picUrl;

					// 拆分商品摘要信息
					try {
						if (item.goodsList.includes("【") && item.goodsList.includes("共")) {
							item.tradeOrder.goodsName = item.goodsList.substr(0, item.goodsList.indexOf(" 共"));
							item.tradeOrder.tradeCount = item.goodsList.substr(item.goodsList.indexOf(" 共")).replace(" 共", "").replace("件", "")
						} else {
							// 摘要商品名称
							let goodsArr = item.goodsList && item.goodsList.split(',') || [];
							if (goodsArr.length > 0) {
								item.tradeOrder.goodsName = goodsArr[0] + (goodsArr.length > 1 ? "等" : "");
							}
							// 计算商品数量
							item.tradeOrder.tradeCount = sumArr(goodsArr.map(t => {
								// 匹配括号中的数字，使用\d表示数字字符，使用+表示数字字符可以出现1次或多次 
								let matchRes = t.match(/\((\d+)\)/);
								// 使用match()方法匹配字符串中的数字 
								return matchRes == null ? 0 : parseInt(matchRes[1]);
							})) || 1;
						}
					} catch (e) {
						item.tradeOrder.goodsName = "";
						item.tradeOrder.tradeCount = 1;
					}

					//处理订单状态 如：待发货-已递交，只显示待发货
					item.tradeOrder.tradeStatusExplain = item.tradeStatus == '19' ? '待商家确认收款' : (item.tradeStatus == '18' ? "支付超时-已关闭" : item.tradeStatus == '0' ? '已取消' : "待结算");

					item.tradeOrder.payment = item.rcvTotal;
					item.tradeOrder.pointTotal = item.scoreTotal;
					item.tradeOrder.tradeNo = item.tradeNo;
					item.tradeOrder.tradeId = item.tradeId;
					item.tradeOrder.receivedPostFee = item.postageTotal;

					// 处理收件信息
					//item.tradeOrder.isLockPhone = true;
					item.tradeOrder.realTel = item.tel;
					item.tradeOrder.mobile = item.tel;
					item.tradeOrder.address = this.getJmallFullAddress(item.country, item.province, item.city, item.district, item.town, item.address);
					item.tradeOrder.sendTo = item.sendTo;
				});

				//合并最新数据和已有数据
				let nowTradeList = navItem.tradeList;
				navItem.tradeList = nowTradeList.concat(newTradeList);

				if (newTradeList.length < that.pageSize) {
					navItem.loadingType = 'nomore';
					navItem.loaded = true;
				} else {
					navItem.pageIndex++;
					navItem.loadingType = 'more';
					navItem.loaded = false;
				}

				that.navList[this.tabCurrentIndex == 1 ? 1 : 4] = navItem;
			},

			/**
			 * 订单导出
			 */
			async outExcel() {
				let that = this;
				let loadingLayer = this.$loading({
					text: '导出中...',
					lock: true,
					background: 'rgba(255, 255, 255, 0.2)'
				});

				let exportTradeList = [];
				let customerId = localStorage.getItem("pc_customerId");
				if (!customerId || customerId == "0") {
					this.$message.error({
						message: "请登录后操作"
					});
					return;
				}

				// 获取吉客云订单
				let jackyunallCount = await this._getOmsTradeCount(this.navList[0].tradeStatus, true)
				if (jackyunallCount > 0) {
					let jsonStr = {};
					//jsonStr.jmallAccountName = localStorage.getItem("SubMemberName") || localStorage.getItem("JackyunUserName");
					jsonStr.tradeStatus = this.navList[0].tradeStatus;
					let jackyuntrade = await tradeUtils.getOmsTradeList(jsonStr, {
						sortOrder: 'DESC',
						sortField: 'tradeTime'
					}, 0, jackyunallCount);
					exportTradeList = exportTradeList.concat(this.genertateExportOmsTradeInfo(jackyuntrade));
				}

				// 获取全部待结算单
				let jmallWaitTradeList = await tradeUtils.getJmallTradeList(0, 1000, "1,16,19");

				// 拼接exportTradeList
				exportTradeList = exportTradeList.concat(this.genertateExportJmallTradeInfo(jmallWaitTradeList));

				exportUtils.xlsx(exportTradeList, this.listHander, '用户订单信息');
				loadingLayer.close(); //关闭加载遮罩层
			},


			/**
			 * 格式化导出需要的待结算订单列表数据
			 */
			genertateExportJmallTradeInfo(tradeList) {
				return tradeList.map(item => {
					let goodsSummary
					try {
						if (item.goodsList.includes("【") && item.goodsList.includes("共")) {
							goodsSummary = item.goodsList.substr(0, item.goodsList.indexOf(" 共"));
						} else {
							// 摘要商品名称
							let goodsArr = item.goodsList && item.goodsList.split(',') || [];
							if (goodsArr.length > 0) {
								goodsSummary = goodsArr[0] + (goodsArr.length > 1 ? "等" : "");
							}

						}
					} catch (e) {
						goodsSummary = "";
					}
					return {
						tradeTime: item.tradeTime,
						tradeNo: item.tradeNo,
						tradeStatus: "未支付",
						payTotal: item.rcvTotal,
						goodsSummary: goodsSummary,
						sendTo: item.sendTo,
						tel: item.tel,
						address: this.getJmallFullAddress(item.country, item.province,
							item.city, item.district, item.town, item.address),
						customerRemark: item.remark || ""
					}
				})
			},

			/**
			 * 格式化导出需要的吉客云订单列表数据
			 */
			genertateExportOmsTradeInfo(tradeList) {
				return tradeList.map(item => {
					let goodsSummary
					if (item.tradeOrder.goodslist) {
						try {
							// 如果是json传，直接反序列化，不是则拆分处理
							let goodsInfoList = item.tradeOrder.goodslist.includes('{"') ? JSON.parse(item.tradeOrder.goodslist).goodsInfoList : item.tradeOrder.goodslist.split(',');
							//获取示例商品,格式：(1)商品名称[规格]
							goodsSummary = goodsInfoList[0] + (goodsInfoList.length > 1 ? '等' : '');
						} catch (e) {
							goodsSummary = item.tradeOrder.goodslist;
						}
					}
					return {
						tradeTime: this.convertTime(item.tradeOrder
							.tradeTime),
						tradeNo: item.tradeOrder.sourceTradeNo,
						tradeStatus: item.tradeOrder.tradeStatusExplain,
						payTotal: item.tradeOrder.payment,
						goodsSummary: goodsSummary,
						sendTo: item.tradeOrderReceiver.receiverName,
						tel: item.tradeOrderReceiver.mobile,
						address: item.tradeOrderReceiver.address,
						customerRemark: item.tradeOrder.buyerMemo || ""
					}
				})
			},

			/**
			 * 获取待结算应收合计
			 */
			async _getAndSetJmallTradePayment(tradeStatus, tradeNo, goodsName, tel, address, beginTime, endTime, sendTo) {
				// 查询应收合计
				let tempTradePayment = await tradeUtils.getJmallTradePayment(tradeStatus, tradeNo, goodsName, tel, address, beginTime, endTime, sendTo) || 0;
				this.tradePayment = tempTradePayment.toFixed(2);
			},

			/**
			 * 获取oms应收合计
			 */
			async _getAndSetOmsTradePayment(paymentReqData, paymentLikeSelectDto) {
				// 需要过滤已取消的订单
				let tempTradeStatus = paymentReqData.tradeStatus.filter(item => item != '5010' && item != '5020' && item != '5030')

				// 查询应收合计
				let tempTradePayment = await tradeUtils.getOmsTradePayment(tempTradeStatus, this.startFilterTime, this.endFilterTime, paymentReqData.notIncludeFlagIdList, paymentReqData.goodslist, paymentLikeSelectDto, paymentReqData.jmallAccountName) || 0;
				this.tradePayment = tempTradePayment.toFixed(2);
			},

			/**
			 * 获取吉客云订单总数s
			 */
			async _getOmsTradeCount(tradeStatus, isNoSet) {
				let jackyunTradeCount = await tradeUtils.getPersonalOmsTradeCount(tradeStatus)

				// 是否需要设置数量
				if (isNoSet) {
					return jackyunTradeCount
				}

				this.navList[2].count = jackyunTradeCount;
				this.navList[2].countLoaded = true;
			},

			/**
			 * 获取吉商城订单总数
			 */
			async _getJmallTradeCount(tradeStatus) {
				let jmallTradeCount = await tradeUtils.getJmallTradeCount(tradeStatus)
				this.navList[1].count = jmallTradeCount;
				this.navList[1].countLoaded = true;
			},

			/**
			 * 刷新数据
			 */
			_refreshTradeList() {
				let index = this.tabCurrentIndex;
				let navItem = this.navList[index];
				navItem.loadingType = 'more';
				navItem.tradeList = [];
				navItem.pageIndex = 0;
				navItem.loaded = false;
				navItem.countLoaded = false;
				(this.tabCurrentIndex == 1 || this.tabCurrentIndex == 4) ? this._getWaitTradeList(): commonUtils.debounce(this._getTradeList());
			},

			/**
			 * 清空
			 */
			clearFn() {
				this.searchValue = "";
				this.selectSearchKey = "";
			},

			/**
			 * 时间统一转为斜杠
			 */
			convertTime(time) {
				return time.replace(/-/g, '/');
			},

			/**
			 * 解析手机号（去除加密前后缀）
			 * @param {Object} tel
			 */
			parseTel(tel) {
				if (!tel) {
					return "";
				}

				if (!tel.includes('differ')) {
					return tel;
				}
				try {
					return tel.substring(tel.indexOf('~*') + 1, tel.indexOf('~/diffe'));
				} catch (e) {
					return tel;
				}
			},

			/**
			 * 模糊手机号
			 * @param {Object} tel
			 */
			getFuzzyTel(tel) {
				try {
					return `*******${tel.substr(tel.length-4)}`;
				} catch (e) {
					return tel;
				}
			},

			/**
			 * 展示时间筛选
			 */
			showTimeFilter() {
				this.isShowTimeFilter = !this.isShowTimeFilter;
			},

			/**
			 * 拼接吉商城完整地址
			 * @param {Object} country
			 * @param {Object} province
			 * @param {Object} city
			 * @param {Object} district
			 * @param {Object} town
			 * @param {Object} address
			 */
			getJmallFullAddress(country, province, city, district, town, address) {
				if (!country || country == "中国") {
					return `${province||''} ${city||''} ${district||''} ${address||''}`;
				} else {
					return `${country||''} ${address||''}`;
				}
			},
		}
	}
</script>

<style scoped="scoped">
	.right-box {
		background-color: #fff;
		padding: 30px !important;
		font-size: 18px;
		height: calc(100% - 60px);
	}

	.top-box {
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	.size18 {
		font-size: 18px !important;
	}

	.tab-box {
		margin-left: 30px;
	}

	.list-box {
		height: 650px;
		overflow: auto;
	}

	.list-box-small {
		height: 600px;
		overflow: auto;
	}


	/deep/.el-input-group__prepend {
		width: 70px;
	}

	/deep/.el-tabs__item {
		font-size: 17px;
		color: #aeaeae;
		padding: 0 40px;
	}

	/deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
		padding-left: 40px;
	}

	/deep/.el-tabs__item:hover,
	/deep/.el-tabs__item.is-active {
		color: #000;
	}

	/deep/.el-tabs__active-bar {
		background-color: #000 !important;
	}

	/deep/.el-badge__content.is-fixed {
		top: 10px;
		right: -5px;
	}

	/deep/.el-badge__content {
		background-color: #000 !important;
	}

	.load-tip {
		color: #999;
		font-size: 14px;
		margin-top: 20px;
		text-align: center;
	}

	.search-box /deep/.el-input-group__append {
		background-color: #f56c6b;
		border-color: #f56c6b;
		color: #fff;
		font-size: 20px;
	}




	.export-button {
		position: absolute;
		top: 0px;
		right: 0px;
		line-height: 8px;
	}

	.content-box {
		padding: 30px;
		height: calc(100% - 240px);
	}

	.detailDialog /deep/ .el-dialog {
		overflow: hidden;
	}

	.dialogBox /deep/ .el-dialog__wrapper {
		overflow: hidden !important;
	}

	.detailDialog /deep/ .el-dialog__body {
		width: 102%;
		padding-top: 0 !important;
		overflow-y: auto !important;
	}

	.top-box-r {
		width: 565px;
	}

	.search-box {
		display: flex;
	}

	.box-arrow {
		margin-left: 20px;
		display: flex;
		font-size: 16px;
		width: 88px;
		line-height: 40px;
	}

	.el-icon-arrow-down {
		font-size: 24px;
		line-height: 40px;
		margin-left: 10px;
	}

	.el-icon-arrow-up {
		font-size: 24px;
		line-height: 36px;
		margin-left: 10px;
	}

	.timefilter-box {
		margin-top: 10px;
		display: flex;
		padding-right: 92px;
	}

	.el-icon-minus {
		margin: 0 10px;
		line-height: 40px;
	}
</style>