<template>
	<div class="log-list">
		<el-dialog title="物流轨迹" :visible.sync="visible" :before-close="onClose" center width="800px">
			<div class="time-line-box">
				<el-timeline>
					<template v-for="(item, index) in tradeLogList">
						<el-timeline-item :timestamp="item.logTime" :color="index== 0 ? '#FF5757' : ''">
							<div :class="{first: index== 0}">{{item.logName}}</div>
							<div>{{item.logDetail}}</div>
							<div class="operator">操作员：{{item.userName}}</div>
						</el-timeline-item>
					</template>
				</el-timeline>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { callFeign } from "../../utils/api-utils/api-callFeign.js"
	import { getParam } from "../../utils/api-utils/api-params.js"
	export default {
		name: "trade-logistic",
		props: {
			isShow: {
				type: Boolean,
				default: false
			},
			tradeId: {
				type: String,
				default: ''
			},
			tradeNo: {
				type: String,
				default: ''
			},
			logisticId: {
				type: String,
				default: ''
			},
			logisticNo: {
				type: String,
				default: ''
			}
		},
		watch: {
			isShow(newValue, oldValue) {
				this.visible = this.isShow;
			},
			tradeId(newValue, oldValue) {
				if (newValue) {
					this._getTradeLog();
				}
			},
		},
		async created() {
			let paramList = await getParam("isShowOmsLogDetail");
			this.isShowOmsLogDetail = paramList.isShowOmsLogDetail == "1";
		},
		data() {
			return {
				visible: this.isShow,
				bOnlyLogisticInfo: false, //是否仅展示物流轨迹
				tradeLogList: [], //订单操作日志
				logisticInfo: [], //物流轨迹信息
				bVideoHide: true, //是否隐藏发货视频
				logisticVideoUrl: [], //发货视频地址
				deliverLog: [], //发货日志
				hasTrailInfo: false, //是否有订单轨迹相关信息
			}
		},
		methods: {
			onClose() {
				this.$emit('close');
			},

			async _getTradeLog() {
				// 获取订单日志
				let tradeLogList = await this.__getTradeLogList(this.tradeId);

				// 更改日期格式
				tradeLogList.forEach(item => {
					item.logTime = this.formatTime2(item.logTime, 'M-D h:m');
				})

				this.tradeLogList = tradeLogList;

				this.$emit('hastrailinfo', this.hasTrailInfo, tradeLogList[0].logTime + " " + tradeLogList[0].logName);

				//获取物流信息
				let logisticInfoList = await this.__getLogisticInfoList(this.logisticId, this.logisticNo);
				if (logisticInfoList && logisticInfoList.length > 0) {
					// 渲染列表
					this.tradeLogList = logisticInfoList.concat(tradeLogList);
				}
			},

			/**
			 * 获取物流信息
			 * @param {*} logisticId 
			 * @param {*} logisticNo 
			 */
			async __getLogisticInfoList(logisticId, logisticNo) {
				// 检测物流ID是否存在
				if (!logisticId || !logisticNo) {
					return [];
				}

				// 物流公司code
				let expressCode = await this.__getExpressCode__(logisticId);

				return new Promise((resolve, reject) => {
					callFeign("lts.trade.routelist", {
						logisticNo,
						expressCode
					}).then((res) => {
						try {
							// 获取物流信息
							let logisticInfoList = res.result.data.logisticRoutesDTO;

							// 处理成和订单日志格式一致（并且倒序处理）
							let resultList = logisticInfoList.map(t => {
								try {
									// 处理时间格式
									t.routeTime = t.routeTime.substring(t.routeTime.indexOf('-') + 1, t.routeTime.lastIndexOf(':'));
								} catch (e) {}

								return {
									logName: t.desc,
									logTime: t.routeTime,
									logDetail: "",
									userName: ""
								}
							});

							// 返回结果（倒序处理）
							resolve(resultList.reverse());
						} catch (e) {
							return resolve([]);
						}
					});
				});
			},

			/**
			 * 根据物流ID获取物流公司code
			 * @param {*} logisticId 
			 */
			__getExpressCode__: async function(logisticId) {
				// 调用接口获取数据
				let res = await callFeign("erp.logistic.getbyid", {
					id: logisticId
				});
				try {
					// 获取当前物流ID对应的物流公司信息
					return res.result.data.expressCode || "";
				} catch (e) {
					return "";
				}
			},

			/**
			 * 搜索对象
			 * @param {*} key 
			 */
			__getTargetLog__(originalLogList, tradeLogList, key) {
				// 搜索所需日志
				let log = originalLogList.find(t => t.logDetail.includes(key));
				if (!log) {
					if (key == "仓库已接单") {
						return this.__getTargetLog__(originalLogList, tradeLogList, "已递交供应商发货");
					} else {
						return tradeLogList;
					}
				}

				// 处理数据格式
				let extraLogList = [];
				//log.logTime = this.formatTime2(log.logTime, 'M-D h:m');
				switch (key) {
					case "订单创建":
						log.logName = "商品已下单";
						extraLogList = this.__getExtraTradeLog__(originalLogList, "仓库已接单");
						break;
					case "仓库已接单":
						extraLogList = this.__getExtraTradeLog__(originalLogList, "确认发货");
						log.logName = "仓库已接单";
						break;
					case "已递交供应商发货":
						extraLogList = this.__getExtraTradeLog__(originalLogList, "确认发货");
						log.logName = "仓库已接单";
						break;
					case "海关审单放行":
						log.logName = "订单清关完成，发货准备中";
						break;
					case "确认发货":
						log.logName = "已发货";
						break;
				}
				log.logDetail = "";

				// 将结果添加到展示列表
				return [...extraLogList, log, ...tradeLogList];
			},

			/**
			 * 获取订单日志列表
			 * @param {*} tradeId 
			 */
			async __getTradeLogList(tradeId) {
				return new Promise((resolve, reject) => {
					callFeign("oms.trade.tradelog", {
						jsonStr: JSON.stringify({
							tradeId
						})
					}).then((res) => {
						let originalLogList = res.result.data;
						// 检测数据有效性
						if (res.code != 200 || !originalLogList || !(originalLogList.length > 0)) {
							return resolve([]);
						}
						this.hasTrailInfo = true;

						// 结果日志数据，只保留3条日志信息（20221213产品要求）
						let tradeLogList = [];
						// 第一条：创建信息
						tradeLogList = this.__getTargetLog__(originalLogList, tradeLogList, "订单创建");
						// 第二条：仓库接单
						tradeLogList = this.__getTargetLog__(originalLogList, tradeLogList, "仓库已接单");
						// 第三条：海关放行
						tradeLogList = this.__getTargetLog__(originalLogList, tradeLogList, "海关审单放行");
						// 第四条：已发货（20221223新增）
						tradeLogList = this.__getTargetLog__(originalLogList, tradeLogList, "确认发货");

						// 放回结果
						resolve(tradeLogList);
					});
				});
			},

			/**
			 * 获取额外日志信息
			 * @param {*} key 
			 */
			__getExtraTradeLog__(originalLogList, endKey) {
				// 没有开启展示销售单日志 直接返回
				if (!this.isShowOmsLogDetail) {
					return []
				}

				// 过滤需要的日志
				let startIndex = endKey != "确认发货" ? 0 : originalLogList.toReversed().findIndex(t => t.logDetail.includes("已递交供应商发货") || t.logDetail.includes("仓库已接单"))
				let endIndex = originalLogList.toReversed().findIndex(t => t.logDetail.includes(endKey));
				let extraTradeList = originalLogList.toReversed().slice(startIndex, endIndex == -1 ? originalLogList.length : endIndex + 1);

				extraTradeList = extraTradeList.filter(t => t.logDetail.includes("促销策略") || t.logDetail.includes("订单修改") || t.logDetail.includes("发货确认时按实际发货数量，调整货品数量") || t.logDetail.includes("刷新货品售价") || t.logDetail.includes("应收邮资策略")).toReversed();

				// 过滤包含敏感词的日志 成本、毛利、批发价
				extraTradeList = extraTradeList.filter(t => !t.logDetail.includes("成本") && !t.logDetail.includes("毛利") && !t.logDetail.includes("批发价") && !t.logDetail.includes("利润"));
			
				// 修改日志内容
				extraTradeList.forEach(element => {
					element.logName = element.logDetail.slice(0, element.logDetail.includes("Ф") ? element.logDetail.indexOf("Ф") : element.logDetail.indexOf("Л"));
					element.logDetail = "";
				});

				return extraTradeList;
			},

			/** 
			 * 时间戳转化为年 月 日 时 分 秒 
			 * number: 传入时间戳 
			 * format：返回格式，支持自定义，但参数必须与formateArr里保持一致 
			 */
			formatTime2(timestamp, format) {
				var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
				var returnArr = [];
				if (timestamp.length == 10) {
					timestamp = timestamp * 1000;
				} else {
					timestamp = timestamp * 1;
				}
				var date = new Date(parseFloat(timestamp));
				returnArr.push(date.getFullYear());
				returnArr.push(this.formatNumber(date.getMonth() + 1));
				returnArr.push(this.formatNumber(date.getDate()));
				returnArr.push(this.formatNumber(date.getHours()));
				returnArr.push(this.formatNumber(date.getMinutes()));
				returnArr.push(this.formatNumber(date.getSeconds()));
				for (var i in returnArr) {
					format = format.replace(formateArr[i], returnArr[i]);
				}
				return format;
			},

			formatNumber(n) {
				n = n.toString()
				return n[1] ? n : '0' + n
			}
		}
	}
</script>

<style scoped="scoped">
	.time-line-box {
		min-height: 300px;
		max-height: 500px;
		overflow: hidden;
		overflow-y: scroll;
	}

	.time-line-box::-webkit-scrollbar {
		display: none;
	}

	.operator {
		color: #aeaeae;
		margin: 12px 0;
		font-size: 12px;
	}

	.first {
		color: #FF5757;
	}

	.logistic-video {
		width: 100%;
	}
</style>