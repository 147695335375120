<template>
		<personal-layout cur-menu="contactservice">
			<div class="content-box flex-center" v-loading="!isLoaded" element-loading-text="加载中">
				<template v-if="isLoaded">
					<div class="item flex-row" v-if="codePic">
						<div class="flex-col flex-center">
							<img :src="codePic" class="code-pic" />
							<div @click.stop="test">添加客服微信，反馈问题</div>
						</div>
					</div>
					<div class="item flex-row" :class="{line: codePic}">
						<div class="flex-col  flex-center">
							<img src="../../assets/images/icon-contact.png" class="code-pic" />
							<div>{{tel}}</div>
						</div>
					</div>
				</template>
			</div>
		</personal-layout>
</template>

<script>
	import personalLayout from '../../components/mine/PersonalLayout.vue';
	export default {
		name: "contact-service",
		components: {
			personalLayout
		},
		data() {
			return {
				codePic: '',
				tel: '',
				isLoaded: false,
				isShowCheckGoodsDialog: false
			}
		},
		created() {
			this.$request.getParam("pc_afterSalesPic, pc_afterSalesTel", (paramlist) => {
				if (paramlist) {
					this.codePic = paramlist.pc_afterSalesPic ? paramlist.pc_afterSalesPic : '';
					this.tel = paramlist.pc_afterSalesTel ? paramlist.pc_afterSalesTel : '';
				}
				this.isLoaded = true;
			})
		},
		methods: { },
	}
</script>

<style scoped="scoped">
	.content-box {
		padding: 30px;
		height: calc(100% - 240px);
	}

	.item {
		padding: 10px 90px;
	}

	.code-pic {
		height: 140px;
		width: 140px;
		margin-bottom: 20px;
	}

	.line {
		border-left: 1px solid #dedede;
	}
</style>
