<template>
	<div class="container">
		<div class='navigation'>
			<i class="el-icon-arrow-left back-btn" @click="gotoTradeList"></i>
			<el-button type="info" class='cancel-btn' plain @click="handCancelTrade">取消订单</el-button>
		</div>
		<div class="flex-center pay-info-box">
			<div class="pay-info-img">
				<img src="../../assets/images/pay/icon-moneybag.svg" />
			</div>
			<div class="pay-info-txt-box">
				<div class="pay-info-txt">订单已提交，等待支付
					<span v-if="outPayInfo.rcvAmount > 0">{{$currency.symbol}}{{outPayInfo.rcvAmount}}</span>
					<span v-if="payScore > 0 && outPayInfo.rcvAmount > 0">+</span>
					<span v-if="payScore > 0 ">{{payScore}}积分</span>
				</div>
				<div v-if="isOpenTradeAutoCancel" class="pay-info-tips">{{tradeLimitTime}}分钟内未付款将跳转至交易关闭列表</div>
			</div>
		</div>
		<div class="adr-info-box">
			<div class="adr-title">收货信息</div>
			<div class="adr-name">{{tradeInfo.sendTo}}</div>
			<div class="adr-tel">{{$utils.regTel(tradeInfo.tel)}}</div>
			<div class="adr-detail">
				{{tradeInfo.province}}{{tradeInfo.city}}{{tradeInfo.district}}{{tradeInfo.town}}{{tradeInfo.address}}
			</div>
		</div>
		<div class="payment-info-box" v-if="tradeInfo.rcvTotal > 0">
			<div class="payment-title">支付方式</div>
			<div class="payment-content">
				<div class="flex-wrap payment-type">
					<div class="flex-center payment-type-option " :class="payType=='wxpay'?'option-selected':''" @click="changePayType('wxpay')" v-if="payTypeShow.indexOf('wxpay')>=0">
						<img src="../../assets/images/pay/icon-wxpay.svg" />
						<span>微信支付</span>
					</div>
					<div class="flex-center payment-type-option" :class="payType=='alipay'?'option-selected':''" @click="changePayType('alipay')" v-if="payTypeShow.indexOf('alipay')>=0">
						<img src="../../assets/images/pay/icon-alipay.svg" />
						<span>支付宝</span>
					</div>
					<div style="position: relative;" class="flex-center payment-type-option" :class="payType=='balancepay'?'option-selected':''" @click="changePayType('balancepay')" v-if="payTypeShow.indexOf('balancepay')>=0">
						<img src="../../assets/images/pay/icon-balancepay.svg" />
						<span>预存款支付</span>
						<div class="customer-balance">
							余额：{{$currency.symbol}}{{$utils.amountFixed(balance, 2)}}</div>
					</div>
					<div class="flex-center payment-type-option" :class="payType=='cashondelivery'?'option-selected':''" @click="changePayType('cashondelivery')" v-if="payTypeShow.indexOf('cashondelivery')>=0">
						<img src="../../assets/images/pay/icon-cashondelivery.svg" />
						<span>货到付款</span>
					</div>
					<div style="position: relative;" class="flex-center payment-type-option" :class="payType=='debtPay'?'option-selected':''" @click="changePayType('debtPay')" v-if="payTypeShow.indexOf('debtPay')>=0 && debtAmountMax > 0">
						<img src="../../assets/images/pay/ico-pay-debt.svg" />
						<span>欠款计应收</span>
						<div class="customer-balance">
							可用额度：{{$currency.symbol}}{{$utils.amountFixed(debtAmountMax, 2)}}</div>
					</div>
					<div class="flex-center payment-type-option" :class="payType=='unionpay'?'option-selected':''" @click="changePayType('unionpay')" v-if="payTypeShow.indexOf('unionpay')>=0">
						<img src="../../assets/images/pay/ico-union-pay.svg" />
						<span>银联支付</span>
					</div>
				</div>
			</div>
			<div class="payment-qrcode-box" v-if="payType=='wxpay'||payType=='alipay'||payType=='unionpay'">
				<div v-if="isOpenTradeAutoCancel" class="flex-center payment-count-down">
					<span>结束时间：</span><span>{{countdownTime}}</span>
				</div>
				<div class="flex-center payment-qrcode">
					<div id="qrCode"></div>
				</div>
				<div class="payment-tips">
					<span>打开手机{{payType=='wxpay'?'微信':(payType=='alipay'?'支付宝':'银联')}}</span>
					<span>扫一扫继续付款</span>
				</div>
			</div>
			<div class="payment-balance-box" v-else-if="payType=='balancepay'">
				<el-button type="danger" class="flex-center balance-btn" @click="balancePay">确认支付</el-button>
			</div>
			<div class="payment-balance-box" v-else-if="payType=='debtPay'">
				<el-button type="danger" class="flex-center balance-btn" @click="debtPay">确认提交</el-button>
			</div>
			<div class="payment-balance-box" v-else-if="payType=='cashondelivery'">
				<el-button type="danger" class="flex-center balance-btn" @click="cashOnDeliveryPay">确认提交</el-button>
			</div>
		</div>
		<div class="payment-balance-box" v-else-if="initFinished">
			<el-button type="danger" class="flex-center balance-btn" @click="scorePay">确认支付</el-button>
		</div>

		<!-- 货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowCheckGoodsDialog" :goodsList="cannotOrderGoodsList" :isNoValidGoods="isNoStockAll" :isShowCancelBtn="false" @confirm="filterNoStockGoods">
		</check-goods-dialog>
		<!-- 货品是否下架弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowBlockCheckGoodsDialog" :goodsList="cannotOrderGoodsList" :isNeedTitle="true" :isNoValidGoods="isNoStockAll" :isBlockUpGoodsCheck="true" :noValidGoodsTip="`商品已下架，请重新下单`" :hasValidGoodsTip="`以下商品已下架`" :confirmText="`移除下架商品`" :cancelText="`回到首页`" @confirm="filterBlockUpGoods">
		</check-goods-dialog>
		<!-- 多单位货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowMultiUnitStockCheckModal" :goodsList="baseUnitOverOrderGoodsList" :isMultiUnitOverStockInPay="true" :isMultiUnitOverStock="true" hasValidGoodsTip="库存不足,请重新选货" @cancel="multiUnitOverStockCancel">
		</check-goods-dialog>
	</div>
</template>

<script>
	import vHeader from '../../components/common/Header.vue';
	import checkGoodsDialog from '../../components/trade/CheckGoodsDialog.vue';
	import QRCode from 'qrcodejs2';
	import goodsUtils from '../../utils/api-utils/api-goods.js'
	import tradeUtils from '../../utils/api-utils/api-trade.js'
	import addressUtils from '../../utils/api-utils/api-address.js'
	import { config, constant } from '../../config.js'
	import { getCustomerInfo } from '../../utils/api-utils/api-customer.js'
	import { getParam, checkCanOrderAreasByParams } from '../../utils/api-utils/api-params.js'

	export default {
		components: {
			vHeader,
			"check-goods-dialog": checkGoodsDialog
		},
		data() {
			return {
				tradeNo: "", // 待支付的订单编号
				tradeInfo: {}, // 待支付订单内容
				payScore: 0,
				payType: "", // 选择的支付方式：wxpay，alipay，balancepay
				balance: 0, // 用户余额
				tradeLimitTime: 15, // 订单自动取消限制时间（单位：分）
				tradeLeftTime: 0, // 订单支付剩余时间（单位：秒）
				countdownTime: "00:00", // 倒计时显示的时间
				isClickPay: false, // 是否已点击支付，重提交控制标记
				userName: "", // 会员名
				payTypeShow: [], // 需要展示的支付类型（根据配置控制）
				countdownInterval: "", // 倒计时控件
				balanceTextClass: "balance-left3", // 用户余额显示位置对应的class
				isPay: false, //支付手动拦截
				isOpenZeroStockBuy: false, //0库存是否可以下单
				canOrderGoodsList: [], // 限购商品列表
				cannotOrderGoodsList: [], // 剩余可购规格Id
				isNoStockAll: false, // 是否无可购商品
				isShowCheckGoodsDialog: false, // 是否展示库存检测弹窗，存在库存不足的货品就展示
				isShowBlockCheckGoodsDialog: false, // 是否展示下架商品检测弹窗，存在下架货品就展示
				paymentType: "",
				debtAmountMax: 0, // 欠款计应收额度
				outPayInfo: {
					outTradeNo: '', // 商户单号
					rcvAmount: 0, // 支付金额
					tradeNo: '',
					payScene: '0' // 默认普通下单
				},
				ispaySuccess: false,
				isOpenTradeAutoCancel: false, // 是否自动取消（综合结果）
				isOpenTradeAutoCancel_normal: false, // 是否自动取消普通订单（未设置测不取消）
				tradeAutoCancelTimeLimit_normal: 9999999999, // 普通订单自动取消时间（分钟）
				tradeAutoCancelTimeLimit_timed: 15, // 抢购订单自动取消时间（分钟），默认15分钟
				isOpenTradeAutoCancel_presale: false, // 是否自动取消预售订单（未设置测不取消）
				tradeAutoCancelTimeLimit_presale: 9999999999, // 预售订单自动取消时间（分钟）
				isShowMultiUnitStockCheckModal: false, //展示多单位库存校验弹窗
				baseUnitOverOrderGoodsList: [], //展示多单位库存不足货品
				tradeCancelTimeout: -1, // 订单取消定时器
			}
		},
		created: function() {
			// 数据初始化
			this._initData();

			// 订单支付标记
			this._updateTradeReservedInfo("正在支付")
		},
		beforeDestroy: async function() {
			// 清除定时器
			this.cancelCountdown();

			// 释放库存
			this._releaseTradeGoodsStockHold();

			// 订单支付标记
			this._updateTradeReservedInfo("");

			try {
				// // 	预售活动且未支付定金
				// if (!this.ispaySuccess && this.tradeInfo.tradeGoodsList[0].act_type == "3" && this.tradeInfo
				// 	.trade_status == "1") {
				// 	// 检测货品库存，结果包含可购和不可购两个列表
				// 	let presaleres = await goodsUtils.checkPresaleGoodsCount(this._getPresaleGoods(true), this.tradeInfo
				// 		.trade_id);
				// }
			} catch (e) {
				//TODO handle the exception
			}
		},
		methods: {
			// 数据初始化
			async _initData() {
				// 获取当前会员名
				this.userName = this.$request.getUserName();

				// 接收并检测
				if (!this._receiveAndCheckParams()) {
					return false;
				}

				// 获取支付相关配置
				await this._getPaySetting();

				// 获取订单信息
				await this._getTradeInfo();

				// 区域可购
				if (!await this._checkOrderAreas()) {
					return false;
				}

				// 限购检测
				await this._checkRestrictedGoods();

				// 检测商品是否是会员等级限制商品
				if (!await this._checkMemberBlockGoods()) {
					return false;
				}

				// 检测商品是否下架
				if (!await this._checkBlockUpGoods()) {
					return false;
				}

				// 检测库存
				if (!await this._checkOrderGoodsStock()) {
					return false;
				}

				// 开始倒计时
				this._countdown();

				// 占用库存
				this.addTradeGoodsStockHold();

				// 设置支付方式默认值，客户档案默认结算方式＞余额＞欠款＞ 其他支付方式，当为其他时默认收银台第一个支付方式即可
				if (this.payTypeShow.length > 0) {
					let defaultPayWay = await this._getDefaultPayWay(this.payTypeShow);
					this.changePayType(defaultPayWay ? defaultPayWay : this.payTypeShow[0]);
				}

				// 开始检测订单是否支付
				this._checkPayStatus();

				// 获取用户预存款（缓存）
				let balance = await this.getCustomerBalance();

				// 获取欠款计应收额度
				await this.getDebtAmountMax();

				// 校验积分
				this._checkScroe();

				this.initFinished = true;
			},

			/**
			 * 获取默认支付方式，客户档案默认结算方式＞余额＞欠款＞ 其他支付方式，当为其他时默认收银台第一个支付方式即可
			 */
			_getDefaultPayWay: async function(payWayList) {
				// 客户档案默认结算方式的对应值
				let payWayMap = {
					1: "balancePay", //担保交易取余额支付
					2: "bankpay", // 银行收款
					3: "cashPay", // 现金收款?
					4: "cashondelivery", // 货到付款
					5: "debtPay", // 欠款计应收
					6: "balancepay", // 客户预存款
					7: "combinePay", // 多种结算?
					9: "", // 电子钱包
				}

				let customerInfo = await getCustomerInfo();
				let defaultPayWay = (customerInfo.defaultSettlementMethod && payWayList.find((item) => item == payWayMap[customerInfo.defaultSettlementMethod])) || payWayList.find((item) => item == "balancepay") || payWayList.find((item) => item == "debtPay") || ""

				if (customerInfo.defaultSettlementMethod && customerInfo.defaultSettlementMethod == "2") {
					// 客户档案中结算方式为银行收款->pc商城设置是否对应结算方式
					defaultPayWay = payWayList.find(item => ["wxpay", "alipay", "unionpay"].includes(item)) || defaultPayWay;
				}

				return defaultPayWay
			},

			/**
			 * 接收并检测传递参数
			 */
			_receiveAndCheckParams() {
				let _this = this;

				// 获取待支付的订单编号
				let tradeNo = _this.$route.params.tradeNo;

				// 检测订单编号是否存在，不存在则跳转到商品列表
				if (!tradeNo) {
					_this.$message.error('未检测到订单编号');
					// 延迟跳转到订单列表
					_this.gotoTradeList(1000);
					return false;
				}

				// 订单编号存在则保存页面参数中
				_this.tradeNo = tradeNo;

				return true;
			},

			/**
			 * 获取支付配置信息
			 */
			async _getPaySetting() {
				let paramKeys = [
					"pc_isOpenDebtPay",
					"pc_isOpenUnionPay",
					"pc_isOpenWxPay",
					"pc_isOpenAliPay",
					"pc_isOpenBalancePay",
					"pc_isOpenCashOnDelivery",
					"isOpenZeroStockBuy",
					"paymentType",
					"pc_isOpenTonglianPay",
					"isOpenTradeAutoCancel_normal",
					"tradeAutoCancelTimeLimit_normal",
					"tradeAutoCancelTimeLimit_timed",
					"isOpenTradeAutoCancel_presale",
					"tradeAutoCancelTimeLimit_presale",
					"isOpenStockHold"
				];
				let paramList = await getParam(paramKeys.join(','));
				// 需要显示的支付类型
				let payTypeShow = [];
				let isOpenTonglian = paramList.pc_isOpenTonglianPay == "1";
				// 是否开启微信支付
				if (paramList.pc_isOpenWxPay == "1" || isOpenTonglian) {
					payTypeShow.push("wxpay");
				}

				// 是否开启支付宝支付
				if (paramList.pc_isOpenAliPay == "1" || isOpenTonglian) {
					payTypeShow.push("alipay");
				}

				// 是否开启预存款支付
				if (paramList.pc_isOpenBalancePay == "1") {
					payTypeShow.push("balancepay");
				}

				// 支付方式为通联支付，添加银联扫码
				if (isOpenTonglian) {
					this.paymentType = "tonglian";
					// 默认开启
					if (paramList.pc_isOpenUnionPay != "0") {
						payTypeShow.push("unionpay");
					}
				}

				// 是否开启货到付款支付方式
				if (paramList.pc_isOpenCashOnDelivery == "1") {
					payTypeShow.push("cashondelivery");
				}

				// 是否开启欠款计应收
				if (paramList.pc_isOpenDebtPay == "1") {
					payTypeShow.push("debtPay");
				}

				// 渲染支付类型到页面
				this.payTypeShow = payTypeShow;

				// 是否开启0库存下单
				if (paramList.isOpenZeroStockBuy && paramList.isOpenZeroStockBuy == "1") {
					this.isOpenZeroStockBuy = true;
				}

				// 普通订单过期时间

				if (paramList.isOpenTradeAutoCancel_normal == "1" && paramList.tradeAutoCancelTimeLimit_normal) {
					this.isOpenTradeAutoCancel_normal = true;
					this.tradeAutoCancelTimeLimit_normal = parseInt(paramList.tradeAutoCancelTimeLimit_normal);
				}

				// 限时抢购订单过期时间
				if (paramList.tradeAutoCancelTimeLimit_timed) {
					this.tradeAutoCancelTimeLimit_timed = parseInt(paramList
						.tradeAutoCancelTimeLimit_timed);
				}

				// 预售订单过期时间
				if (paramList.isOpenTradeAutoCancel_presale == "1" && paramList.tradeAutoCancelTimeLimit_presale) {
					this.isOpenTradeAutoCancel_presale = true;
					this.tradeAutoCancelTimeLimit_presale = parseInt(paramList.tradeAutoCancelTimeLimit_presale);
				}

				this.isOpenStockHold = paramList.isOpenStockHold == "1"
			},

			/**
			 * 获取待支付订单信息
			 */
			async _getTradeInfo() {
				let tradeInfo = await tradeUtils.getJmallTradeDetail(this.tradeNo);
				debugger
				if (!tradeInfo.tradeId) {
					this.$message.error("支付信息获取失败，请重试")
					// 延迟跳转到订单列表
					this.gotoTradeList(1000);
				}
				// 将订单详情赋值的页面参数
				this.tradeInfo = tradeInfo;
				this.payScore = tradeInfo.scoreTotal;
				// 检测订单数据是否合法
				if (!this.checkTradeInfo()) {
					// 延迟跳转到订单列表
					this.gotoTradeList(100);
					return false
				}

				// 获取支付金额
				await this._getCombinePayInfo();
			},

			// 获取支付信息
			async _getCombinePayInfo() {
				let outPayInfoList = await tradeUtils.getCombinePayInfo(this.tradeNo);
				let outPayInfo = {};
				if (this.tradeInfo.tradeType == "3") {
					let firstPayInfo = outPayInfoList.find(item => item.payStatus == "2");
					if (firstPayInfo) {
						if (firstPayInfo.payType == '电子钱包') {
							this.payTypeShow = [];
						} else if (['余额支付', '欠款计应收', '客户预存款'].includes(firstPayInfo.payType)) {
							this.payTypeShow = this.payTypeShow.filter(item => ['debtPay',
								'balancepay'
							].includes(item));
						} else {
							this.payTypeShow = this.payTypeShow.filter(item => !['debtPay',
								'balancepay'
							].includes(item));
						}
						this.payScore = 0;
					}
					// 根据场景值排序
					outPayInfoList = outPayInfoList.filter(item => {
						return item.payStatus != "2"
					}).sort((x, y) => x["payScene"] - y["payScene"]);

					outPayInfo = {
						outTradeNo: outPayInfoList[0].outTradeNo, // 商户单号
						rcvAmount: outPayInfoList[0].rcvAmount, // 支付金额
						tradeNo: this.tradeNo,
						payScene: outPayInfoList[0].payScene
					}

				} else {
					outPayInfo = {
						outTradeNo: outPayInfoList[0].outTradeNo, // 商户单号
						rcvAmount: outPayInfoList[0].rcvAmount, // 支付金额
						tradeNo: this.tradeNo,
						payScene: outPayInfoList[0].payScene
					}
				}
				this.outPayInfo = outPayInfo;
				this.rcvTotal = outPayInfo.rcvAmount;
				return outPayInfo;
			},

			/**
			 * 更新订单保留字段信息
			 * @param {Object} content
			 */
			_updateTradeReservedInfo(content) {
				this.$request.jackyunPost({
					method: "jmall.trade.updatetradereservedinfo",
					tradeNo: this.tradeNo,
					reserved1: content
				}, (res) => {
					if (res.code != 200) {
						console.log("更新保留字段失败：", res);
					}
				});
			},

			/**
			 * 检测订单数据是否合理
			 */
			checkTradeInfo() {
				if (this.tradeInfo.tradeStatus == "16") {
					return true;
				}

				// 检测订单状态是否正确
				if (this.tradeInfo.tradeStatus != "1") {
					this.$message.error("订单状态不正确");
					return false;
				}

				// 检测是否为抢购订单
				let isTimedTrade = false;
				try {
					isTimedTrade = !!(this.tradeInfo.tradeGoodsList.find(t => t.actType == "1"));
				} catch (e) {}

				// 是否开启自动取消订单
				this.isOpenTradeAutoCancel = this.isOpenTradeAutoCancel || isTimedTrade;
				// 获取有效的订单到期时间，普通订单和抢购订单需要做区分
				this.tradeLimitTime = isTimedTrade ? this.tradeAutoCancelTimeLimit_timed : this
					.tradeAutoCancelTimeLimit_normal;

				// 获取当前订单下单时间和当前时间差值（单位：秒）
				let timeDifference = (new Date().getTime() - new Date(this.tradeInfo.tradeTime).getTime()) / 1000;
				// 预售订单收款按照普通订单处理，尾款不会取消，所以该功能隐藏，产品已确认，2024-03-08
				// 检测是否为预售订单
				let isPresaleTrade = false;
				try {
					isPresaleTrade = !!(this.tradeInfo.tradeGoodsList.find(t => t.actType == "3"));
				} catch (e) {}

				// 获取有效的订单到期时间，普通订单和抢购订单需要做区分
				if (isTimedTrade) {
					this.isOpenTradeAutoCancel = true;
					this.tradeLimitTime = this.tradeAutoCancelTimeLimit_timed;
				} else if (isPresaleTrade) {
					this.isOpenTradeAutoCancel = this.isOpenTradeAutoCancel_presale;
					this.tradeLimitTime = this.tradeAutoCancelTimeLimit_presale;
				} else {
					this.isOpenTradeAutoCancel = this.isOpenTradeAutoCancel_normal;
					this.tradeLimitTime = this.tradeAutoCancelTimeLimit_normal;
				}

				// 检测订单是否超时
				if (timeDifference > this.tradeLimitTime * 60) {
					// 取消订单
					this.cancelTrade();
					this.$message.error("订单已超时");
					return false;
				}

				// 赋值订单剩余支付时间
				this.tradeLeftTime = this.tradeLimitTime * 60 - (timeDifference > 0 ? timeDifference : 0);

				return true;
			},

			/**
			 * 获取用户余额
			 */
			async getCustomerBalance() {
				this.balance = await this.$public.getCustomerBalance();
			},
			/**
			 * 获取用户的欠款额度
			 */
			async getDebtAmountMax() {
				let customerInfo = await getCustomerInfo();
				let debtAmountMax = parseFloat(customerInfo.debtAmountMax || 0);
				this.$eventBus.$emit("updateHeaderBalance");
				let balance = parseFloat(this.balance);
				if (balance > 0) {
					this.debtAmountMax = debtAmountMax.toFixed(2);
				} else {
					this.debtAmountMax = (balance + debtAmountMax).toFixed(2);
				}
			},

			/**
			 * 切换支付方式
			 * @param {Object} payType 传入的支付方式：wxpay，alipay，balancepay
			 */
			async changePayType(payType) {
				let _this = this;

				// 检测订单信息是否加载完毕
				if (!_this.tradeInfo.tradeId) {
					return _this.$message("订单信息加载中");
				}

				// 检测积分
				if (!await _this._checkScroe()) {
					return false;
				}

				// 如果金额为0，则直接返回错位
				if (parseFloat(_this.tradeInfo.rcvTotal) == 0) {
					return false;
				}

				// 重复选择则不做处理
				if (payType == _this.payType) {
					return false;
				}

				// 将支付方式赋值到参数，变更页面显示
				_this.payType = payType;
				try {
					// 清空二维码容器内容
					document.getElementById("qrCode").innerHTML = "";
				} catch (e) {}

				// 检测是否为微信或者支付宝支付，是则需要生成付款码
				if (["wxpay", "alipay", "unionpay"].indexOf(payType) >= 0) {
					// 获取支付宝付款
					_this.getPayUrlQRCode();
				}
			},

			/**
			 * 获取支付二维码
			 */
			getPayUrlQRCode() {
				let _this = this;
				let url = config.payUrl;
				let requestJson = {
					memberName: _this.userName,
					accountName: "",
					tradeNo: _this.outPayInfo.tradeNo,
					fee: _this.outPayInfo.rcvAmount,
					outTradeNo: _this.outPayInfo.outTradeNo,
					payScene: _this.outPayInfo.payScene,
					payType: _this.payType,
					checkStock: !_this.isOpenZeroStockBuy,
					purpose: "buy",
					shopId: localStorage.getItem("pc_channelId"),
					goodsInfo: ""
				}
				// 请求支付接口获取付款二维码地址
				_this.$request.commonPost(
					url, requestJson,
					function(res) {
						// 检测支付信息是否正确获取
						if (res.data.error_code != '0') {
							// 提示错误原因
							// return _this.$message({
							// 	showClose: true,
							// 	message: res.data.error_info,
							// 	type: 'error',
							// 	duration: 0
							// });
							return _this.$alert(res.data.error_info, '', {
								confirmButtonText: '我知道了'
							});
						}

						// 生成支付二维码
						setTimeout(function() {
							_this.createQRCode(res.data.data);
						}, 100);
					});
			},

			/**
			 * 创建二维码
			 */
			createQRCode(content) {
				// 没有内容则直接返回
				if (!content) {
					return false;
				}

				// 清空二维码容器内容，否则会一直叠加生成
				document.getElementById("qrCode").innerHTML = "";

				// 生成二维码
				new QRCode("qrCode", {
					text: content,
					width: 250,
					height: 250,
					colorDark: "#333333", //二维码颜色
					colorLight: "#ffffff", //二维码背景色
					correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
				});
			},

			/**
			 * 跳转到订单列表
			 */
			gotoTradeList(sleep) {
				let _this = this;
				setTimeout(function() {
					_this.$router.replace({
						name: 'tradelist'
					});
				}, sleep || 1);
			},

			/**
			 * 手动取消订单
			 */
			handCancelTrade() {
				let _this = this;

				_this.$confirm('确认取消订单？')
					.then(_ => {
						// 取消订单
						_this.cancelTrade();
					})
					.catch(_ => {});
			},

			/**
			 * 取消订单
			 */
			async cancelTrade() {
				let _this = this;
				if (_this.tradeInfo.tradeStatus == '16') {
					return;
				}
				// 改为jmall接口
				let result = await tradeUtils.cancelTrade(_this.tradeInfo.tradeId);
				if (!result) {
					return _this.$message.error("取消失败");
				} else {
					// 取消成功提示
					_this.$message.success("订单已取消");
					_this.isHandedCancelTrade = true;

					// 延迟跳转到商品列表（由于未支付订单取消后无法在订单列表展示，所以这边跳转到商品列表）
					this.tradeCancelTimeout = setTimeout(function() {
						_this.$router.replace({
							name: 'tradelist',
							params: {
								tabIndex: "4"
							},
							meta: {
								isEdit: true
							}
						});
					}, 1000);
				}

			},

			/**
			 * 倒计时
			 */
			_countdown() {
				// 未开启则无需倒计时，预售尾款订单也无需要倒计时
				if (!this.isOpenTradeAutoCancel || this.tradeInfo.tradeStatus == "16") {
					return;
				}

				let _this = this;
				_this.countdownInterval = setInterval(function() {
					// 显示到页面上的倒计时时间（00:00）
					_this.countdownTime = _this.$utils.formatNumber(parseInt(_this.tradeLeftTime / 60), 2) + ":" +
						_this.$utils.formatNumber(
							parseInt(_this.tradeLeftTime %
								60), 2);

					// 秒数减一，继续倒计时
					_this.tradeLeftTime--;

					// 检测倒计时是否小于0，小于0则自动取消订单
					if (_this.tradeLeftTime < 0) {
						// 停止倒计时
						clearInterval(_this.countdownInterval);
						// // 取消订单
						_this.cancelTrade();
						// 不再取消订单，改为跳转到待结算列表
						//_this.gotoTradeList(10);
					}
				}, 1000);
			},

			/**
			 * 取消倒计时
			 */
			cancelCountdown() {
				let _this = this;
				// 停止倒计时
				clearInterval(_this.countdownInterval);
			},

			/**
			 * 占用库存
			 */
			addTradeGoodsStockHold() {
				if (!this.isOpenStockHold) {
					return
				}
				tradeUtils.addTradeGoodsStockHold(this.tradeInfo.tradeId);
			},

			/**
			 * 释放库存
			 */
			_releaseTradeGoodsStockHold() {
				if (!this.isOpenStockHold) {
					return
				}
				tradeUtils.releaseTradeGoodsStockHold(this.tradeInfo.tradeId);
			},

			/**
			 * 检测订单是否已经支付
			 */
			_checkPayStatus() {
				// 若当前页不是支付页面，则终止查询
				if (!this.__isPayPage()) {
					return;
				}

				let _this = this;

				// 如果没有选择支付方式则不检测
				if (!_this.payType) {
					// 再次进行检测调用
					_this.checkAgain();
					return false;
				}

				_this.checkWxPayStatus();

			},

			/**
			 * 检测微信支付状态
			 */
			async checkWxPayStatus() {
				let _this = this;

				if (!_this.outPayInfo.outTradeNo) {
					_this.checkAgain();
				}
				// 获取当前订单状态
				let tradeStatus = await tradeUtils.getTradeStatus(this.tradeNo);

				// 若当前页不是支付页面，则终止查询
				if (!_this.__isPayPage() || _this.isHandedCancelTrade || _this.ispaySuccess) {
					return;
				}

				if (tradeStatus == 2) {
					// 支付成功
					return this.paySuccess();
				}
				// 仅以下状态可以继续支付（待支付，预售，手动取消，超时取消）
				if ([1, 16, 0, 18].includes(tradeStatus)) {
					return this.checkAgain();
				} else {
					this.$message.error("订单状态已改变，请重新发起支付");
					// 延迟跳转到订单列表
					this.gotoTradeList(1000);
				}
			},

			/**
			 * 再次检测支付结果
			 */
			checkAgain() {
				let _this = this;

				// 递归调用，1.5秒检测一次
				setTimeout(function() {
					_this._checkPayStatus();
				}, 1500);
			},

			/**
			 * 支付成功执行的操作
			 */
			paySuccess() {
				let _this = this;
				let successTip = "支付成功";
				if (["cashondelivery", "debtPay"].includes(_this.payType)) {
					successTip = "提交成功";
				}

				// 清除购物车缓存
				localStorage.removeItem(constant.DATA_KEY_CART_CACHE)

				// 支付成功提示
				_this.$message.success(successTip);

				// 停止倒计时，否则会在支付成功界面继续执行
				_this.cancelCountdown();

				_this.$eventBus.$emit("updateHeaderBalance");

				// 支付成功
				_this.ispaySuccess = true;
				// 延迟跳转到支付成功页面
				setTimeout(function() {
					_this.$router.replace({
						name: 'paysuccess',
						params: {
							tradeNo: _this.tradeNo,
							payScene: _this.outPayInfo.payScene
						},
					});
				}, 1000);
			},
			// 欠款计应收支付1
			debtPay() {
				let _this = this;

				// 欠款计应收 小于应收 直接返回提示
				if (parseFloat(_this.debtAmountMax) <= parseFloat(_this.tradeInfo.rcvTotal)) {
					return _this.$message("本订单已超出欠款额度，请先结算欠款或更换支付方式");
				}
				// 检测是否重复点击
				if (_this.isClickPay) {
					return false;
				}

				// 询问确认是否使用余额支付
				_this.$confirm('确认使用欠款计应收支付？')
					.then(async _ => {
						// 将支付标记置为是
						_this.isClickPay = true;
						if (await tradeUtils.tradePayConfirmAndPush(_this.tradeNo, _this.outPayInfo.outTradeNo,
								_this.outPayInfo.rcvAmount, '欠款计应收', _this.outPayInfo.payScene)) {
							// 支付成功
							_this.paySuccess();
						} else {
							// 将支付标记置为否
							_this.isClickPay = false;

							// 弹出错误提示
							return _this.$message.error('推单失败');
						}

					})
					.catch(_ => {});
			},
			/**
			 * 余额支付
			 */
			balancePay() {
				let _this = this;

				// 余额 小于应收 直接返回提示
				if (parseFloat(_this.balance) < parseFloat(_this.tradeInfo.rcvTotal)) {
					return _this.$message("您的预存款余额为" + _this.balance + "元，请充值余额或更换支付方式");
				}

				// 检测是否重复点击
				if (_this.isClickPay) {
					return false;
				}

				// 询问确认是否使用余额支付
				_this.$confirm('确认使用余额支付？')
					.then(async _ => {
						// 将支付标记置为是
						_this.isClickPay = true;

						if (await tradeUtils.tradePayConfirmAndPush(_this.tradeNo, _this.outPayInfo.outTradeNo,
								_this.outPayInfo.rcvAmount, '客户预存款', _this.outPayInfo.payScene)) {
							// 支付成功
							_this.paySuccess();
						} else {
							// 将支付标记置为否
							_this.isClickPay = false;

							// 弹出错误提示
							return _this.$message.error('推单失败');
						}

					})
					.catch(_ => {});
			},

			/**
			 * 积分支付
			 */
			async scorePay() {
				let customerInfo = await getCustomerInfo();
				// 检测用户积分是否充足
				if (this.payScore > 0 &&
					(!customerInfo.integralBalance || (parseFloat(customerInfo.integralBalance) || 0) < this.payScore)) {
					// 弹出错误提示
					return this.$message.error('积分不足');
				}
				if (await tradeUtils.tradePayConfirmAndPush(this.tradeNo, this.outPayInfo.outTradeNo,
						this.outPayInfo.rcvAmount, '', this.outPayInfo.payScene)) {
					// 支付成功
					this.paySuccess();
				} else {
					// 将支付标记置为否
					this.isClickPay = false;

					// 弹出错误提示
					return this.$message.error('推单失败');
				}
			},

			/**
			 * 货到付款付
			 */
			cashOnDeliveryPay() {
				let _this = this;

				// 检测是否重复点击
				if (_this.isClickPay) {
					return false;
				}

				// 询问确认是否使用余额支付
				_this.$confirm('确认货到付款下单？')
					.then(async _ => {
						// 将支付标记置为是
						_this.isClickPay = true;


						if (await tradeUtils.tradePayConfirmAndPush(_this.tradeNo, _this.outPayInfo.outTradeNo,
								_this.outPayInfo.rcvAmount, '货到付款', _this.outPayInfo.payScene)) {
							// 支付成功
							_this.paySuccess();
						} else {
							// 将支付标记置为否
							_this.isClickPay = false;

							// 弹出错误提示
							return _this.$message.error('推单失败');
						}
					})
					.catch(_ => {});
			},

			/**
			 * 检测是否为支付界面
			 */
			__isPayPage() {
				try {
					return location.href.includes('/pay');
				} catch (e) {
					return false;
				}
			},
			/**
			 * 获取预售商品
			 */
			_getPresaleGoods(refund = false) {
				let _this = this;
				let preCheckGoodsList = _this.tradeInfo.tradeGoodsList.map(t => {
					return {
						goodsId: t.goodsId,
						goodsName: t.goodsName,
						specId: t.specId,
						unitName: t.unit,
						goodsCount: refund ? parseInt(-t.sellCount) : parseInt(t.sellCount),
						activityType: t.actType,
						actId: t.actId
					}
				});
				return preCheckGoodsList[0];
			},

			/**
			 * 检测订单是否已经支付
			 */
			async _checkScroe() {
				let customerInfo = await getCustomerInfo();
				// 检测用户积分是否充足
				if (this.payScore > 0 &&
					(!customerInfo.integralBalance || (parseFloat(customerInfo.integralBalance) || 0) < this.payScore)) {
					// 弹出错误提示
					this.$message.error('积分不足');
					return false
				} else {
					return true
				}
			},

			/**
			 * 区域可购检测
			 */
			async _checkOrderAreas() {
				let canOrder = await checkCanOrderAreasByParams(this.tradeInfo.logisticType, this.tradeInfo)

				// 不可下单则弹出提示
				if (!canOrder) {
					this.$message.error("该配送地址不能下单");
					// 延迟跳转到订单列表
					this.gotoTradeList(10);
				}
				return canOrder;
			},

			/**
			 * 校验限购
			 */
			async _checkRestrictedGoods() {
				// 生成检测数据
				let specIds = this.tradeInfo.tradeGoodsList.map(t => t.specId);
				let {
					province,
					city,
					district
				} = this.tradeInfo;
				// 调用限购检测接口，获取检测结果（限购商品+有效商品）
				let res = await tradeUtils.checkRestrictedGoods(province, city, district, specIds);
				if (res.restrictedGoodsList && res.restrictedGoodsList.length > 0) {
					// 获取后台设置的提示文本
					let paramList = await getParam("restricted_explainText")

					this.$message.error(paramList.restricted_explainText || "该配送地址不能下单");
					// 延迟跳转到订单列表
					_this.gotoTradeList(1000);
					return;
				} else {
					return true;
				}
			},
			/**
			 * 检测下单库存检测
			 */
			async _checkOrderGoodsStock() {
				try {
					// 预售创建订单占用改为后端创建订单时候占用
					if (this.tradeInfo.isPresale == "1") {
						return true;
					}
					// if (this.tradeInfo.tradeGoodsList[0].act_type == "3" && this.tradeInfo.trade_status == "1") {
					// // 检测货品库存，结果包含可购和不可购两个列表
					// let presaleres = await goodsUtils.checkPresaleGoodsCount(this._getPresaleGoods(), this
					// 	.tradeInfo
					// 	.trade_id);
					// if (!presaleres) {
					// 	this.$message.error("预售库存不足");
					// 	// 延迟跳转到订单列表
					// 	this.gotoTradeList(1000);
					// 	return;
					// 	return true;
					// }
					// 生成检测数据
					let preCheckGoodsList = this.tradeInfo.tradeGoodsList.map(t => {
						return {
							goodsId: t.goodsId,
							specId: t.specId,
							unitName: t.unit || "",
							goodsCount: t.sellCount,
							activityType: t.actType,
							actId: t.actId
						}
					});

					// 检测货品库存，结果包含可购和不可购两个列表
					let res = await goodsUtils.checkOrderGoodsStock(preCheckGoodsList, this.tradeInfo.tradeId);

					if (res.baseUnitOverOrderGoodsList.length == 0) {
						return true;
					}

					// 超卖情况下，下单商品包含多单位
					if (this._checkCartHasMultiUnitOverOrder(res)) {
						this.isShowMultiUnitStockCheckModal = true;
						this.baseUnitOverOrderGoodsList = res.baseUnitOverOrderGoodsList;
					} else {
						// 数据渲染
						this.canOrderGoodsList = res.canOrderGoodsList; // 限购商品列表
						this.cannotOrderGoodsList = res.cannotOrderGoodsList; // 剩余可购规格Id
						this.isNoStockAll = res.canOrderGoodsList.length <= 0; // 是否无可购商品
						this.isShowCheckGoodsDialog = true; // 是否展示库存检测弹窗，存在库存不足的货品就展示
					}
					return false;
				} catch (e) {
					return true;
				}
			},

			/**
			 * 检测超卖情况下下单货品是否包含多单位
			 */
			_checkCartHasMultiUnitOverOrder: function(checkStockResult) {
				// 超卖商品的SpecId数组
				let resultSpecIdList = checkStockResult.baseUnitOverOrderGoodsList.map((item) => item.specId);
				let cartUnitOverOrderListLength = this.tradeInfo.tradeGoodsList.filter(t => resultSpecIdList.includes(t.specId)).length;
				return cartUnitOverOrderListLength > resultSpecIdList.length
			},

			/**
			 * 检测下单库存检测
			 */
			async _checkBlockUpGoods() {
				try {
					// 生成检测数据
					let reqTradeGoods = this.tradeInfo.tradeGoodsList.map(t => {
						return {
							goodsId: t.goodsId,
							specId: t.specId,
							specName: t.specName,
							goodsName: t.goodsName,
							//picUrl:t.pic_url,
						}
					});

					// 校验货品是否上架
					let blockUpGoodsList = await goodsUtils.checkBlockUpGoods(reqTradeGoods);
					blockUpGoodsList.forEach((item) => {
						let tradeGood = this.tradeInfo.tradeGoodsList.find((tradeItem) => item.goodsId == tradeItem.goodsId)
						item.picUrl = tradeGood.picUrl,
							item.specId = tradeGood.specId,
							item.goodsName = tradeGood.goodsName,
							item.specName = tradeGood.specName
					})
					this.cannotOrderGoodsList = blockUpGoodsList;
					this.isNoStockAll = this.cannotOrderGoodsList.length == reqTradeGoods.length; // 是否无可购商品
					//this.isNoStockAll = false
					if (blockUpGoodsList.length > 0) {
						this.isShowBlockCheckGoodsDialog = true;
					}
					return blockUpGoodsList.length == 0;
				} catch (e) {
					return true;
				}
			},

			/**
			 * 取消多规格单位库存检测弹窗
			 */
			multiUnitOverStockCancel() {
				this.isShowMultiUnitStockCheckModal = false;
				//将此订单商品加入购物车(接口为对活动处理，按普通商品)
				this.$request.postData({
					method: "pc.trade.addtradegoodstocart",
					trade_id: this.tradeInfo.tradeId,
					customer_id: this.tradeInfo.customerId,
					shop_id: this.tradeInfo.shopId || 0
				}, (data) => {
					if (data.error_code == "0") {
						this.$router.push({
							name: 'shopcart',
							params: {
								cartFrom: '再来一单'
							}
						});
					} else {
						this.$message.error(data.error_info);
					}
				});
			},

			/**
			 * 检测下单货品是否是会员等级限制商品
			 */
			async _checkMemberBlockGoods() {
				try {
					// 校验货品是否是会员等级限制商品  提示跟下架的情况相同
					let blockUpGoodsList = await goodsUtils.checkMemberBlockGoods(this.tradeInfo.tradeGoodsList.map((item) => item.goodsId));
					blockUpGoodsList.forEach((item) => {
						let tradeGood = this.tradeInfo.tradeGoodsList.find((tradeItem) => item.goodsId == tradeItem.goodsId)
						item.picUrl = tradeGood.picUrl,
							item.specId = tradeGood.specId,
							item.goodsName = tradeGood.goodsName,
							item.specName = tradeGood.specName
					})
					this.cannotOrderGoodsList = blockUpGoodsList;
					this.isNoStockAll = this.cannotOrderGoodsList.length == this.tradeInfo.tradeGoodsList.length; // 是否无可购商品
					//this.isNoStockAll = false
					if (blockUpGoodsList.length > 0) {
						this.isShowBlockCheckGoodsDialog = true;
					}
					return blockUpGoodsList.length == 0;
				} catch (e) {
					return true;
				}
			},

			/**
			 * 组件方法：过滤下架货品继续下单
			 */
			async filterBlockUpGoods() {
				// 接收新的待下单货品
				let newOrderGoodsList = [];
				// 遍历当前下单货品，排除不可购商品重新生成待下单货品
				let cannotOrderGoodsList = this.cannotOrderGoodsList;
				let canOrderGoods = this.tradeInfo.tradeGoodsList.filter((goods) => {
					return !cannotOrderGoodsList.find(t => t.goodsId == goods.goodsId)
				}) || [];
				if (canOrderGoods.length > 0) {
					canOrderGoods.forEach((item) => {
						newOrderGoodsList.push(item);
					})
				}

				// 加入临时购物车
				let tempCartIds = await this._addTempShoppingCart(newOrderGoodsList);


				// 获取订单地址对应的ID
				let addressId = await this._getAddressId();

				// 跳转到下单页
				this.$router.replace({
					name: 'tradeconfirm',
					params: {
						trade_from: this.tradeInfo.tradeFrom,
						cartIds: tempCartIds,
						addressId
					}
				});
			},

			/**
			 * 组件方法：过滤无库存货品继续下单
			 */
			async filterNoStockGoods() {
				// 接收新的待下单货品
				let newOrderGoodsList = [];
				// 遍历当前下单货品，结合可购列表重新生成待下单货品
				this.tradeInfo.tradeGoodsList.forEach((goods, index) => {
					// 获取对应的可购货品信息
					let canOrderGoods = this.canOrderGoodsList.find(t => t.goodsId == goods.goodsId && t
						.specId ==
						goods.specId && t.unitName == goods.unit);
					// 存在对应的可购商品，则修改其数量并添加到最新可购列表
					if (canOrderGoods) {
						goods.sellCount = canOrderGoods.goodsCount;
						newOrderGoodsList.push(goods);
					}
				});

				// 加入临时购物车
				let tempCartIds = await this._addTempShoppingCart(newOrderGoodsList);

				// 获取订单地址对应的ID
				let addressId = await this._getAddressId();

				// 跳转到下单页
				this.$router.replace({
					name: 'tradeconfirm',
					params: {
						trade_from: this.tradeInfo.tradeFrom,
						cartIds: tempCartIds,
						addressId
					}
				});
			},

			/**
			 * 加入临时购物车
			 * @param {*} validGoodsList 
			 */
			async _addTempShoppingCart(tradeGoodsList) {
				// 获取渠道Id和客户Id
				let channelId = localStorage.getItem("pc_channelId");
				let customerId = localStorage.getItem("pc_customerId");

				// 生成临时购物车数据
				let tempShoppingCartList = tradeGoodsList.map(t => {
					return {
						goodsId: t.goodsId,
						specId: t.specId,
						goodsCount: t.sellCount,
						unitName: t.unit,
						unitCountRate: t.unitCountRate,
						cartType: "-1",
						actType: t.actType || "0",
						actId: t.actId || "0",
					}
				});

				let cartIds = await goodsUtils.addTempShoppingCartBatch(tempShoppingCartList);

				// 检测获取结果是否正确
				if (cartIds == "0") {
					this.$message.error('货品移除失败，请重新下单');
					// 延迟跳转到订单列表
					this.gotoTradeList(1000);
					return;
				}
				return cartIds;
			},

			/**
			 * 查找当前下单地址对应的地址Id
			 */
			async _getAddressId() {
				// 获取地址信息
				let {
					province,
					city,
					district,
					address,
					tel,
				} = this.tradeInfo;
				let addressId = await addressUtils.getAddressIdbydetail(province, city, district, address);

				return addressId;
			},
		}
	}
</script>

<style scoped="scoped">
	.container {
		padding: 20px 30px;
		color: #333;
		background-color: #fff;
		margin-top: 30px;
	}

	.navigation {
		height: 40px;
		color: #333;
		margin-top: 10px;
	}

	.back-btn {
		font-size: 40px;
		line-height: 40px;
		vertical-align: top;
		cursor: pointer;
	}

	.cancel-btn {
		vertical-align: top;
		float: right;
	}

	.pay-info-box {
		padding: 20px 0 40px 0;
		border-bottom: 1px solid #e7e7e7;
	}

	.pay-info-img {
		background: #E7FCF0;
		width: 130px;
		height: 130px;
		border-radius: 70px
	}

	.pay-info-img img {
		width: 60px;
		height: 60px;
		margin: 33px 35px;
	}

	.pay-info-txt-box {
		margin-left: 30px;
	}

	.pay-info-txt {
		font-size: 23px;
	}

	.pay-info-txt span {
		color: #ff5757;
	}

	.pay-info-tips {
		font-size: 15px;
		color: #a1a1a1;
		margin-top: 15px;
	}

	.adr-info-box {
		padding: 40px 0px;
		border-bottom: 1px solid #e7e7e7;
	}

	.adr-info-box div {
		display: inline-block;
		text-align: center;
		width: 140px;
		font-size: 15px;
	}

	.adr-title {
		font-size: 18px !important;
	}

	.adr-tel {
		width: 160px !important;
	}

	.adr-detail {
		width: 680px !important;
		text-align: left !important;
		padding-left: 60px;
	}

	.payment-info-box {
		padding: 40px 0;
	}

	.payment-title {
		display: inline-block;
		text-align: center;
		width: 140px;
		font-size: 18px;
		line-height: 74px;
		vertical-align: top;
	}

	.payment-content {
		display: inline-block;
	}

	.payment-type-option {
		font-size: 18px;
		border: 1px solid #ddd;
		padding: 20px;
		border-radius: 3px;
		cursor: pointer;
		margin: 20px 35px;
		width: 160px;
	}

	.payment-type-option img {
		width: 32px;
		height: 32px;
	}

	.payment-type-option span {
		margin-left: 20px;
	}

	.payment-qrcode-box {
		padding-top: 60px;
	}

	.payment-count-down {
		font-size: 20px;
		color: #ff5757;
		line-height: 30px;
	}

	.payment-count-down span {
		padding: 0 5px;
	}

	.payment-qrcode {
		padding: 20px 0;
	}

	.payment-tips {
		text-align: center;
		color: #aaa;
	}

	.payment-tips span {
		display: block;
	}

	.option-selected {
		border: 1px solid #ff5757;
	}

	.payment-balance-box {
		text-align: center;
	}

	.balance-btn {
		width: 180px;
		height: 60px;
		margin-top: 100px;
		font-size: 17px;
	}

	.customer-balance {
		color: #aaa;
		line-height: 14px;
		width: 202px;
		/* 		margin-left: 644px; */
		text-align: center;
		font-size: 14px;
		position: absolute;
		bottom: 2px;
	}

	.balance-left1 {
		margin-left: 160px !important;
	}

	.balance-left2 {
		margin-left: 402px !important;
	}

	.balance-left3 {
		margin-left: 644px !important;
	}
</style>