<!-- 登录弹窗 -->
<template>
	<div>
		<!-- 登录表单 -->
		<el-dialog width="400px" :modal-append-to-body='false' :close-on-click-modal="false" :visible.sync="isShow" :before-close="loginDialogClose">
			<el-form class="login-box">
				<div class="login-title">{{forgetClicked?"忘记密码":"账号登录"}}</div>
				<!-- 账号密码登录 -->
				<div v-show="loginType == 'pwd'">
					<el-form-item class="login-item">
						<el-input v-model="loginName" placeholder="请输入用户名"></el-input>
					</el-form-item>
					<el-form-item class="login-item" style="text-align: right;">
						<el-input v-model="loginPwd" minlength="6" placeholder="请输入密码" show-password></el-input>
						<el-button type="text" @click="forgetPwd">忘记密码？</el-button>
					</el-form-item>
					<el-form-item class="login-item">
						<el-button class="btn-login" type="danger" @click="loginByPwd" :loading="loginClicked">登 录</el-button>
					</el-form-item>
					<el-form-item class="login-item" style="margin-top: 10px;">
						<el-button class="btn-login btn-change-type" @click="loginType = 'tel'">使用手机号登录</el-button>
					</el-form-item>
				</div>
				<!-- 手机号验证码登录 -->
				<div v-show="loginType == 'tel'">
					<el-form-item class="login-item">
						<el-input v-model="loginTel" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号"></el-input>
					</el-form-item>
					<el-form-item class="login-item">
						<el-input class="input-vcode" v-model="loginCode" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入验证码"></el-input>
						<el-button class="btn-vcode" type="danger" @click="getVerificationCode">{{vcodeBtnTxt}}</el-button>
					</el-form-item>
					<el-form-item class="login-item" style="margin-top: 60px;">
						<el-button class="btn-login" type="danger" @click="loginCheck" :loading="loginClicked">{{forgetClicked?"确 定":"登 录"}}</el-button>
					</el-form-item>
					<el-form-item v-show="!forgetClicked" class="login-item" style="margin-top: 10px;">
						<el-button class="btn-login btn-change-type" @click="loginType = 'pwd'">使用用户名密码登录</el-button>
					</el-form-item>
				</div>
			</el-form>
		</el-dialog>
		<!-- 完善用户名密码信息弹窗 -->
		<name-pwd-dialog :isShow="showCompleteInfo" :oldName="oldLoginName" :tel="loginTel" @success="completeSuccess"></name-pwd-dialog>
	</div>
</template>

<script>
	import NamePwdDialog from './NamePwdDialog.vue';
	import { getCustomerInfo } from '../../utils/api-utils/api-customer.js';
	var interval;
	export default {
		name: 'login-dialog',
		components: {
			'name-pwd-dialog': NamePwdDialog
		},
		props: {
			isShow: {
				type: Boolean,
				default: false
			},
			showClose: {
				type: Boolean,
				default: true
			} //是否显示关闭按钮
		},
		data() {
			return {
				dialogVisible: false, // 是否显示登录弹窗
				loginTel: "", // 用户输入的手机号
				loginCode: "", // 用户输入的验证码
				sysLoginCode: "66991", // 系统发送的验证码，用作校验
				vcodeBtnTxt: "获取验证码", // 获取验证码按钮文字
				vcodeIntervalTime: 60, // 验证码发送间隔 
				loginClicked: false, // 按钮已点击
				loginType: "pwd", // 登录方式 tel:手机号验证码, pwd:登录名密码
				loginName: "", //登录名
				loginPwd: "", //登录密码
				showCompleteInfo: false, //完善信息弹窗（用户名密码）
				forgetClicked: false, //点击了忘记密码
				oldLoginName: "", //原登录名
			}
		},
		mounted() {
			//监听登录弹窗是否显示,是否可关闭
			// this.$eventBus.$on("showLoginDialog", (showLogin, showClose) => {
			// 	this.loginTel = "";
			// 	this.loginCode = "";
			// 	clearInterval(interval);
			// 	this.vcodeBtnTxt = "获取验证码";
			// 	this.vcodeIntervalTime = 60;
			// })
		},
		methods: {
			/**
			 * 弹窗关闭事件
			 */
			loginDialogClose() {
				//强制登录的页面，关闭时回到首页
				if (!this.showClose && this.$route.name == "home") {
					location.reload();
				} else if (!this.showClose) {
					this.$router.push({
						name: 'home'
					})
				}

				// 弹窗关闭时调用父级传递方法close内容，将关闭信息传递到父级
				this.$emit('close');

				// 重置忘记密码操作
				this.forgetClicked = false;
			},

			/**
			 * 获取验证码
			 */
			getVerificationCode() {
				let _this = this;

				//关闭所有警告
				_this.$message.closeAll();

				// 检测是否可以再次获取验证码
				if (_this.vcodeBtnTxt != "获取验证码") {
					return false;
				}

				// 检测验证码输入是否正确
				if (!_this.loginTel) {
					return _this.$message.error('请输入手机号');
				}

				// 检测手机号格式是否正确
				if (!_this.$utils.checkTel(_this.loginTel)) {
					return _this.$message.error('手机号格式不正确');
				}

				// 发送请求获取验证码
				_this.$request.jackyunPost({
					method: "jmall.sms.sendverificationcode",
					jsonStr: JSON.stringify({
						loginTel: _this.loginTel,
					})
				}, (data) => {

					// 检测验证码发送是否正确
					if (data.code != 200) {
						return _this.$message.error(data.msg);
					}
					// 正确情况记录验证码，用作前端校验
					_this.getSysLoginCode = true;

					// 开始倒计时
					_this.countdown();

				});
			},
			/**
			 * 校验验证码
			 */
			checkVerificationCode() {
				let _this = this;
				return new Promise((resolve, reject) => {
					// 发送请求获取验证码
					_this.$request.jackyunPost({
						method: "jmall.sms.checkverificationcode",
						jsonStr: JSON.stringify({
							loginTel: _this.loginTel,
							verificationCode: _this.loginCode
						})
					}, (data) => {
						// 检测验证码发送是否正确
						if (data.code != 200) {
							return resolve(false);
						} else {
							return resolve(true);
						}

					});
				})
			},

			/**
			 * 倒计时
			 */
			countdown() {
				let _this = this;
				interval = setInterval(function() {
					_this.vcodeBtnTxt = _this.vcodeIntervalTime + "秒";
					_this.vcodeIntervalTime = _this.vcodeIntervalTime - 1;
					if (_this.vcodeIntervalTime < 0) {
						_this.vcodeBtnTxt = "获取验证码";
						_this.vcodeIntervalTime = 60;
						clearInterval(interval);
					}
				}, 1000);
			},

			/**
			 * 登录验证
			 */
			async loginCheck() {
				let _this = this;
				//关闭所有警告
				_this.$message.closeAll();

				// 检测手机号是否输入
				if (!_this.loginTel) {
					return _this.$message.error('请输入手机号');
				}

				// 检测手机号格式是否正确
				if (!_this.$utils.checkTel(_this.loginTel)) {
					return _this.$message.error('手机号格式不正确');
				}

				// 检测验证码输入是否正确
				if (!_this.loginCode) {
					return _this.$message.error('请输入验证码');
				}

				//检测验证码是否已经获取
				if (!_this.getSysLoginCode) {
					return _this.$message.error('请获取验证码');
				}
				// if (!await _this.checkVerificationCode()) {
				// 	return _this.$message.error('验证码不正确');
				// }

				// 检测验证码是否填写正确
				// if (_this.loginCode != _this.sysLoginCode) {
				// 	return _this.$message.error('验证码不正确');
				// }

				// 获取用户信息
				_this._getCustomerInfoByTel();
			},

			/**
			 * 获取用户信息
			 * @param {Object} isSkipRegister 是否跳过注册（如果没有获取到会员信息是否自动注册标记）
			 */
			async _getCustomerInfoByTel() {
				let _this = this;
				if (_this.loginClicked) {
					return _this.$message.error('请勿重复点击');
				}
				_this.loginClicked = true;

				// 手机号登录
				let customer = await getCustomerInfo({ tel: _this.loginTel, verificationCode: _this.loginCode }, true);

				_this.loginClicked = false;
				if (!customer.customerId || customer.error) {
					return _this.$message.error(customer.error);
				}

				// 判断是否有用户名密码，没有的话，提示设置（忘记密码也需要弹窗重置）
				if (!customer || this.forgetClicked) {
					this.oldLoginName = "";
					this.showCompleteInfo = true;
					this.forgetClicked = false;
				} else if (!customer.loginName) {
					this.oldLoginName = customer.loginName || "";
					this.showCompleteInfo = true;
				} else {
					_this.completeSuccess();
				}
			},

			/**
			 * 用户名密码登录
			 */
			loginByPwd() {
				let _this = this;

				//关闭所有警告
				_this.$message.closeAll();

				// 检测用户名是否输入
				if (!_this.loginName) {
					return _this.$message.error('请输入用户名');
				}

				// 检测密码是否输入
				if (!_this.loginPwd) {
					return _this.$message.error('请输入密码');
				}
				
				// 检测密码是否输入
				// if (_this.loginPwd.length < 6) {
				// 	return _this.$message.error('请至少输入6位数密码');
				// }

				// 获取用户信息
				_this.getCustomerByPwd();
			},

			/**
			 * 根据用户名密码，获取用户信息
			 */
			async getCustomerByPwd() {
				let _this = this;
				if (_this.loginClicked) {
					return _this.$message.error('请勿重复点击');
				}
				_this.loginClicked = true;

				//用户名密码登录
				let customer = await getCustomerInfo({ loginName: _this.loginName, loginPwd: btoa(encodeURIComponent(_this.loginPwd))}, true)
				_this.loginClicked = false;
				if (!customer.customerId || customer.error) {
					//return;
					return _this.$message.error(customer.error);
				}

				// 刷新当前页面
				_this.completeSuccess();
			},

			/**
			 * 用户名密码设置完成
			 */
			completeSuccess() {
				// 获取用户信息
				this.showCompleteInfo = false;
				this.$emit('close');
				// location.reload();
			},

			/**
			 * 忘记密码（先用手机号登录，再设置密码）
			 */
			forgetPwd() {
				this.loginType = 'tel';
				this.forgetClicked = true;
			}
		}
	}
</script>

<style scoped="scoped">
	.login-box {
		padding: 0 50px 30px 50px;
	}

	.login-title {
		text-align: center;
		font-size: 26px;
		margin-bottom: 50px;
	}

	.login-item {
		margin-bottom: 20px;
	}

	.input-vcode {
		width: 138px;
	}

	.btn-vcode {
		margin-left: 10px;
		width: 112px;
	}

	.btn-login {
		width: 100%;
	}

	.btn-change-type {
		border: 1px solid #f56c6c;
		color: #f56c6c;
	}
</style>