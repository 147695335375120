<template>
	<div class="cgoods-list flex-col">
		<el-row class="cgoods-item" :gutter="30" v-for="(item, index) in goodslist" :key="index">
			<el-col :span="4">
				<el-image class="cgoods-img" fit='scale-down' :src="$utils.setImg(item.pic0)" :data-goodsid="item.goodsId" @click="gotoGoodsDetail"></el-image>
			</el-col>
			<el-col :span="8" style="position: relative;">
				<div class='goods-box' :data-goodsid="item.goodsId" @click="gotoGoodsDetail">
					<div class="cgoods-name">{{item.goodsName}}</div>
					<div class="cspec-name" style='height: 20px;'>
						{{(isShowSingleSpec && item.isMulti=='0')|| item.isMulti!='0'?item.skuName:''}}
					</div>
				</div>
				<div class="flex-row barcode-class" style="">
					<div class="cspec-name" v-if="item.barcode">
						条码:{{item.barcode}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</div>
					<div class="cspec-name" v-if="item.serialNos.length>0">
						唯一码:{{item.serialNos.toString()}}
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="cgoods-price">
					<span v-show="item.sellPrice > 0 || item.sellPoint == 0">{{$currency.symbol}}{{$utils.amountFixed(item.sellPrice,2)}}</span>
					<span v-show="item.sellPrice != 0 && item.sellPoint > 0"> + </span>
					<span v-show="item.sellPoint > 0">{{item.sellPoint}}</span>
					<span v-show="item.sellPoint > 0" class="sscore-text">积分</span>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="cgoods-price">x {{item.sellCount}} {{item.unit}}</div>
			</el-col>

		</el-row>

	</div>
</template>

<script>
	export default {
		name: "goods-list",
		props: {
			goodslist: {
				type: Array,
				default: function() {
					return [];
				}
			},
			isShowSingleSpec: {
				type: Boolean,
				default: true
			}
		},
		methods: {
			/**
			 * 跳转商品详情
			 * @param {Object} e
			 */
			gotoGoodsDetail(e) {
				let goodsId = e.currentTarget.dataset.goodsid;
				let encode = window.btoa(window.encodeURIComponent(goodsId))
				this.$router.push({
					name: 'goodsdetail',
					query: {
						id: encode
					}
				});
			},
		}
	}
</script>

<style scoped="scoped">
	.cgoods-list :last-child {
		border: none !important;
	}

	.cgoods-item {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dedede;
	}

	.cgoods-img {
		width: 100%;
		height: 110px;
	}

	.cspec-name {
		margin-top: 20px;
		font-size: 15px;
		color: #666;
		word-break: break-all;
	}

	.sscore-text {
		font-size: 15px;
		margin-left: 3px;
	}

	.goods-box {
		min-height: 70px;
		height: 110px;
	}

	.barcode-class {
		position: absolute;
		bottom: 3px;
		width: 760px;
	}
</style>