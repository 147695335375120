const request = require('../request-utils.js');

import { callFeign } from "./api-callFeign.js"

/**
 * 获取地址列表
 */
const getAddressList = async (keyword, pageSize = 50, pageIndex = 0) => {
	let customerId = localStorage.getItem("pc_customerId");
	if (!customerId) {
		return [];
	}

	// 获取并返回地址列表
	return await _getAddressListFromOrigin(customerId, keyword, "", "", pageSize, pageIndex);
}

/**
 * 获取地址详情
 */
const getAddressDetail = async (addressId) => {
	let customerId = localStorage.getItem("pc_customerId");
	console.log("customerId:", customerId)
	if (!customerId) {
		return {};
	}
	if (addressId) {
		customerId = "";
	}
	// 获取并返回地址列表
	let addressList = await _getAddressListFromOrigin(customerId, "", addressId, "");
	if (addressList.length <= 0) {
		return {};
	}

	return addressList[0];
}

/**
 * 获取地址详情
 */
const getDefaultAddress = async () => {
	let customerId = localStorage.getItem("pc_customerId");
	if (!customerId) {
		return {};
	}

	// 获取并返回地址列表
	let addressList = await _getAddressListFromOrigin(customerId, "", "", 1);
	if (addressList.length <= 0) {
		return {};
	}

	return addressList[0];
}

/**
 * 获取原始地址列表
 */
const _getAddressListFromOrigin = (customerId, keyword, addressId = "", isDefault = "", pageSize = 50, pageIndex = 0) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.address.getaddresslist",
			customerId,
			keyword,
			addressId,
			isDefault,
			pageSize,
			pageIndex
		}, (res) => {
			let addressList = [];
			if (res.code == 200) {
				try {
					addressList = res.result.data || [];
					addressList.forEach(t => {
						t.country = t.country || "中国";
						t.province = t.province || "";
						t.city = t.city || "";
						t.district = t.district || "";
						t.town = t.town || "";
						t.isDefault = t.isDefault == 1;
					})
				} catch (e) {}
			} else {
				console.log("地址列表获取失败：", res);
			}
			resolve(addressList);
		});
	});
}

/**
 * 获取地址列表数量
 */
const getAddressListCount = async (keyword) => {
	let customerId = localStorage.getItem("pc_customerId");
	if (!customerId) {
		return 0;
	}

	// 获取并返回地址列表
	return await _getAddressListCountFromOrigin(customerId, keyword);
}

/**
 * 获取原始地址列表数量
 */
const _getAddressListCountFromOrigin = (customerId, keyword) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.address.getaddresslistcount",
			customerId,
			keyword
		}, (res) => {
			if (res.code == 200) {
				resolve(res.result.data || 0);
			} else {
				resolve(0);
			}
		});
	});
}

/**
 * 删除地址
 */
const deleteAddress = (addressIds) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.address.deleteaddress",
			addressIdList: addressIds
		}, (res) => {
			resolve(res.code == 200);
		});
	});
}

/**
 * 根据地址详情反查地址ID
 */
const getAddressIdbydetail = (province, city, district, adr) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.address.getaddressidbydetail",
			province,
			city,
			district,
			adr
		}, (res) => {
			let data = 0;
			if (res.code == 200) {
				data = res.result.data;
			}
			resolve(data);
		});
	});
}

/**
 * 获取经纬度
 */
const getLocationByAddress = (address) => {
	return new Promise((resolve, reject) => {
		request.default.jackyunPost({
			method: "jmall.address.getloactionbyadress",
			jsonStr: {
				address
			}
		}, (res) => {
			let location = {
				longitude: 0,
				latitude: 0
			};
			if (res.code == 200) {
				location.longitude = res.result.data.lng;
				location.latitude = res.result.data.lat;
			}
			resolve(location);
		});
	});
}

/**
 * 地址解析
 */
const addressrAnalyze = (address) => {
	//地址解析接口
	let jsonStr = JSON.stringify({
		text: address
	});
	return new Promise((resolve, reject) => {
		callFeign("oms.trade.adranalyze", {
			jsonStr
		}).then((res) => {
			// 检测获取结果，失败则返回null
			if (res.code != 200) {
				resolve(null);
				return this.message.error(res.data.error_info || "解析失败");
			}

			// 获取解析结果
			let address = res.result.data.tradeOrderReceiver;
			address.country = address.country || "中国";
			address.state = address.state || "";
			address.city = address.city || "";
			address.district = address.district || "";
			address.town = address.town || "";
			address.address = address.address || "";
			address.receiverName = address.receiverName || "";
			address.mobile = address.mobile || "";

			// 返回解析结果
			resolve(address);
		});
	});
}

/**
 * 获取国家列表
 */
const getCountryList = () => {
	return new Promise((resolve, reject) => {
		callFeign("erp.address.getcountrylist", {}, "v1").then((res) => {
			try {
				// 生成指定格式数据
				let countryList = res.result.data.map(t => {
					return {
						label: `${t.countryName} - ${t.countryCode.toLocaleLowerCase()}`,
						value: `${t.countryName}`
					};
				});

				localStorage.setItem("countryList", JSON.stringify(countryList));
				resolve(countryList);
			} catch (e) {
				resolve([]);
				console.log('国家列表加载异常：' + e.message);
			}
		});
	});
}

export default {
	getAddressList,
	getAddressDetail,
	getDefaultAddress,
	getAddressListCount,
	deleteAddress,
	getLocationByAddress,
	addressrAnalyze,
	getCountryList,
	getAddressIdbydetail
}