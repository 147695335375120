<template>
	<div class="container trade-container">
		<div class="content-box">
			<div class="info-box">
				<div class="flex-row back-box">
					<i class="el-icon-arrow-left back-btn" @click="goBack"></i>
					<div class="flex-between" style="width: 100%;">
						<div class="title">配送地址</div>
						<div class="title" style="cursor: pointer;" @click="openAddress"><i class="el-icon-location" />使用其它地址</div>
					</div>

				</div>
				<address-list :addresslist="addressShowList" :selectid="addrSelectedId" @success="editAddrSuccess" @change="AddressChange"></address-list>
			</div>
			<div class="info-box">
				<div class="title">商品信息</div>
				<goods-list :isShowSingleSpec="isShowSingleSpec" :goodslist="tradeGoods"></goods-list>
			</div>
			<div class="info-box">
				<div class="row-box flex-row ">
					<div>物流选择</div>
					<el-select :popper-append-to-body="false" v-model="selectedlogisticName" placeholder="请选择" @change="logisticChange">
						<el-option v-for="item in logisticList" :key="item.id" :value="item.showname">
						</el-option>
					</el-select>
				</div>
				<div class="row-box flex-row  date_picker mt10" v-if="isOpenExpectedDeliveryTime">
					<!-- <div>期望送达时间</div>
					<el-date-picker style="width: 217px;" format="yyyy-MM-dd HH:mm:ss" popper-class="date_picker"
						value-format="yyyy-MM-dd HH:mm:ss" v-model="expectReciveTime" type="datetime" placeholder="请选择"
						:picker-options="pickerExpectReciveOptions">
					</el-date-picker> -->
					<div>期望发货时间</div>
					<el-date-picker style="width: 217px;" format="yyyy-MM-dd" popper-class="date_picker" value-format="yyyy-MM-dd" v-model="expectedDeliveryTime" type="date" placeholder="请选择" :picker-options="pickerExpectReciveOptions">
					</el-date-picker>
					<!-- <el-select :popper-append-to-body="false" v-model="selectedlogisticName" placeholder="请选择"
						@change="logisticChange">
						<el-option v-for="item in logisticList" :key="item.id" :value="item.showname">
						</el-option>
					</el-select> -->
				</div>
				<div class="row-box flex-row mt10" v-if="isOpenCollectBuyerPhone">
					<div>订购人电话</div>
					<el-input type="text" style="width: 217px;" v-model="changeTel" placeholder="请填写订购人手机号（选填）"></el-input>
				</div>
				<!-- <div class="row-box row-remark flew-row1" v-if="isOpenCollectBuyerPhone">
					<div style="width:60%" class="flew-row1">
						<div class="tip-text">发货时间</div>
						<el-date-picker style="width: 217px;" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
							v-model="deliveryDate" type="date" placeholder="请选择发货时间" :picker-options="pickerOptions">
						</el-date-picker>
					</div>
					<div style="width:40%" class="flew-row1">
						<div class="tip-text2">兑换人手机号</div>
						<el-input type="text" style="width: 217px;" v-model="changeTel"
							placeholder="请填写兑换人手机号（选填）"></el-input>
					</div>

				</div> -->
				<div v-if="isOpenOrderSentAttachments" class="row-box row-remark flex-row">
					<div>{{orderSentAttachmentsRemind}}</div>
					<el-upload accept=".jpg,.jpeg,.png" :limit="3" :action="config.uploadFileUrl" list-type="picture-card" :data="{
						userName: userName,
						fileClass: 'fileImg',
					  }" :on-preview="_handlePictureCardPreview" :on-exceed="_handlePictureCardExceed" :on-success="_handleSuccess">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
				<div v-if="isShowRequiredField" class="row-box row-remark flex-row">
					<div style="width: 170px;">{{requiredFieldShowName}}</div>
					<el-input class="trade-remark" type="textarea" maxlength="200" v-model="requiredFieldText" show-word-limit></el-input>
				</div>
				<div class="row-box row-remark flex-row">
					<div>订单备注</div>
					<el-input class="trade-remark" type="textarea" maxlength="500" v-model="customerRemark" show-word-limit></el-input>
				</div>


			</div>
			<div class="info-box total-box">
				<el-form label-position="right" label-width="100px" class="flex-col">
					<el-form-item label="商品合计 :">
						<div class="money-text">
							<span v-show="goodsTotal > 0 || scoreTotal == 0">{{$currency.symbol}}{{$utils.amountFixed(goodsTotal, 2)}}</span>
							<span v-show="goodsTotal != 0 && scoreTotal > 0"> + </span>
							<span v-show="scoreTotal > 0">{{scoreTotal}}</span>
							<span v-show="scoreTotal > 0" class="score-text size14">积分</span>
						</div>
					</el-form-item>

					<el-form-item label="运费 :">
						<div class="money-text  ">
							{{$currency.symbol}}{{$utils.amountFixed(freight, 2)}}
						</div>
						<div class="freepostage" v-if="showFreepostageDifference "> 再买{{freepostagedifference}}元可享受包邮权益</div>
					</el-form-item>
					<el-form-item v-if="isPresaleTrade" label="合计 :">
						<div class="money-text">
							<span v-show="rcvTotal > 0 || scoreTotal == 0">{{$currency.symbol}}{{$utils.amountFixed(rcvTotal, 2)}}</span>
							<span v-show="scoreTotal > 0"> + </span>
							<span v-show="scoreTotal > 0">{{scoreTotal}}</span>
							<span v-show="scoreTotal > 0" class="score-text size16">积分</span>
						</div>
					</el-form-item>
					<el-form-item v-if="isPresaleTrade" label="定金比例 :">
						<div class="money-text">{{depositRatio}}%</div>
					</el-form-item>
					<el-form-item label="应付 :">
						<div class="money-text total">
							<span v-show="showRcvTotal > 0 || scoreTotal == 0">{{$currency.symbol}}{{$utils.amountFixed(showRcvTotal, 2)}}</span>
							<span v-show="scoreTotal > 0"> + </span>
							<span v-show="scoreTotal > 0">{{scoreTotal}}</span>
							<span v-show="scoreTotal > 0" class="score-text size16">积分</span>
						</div>
					</el-form-item>
					<el-form-item class="pay-box ">
						<div class="freepostage-text">

							<el-button type="danger" class="pay-btn" @click="toPay">提交订单</el-button>
						</div>

					</el-form-item>
				</el-form>
			</div>
		</div>
		<selectAddr v-if="addressSelectVisible" :key="addressSelectkey" :addressListVisible="addressSelectVisible" :selectAddressid="addrSelectedId" @val-change="AddressChangeInfo" @cancel="closeSelectAddr"></selectAddr>
		<!-- 货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowCheckGoodsDialog" :goodsList="cannotOrderGoodsList" :isNoValidGoods="isNoStockAll" @confirm="filterNoStockGoods">
		</check-goods-dialog>
		<!-- 货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowBlockCheckGoodsDialog" :goodsList="cannotOrderGoodsList" :isNeedTitle="true" :isNoValidGoods="isNoStockAll" :isBlockUpGoodsCheck="true" :noValidGoodsTip="`商品已下架，请重新下单`" :hasValidGoodsTip="`以下商品已下架`" :confirmText="`移除下架商品`" :cancelText="`回到首页`" @confirm="filterBlockUpGoods">
		</check-goods-dialog>
		<!-- 多单位货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowMultiUnitStockCheckModal" :goodsList="baseUnitOverOrderGoodsList" :isMultiUnitOverStock="true" hasValidGoodsTip="库存不足,请重新选货" @cancel="multiUnitOverStockCancel">
		</check-goods-dialog>

		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import vHeader from '../../components/common/Header.vue'
	import goodsList from '../../components/trade/GoodsList.vue'
	import addressList from '../../components/trade/AddressList.vue'
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import tradeUtils from '../../utils/api-utils/api-trade.js'
	import selectAddr from '../../components/trade/TradeConfirmAddressList.vue';
	import checkGoodsDialog from '../../components/trade/CheckGoodsDialog.vue';
	import commonUtil from '../../utils/common-utlis.js';
	import addressUtils from '../../utils/api-utils/api-address.js';


	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js';
	import {
		calcMarketingGoodsTotalAmount,
		getPresaleInfoByGoodsId
	} from '../../utils/api-utils/api-activity.js';
	import {
		config
	} from '../../config.js';
	import {
		getShoppingCartList
	} from '../../utils/api-utils/api-goods-shoppingcart.js';
	import {
		getChannelInfo
	} from '../../utils/api-utils/api-channel.js';

	export default {
		name: 'tradeconfirm',
		components: {
			vHeader,
			goodsList,
			addressList,
			selectAddr,
			"check-goods-dialog": checkGoodsDialog
		},
		computed: {
			pickerExpectReciveOptions() {
				//选择的日期
				let curDate = this.$moment(this.expectReciveTime || this.minTime).format("YYYY-MM-DD");
				//最小日期
				let tempData = new Date();
				let deliveryDelayedDays = (this.tradeGoods.find(item => item.stock <= 0) ? (this.overStockGoodsDelayedDays || this.expectedDeliveryDelayedDays) : this.expectedDeliveryDelayedDays) || "0";
				let tempMinDate = new Date(tempData.setDate(tempData.getDate() + deliveryDelayedDays / 1));
				let minDate = this.$moment(tempMinDate).format("YYYY-MM-DD");
				//前推一天，防止当天日期无法选择
				let preTime = this.minTime - 24 * 3600000 + deliveryDelayedDays / 1 * 24 * 3600000;
				// 如果选择的日期为最小日期，则设置为最小日期的时分秒
				let str = "";
				if (curDate == minDate) {
					str = this.$moment(this.minTime).format("HH:mm:ss");
				} else {
					str = "00:00:00"
				}
				return {
					disabledDate: (time) => {
						return time.getTime() < preTime || time.getTime() < minDate || time.getTime() > this.maxTime;
					},
					selectableRange: str + " - 23:59:59"
				}
			},
			userName() {
				return localStorage.getItem('SubMemberName') || localStorage.getItem('JackyunUserName')
			}
		},
		data() {
			return {
				pickerOptions: {
					disabledDate: this.checkTime
				},
				minTime: Date.now(),
				tradeFrom: '', //订单来源
				addressList: [], //地址列表
				addressShowList: [], // 展示列表
				addrSelectedId: '', //选中的地址id
				address: {}, //下单地址
				tradeGoods: [], //商品列表
				isOpenCityBuyLimit: false, //区域限购
				buyLimitCities: '', //限购地区
				logisticList: [], //物流列表
				bindLogisticId: '', //物流id
				selectedlogisticName: '', //物流名称
				logisticType: 1, //默认普通快递
				goodsTotal: 0.00, //商品总金额
				scoreTotal: 0, //商品总积分
				weightTotal: 0, //重量
				freight: 0.00, //运费
				tradeTotal: 0.00, //除掉运费的总和
				rcvTotal: 0.00, //实际支付	
				showRcvTotal: 0, // 用于展示的应收金额（预售情况即为首款金额）
				isPostageOver: false, //是否已完成运费计算
				isClickPay: false, //防止重复提交
				channelId: '', //渠道信息
				customerRemark: '', //订单备注
				requiredFieldText: '', //必填字段内容
				isOpenZeroStockBuy: false, // 0库存下单
				isShowSingleSpec: true, //展示单规格
				addressSelectVisible: false,
				addressSelectkey: true,
				warehouseId: '',
				blockUpGoodsRenderList: [], // 下架商品列表
				canOrderGoodsList: [], // 限购商品列表
				cannotOrderGoodsList: [], // 剩余可购规格Id
				isNoStockAll: false, // 是否无可购商品
				isShowCheckGoodsDialog: false, // 是否展示库存检测弹窗，存在库存不足的货品就展示
				isShowBlockCheckGoodsDialog: false, // 是否展示下架商品检测弹窗，存在下架货品就展示
				channelInfo: {},
				currencyCode: '',
				currencyName: '',
				isOpenDistributorTradeType: false, // 是否开启分销商代销发货
				isOpenOrderSentAttachments: false,
				isShowRequiredField: false,
				requiredFieldToOms: "",
				requiredFieldShowName: "",
				orderSentAttachmentsRemind: "",
				defaultAccountId: "", // 默认结算账户Id
				defaultAccountName: "", // 默认结算账户名称
				depositRatio: 100, // 预售比例
				isPresaleTrade: false, // 是否为预售订单
				deliveryDate: '',
				expectReciveTime: '',
				changeTel: '',
				isShowCollectChangerInfo: false,
				couponExchangeDelayedSendDays: 0,
				expectedDeliveryTime: '', // 期望发货时间
				freepostagedifference: undefined, //包邮差额
				isOpenExpectedDeliveryTime: false, // 是否开启期望发货时间
				isOpenCollectBuyerPhone: false, // 订购人电话
				isOpenExpectReciveTime: false, // 开启期望送达时间
				freePostageType: 'rcvTotal', // 包邮类型（rcvTotal：实收金额，goodsTotal：商品金额，默认：rcvTotal）
				showFreepostageDifference: false, //是否显示包邮
				expectedDeliveryDelayedDays: "", //期望发货推迟时间
				isShowMultiUnitStockCheckModal: false, //展示多单位库存校验弹窗
				baseUnitOverOrderGoodsList: [], //展示多单位库存不足货品
				config: config, //获取url参数
				attachList: [], // 附件urlList
				dialogImageUrl: '',
				dialogVisible: false,
				tradeAmountLimit_isEnabled: false, // 是否开启下单金额限制
				tradeAmountLimit_limitType: "rcvTotal", // 下单金额限制类型（rcvTotal：实收金额，goodsTotal：商品金额，默认：rcvTotal）
				tradeAmountLimit_limitAmount: 0, // 下单金额限制金额
			}
		},
		// beforeRouteEnter(to, from, next) {
		// 	//判断是否是来自下单页面
		// 	if (['goodsdetail', 'shopcart', 'timedsalegoodsdetail', 'tradeconfirm'].includes(from.name)) {
		// 		next();
		// 	} else {
		// 		next({
		// 			name: 'goodslist'
		// 		});
		// 	}
		// },
		async mounted() {
			// 页面数据初始化
			this._initData(this.$route.params);

			// 页面数据初始化
			await this._getCustomerInfo();

			// 获取配置信息
			await this._getParam();

			// 获取购物车列表
			await this._loadShoppingCartList();

			// 加载地址信息
			this._loadAddress(this.$route.params.addressId);

			// 获取预售商品信息
			this._getPresaleGoodsInfo();
		},
		methods: {
			/**
			 * 页面数据初始化
			 */
			_initData(params) {
				// 获取购物车Id
				this.cartIds = params.cartIds || '';
				// 获取订单来源
				this.tradeFrom = params.trade_from;

				// 参数检测，参数无效则返回上一页
				if (!this.cartIds) {
					this.goBack();
					this.$message("未检测到商品信息");
				}
			},

			/**
			 * 页面数据初始化
			 */
			async _getCustomerInfo() {
				// 获取客户信息
				let customerInfo = await getCustomerInfo()
				this.customerInfo = customerInfo;
				this.customerId = customerInfo.customerId;
				this.nickname = customerInfo.nickname || "";
			},

			//读取配置参数
			async _getParam() {
				let paramList = await getParam(
					"isCollectChangerInfo,couponExchangeDelayedSendDays,isOpenExpectReciveTime,bindSaleChannelId,isOpenCityBuyLimit,buyLimitCities,bindLogisticList,isOpenZeroStockBuy,isShowSingleSpec,currencyCode,currencyName,isOpenDistributorTradeType,defaultAccountId,defaultAccountName,freePostageType,isOpenCollectBuyerPhone,isOpenExpectedDeliveryTime,expectedDeliveryDelayedDays,overStockGoodsDelayedDays,requiredFieldShowName,requiredFieldToOms,isShowRequiredField,isOpenOrderSentAttachments,OrderSentAttachmentsRemind,isMustIDCard,tradeAmountLimit_isEnabled,tradeAmountLimit_limitType,tradeAmountLimit_limitAmount"
				)
				this.channelId = paramList.bindSaleChannelId || '';
				// 0库存下单
				this.isOpenZeroStockBuy = paramList.isOpenZeroStockBuy == "1";
				// 是否开启区域限购
				if (paramList.isOpenCityBuyLimit && paramList.isOpenCityBuyLimit == "1") {
					this.isOpenCityBuyLimit = true;
					this.buyLimitCities = paramList.buyLimitCities ? JSON.parse(paramList.buyLimitCities) :
						'';
				}
				this.isShowSingleSpec = paramList.isShowSingleSpec == "0" ? false : true;
				this.currencyCode = paramList.currencyCode || 'CNY';
				this.currencyName = paramList.currencyName || '人民币';
				this.isOpenDistributorTradeType = paramList.isOpenDistributorTradeType == "1";
				this.isOpenOrderSentAttachments = paramList.isOpenOrderSentAttachments == "1"; // 是否开启附件必传
				this.isShowRequiredField = paramList.isShowRequiredField == "1"; // 是否开启必填字段设置项
				this.orderSentAttachmentsRemind = paramList.OrderSentAttachmentsRemind; // 附件字段
				this.requiredFieldToOms = paramList.requiredFieldToOms; // 传至oms自定义字段
				this.requiredFieldShowName = paramList.requiredFieldShowName; // 自定义字段展示名称
				this.isMustIDCard = paramList.isMustIDCard == "1"; // 证件必传

				// 获取可选物流列表
				if (paramList.bindLogisticList) {
					let bindLogisticList = JSON.parse(paramList.bindLogisticList);
					if (bindLogisticList && bindLogisticList.length > 0) {
						let logisticList = [];
						let logisticWays = [];
						for (let item of bindLogisticList) {
							let logistic = {};
							logistic.name = item.split(',')[0];
							logistic.id = item.split(',')[1];
							logistic.showname = item.split(',').length > 1 ? item.split(',')[2] : item
								.split(',')[0];
							logisticList.push(logistic);
						}
						this.logisticList = logisticList;
						this.bindLogisticId = logisticList[0].id;
						this.selectedlogisticName = logisticList[0].showname;
						this._calcFreight();
					}
				}

				// 是否开启收集发货时间以及发货人手机号
				this.isShowCollectChangerInfo = paramList.isCollectChangerInfo == "1";
				// 是否开启订购人电话
				this.isOpenCollectBuyerPhone = paramList.isOpenCollectBuyerPhone == "1";
				// 是否开启期望发货时间
				this.isOpenExpectedDeliveryTime = paramList.isOpenExpectedDeliveryTime == "1";
				// 期望发货延迟时间
				this.expectedDeliveryDelayedDays = paramList.expectedDeliveryDelayedDays;
				// 缺货商品发货延迟时间
				this.overStockGoodsDelayedDays = paramList.overStockGoodsDelayedDays; // 存在缺货商品时延迟时间

				this.isOpenExpectReciveTime = paramList.isOpenExpectReciveTime == "1";
				// 默认结算账户信息
				this.defaultAccountId = paramList.defaultAccountId;
				this.defaultAccountName = paramList.defaultAccountName;
				// 包邮金额类型
				this.freePostageType = paramList.freePostageType == "goodsTotal" ? "goodsTotal" : "rcvTotal";

				// 是否开启下单金额限制
				this.tradeAmountLimit_isEnabled = paramList.tradeAmountLimit_isEnabled == "1";
				// 下单金额限制类型（rcvTotal：实收金额，goodsTotal：商品金额，默认：rcvTotal）
				this.tradeAmountLimit_limitType = paramList.tradeAmountLimit_limitType || "rcvTotal";
				// 下单金额限制金额
				this.tradeAmountLimit_limitAmount = parseFloat(paramList.tradeAmountLimit_limitAmount || "0");
			},

			/**
			 * 加载购物车列表
			 */
			async _loadShoppingCartList() {
				// 加载购物车信息
				let shoppingCartList = await getShoppingCartList("", this.cartIds.split(','), true, true, true, false, false, false);

				// 检测是否存在购物车数据，不存在则不进行后续操作
				if (!(shoppingCartList && shoppingCartList.length > 0)) {
					this.goBack();
					this.$message("未检测到商品信息");
					return;
				}

				// 获取促销价
				await this.__calcMarketingPrice(shoppingCartList);

				// 渲染购物车数据
				this.tradeGoods = shoppingCartList;

				console.log("购物车数据：", shoppingCartList);

				// 计算货品价格和积分
				this._calcTradeTotal();
			},

			/**
			 * 计算营销活动价格
			 */
			async __calcMarketingPrice(shoppingCartList) {
				// 生成价格计算所需货品列表对象
				let tobeCalcGoodsList = shoppingCartList.map(t => {
					return {
						goodsId: t.goodsId,
						specId: t.specId,
						unitName: t.unitName,
						goodsCount: t.goodsCount,
						executionPrice: t.originalExecutionPrice || t.executionPrice || 0,
						score: t.score || 0,
						baseUnit: t.baseUnit,
						countRate: t.unitCountRate,
						cartId: t.cartId,
						actType: t.actType
					}
				});

				await calcMarketingGoodsTotalAmount(tobeCalcGoodsList);

				// 根据出处的结果反向修改每个商品的执行价
				shoppingCartList.forEach(cart => {
					let calcRes = tobeCalcGoodsList.find(t => t.cartId == cart.cartId) || {};
					cart.executionPrice = calcRes.marketingPrice || calcRes.executionPrice || cart.executionPrice;
					cart.marketingLabelName = calcRes.marketingLabelName || "";
				});
			},

			//计算订单总额
			_calcTradeTotal() {
				let goodList = this.tradeGoods;
				let goodsTotal = 0,
					scoreTotal = 0,
					weightTotal = 0,
					// 预售金额
					presaleTotal = 0,
					rcvTotal = 0;
				for (let spec of goodList) {
					let count = parseInt(spec.goodsCount);
					goodsTotal += parseFloat(spec.executionPrice) * count;
					scoreTotal += parseInt(spec.score) * count;
					weightTotal += parseFloat(spec.weight) * count * (spec.unit_count_rate || 1);
				}
				goodsTotal = parseFloat((Math.round(goodsTotal * 10 * 10) / 100).toFixed(2));
				this.goodsTotal = goodsTotal;
				this.scoreTotal = scoreTotal;
				this.weightTotal = weightTotal;
				this.tradeTotal = goodsTotal;

				// 预售暂时不支持加入购物车,后续如果支持,需要调整
				this.rcvTotal = goodsTotal + this.freight;
				this.showRcvTotal = this.isPresaleTrade ? parseFloat((this.rcvTotal * this.depositRatio / 100).toFixed(
					2)) : this.rcvTotal;
			},
			// 校验时间
			checkTime(time) {
				return time.getTime() - this.couponExchangeDelayedSendDays * 24 * 60 * 60 * 1000 < Date.now() - 86400000
			},
			//获取用户地址
			async _loadAddress(addressId) {
				this.isPostageOver = false;

				// 获取地址信息，没有传入Id则获取默认地址
				let addressInfo = {};
				if (addressId) {
					addressInfo = await addressUtils.getAddressDetail(addressId);
				} else {
					addressInfo = await addressUtils.getDefaultAddress();
				}
				if (!addressInfo.addressId) {
					return;
				}
				// 渲染地址信息
				this.address = addressInfo;
				this.addrSelectedId = addressInfo.addressId.toString();
				this.addressShowList = [addressInfo];

				// 检测限购区域
				if (!this._checkBuyLimitCities()) {
					this.$message.error("该配送地址不能下单");
					return;
				}

				// 计算邮资
				this._calcFreight();
			},

			/**
			 * 获取预售商品信息
			 */
			async _getPresaleGoodsInfo() {
				// 获取下单商品中预售商品的信息
				let presaleGoodsInfo = this.tradeGoods.find(t => t.actType == "3");

				// 组装请求数据
				let tmpSkuList = this.tradeGoods.map(item => {
					return {
						specId: item.specId,
						unit: item.unitName,
						goodsId: item.goodsId
					}
				})
				// 获取预售信息
				let presaleInfo = await getPresaleInfoByGoodsId(tmpSkuList);

				// 预售商品存在，则标记为预收单以及赋值预售比例
				if (!!presaleGoodsInfo && !!presaleInfo) {
					this.isPresaleTrade = true;
					this.depositRatio = presaleInfo[0].depositRatio;
				}
			},

			/**
			 * 获取预售商品信息
			 */
			_getPostage(reqData) {
				return new Promise((resolve, reject) => {
					// 发送请求获取验证码
					this.$request.jackyunPost({
						method: "jmall.trade.postagecalc",
						...reqData
					}, (res) => {
						let postage = -1;
						// 检测验证码发送是否正确
						if (res.code == 200) {
							postage = parseFloat(res.result.data);
						} else {
							this.$message.error(res.msg || '邮资计算失败');
						}
						return resolve(postage);
					});
				})

			},


			//计算运费，邮资计算
			async _calcFreight() {
				let that = this;
				let address = that.address;
				if (!address.addressId) {
					return;
				}
				// if (!that.bindLogisticId) {
				// 	return;
				// }
				if (!that.channelId) {
					return;
				}
				// 第一次计算邮资前获取warehouseId，之后从页面中读取
				if (!!that.channelInfo) {
					// 获取仓库Id
					let warehouseIds = localStorage.getItem("pc_warehouseId") || '';
					// 下单确认重新刷新渠道Info
					let channelInfo = await getChannelInfo(that.channelId, true);
					let warehouseId = "";
					if (channelInfo) {
						warehouseId = channelInfo.warehouseId;
					} else if (!warehouseIds && warehouseIds.length > 0) {
						warehouseId = warehouseIds.split(",")[0];
					} else {
						return;
					}
					that.channelInfo = channelInfo;
					that.warehouseId = warehouseId;
				}

				// 检测包邮金额执行依据
				let tradeTotal = this.freePostageType == "goodsTotal" ? this.goodsTotal : this.tradeTotal;
				let rcvTotal = this.tradeTotal;

				// 获取下单货品明细信息（规格单位级，用作邮资计算分仓邮资判断）
				let orderGoodsList = this.__getOrderGoodsList__();

				// 获取下单货品标签分类等信息（货品级，用作邮资策略计算）
				let orderGoodsInfo = await this.__getOrderGoodsInfo__();

				// 获取客户标签（营销设置中会有客户标签排除标记）
				let customerTagList = this.customerInfo.tagArr || [];

				let reqData = {
					customerId: that.customerId,
					channelId: that.channelId,
					warehouseId: that.warehouseId,
					logisticId: that.bindLogisticId,
					logisticType: that.logisticType,
					country: address.country,
					province: address.province,
					city: address.city,
					district: address.district || '',
					town: address.town,
					weightTotal: that.weightTotal,
					tradeTotal: tradeTotal,
					rcvTotal: rcvTotal,
					distance: 99999999,
					orderGoodsList: JSON.stringify(orderGoodsList),
					orderGoodsInfo: JSON.stringify(orderGoodsInfo),
					customerTag: customerTagList.join(",")
				}

				// 获取邮资
				let postage = await this._getPostage(reqData);
				if (postage < 0) {
					return;
				}

				// 参数赋值
				that.isPostageOver = true;
				that.freight = postage;

				// 计算包邮差额
				that.__getfreepostagedifference(reqData);

				// 计算总价
				that._calcTradeTotal();
			},

			/**
			 * 获取下单货品信息（规格单位级，用作邮资计算分仓邮资判断）
			 */
			__getOrderGoodsList__() {
				let orderGoodsList = this.tradeGoods.map(t => {
					return {
						goodsId: t.goodsId,
						specId: t.specId,
						unitName: t.unitName,
						sellCount: t.goodsCount,
					};
				});
				return orderGoodsList;
			},

			/**
			 * 获取下单货品标签分类等信息（货品级，用作邮资策略计算）
			 */
			async __getOrderGoodsInfo__() {
				// 已经生成过，则不重新获取
				if (this.orderGoodsInfoList) {
					return this.orderGoodsInfoList;
				}

				// 获取goodsId集合
				let goodsIdList = this.tradeGoods.map(t => t.goodsId);

				// 获取吉客云商品扩展信息
				let jackyunGoodsExtendedInfoList = await goodsUtils.getJackyunGoodsExtendedInfo(goodsIdList);

				// 订购货品信息
				let orderGoodsInfoList = [];
				this.tradeGoods.forEach(cart => {
					// 获取当前商品的扩展信息
					let currExtendedInfo = jackyunGoodsExtendedInfoList.find(t => t.goodsId == cart.goodsId);
					if (currExtendedInfo) {
						// 获取基础单位购买数量（按件计费需要使用）
						let baseUnitCount = goodsUtils.convertToBaseUnitCount(currExtendedInfo.unitInfo, cart.unitName, cart.goodsCount)
						// 添加到结果对象
						orderGoodsInfoList.push({
							goodsId: cart.goodsId,
							specId: cart.specId,
							unit: cart.unitName,
							sellCount: cart.goodsCount,
							cateId: currExtendedInfo.jackyunCateId || 0,
							goodsTags: currExtendedInfo.jackyunFlagData || "",
							isFit: cart.isPackage,
							baseUnitSellCount: baseUnitCount, // 该字段暂时不生效，后端会去除，改为OMS自动计算
						});
					}
				});

				// 记录结果，不再重复获取
				this.orderGoodsInfoList = orderGoodsInfoList;
				return orderGoodsInfoList;
			},

			//检测区域限购
			_checkBuyLimitCities() {
				let canBuy = true;
				if (this.isOpenCityBuyLimit && this.buyLimitCities.length > 0) {
					canBuy = false;
					let address = this.address;
					let buyLimitCities = this.buyLimitCities;
					let province = address.province ? address.province.substring(0, 2) : "";
					let city = address.city ? address.city : "";
					let town = address.district ? address.district : "";
					for (let i = 0; i < buyLimitCities.length; i++) {
						let item = buyLimitCities[i];
						let limit_province = item.province ? item.province.substring(0, 2) : "";
						let limit_city = item.city ? item.city : "";
						let limit_town = item.town ? item.town : "";
						if (limit_province == province) {
							if (limit_city && limit_city != "全部") {
								if (limit_city == city || limit_city.replace("市", "") == city.replace("市", "")) {
									if (limit_town && limit_town != "全部") {
										if (limit_town == town || limit_town.replace("市", "") == town.replace("市", "") ||
											limit_town.replace("县", "") == town.replace("县", "") ||
											limit_town.replace("区", "") == town.replace("区", "")) {
											canBuy = true;
											break;
										}
									} else {
										canBuy = true;
										break;
									}
								}
							} else {
								canBuy = true;
								break;
							}
						}
					}
				}
				return canBuy;
			},
			//检测数据
			_checkData() {
				//关闭所有警告
				this.$message.closeAll();
				if (this.showRcvTotal >= Math.pow(10, 12)) {
					this.$message.error("订单金额过大，请分批下单");
					return;
				}
				if (this.isClickPay) {
					this.$message.error("请勿重复提交");
					return;
				}

				// 检测下单金额
				if (this.tradeAmountLimit_isEnabled) {
					// 获取需要检测的金额
					let limitAmount = this.tradeAmountLimit_limitType == "goodsTotal" ? this.goodsTotal : this.rcvTotal;
					if (limitAmount < this.tradeAmountLimit_limitAmount) {
						this.$message.error(`${this.tradeAmountLimit_limitType == "goodsTotal"?"商品合计":"实付金额"}需大于${this.tradeAmountLimit_limitAmount.toFixed(2)}，请增加货品后下单`);
						return;
					}
				}

				if (!this.address.address) {
					this.$message.error("请选择收件地址");
					return;
				}
				if (!this._checkBuyLimitCities()) {
					this.$message.error("该配送地址不能下单");
					return;
				}
				if (!this.isPostageOver) {
					this.$message.error("运费计算中，请稍等");
					return;
				}
				// if (this.isShowCollectChangerInfo ) {
				// 	this.$message.error("订购人电话不可为空");
				// 	return;
				// }
				if (this.isOpenExpectedDeliveryTime && !this.expectedDeliveryTime) {
					this.$message.error("发货时间不可为空");
					return;
				}

				if (this.isShowRequiredField && !this.requiredFieldText) {
					this.$message.error(`请填写${this.requiredFieldShowName}`);
					return;
				}

				if (this.isOpenOrderSentAttachments && this.attachList.length == 0) {
					this.$message.error(this.orderSentAttachmentsRemind);
					return;
				}

				// 检测启证件必传
				if (this.isMustIDCard && [1, 3].includes(this.logisticType) && (!this.address.idCard || !this.address.idCardName)) {
					this.$message.error("请补充证件信息");
					return;
				}

				// if(this.isOpenExpectReciveTime && !this.expectReciveTime){
				// 	this.$message.error("期望送达时间不能为空");
				// 	return;
				// }
				return true;
			},
			// 获取客户备注
			_getCustomerRemark_() {
				let that = this;
				// 所有用户备注集合
				let customerRemarkList = [];
				if (!!that.customerRemark) {
					customerRemarkList.push(that.customerRemark);
				}
				if (that.isOpenExpectedDeliveryTime) {
					customerRemarkList.push('期望发货时间：' + that.expectedDeliveryTime);

				}
				if (that.changeTel) {
					customerRemarkList.push('订购人电话:' + that.changeTel)
				}
				return customerRemarkList.join('；');
			},
			//获取订单信息
			async _getTradeInfo(goodsList) {
				let that = this;
				let address = that.address;

				let logisticInfo = that.logisticList.find(item => item.id == that.bindLogisticId);
				let tradeInfo = {
					tradeType: this.isPresaleTrade ? "3" : "1",
					channelId: that.channelId,
					channelName: that.channelInfo.channelName,
					warehouseId: that.channelInfo.warehouseId,
					warehouseName: that.channelInfo.warehouseName,
					customerId: that.customerId,
					tradeNick: this.customerInfo.nickname || this.customerInfo.tel,
					upperId: this.customerInfo.upperID,
					logisticType: that.logisticType,
					logisticId: that.bindLogisticId,
					logisticName: logisticInfo ? logisticInfo.name : that.selectedlogisticName,
					sendTo: address.name,
					country: address.country,
					province: address.province,
					city: address.city,
					district: address.district,
					town: address.town,
					address: address.address,
					tel: address.tel,
					tradeFrom: that.tradeFrom,
					idCardType: address.idCardType,
					idCardNo: address.idCard,
					idCardName: address.idCardName,
					// pc暂不支持对接卡券以及积分抵扣
					couponCode: "",
					couponTotal: 0,
					scoreDeductionTotal: 0,
					deductionScoreTotal: 0,
					goodsTotal: that.goodsTotal,
					postageTotal: that.freight,
					allTotal: that.goodsTotal + that.freight,
					rcvTotal: that.rcvTotal,
					exchangeScoreTotal: that.scoreTotal,
					onlinePlatTypeCode: that.channelInfo.onlinePlatTypeCode || "",
					currencyCode: that.currencyCode,
					currencyName: that.currencyName,
					isDistributorSubSend: (that.isOpenDistributorTradeType && [1, "1", "True", "true", true]
						.includes(
							this.customerInfo.isDistributor)) ? 1 : 0,
					sellerId: "",
					sellerName: "",
					distance: that.channelInfo.distance,
					customerRemark: that._getCustomerRemark_(),
					goodsInfo: that.__getGoodsInfo__(),
					expectedDeliveryTime: that.expectedDeliveryTime,
					expectedSendGoodsTime: that.expectedDeliveryTime,
					customerAccount: that.__getCustomerAccount(), // 客户账号
					attachUrls: this.attachList.join(";"),
				};

				// 开启自定义字段 填充相关数据
				if (this.isShowRequiredField) {
					tradeInfo.customColumnStr1 = {
						chName: this.requiredFieldShowName,
						omsColumnEnName: commonUtil.underlineToHump(this.requiredFieldToOms),
						columnValue: this.requiredFieldText
					};
				}
				// 补充结算账户信息
				let accountInfo = this.__getAccountlInfo();
				tradeInfo.accountId = accountInfo.accountId;
				tradeInfo.accountName = accountInfo.accountName;
				let sellInfo = await tradeUtils.getTradeManager();
				tradeInfo.sellerId = accountInfo.sellerId;
				tradeInfo.sellerName = accountInfo.sellerName;

				tradeInfo.tradeGoodsList = goodsList.map(t => {
					let goods = {
						goodsId: t.goodsId,
						specId: t.specId,
						goodsName: t.goodsName,
						specName: t.specName,
						unitName: t.unitName,
						unitCountRate: t.unitCountRate,
						sellCount: t.goodsCount,
						sellPrice: t.executionPrice,
						sellTotal: t.goodsCount * t.executionPrice,
						sellScore: t.score,
						scoreTotal: t.score * t.goodsCount,
						actType: t.actType || '',
						actId: t.actId || '',
						marketingId: t.marketingId,
						cartId: t.cartId || '',
						specCode: t.specCode,
						originalPrice: t.referencePrice,
						// pc未对接分仓邮资库存
						warehouseId: ''
					};
					return goods;
				});

				return tradeInfo;
			},
			/**
			 * 商品描述
			 */
			__getGoodsInfo__() {
				// 先获取一个数据相对完整的货品
				let exampleGoods = this.tradeGoods.find(t => !!t.goodsName && !!t.specName && !!t.unitName);
				if (!exampleGoods) {
					exampleGoods = this.tradeGoods[0];
				}

				// 计算所有购买商品的总数量
				let allBuyCount = commonUtil.sumArr(this.tradeGoods, "goodsCount");

				// 计算商品大类的数量
				let goodsCount = this.tradeGoods.length;

				// 生成商品摘要信息
				return `${exampleGoods.goodsName}【${exampleGoods.specName}】（${exampleGoods.goodsCount}${exampleGoods.unitName}）${goodsCount > 1 ? "等" : ""} 共${allBuyCount}件`;

			},
			//编辑地址保存成功
			editAddrSuccess(obj) {
				this.address = obj;

				if (!this._checkBuyLimitCities()) {
					this.$message.error({
						message: "该配送地址不能下单"
					});
					return;
				}
				this.addressShowList = [obj];
				this.addrSelectedId = obj.addressId;
				this.isPostageOver = false;
				this._calcFreight();
			},
			//切换地址
			AddressChange(e) {
				// this.addrSelectedId = e;
				// this.address = this.addressList.find((item) => (e == item.addressId));
				// if (!this._checkBuyLimitCities()) {
				// 	this.$message.error({
				// 		message: "该配送地址不能下单"
				// 	});
				// 	return;
				// }
				// this.isPostageOver = false;
				// this._calcFreight();
			},
			AddressChangeInfo(e) {
				this.addressSelectVisible = false;
				this.addrSelectedId = e.addressId;
				this.address = e;

				this.addressShowList = [e];
				if (!this._checkBuyLimitCities()) {
					this.$message.error({
						message: "该配送地址不能下单"
					});
					return;
				}
				this.isPostageOver = false;
				this._calcFreight();
			},
			//切换物流
			logisticChange(e) {
				this.bindLogisticId = this.logisticList.find((item) => (e == item.showname)).id;
				this.isPostageOver = false;
				this._calcFreight();
			},

			// 限购检测
			async _checkRestrictedGoods(goodsList) {
				// 获取省市区信息
				let {
					province,
					city,
					district
				} = this.address;
				// 获取当前货品规格Id集合
				let specIds = goodsList.map(t => t.specId);
				// 调用限购检测接口，获取检测结果（限购商品+有效商品）
				let res = await tradeUtils.checkRestrictedGoods(province, city, district, specIds);
				if (res.restrictedGoodsList && res.restrictedGoodsList.length > 0) {
					return false;
				} else {
					return true;
				}
			},
			// 校验用户下单时候的积分
			async _checkScore() {
				// 重置customerInfo信息
				let customerInfo = await getCustomerInfo({}, true);
				// 获取客户积分
				let customerScore = !!customerInfo.integralBalance && customerInfo.integralBalance > 0 ? customerInfo
					.integralBalance : 0;
				return this.scoreTotal <= customerScore;
			},

			//去支付
			async toPay() {
				let that = this;
				// 检测下单参数
				if (!that._checkData()) {
					return;
				}
				that.isClickPay = true;

				// 检测下单货品是否满足限时抢购活动的会员等级要求
				if (await this._checkActivityMemberGrade()) {
					that.isClickPay = false;
					return this.$message.error("抢购活动已失效");
				}


				// 检测货品是否是会员等级限制商品
				if (await this._checkMemberBlockGoods()) {
					that.isClickPay = false;
					return this.$message.error("存在下架商品");
				}

				// 检测货品是否下架
				if (await this._checkBlockUpGoods()) {
					that.isClickPay = false;
					return this.$message.error("存在下架商品");
				}

				// 关闭加载遮罩层
				if (!await this._checkOrderGoodsStock()) {
					that.isClickPay = false;
					return;
				}
				let filteredGoodsList = this.tradeGoods;

				// 检测库存过滤后是否还有货品，没有则提示异常信息
				if (filteredGoodsList.length <= 0) {
					that.isClickPay = false;
					return this.$message.error("暂无可下单商品");
				}
				let checkRestrictedBool = await this._checkRestrictedGoods(filteredGoodsList)
				// 区域限购
				if (!await this._checkRestrictedGoods(filteredGoodsList)) {
					that.isClickPay = false;

					// 获取后台设置的提示文本
					let paramList = await getParam("restricted_explainText")

					// 数据渲染
					return this.$message.error(paramList.restricted_explainText || "该配送地址不能下单");
				}
				// 校验积分
				if (!await this._checkScore()) {
					return this.$message.error("您的积分不足");
				}

				this.createTradeInfo(filteredGoodsList);
			},
			/**
			 * 创建订单并前往结算页面
			 * @param {Object} filteredGoodsList
			 */
			async createTradeInfo(filteredGoodsList) {
				let that = this;
				let trade = await that._getTradeInfo(filteredGoodsList);
				that.$request.jackyunPost({
					method: "jmall.trade.tradecreate",
					jsonStr: JSON.stringify(trade)
				}, function(res) {
					if (res.code == '200') {
						that.$eventBus.$emit("updateHeaderShopCartCount"); //修改购物车总数
						that.$router.push({
							name: 'pay',
							params: {
								tradeNo: res.result.data.tradeNo
							}
						});
					} else {
						that.$message.error({
							message: res.msg
						});
						that.isClickPay = false;
					}
				});
			},
			// 返回上一页
			goBack() {
				this.$router.back(-1);
			},
			/**
			 * 检测下单货品库存
			 */
			async _checkGoodsStock(goodsList) {
				try {
					// 获取所有Id集合
					let skuIds = goodsList.map(t => t.specId);

					// 获取库存数据
					let stockList = await goodsUtils.getSkuStockList(skuIds);

					// 补充库存数据
					goodsList.forEach(spec => {
						let stockInfo = stockList.find(t => t.skuId == spec
							.specId &&
							(t.unitName == spec.unitName || !t.unitName));
						if (!!stockInfo) {
							spec.stock = stockInfo.stock;
						} else {
							spec.stock = 0;
						}
					});

					// 检测是否包含负库存商品
					let stockNotEnoughGoodsNamelist = goodsList.filter(t => t
						.stock < t.goodsCount).map(t =>
						`${t.goodsName}【${t.specName}-${t.unitName}】`);

					// 检测库存不足的货品
					if (stockNotEnoughGoodsNamelist && stockNotEnoughGoodsNamelist.length >
						0) {
						await this.$confirm(
							`${stockNotEnoughGoodsNamelist.join('，')} 因库存变动，导致库存不足，是否忽略无库存或者购买最大可用库存商品，继续下单？`
						);
					}
					return true;
				} catch (e) {
					return false;
				}
			},

			// 打开我的地址页面
			openAddress() {
				this.addressSelectVisible = true;
				this.addressSelectkey = !this.addressSelectkey;
			},

			/**
			 * 过滤货品数据，将库存为0的商品去除，以及修改选购量
			 */
			_filterGoodsList(goodsList) {
				// 去除库存为0的商品
				goodsList = goodsList.filter(t => t.stock > 0);

				// 修改库存不足的商品选购量
				goodsList.forEach(t => {
					if (t.stock > 0 && t.stock < t.goodsCount) {
						t.goodsCount = t.stock;
					}
				});

				return goodsList;
			},

			// 关闭地址选择
			closeSelectAddr() {
				this.addressSelectVisible = false;
			},

			/**
			 * 检测下单货品库存
			 */
			async _checkOrderGoodsStock() {
				try {
					// 接口需要传入tradeId
					if (this.tradeGoods[0].actType == "3") {
						let tradeGoods = this.tradeGoods[0];

						// 校验预售库存
						let presaleStockEnough = await goodsUtils.checkPresaleGoodsCountNoTradeId(tradeGoods.actId,
							tradeGoods.goodsId, tradeGoods.specId, tradeGoods.unitName, tradeGoods.goodsCount);
						if (!presaleStockEnough) {
							this.$message.error("预售库存不足");
						}
						return presaleStockEnough;
					}

					// 生成检测数据
					let preCheckGoodsList = this.tradeGoods.map(t => {
						return {
							goodsId: t.goodsId,
							specId: t.specId,
							unitName: t.unitName || "",
							goodsCount: t.goodsCount,
							activityType: t.actType,
							actId: t.actId
						}
					});

					// 检测货品库存，结果包含可购和不可购两个列表
					let res = await goodsUtils.checkOrderGoodsStock(preCheckGoodsList);

					if (res.baseUnitOverOrderGoodsList.length == 0) {
						return true;
					}

					// 超卖情况下，下单商品包含多单位
					if (this._checkCartHasMultiUnitOverOrder(res)) {
						this.isShowMultiUnitStockCheckModal = true;
						this.baseUnitOverOrderGoodsList = res.baseUnitOverOrderGoodsList;
					} else {
						// 数据渲染
						this.canOrderGoodsList = res.canOrderGoodsList; // 限购商品列表
						this.cannotOrderGoodsList = res.baseUnitOverOrderGoodsList; // 剩余可购规格Id
						this.isNoStockAll = res.canOrderGoodsList.length <= 0; // 是否无可购商品
						this.isShowCheckGoodsDialog = true; // 是否展示库存检测弹窗，存在库存不足的货品就展示
					}
					return false;

				} catch (e) {
					return true;
				}
			},

			/**
			 * 检测下单货品库存
			 */
			async _checkBlockUpGoods() {
				try {
					let reqTradeGoods = this.tradeGoods.map(item => {
						return {
							goodsId: item.goodsId,
							specId: item.specId,
							goodsName: item.goodsName,
							specName: item.specName,
						}
					});

					// 校验货品是否上架
					let blockUpGoodsList = await goodsUtils.checkBlockUpGoods(reqTradeGoods);
					blockUpGoodsList.forEach((item) => {
						let tradeGood = this.tradeGoods.find((tradeItem) => item.goodsId == tradeItem.goodsId)
						item.picUrl = tradeGood.coverPic,
							item.specId = tradeGood.specId,
							item.goodsName = tradeGood.goodsName,
							item.specName = tradeGood.specName
					})
					this.cannotOrderGoodsList = blockUpGoodsList;
					this.isNoStockAll = this.cannotOrderGoodsList.length == reqTradeGoods.length; // 是否无可购商品
					//this.isNoStockAll = false
					if (blockUpGoodsList.length > 0) {
						this.isShowBlockCheckGoodsDialog = true;
					}
					return blockUpGoodsList.length > 0;
				} catch (e) {
					return false;
				}
			},

			/**
			 * 取消多规格单位库存检测弹窗
			 */
			multiUnitOverStockCancel() {
				this.isShowMultiUnitStockCheckModal = false;
				this.goBack()
			},

			/**
			 * 检测下单货品是否满足限时抢购活动的会员等级要求
			 */
			async _checkActivityMemberGrade() {
				// this.tradeGoods没有抢购商品时isHaveGoodsInvalid 返回false
				if (this.tradeGoods.filter(item => item.actType == 1).length == 0) {
					return false
				}
				let extraInfolist = await goodsUtils.getGoodsExtraInfo(this.tradeGoods.filter(item => item.actType == 1).map(item => item.goodsId));
				// 存在不满足限时抢购会员等级限制的商品
				let isHaveGoodsInvalid = true;
				if (!!extraInfolist) {
					isHaveGoodsInvalid = !(extraInfolist.length == this.tradeGoods.filter(item => item.actType == 1).length);
				}
				return isHaveGoodsInvalid
			},

			/**
			 * 检测下单货品是否是超卖（多单位）
			 */
			async _checkMultiUnitOverStock() {
				try {
					let orderGoodsList = this.tradeGoods.map((item) => {
						return {
							goodsId: item.goodsId,
							specId: item.specId,
							unitName: item.unitName,
							goodsCount: item.goodsCount
						}
					})
					let checkStockResult = await goodsUtils.checkOrderGoodsStock(orderGoodsList);
					// 关闭加载遮罩层
					if (checkStockResult.baseUnitOverOrderGoodsList && checkStockResult.baseUnitOverOrderGoodsList.length > 0) {
						this.isShowMultiUnitStockCheckModal = true;
						this.baseUnitOverOrderGoodsList = checkStockResult.baseUnitOverOrderGoodsList;
						return true;
					}
					return false;
				} catch (e) {
					return false;
				}

			},

			/**
			 * 检测下单货品是否是会员等级限制商品
			 */
			async _checkMemberBlockGoods() {
				try {
					let reqTradeGoods = {};
					// 获取缓存中信息
					let customerInfo = this.customerInfo;
					let viplevel = customerInfo && customerInfo.vipLevel ? customerInfo.vipLevel : "";
					reqTradeGoods.memberGrade = viplevel;
					reqTradeGoods.goodsIdList = this.tradeGoods.map((item) => item.goodsId)

					// 校验货品是否是会员等级限制商品  提示跟下架的情况相同
					let blockUpGoodsList = await goodsUtils.checkMemberBlockGoods(reqTradeGoods);
					blockUpGoodsList.forEach((item) => {
						let tradeGood = this.tradeGoods.find((tradeItem) => item.goodsId == tradeItem.goodsId)
						item.picUrl = tradeGood.coverPic,
							item.specId = tradeGood.specId,
							item.goodsName = tradeGood.goodsName,
							item.specName = tradeGood.specName
					})
					this.cannotOrderGoodsList = blockUpGoodsList;
					this.isNoStockAll = this.cannotOrderGoodsList.length == this.tradeGoods.length; // 是否无可购商品
					//this.isNoStockAll = false
					if (blockUpGoodsList.length > 0) {
						this.isShowBlockCheckGoodsDialog = true;
					}
					return blockUpGoodsList.length > 0;
				} catch (e) {
					return false;
				}
			},

			/**
			 * 检测超卖情况下下单货品是否包含多单位
			 */
			_checkCartHasMultiUnitOverOrder: function(checkStockResult) {
				// 超卖商品的SpecId数组
				let resultSpecIdList = checkStockResult.baseUnitOverOrderGoodsList.map((item) => item.specId);
				let cartUnitOverOrderListLength = this.tradeGoods.filter(t => resultSpecIdList.includes(t.specId)).length;
				return cartUnitOverOrderListLength > resultSpecIdList.length
			},

			/**
			 * 组件方法：过滤无库存货品继续下单
			 */
			async filterBlockUpGoods() {
				// 接收新的待下单货品
				let newOrderGoodsList = [];
				// 遍历当前下单货品，排除不可购商品重新生成待下单货品
				let cannotOrderGoodsList = this.cannotOrderGoodsList;
				let canOrderGoods = this.tradeGoods.filter((goods) => {
					return !cannotOrderGoodsList.find(t => t.goodsId == goods.goodsId)
				});
				newOrderGoodsList = canOrderGoods;

				this.__addTempShoppingCartBatchAndRefresh(newOrderGoodsList, "isShowBlockCheckGoodsDialog")
			},

			/**
			 * 组件方法：过滤无库存货品继续下单
			 */
			filterNoStockGoods() {
				// 接收新的待下单货品
				let newOrderGoodsList = [];
				// 遍历当前下单货品，结合可购列表重新生成待下单货品
				this.tradeGoods.forEach((goods, index) => {
					// 获取对应的可购货品信息
					let canOrderGoods = this.canOrderGoodsList.find(t => t.goodsId == goods.goodsId && t.specId ==
						goods.specId && t.unitName == goods.unitName);
					// 存在对应的可购商品，则修改其数量并添加到最新可购列表
					if (canOrderGoods) {
						goods.goodsCount = canOrderGoods.goodsCount;
						newOrderGoodsList.push(goods);
					}
				});

				this.__addTempShoppingCartBatchAndRefresh(newOrderGoodsList, "isShowCheckGoodsDialog")
			},

			/**
			 * 加入临时购物车并刷新页面
			 */
			async __addTempShoppingCartBatchAndRefresh(newOrderGoodsList, dialogJudgeName) {
				// 补充单位比例以及购物车属性
				newOrderGoodsList.forEach((goods, index) => {
					try {
						// 获取当前购物车对应的
						let cartInfo = this.tradeGoods.find(t => t.goodsId == goods.goodsId && t.specId == goods.specId && t.unitName == goods.unitName);
						goods.unitCountRate = cartInfo.unitCountRate;
						goods.cartType = cartInfo.cartType;
						goods.actType = cartInfo.actType;
						goods.actId = cartInfo.actId;
					} catch (e) {}
				});

				// 加入临时购物车
				let tempCartIds = await goodsUtils.addTempShoppingCartBatch(newOrderGoodsList);
				if (tempCartIds) {
					// 如果是购物车下单，则需要删除原购物车信息，非购物车数据就无需处理
					if (this.tradeGoods.length >= 1 && !!this.tradeGoods[0].cartId) {
						// 删除原购物车数据
						goodsUtils.deleteShoppingCart(this.tradeGoods.map(t => t.cartId));
					}

					// 刷新页面
					this.__refreshPage(tempCartIds);
				} else {
					this.$message("数据移除失败，请重试");
					this.goBack();
				}

				// 关闭弹窗
				this[dialogJudgeName] = false;
			},

			/**
			 * 刷新页面
			 */
			async __refreshPage(tempCartIds) {
				// 刷新页面数据
				this._initData({
					cartIds: tempCartIds + "",
					trade_from: this.tradeFrom
				});
				await this._loadShoppingCartList();

				// 计算邮资
				this._calcFreight();
			},

			/**
			 * 获取客户账号
			 */
			__getCustomerAccount() {
				try {
					// 获取客户档案信息
					let customerInfo = this.customerInfo;

					// 检测客户账号是否存在
					if (!customerInfo.customerSourceArr || customerInfo.customerSourceArr.length <= 0) {
						return "";
					}

					// 优先获取默认账户
					let accountInfo = customerInfo.customerSourceArr.find(t => t.isDefault == "1");
					if (accountInfo) {
						return accountInfo.customerAccount || "";
					}

					// 次级获取当前渠道账户
					let currentChannelId = localStorage.getItem('pc_channelId');
					accountInfo = customerInfo.customerSourceArr.find(t => t.channelId == currentChannelId);
					if (accountInfo) {
						return accountInfo.customerAccount || "";
					}

					// 默认返回第一个账户
					return customerInfo.customerSourceArr[0].customerAccount || "";
				} catch (e) {
					return "";
				}
			},

			/**
			 * 获取当前结算账户信息
			 */
			__getAccountlInfo() {
				// 已经在吉商城配置了结算账户，则直接取配置信息
				if (this.defaultAccountId) {
					return {
						accountId: this.defaultAccountId,
						accountName: this.defaultAccountName
					}
				}

				// 没有配置的情况，则获取当前渠道的默认结算账户
				let currChannelInfo = localStorage.getItem("pc_channelInfo");
				// 获取渠道默认结算账户
				try {
					let channelDefaultAccountInfo = currChannelInfo.salesSettlementAccountList.find(t => t.isDefault ==
						1);
					return {
						accountId: channelDefaultAccountInfo.bankaccountId,
						accountName: channelDefaultAccountInfo.bankaccountName
					}
				} catch (e) {}

				return {
					accountId: "",
					accountName: ""
				};
			},

			/**
			 * 附件上传
			 */
			_handlePictureCardPreview(response, file, fileList) {
				this.dialogImageUrl = response.response.error_info;
				this.dialogVisible = true;
			},
			_handlePictureCardExceed(files, fileList) {
				this.$message.error("附件数量不可超出3个")
			},
			_handleSuccess(response, file, fileList) {
				if (response.error_code == "0") {
					this.attachList.push(response.error_info)
				}
			},


			/**
			 * 获取包邮差价
			 */
			async __getfreepostagedifference(reqData) {
				// 邮资判断
				if (this.freight == 0) {
					this.showFreepostageDifference = false;
					return;
				}

				// 获取客户标签（营销设置中会有客户标签排除标记）
				let customerTagList = this.customerInfo.tagArr || [];

				let obj = {
					channelId: reqData.channelId,
					distance: reqData.distance,
					logisticId: reqData.bindLogisticId,
					logisticType: reqData.logisticType,
					province: reqData.province,
					tradeTotal: reqData.tradeTotal,
					vipLevel: localStorage.getItem('pc_defaultMemberGradeId'),
					weightTotal: reqData.weightTotal,
					customerTag: customerTagList.join(",")
				};

				// 获取包邮差额
				let sellInfo = await tradeUtils.calfreepostagedifference(JSON.stringify(obj));

				// 参数赋值
				this.freepostagedifference = sellInfo.result.data.freePostageDifference;
				this.showFreepostageDifference = sellInfo.result.data.isHavePostage && !sellInfo.result.data.isFreePostage && sellInfo.result.data.freePostageDifference < 999;

			},
		}
	}
</script>

<style scoped="scoped">
	.date_picker /deep/.el-picker__footer .el-button--text {
		display: none;
	}

	.trade-container {
		background-color: #F2F2F2;
		font-size: 18px;
	}

	.content-box {
		padding: 30px 80px;
	}

	.tip-text {
		width: 78px;
		padding-right: 20px;
		line-height: 40px;
	}

	.tip-text2 {
		width: 110px;
		line-height: 40px;
		padding-right: 50px;
	}

	.info-box {
		background-color: #fff;
		margin-bottom: 10px;
		padding: 30px 50px;
	}

	.back-box {
		margin-left: -30px;
	}

	.flew-row1 {
		display: flex;
		flex-direction: row;
	}

	.back-btn {
		font-size: 42px;
		position: relative;
		top: -10px;
		right: 10px;
	}

	.title {
		margin-bottom: 26px;
	}

	.row-box {
		justify-content: space-between;
		align-items: center;
	}

	.money-text {
		text-align: right;
		margin-left: 50px;
	}

	.total-box {
		display: flex;
		justify-content: flex-end;
	}

	.total-box /deep/.el-form-item__label,
	.total-box /deep/.el-form-item__content {
		font-size: 18px !important;
		color: #666 !important;
	}

	.total-box /deep/.el-form-item {
		margin-bottom: 20px !important;
	}

	.total {
		color: #000;
		font-size: 24px;
		font-weight: bold;
	}

	.pay-box {
		height: 130px;
		display: flex;
		justify-content: flex-end;
	}

	.pay-btn {
		height: 75px;
		width: 190px;
		font-size: 17px !important;
		margin: 40px 0;
	}

	.score-text {
		position: relative;
		bottom: 2px;
		left: 5px;
	}

	.size14 {
		font-size: 14px;
	}

	.size16 {
		font-size: 16px;
	}

	.row-remark {
		margin-top: 20px;
	}

	.trade-remark {
		width: 400px;
	}

	.mt10 {
		margin-top: 10px;

	}

	.freepostage {
		position: absolute;
		/* line-height: 155px; */
		left: 100px;
		transform: translateX(-157px) translateY(-12px);
		/* transform: translateY(10px); */
		width: 250px;
		font-size: 14px;
		color: red;
		text-align: right;
	}

	.freepostage-text {
		display: flex;
	}

	.presale-style {
		background: #0c0c0c;
		color: #FFFFFF;
		padding: 3px 10px;
		font-size: 14px;
		vertical-align: text-top;
	}

	/deep/.el-upload--picture-card {
		width: 100px !important;
		height: 100px !important;
	}

	.el-icon-plus {
		transform: translateY(-16px);
	}

	/* /deep/textarea {
		width: 400px;
		margin-left: 442px;
		height: 40px;
	} */

	/deep/.el-upload-list__item {
		width: 100px !important;
		height: 100px !important;
	}
</style>