import requestUtils from '../request-utils.js';

/**
 * 调用其他业务组接口
 * @param {*} interfaceCode 
 * @param {*} reqData 
 * @param {*} version 
 */
const callFeign = (interfaceCode, reqData = {}, version = '') => {
	// 调用转发接口
	return new Promise(async (resolve, reject) => {
		// 获取用户信息和手机号（部分接口需要强校验手机号）(此处用户信息获取缓存数据，如果调用api-customer，会造成循环引用)
		let customerInfo = JSON.parse(localStorage.getItem("pc_customerInfo")||"{}");
		let mobile = customerInfo.dbPhone || customerInfo.phone || "";
		let customerId = customerInfo.customerId || "";

		// 验证检测入参是对象还是jsonStr，后端需要根据标识转换，并且将入参转为jsonStr
		let isObj = true;
		let jsonStr = "";
		// 入参是jsonStr，则直接传递，否则需要转为jsonStr
		if (reqData && !!reqData.jsonStr) {
			isObj = false;
			jsonStr = reqData.jsonStr;
		} else {
			isObj = true;
			jsonStr = JSON.stringify(reqData);
		}

		// 请求方法名称
		let methodName = "jmall.transport.callfeign";
		if (version == "v1") {
			methodName = "jmall.transport.callfeign.v1";
		}

		// 调用转发接口
		requestUtils.jackyunPost({
			method: methodName,
			interfaceCode,
			jsonStr,
			isObj,
			mobile,
			customerId,
			loginFromWx: false
		}, (res) => {
			if (res.code != 200) {
				resolve(res);
			} else {
				// 直接返回结果数据，不做处理
				resolve(res.result.data);
			}
		});
	});
}

/**
 * 调用其他业务组接口（http://192.168.88.47/web/#/142/20557）（功能改版，老接口不变，20240205之后新增的转发接口用这个）
 * @param {*} interfaceCode 接口编号，后端给 
 * @param {*} reqData 请求json串
 */
const callFeignV1 = (interfaceCode, reqData = {}) => {
  return callFeign(interfaceCode, reqData, "v1");
}

export {
	callFeign,
	callFeignV1
}