<template>
	<personal-layout cur-menu="tradelist">
		<div class="right-box">
			<div class="flex-row back-box">
				<i class="el-icon-arrow-left back-btn" @click="goBack"></i>
				<div class="title">订单明细</div>
			</div>
			<div class="status-box">
				<div>
					<div class="trade-status" v-if="tradeDetail.tradeStatus == '5020'">销售单状态：原始单-被合并
					</div>
					<div class="trade-status" v-else-if="tradeDetail.tradeStatus == '5030'">销售单状态：原始单-被拆分
					</div>
					<div class="trade-status" v-else>
						销售单状态：{{!!tradeDetail.tradeStatusExplain && tradeDetail.tradeStatusExplain.indexOf('代销发货') >= 0 ?'待发货' :tradeDetail.tradeStatusExplain}}
					</div>
					<div class="trade-status" v-if="sellbackInfo.sellbackStatusExplain">售后单状态：{{sellbackInfo.sellbackStatusExplain}}</div>
				</div>

				<div class="trade-log flex-row" v-show="isShowTrailInfo" @click="showTradeLog">
					<div class="simple-info" v-show="false"> {{simpleTrailInfo}}</div>
					<div class="log-btn">物流轨迹</div>
				</div>
			</div>
			<div class="info-box" v-if="tradeDetail.logisticType == '1' ||tradeDetail.logisticType == '3'">
				<div class="small-title">收件人信息</div>
				<div class="base-info">
					<div><span class="base-item">姓名：</span><span>{{tradeReceiver.receiverName}}</span></div>
					<div><span class="base-item">电话：</span><span>{{tradeReceiver.mobile}}</span></div>
					<div><span class="base-item">地址：</span><span>{{tradeReceiver.address}}</span></div>
				</div>
			</div>
			<div class="info-box" v-else-if="tradeDetail.logisticType == '2'">
				<div class="small-title">自提信息</div>
				<div class="base-info">
					<div><span class="base-item">自提点：</span><span>{{tradeDetail.channelAnotherName}}</span></div>
					<div><span class="base-item">地 点：</span><span>{{tradeReceiver.address}}</span></div>
					<div><span class="base-item">自提人：</span><span>{{tradeReceiver.receiverName}} &nbsp;
							{{$utils.regTel(tradeReceiver.mobile)}}</span></div>
				</div>
			</div>
			<div class="info-box">
				<div class="small-title">订单信息</div>
				<div class="base-info">
					<el-row :gutter="20">
						<el-col :span="tradeDetail.logisticType != '2' ? 12 : 24">
							<div><span class="base-item">交易时间：</span><span>{{tradeDetail.tradeTime}}</span></div>
							<div><span class="base-item">订单编号：</span><span>{{tradeDetail.tradeNo}}</span><span v-show="tradeDetail.tradeNo" class="copy-btn" v-clipboard:copy="tradeDetail.tradeNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">复制</span></div>
							<div><span class="base-item">原始单号：</span><span>{{tradeDetail.sourceTradeNo}}</span><span v-show="tradeDetail.sourceTradeNo" class="copy-btn" v-clipboard:copy="tradeDetail.sourceTradeNo" v-clipboard:success="onCopySuccess" v-clipboard:error="onCopyError">复制</span></div>
						</el-col>
						<el-col :span="12" v-show="tradeDetail.logisticType != '2'">
							<div><span class="base-item">物流公司：</span><span>{{tradeDetail.logisticName}}</span></div>
							<div><span class="base-item">物流单号：</span>
								<span>{{tradeDetail.mainPostid}}</span>
							</div>
							<div class="flex-row">
								<div class="base-item">备注：</div>
								<div>{{tradeDetail.buyerMemo}}</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="info-box">
				<div class="small-title">商品清单</div>
				<goods-list :isShowSingleSpec="isShowSingleSpec" :goodslist="tradeGoods"></goods-list>
			</div>
			<div class="info-box total-box">
				<el-form label-position="right" label-width="100px" class="flex-col">
					<el-form-item label="商品总数 :">
						<div class="money-text">x {{$utils.amountFixed(tradeDetail.tradeCount, 0)}}</div>
					</el-form-item>
					<el-form-item label="优    惠 :" v-if="tradeDetail.discountFee>0">
						<div class="money-text">
							{{$currency.symbol}}{{$utils.amountFixed(tradeDetail.discountFee, 2)}}
						</div>
					</el-form-item>
					<el-form-item label="运    费 :">
						<div class="money-text">
							{{$currency.symbol}}{{$utils.amountFixed(tradeDetail.receivedPostFee, 2)}}
						</div>
					</el-form-item>
					<el-form-item label="货品积分 :" v-if="tradeDetail.goodsScoreTotal>0">
						<div class="money-text">
							{{tradeDetail.goodsScoreTotal}}积分
						</div>
					</el-form-item>
					<el-form-item label="抵扣积分 :" v-if="tradeDetail.deductionScoreTotal>0">
						<div class="money-text">
							{{tradeDetail.deductionScoreTotal}}积分
						</div>
					</el-form-item>
					<el-form-item label="订单共计 :">
						<div class="money-text total">
							<span v-show="tradeDetail.payment > 0 || tradeDetail.pointTotal == 0">{{$currency.symbol}}{{$utils.amountFixed(tradeDetail.payment, 2)}}</span>
							<span v-show="tradeDetail.payment != 0 && tradeDetail.pointTotal > 0"> + </span>
							<span v-show="tradeDetail.pointTotal > 0">{{tradeDetail.pointTotal}}</span>
							<span v-show="tradeDetail.pointTotal > 0" class="score-text">积分</span>
						</div>
					</el-form-item>
					<div class="refund-button-div">
						<!-- 测试代码 -->
						<!-- <el-button plain @click="showReturnGoodsDialog()">申请退货</el-button> -->
						<div v-if="tradeDetail.canRefundType == 1">
							<el-button v-if="sellbackInfo.sellbackStatus==-1||sellbackInfo.sellbackStatus==1" plain @click="dialogVisible = true">
								申请退款
							</el-button>
							<el-button v-else disabled plain>{{sellbackInfo.sellbackStatusExplain}}</el-button>
						</div>
						<div v-if="tradeDetail.canRefundType == 2">
							<el-button plain @click="showReturnGoodsDialog()">申请退货</el-button>
						</div>
					</div>
				</el-form>
			</div>
			<!-- 退款弹窗 -->
			<div>
				<el-dialog :model="refundForm" title="退款原因" :visible.sync="dialogVisible" width="400px" center>
					<el-form style="margin: 0 auto;width: 300px;">
						<el-form-item>
							<el-select v-model="refundForm.reson">
								<el-option v-for="item in refundResonList" :key="item" :value="item"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-input rows="8" type="textarea" placeholder="请输入备注" v-model="refundForm.remark">
							</el-input>
						</el-form-item>
					</el-form>
					<span slot="footer" class="dialog-footer">
						<el-button plain @click="refundBtn()">确 定</el-button>
						<el-button @click="dialogVisible = false">取 消</el-button>
					</span>
				</el-dialog>
			</div>
			<div>
				<el-dialog title="退货申请" :showClose="false" :visible.sync="isShowReturnGoodsDialog" width="550px" :close-on-click-modal="false">
					<div class='return-goods-box'>
						<el-table ref="selectedReturnGoodsList" :data="canReturnGoodsList" style="width: 500px" :show-header="false" @selection-change="selectReturnGoods">
							<el-table-column type="selection" width="50">
							</el-table-column>
							<el-table-column label="图片" width="80">
								<template slot-scope="scope">
									<img :src="$utils.setImg(scope.row.picUrl)" style="height: 50px" />
								</template>
							</el-table-column>
							<el-table-column label="商品信息" width="200" prop="goodsName,specName,unit,sellPrice,sellCount">
								<template slot-scope="scope">
									<div>{{scope.row.goodsName}}</div>
									<div>{{scope.row.specName}} - {{scope.row.unit}}</div>
									<span>{{$currency.symbol}}{{$utils.amountFixed(scope.row.sellPrice, 2)}}</span>
								</template>
							</el-table-column>
							<el-table-column label="计数器" prop="sellCount,maxReturnCount">
								<template slot-scope="scope">
									<el-input-number class="number" v-model="scope.row.sellCount" :min="1" :max="scope.row.maxReturnCount" size="mini" label="退货数量" @change="changeReturnCount(scope.$index,$event)">
									</el-input-number>
								</template>
							</el-table-column>
							<div slot="append">
								<div class="info_footer">
									<el-checkbox class="allCheck" :indeterminate="isIndeterminate" v-model="isSelectAllReturnGoods" @change="selectAllReturnGoods()">全选</el-checkbox>
									<span class="span_footer">数量：{{sumCount}}</span>
								</div>
							</div>
						</el-table>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button plain @click="showReturnMainDialog()">确 定</el-button>
						<el-button @click="isShowReturnGoodsDialog = false">取 消</el-button>
					</span>
				</el-dialog>
			</div>
		</div>
		<div class="dialogBox">
			<el-dialog class="detailDialog" title="查看轨迹" :visible.sync="trajectoryShow" style="margin-left: 28%;width:880px;overflow: hidden;">
				<iframe :src="webviewSrc" style="border: none; width: 402px; height: 628px;"></iframe>
			</el-dialog>
		</div>
		<!-- 退货物流信息弹窗 -->
		<div>
			<el-dialog title="退货信息填写" :visible.sync="isShowReturnMainDialog" width="490px" class="dialogClass" custom-class="return-dialog" :close-on-click-modal="false">
				<el-form :model="refundLogistics" ref="refundLogistics" style="width: 80%; margin: 0 auto;">
					<el-form-item label="退款金额" :rules="refundGoodsRules.sellBackApplyAmount" prop="sellBackApplyAmount">
						<el-input v-model="refundLogistics.sellBackApplyAmount" oninput="value=value.replace(/[^0-9.]/g,'')" :placeholder="returnPlaceHolder" />
					</el-form-item>
					<el-form-item label="物流方式">
						<el-select v-model="refundLogistics.logisticName" value-key="logisticId" style="width: 100%;min-width: 320px;">
							<el-option v-for="item in logisticsWays" :label="item.logisticName" :key="item.logisticId" :value="item.logisticName"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="物流单号" :rules="refundGoodsRules.postId" prop="rcv_post_id">
						<el-input v-model="refundLogistics.rcv_post_id" style="100%;"></el-input>
					</el-form-item>
					<el-form-item label="退货原因" :rules="refundGoodsRules.refundReason" prop="reason">
						<el-radio v-for="item in refundGoodsReson" v-model="refundLogistics.reason" :label="item" style="width:320px"></el-radio>
					</el-form-item>
					<el-form-item label="备注留言">
						<el-input type="textarea" v-model="refundLogistics.remark" style="100%;"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button plain @click="returnBtn()">确 定</el-button>
					<el-button @click="isShowReturnMainDialog = false">取 消</el-button>
				</span>
			</el-dialog>
		</div>
		<trade-logistic :isShow="showLogDialog" :tradeId="tradeDetail.tradeId" :tradeNo="tradeDetail.tradeNo" :logisticId="tradeDetail.logisticId" :logisticNo="tradeDetail.mainPostid" @hastrailinfo="hasTrailInfo" @close="closeLogDialog"></trade-logistic>
	</personal-layout>

</template>

<script>
	import '../../theme/element/theme/self.css'
	import _ from 'lodash'
	import personalLayout from '../../components/mine/PersonalLayout.vue'
	import goodsList from '../../components/trade/GoodsListJmc.vue'
	import tradeLogistic from '../../components/trade/TradeLogistic.vue'
	import commonUtils from '../../utils/common-utlis.js'
	import tradeApi from '../../utils/api-utils/api-trade.js';
	import refundApi from '../../utils/api-utils/api-refund.js';
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import {
		callFeign
	} from '../../utils/api-utils/api-callFeign.js';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import {
		system
	} from '../../utils/api-utils/api-sys.js';
	export default {
		name: 'tradedetail',
		components: {
			personalLayout,
			goodsList,
			tradeLogistic
		},

		data() {
			const validateMoney = (rule, val, callback) => {
				let that = this;
				if (val.length == 0) {
					callback("请输入退款金额");
				}
				let num = val.toString(); //先转换成字符串类型
				if (num.indexOf('.') == 0) { //第一位就是 .
					num = '0' + num
				}
				num = num.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				num = num.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
				num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
				if (num.indexOf(".") < 0 && num != "") {
					num = parseFloat(num);
				}
				if (num > that.maxReturnAmount) {
					num = that.maxReturnAmount;
				}
				that.refundLogistics.sellBackApplyAmount = num;
				callback();
			};
			return {
				tradeNo: "", //this.$route.params.tradeNo, //接收上一个页面的传值
				tradeDetail: {},
				tradeReceiver: {},
				jmallTradeDetail: {},
				tradeGoods: [],
				statusText: ['已取消', '待结算', '待审核', '待发货', '已发货', '已完成', '', '', '', '退款中', '退款完成', '', '', '', '退货中',
					'退货完成'
				],
				isShowTrailInfo: false, //是否有订单轨迹相关信息
				simpleTrailInfo: '', //简易物流信息
				showLogDialog: false, //是否展示物流轨迹	
				dialogVisible: false, //退款弹窗
				isShowReturnGoodsDialog: false, //退货弹窗
				isShowReturnMainDialog: false, //退货物流信息弹窗
				refundResonList: ["拍错/多拍", "不想要了", "订单信息有误", "其他"],
				refundForm: {
					reson: '',
					remark: '',
				},
				customer: {}, //顾客信息
				isSelectAllReturnGoods: false, // 全选
				refundGoodsSelection: [], //选中退货商品信息
				isIndeterminate: false, // 全选确定状态
				sumCount: 0, //退货数量
				sumScore: 0, //退货积分
				//退货物流信息
				refundLogistics: {
					logisticId: '',
					logisticName: '',
					rcv_post_id: '',
					reason: '卖家发错货',
					remark: '',
					sellBackApplyAmount: ''
				},
				logisticsWays: [], // 物流方式
				refundGoodsReson: ["卖家发错货", "做工问题", "描述不符", "其他"], //退货原因
				refundGoodsRules: {
					postId: [{
						required: true,
						message: '请输入物流单号',
						trigger: 'blur'
					}],
					refundReason: [{
						required: true,
						message: '请选择退货原因',
						trigger: 'change'
					}],
					sellBackApplyAmount: [{
						required: true,
						trigger: 'change',
						validator: validateMoney

					}]
				},
				subTradeList: [],
				sellbackStatus: '', //退款状态
				sellbackType: '', // 退款类型
				isClickRefund: true, // 退款/退货按钮显示/不显示
				reFundList: [], // 退货数据列表\
				isShowSingleSpec: true, // 是否展示单规格
				logisticName: "", // 订单物流名称
				trajectoryShow: false,
				webviewSrc: "",
				config: {
					isOpenCannotReturnTime: false,
					cannotReturnDays: 0
				},
				returnPlaceHolder: "",
				omsTradeInfo: {}, // 原始销售单信息
				originalTradeList: [], // 原始单列表
				originalTradeGoodsList: [], // 原始单商品列表（多个订单的货品合并到一起，并附加一些订单信息到商品上）
				sellbackInfo: {}, // 当前订单的退款信息
				canReturnGoodsList: [], // 可退货商品列表
				selectedReturnGoodsList: [], // 选中的退货商品列表
				maxReturnAmount: 0, // 最大可退金额

			}
		},
		created() {
			// 默认绑定退款原因(拍错/多拍)
			this.refundForm.reson = this.refundResonList[0];
		},
		async mounted() {
			// 接收参数	
			await this._getParams();

			// 获取订单详情
			this._getTradeDetail();

			// 加载当前订单的退款信息
			this._loadSellbackInfo();
		},
		methods: {
			hasTrailInfo(hasInfo, info) {
				this.isShowTrailInfo = hasInfo;
				this.simpleTrailInfo = info;
			},
			showTradeLog() {
				this.showLogDialog = true;
			},
			closeLogDialog() {
				this.showLogDialog = false;
			},
			//获取吉客云订单明细
			async _getTradeDetail() {
				let omsTradeInfo = await tradeApi.getOmsTradeDetail(this.tradeNo);

				if (!omsTradeInfo.tradeId) {
					this.$message.error({
						message: '订单不存在'
					});
					this.$router.back(-1);
				}

				// 获取吉商城图片列表
				let picList = await tradeApi.getPicByTradeNoList([omsTradeInfo.sourceTradeNo]);

				// 获取原始单列表
				let originalTradeList = await this._getOriginalTradeList(omsTradeInfo.sourceTradeNo);
				this.originalTradeList = originalTradeList;

				// 生成原始单货品列表（多个订单的货品合并到一起，并附加一些订单信息到商品上）
				let originalTradeGoodsList = this._getOriginalTradeGoodsList(originalTradeList);
				this.originalTradeGoodsList = originalTradeGoodsList;

				// 将oms商品列表和原始商品匹配
				this._matchOriginalTradeGoodsList(omsTradeInfo, originalTradeGoodsList);

				let tradeDetail = omsTradeInfo.tradeOrder; //订单信息
				tradeDetail.mainPostid = tradeDetail.mainPostid + (omsTradeInfo
					.extraLogisticNo ? ("," + omsTradeInfo.extraLogisticNo) : "");
				tradeDetail.tradeStatusExplain = tradeDetail.tradeStatusExplain.split('-')[0];
				// 订单状态说明
				tradeDetail.tradeStatusExplain = tradeDetail.tradeStatusExplain.split('-')[0];
				if (tradeDetail.tradeStatus == 5020) {
					tradeDetail.tradeStatusExplain = "原始单-被合并";
				} else if (tradeDetail.tradeStatus == 5030) {
					tradeDetail.tradeStatusExplain = "原始单-被拆分";
				} else if (tradeDetail.tradeStatusExplain.includes("代销发货")) {
					tradeDetail.tradeStatusExplain = "待发货";
				}
				tradeDetail.tradeOrderPayArr = omsTradeInfo.tradeOrderPayArr; // 支付金额信息
				tradeDetail.tradeOrderPayArr.forEach(item => {
					item.payment = item.payment.toFixed(2);
				})

				// 订单解密
				await tradeApi.tradeDecrypt(omsTradeInfo);

				let tradeGoods = omsTradeInfo.tradeOrderGoodsArr; //货品信息
				let tradeReceiver = omsTradeInfo.tradeOrderReceiver; //收件信息

				//格式化订单总价
				tradeDetail.payment = parseFloat(tradeDetail.payment).toFixed(2);
				//重新封装货品信息
				for (let i = 0; i < tradeGoods.length; i++) {
					tradeGoods[i].pic0 = picList.filter(item => item.specId == tradeGoods[i].skuId && !!item.picUrl)[0] && picList.filter(item => item.specId == tradeGoods[i].skuId && !!item.picUrl)[0].picUrl;
					//提取货品图片
					if (tradeGoods[i].skuImgUrl) {
						let picInfo = JSON.parse(tradeGoods[i].skuImgUrl);
						tradeGoods[i].pic400 = picInfo.pic400x400;
						tradeGoods[i].pic50 = picInfo.pic50x50;
						tradeGoods[i].pic0 = tradeGoods[i].pic0 || picInfo.pic0x0;
					} else {
						tradeGoods[i].pic400 = '/Images/MobileUse/default.png';
						tradeGoods[i].pic0 = tradeGoods[i].pic0 || '/Images/MobileUse/default.png';
					}
					//格式化货品单价
					tradeGoods[i].sellPrice = parseFloat(tradeGoods[i].sellPrice).toFixed(2);
					//格式化货品总价
					tradeGoods[i].sellTotal = parseFloat(tradeGoods[i].sellTotal).toFixed(2);
				}
				if (this.config.channelAnotherNameList) {
					let matchObj = this.config.channelAnotherNameList.find((anotherNameItem) => tradeDetail.logisticId == anotherNameItem.channelId)
					let logisticName = matchObj && matchObj.channelAnotherName;
					tradeDetail.channelAnotherName = logisticName || tradeDetail.logisticName;
				}

				// 检测并生成允许退款/退货的类型（0 不可退，1 可退款，2 可退货）
				tradeDetail.canRefundType = await this.__getCanRefundTypeByJackyunTradeStatus(tradeDetail.tradeStatus, tradeDetail.sysFlagIds);

				//渲染订单详情界面			       
				this.tradeDetail = tradeDetail;
				this.refundGoodsRules.postId[0].required = tradeDetail.logisticType != 2;
				this.__getLogisticByLogisticId(tradeDetail.logisticId, tradeDetail.logisticName);
				this.tradeReceiver = tradeReceiver;
				this.tradeGoods = tradeGoods;
				//获取商城商品图片
				this.webviewSrc = "https://m.kuaidi100.com/result.jsp?nu=" + tradeDetail.mainPostid

			},

			/**
			 * 将oms商品列表和原始商品匹配
			 * @param {*} omsTradeInfo 
			 * @param {*} originalTradeGoodsList 
			 */
			_matchOriginalTradeGoodsList(omsTradeInfo, originalTradeGoodsList) {
				// 遍历销售单货品列表，生成退款货品列表（和网店信息通过subTradeId关联）
				let newTradeOrderGoodsArr = [];
				let goodsScoreTotal = 0; // 记录商品购买使用积分总和
				let deductionScoreTotal = 0; // 记录积分抵扣使用积分总和
				omsTradeInfo.tradeOrderGoodsArr.forEach(omsTradeGoods => {
					// 筛选出退款货品关联的原始单货品数据
					let curSourceGoodsList = originalTradeGoodsList.filter(sourceGoods => sourceGoods.tradeNo == omsTradeGoods.sourceTradeNo &&
						sourceGoods.goodsId == omsTradeGoods.goodsId && sourceGoods.specId == omsTradeGoods.specId && sourceGoods.unit == omsTradeGoods.unit);

					// 补一条当前不在原始单情况的补齐数据
					curSourceGoodsList.push({
						goodsId: omsTradeGoods.goodsId,
						specId: omsTradeGoods.skuId,
						unit: omsTradeGoods.unit,
						tradeId: 0,
						tradeNo: "",
						sellScore: 0,
						sellCount: 99999999,
						singleGoodsDeductionScore: 0
					});

					// 剩余待退货品数量
					omsTradeGoods.remainingCount = omsTradeGoods.sellCount;

					// 将退款货品分配到原始单上（原始单没有的则不需要处理）
					curSourceGoodsList.forEach(sourceGoods => {
						if (omsTradeGoods.remainingCount > 0) {
							// 计算退货数量
							let sellCount = 0;
							if (omsTradeGoods.remainingCount <= sourceGoods.sellCount) {
								sellCount = omsTradeGoods.remainingCount;
								omsTradeGoods.remainingCount = 0;
							} else {
								sellCount = sourceGoods.sellCount;
								omsTradeGoods.remainingCount -= sourceGoods.sellCount;
							}

							// 复制当前OMS货品信息
							let newOrderGoodsItem = JSON.parse(JSON.stringify(omsTradeGoods));

							// 重新计算当前商品所用积分
							newOrderGoodsItem.sellPoint = sourceGoods.sellScore; // 单个商品购买所需积分
							newOrderGoodsItem.sellCount = sellCount;

							// 统计积分总和
							goodsScoreTotal += newOrderGoodsItem.sellPoint * sellCount;
							deductionScoreTotal += sourceGoods.singleGoodsDeductionScore * sellCount;

							// 生成退货货品
							newTradeOrderGoodsArr.push(newOrderGoodsItem);
						}
					});
				});

				// 记录积分总和
				omsTradeInfo.tradeOrder.goodsScoreTotal = goodsScoreTotal;
				omsTradeInfo.tradeOrder.deductionScoreTotal = parseInt(deductionScoreTotal);
				omsTradeInfo.tradeOrder.pointTotal = goodsScoreTotal + omsTradeInfo.tradeOrder.deductionScoreTotal;

				return omsTradeInfo.tradeOrderGoodsArr = newTradeOrderGoodsArr;
			},

			/**
			 * 根据吉客云订单状态，生成允许退款/退货的类型（0 不可退，1 可退款，2 可退货）
			 * @param {*} tradeStatus 
			 */
			async __getCanRefundTypeByJackyunTradeStatus(tradeStatus, sysFlagIds = "") {
				/**
				 * 需要隐藏退款按钮的吉客云系统标记：10-虚拟发货、
				 */
				const omsTradeSysFlagIdListForHideRefundBtn = ["10"];
				// 检测oms订单系统标记，存在指定标记的订单则不可退款/退货
				let sysFlagIdList = (sysFlagIds || "").split(',');
				let notAllowedFlagId = sysFlagIdList.find(flagId => omsTradeSysFlagIdListForHideRefundBtn.includes(flagId));
				if (notAllowedFlagId) {
					return 0;
				}

				// 仅“已完成”可退货
				if ([9090].includes(tradeStatus)) {
					return 2;
				}

				// 根据后台配置判断能否退款
				let paramList = await getParam("canRefundStatusSelect");
				let canRefundPaymentStatusOfParam = paramList.canRefundStatusSelect || "3";
				// 重新排序 后台设置可能是 "3,1,2"
				canRefundPaymentStatusOfParam = canRefundPaymentStatusOfParam.split(",").sort((a, b) => a - b).join(",");

				let refundPaymentStatus = [];
				switch (canRefundPaymentStatusOfParam) {
					// 待审核
					case "1":
						refundPaymentStatus = [1010];
						break;
						// 待复核
					case "2":
						refundPaymentStatus = [1050];
						break;
					case "1,2":
						refundPaymentStatus = [1010, 1050];
						break;
						// 除发货在途、已完成外的状态
					case "3":
					case "1,2,3":
					case "1,3":
					case "2,3":
						refundPaymentStatus = [6000, 9090];
						break;
						// 不允许发起退款
					case "4":
						refundPaymentStatus = [];
						break;
					default:
						refundPaymentStatus = [];
				}

				if (canRefundPaymentStatusOfParam == "4" || (canRefundPaymentStatusOfParam.includes("3") ? refundPaymentStatus.includes(tradeStatus) : !refundPaymentStatus.includes(tradeStatus))) {
					return 0;
				}

				// 剩余状态均允许退款
				return 1;
			},

			// 获取配置
			async _getParams() {
				// 获取用户信息
				this.customer = await getCustomerInfo();

				// 接收路由参数
				this.tradeNo = this.$route.query.no;

				// 接收配置参数
				let paramList = await getParam("isOpenCannotReturnTime,cannotReturnDays,channelAnotherNameList,isShowSingleSpec")
				this.config.isShowSingleSpec = paramList.isOpenCannotReturnTime == "1";
				this.config.isOpenCannotReturnTime = paramList.isOpenCannotReturnTime != "0";
				this.config.cannotReturnDays = parseInt(paramList.cannotReturnDays || "14");
				this.config.channelAnotherNameList = paramList.channelAnotherNameList && JSON.parse(paramList.channelAnotherNameList) || [];
			},
			//获取吉客云物流方式
			async getLogisticsWays() {
				let that = this;
				let paramList = await getParam("bindRefundLogisticList,bindLogisticList")
				var bindRefundLogisticList = !!paramList.bindRefundLogisticList ? JSON.parse(paramList
					.bindRefundLogisticList) : JSON.parse(paramList.bindLogisticList);
				if (bindRefundLogisticList && bindRefundLogisticList.length > 0) {
					var logisticsWays = [];

					bindRefundLogisticList.forEach((item) => {
						let arrLogi = item.split(',');

						logisticsWays.push({
							logisticName: arrLogi.length > 2 ? arrLogi[2] : arrLogi[0],
							logisticId: arrLogi[1]
						});
					})
					that.logisticsWays = logisticsWays;
					that.refundLogistics.logisticName = logisticsWays[0].logisticName;
					that.refundLogistics.logisticId = logisticsWays[0].logisticId;
				}

			},

			onCopySuccess() {
				this.$message({
					message: "复制成功",
					type: 'success'
				});
			},
			onCopyError(e) {
				this.$message.error({
					message: "复制失败"
				});
			},
			goBack() {
				this.$router.back(-1);
			},
			displayTrajectory() {
				this.trajectoryShow = true;
			},

			// differ解密
			decryptDiffer(mobile) {
				let that = this;

				return new Promise(function(resolve, reject) {
					if (!that.checkIsLock(mobile)) {
						resolve(mobile);
						return;
					}
					var items = [];
					items = that.initdecryptJson(items, "phone", mobile);
					var sensitiveOrders = {
						"items": items
					};
					var obj = {
						"encryptModel": "DIFFER"
					};
					obj["sensitiveOrders"] = sensitiveOrders;
					that.$request.jackyunPost({
						method: "trade.decryptdata",
						jsonStr: JSON.stringify(obj)
					}, function(data) {
						if (data.code == 200 && data.result.data.isSuccess == 1) {
							var sensitiveOrders = data.result.data.sensitiveOrders;
							if (sensitiveOrders[0].items[0].isSuccess == 1) {
								resolve(sensitiveOrders[0].items[0].decryptStr);
							}
						}
						resolve(mobile);
					})
				})
			},
			// 校验是否加锁
			checkIsLock(content) {
				let that = this;
				var reg = /.*~\/(\S*)\/~1~~/;
				if (!reg.test(content)) {
					return false;
				}
				return true;
			},

			/**
			 * 解密参数
			 * @param {*} items 
			 * @param {*} type 
			 * @param {*} content 
			 */
			initdecryptJson(items, type, content) {
				var reg = /.*~\/(\S*)\/~1~~/;
				if (reg.test(content)) {
					let plat = content.match(reg)[1];
					if (plat == "differ") {
						var decryContent = {};
						decryContent["type"] = type;
						decryContent["encryptStr"] = content;
						items.push(decryContent);
					}
				}
				return items;
			},

			// 获取吉客云物流信息(非别名)
			async __getLogisticByLogisticId(logisticId, logisticName) {
				if (logisticId == 0) {
					this.logisticName = logisticName;
					return;
				}

				let res = await callFeign("erp.logistic.getbyid", {
					id: logisticId
				});
				if (res.code == 200 && !!res.result.data) {
					logisticName = res.result.data.expressCode || "";
				}
				this.logisticName = logisticName;
			},

			/**
			 * 加载退款单状态
			 * @param {*} tradeNo 
			 */
			async _loadSellbackInfo() {
				// 获取退款单状态
				let sellbackInfoList = await refundApi.getRefundStatusByJackyunTradeNo([this.tradeNo]);

				// 处理当前订单的退款状态信息
				let sellbackInfo = this.__processingTradeRefundStatus(sellbackInfoList);

				this.sellbackInfo = sellbackInfo;
			},

			/**
			 * 订单退款单状态处理（一个订单可能对应多个退款单）
			 * @param {*} refundStatusList 
			 * @param {*} tradeInfo 
			 */
			__processingTradeRefundStatus(refundStatusList, trade = {}) {
				/**
				 * 退款状态（0待审核，1驳回，2审核通过）
				 * 退款类型（0退款，1退货）
				 */

				// 根据退款状态去重，多个相同状态的退款单做同样的状态处理
				refundStatusList = commonUtils.distinctArr(refundStatusList, "sellbackStatus");
				if (!refundStatusList || refundStatusList.length <= 0) {
					trade.sellbackStatus = -1;
					trade.sellbackType = -1;
					trade.sellbackStatusExplain = "";
					return trade;
				}

				// 当前订单退款状态，单一有效的状态数据
				let curTradeRefundInfo;
				if (refundStatusList.length == 1) {
					curTradeRefundInfo = refundStatusList[0];
				} else {
					// 去除“驳回”状态的单据，以当前生效的退款单为准
					let refundStatusNotRejectList = refundStatusList.filter(t => t.sellbackStatus != 1);
					if (refundStatusNotRejectList && refundStatusNotRejectList.length == 1) {
						curTradeRefundInfo = refundStatusNotRejectList[0];
					}
				}

				// 没有单一可判断的退款单状态，存在多条退款单状态的情况，显示“退款”或者“退货”
				if (!curTradeRefundInfo) {
					trade.sellbackStatus = refundStatusList[0].sellbackStatus;
					trade.sellbackType = refundStatusList[0].sellbackType;
					trade.sellbackStatusExplain = refundStatusList[0].sellbackType == 2 ? "退货" : "退款";
					return trade;
				}

				// 退款状态（0待审核，1驳回，2同意）
				trade.sellbackStatus = curTradeRefundInfo.sellbackStatus;
				// 退款类型（1退款，2退货）
				trade.sellbackType = curTradeRefundInfo.sellbackType;
				// 退款状态说明
				if (trade.sellbackType == 1 && trade.sellbackStatus == 0) {
					trade.sellbackStatusExplain = "待商家同意退款";
				} else if (trade.sellbackType == 1 && trade.sellbackStatus == 1) {
					trade.sellbackStatusExplain = "商家拒绝退款";
				} else if (trade.sellbackType == 1 && trade.sellbackStatus == 2) {
					trade.sellbackStatusExplain = "退款成功";
				} else if (trade.sellbackType == 2 && trade.sellbackStatus == 0) {
					trade.sellbackStatusExplain = "待商家同意退货";
				} else if (trade.sellbackType == 2 && trade.sellbackStatus == 1) {
					trade.sellbackStatusExplain = "商家拒绝退货";
				} else if (trade.sellbackType == 2 && trade.sellbackStatus == 2) {
					trade.sellbackStatusExplain = "退货成功";
				} else {
					trade.sellbackStatusExplain = "退款/退货";
				}
				return trade;
			},

			/**
			 * 申请退款
			 */
			refundBtn: _.debounce(function() {
				let loading;
				this.$confirm('确定退款?', '提示').then(async () => {
					// 显示“提交中”弹窗
					loading = this.$loading({
						text: '提交中',

					});

					// 获取检测oms订单详情
					let omsTradeInfo = await this._getGetAndCheckOmsTradeInfo(1);
					console.log(omsTradeInfo);
					// 未获取到订单信息则关闭退款弹窗（提示窗在检测中已有）
					if (!omsTradeInfo) {
						// 关闭退款弹窗
						this.dialogVisible = true;
						// 关闭“提交中”弹窗
						loading.close();
						return;
					}

					// 获取原始单列表
					let originalTradeList = await this._getOriginalTradeList(omsTradeInfo.sourceTradeNo);

					// 生成原始单货品列表（多个订单的货品合并到一起，并附加一些订单信息到商品上）
					let originalTradeGoodsList = this._getOriginalTradeGoodsList(originalTradeList);

					// 生成退款商品列表
					let sellbackGoodsList = this._getSellBackGoodsList(omsTradeInfo.tradeOrderGoodsArr, originalTradeGoodsList, omsTradeInfo.tradeOrderGoodsRelateOnlineArr, 1);

					// 获取卡券使用明细
					let couponUseDetail = await tradeApi.getCouponUseDetail(omsTradeInfo.sourceTradeNo.split(","));

					// 生成卡券返还信息（用作自动审核）
					this.returnCardTicketInfoList = this._getReturnCardTicketInfoListNew(sellbackGoodsList, omsTradeInfo, originalTradeGoodsList, originalTradeList, couponUseDetail);

					// 获取退款所需额外数据（金额合计、积分合计、卡券合计等）
					let extendedInfo = this._getSellbackExtendedInfo(originalTradeList, sellbackGoodsList, omsTradeInfo);

					// 组合装拆成单件退款情况兼容
					await this._packageGoodsRefundDataHandling(omsTradeInfo, originalTradeList, originalTradeGoodsList, this.returnCardTicketInfoList, extendedInfo, true, couponUseDetail);

					// 获取客户信息
					let customerInfo = await getCustomerInfo()

					// 退款单信息
					let sellBackInfo = {
						sellbackType: 1, // 退货类型（1退款 2退货）
						tradeNo: omsTradeInfo.sourceTradeNo, // 原关联订单号（多个）
						jackyunTradeId: omsTradeInfo.tradeId, // 吉客云订单ID
						jackyunTradeNo: omsTradeInfo.tradeNo, // 吉客云订单号
						customerId: customerInfo.customerId, // 客户id
						originalTotalAmount: extendedInfo.originalTotalAmount, // 原单总金额
						couponDeductAmount: extendedInfo.couponDeductAmount, // 卡券抵扣金额
						shouldRefundAmount: extendedInfo.shouldRefundAmount, // 应退金额
						shouldReturnScoreTotal: extendedInfo.shouldReturnScoreTotal, // 应退总积分
						sellbackCount: extendedInfo.allReturnGoodsCount, // 退货总数量
						shopId: omsTradeInfo.tradeOrder.shopId, // 渠道ID（吉客云订单）
						shopName: omsTradeInfo.tradeOrder.shopName, // 渠道名称（吉客云订单）
						accountId: originalTradeList[0].accountId, // 原单下单结算账户
						chargeType: omsTradeInfo.tradeOrder.chargeTypeExplain, // 结算方式（无效字段）
						currencyCode: omsTradeInfo.tradeOrder.chargeCurrencyCode, // 币种符号
						currencyName: omsTradeInfo.tradeOrder.chargeCurrency, // 币种名称
						payType: extendedInfo.payTypes, // 支付方式
						reason: this.refundForm.reson, // 	退换原因
						remark: this.refundForm.remark, // 退款备注
						sellBackGoodsList: sellbackGoodsList, // 退款商品信息
						returnCardTicketInfoList: this.returnCardTicketInfoList, // 返还卡券信息
						// 记录原始单信息，无实际作用，仅后台展示
						tradeNick: omsTradeInfo.tradeOrderCustomer.customerName,
						sendTo: omsTradeInfo.tradeOrderReceiver.receiverName,
						tel: omsTradeInfo.tradeOrderReceiver.mobile,
						country: omsTradeInfo.tradeOrderReceiver.country,
						province: omsTradeInfo.tradeOrderReceiver.state,
						city: omsTradeInfo.tradeOrderReceiver.city,
						town: omsTradeInfo.tradeOrderReceiver.district,
						address: omsTradeInfo.tradeOrderReceiver.address.replace('  ', ' '),
						orderVersion: 1,
						// 补充退换补发单需要的参数
						sendTime: omsTradeInfo.tradeOrder.consignTime || "", // 发货时间
						payTime: omsTradeInfo.tradeOrder.payTime || "", // 支付时间
						omsTradeStatus: omsTradeInfo.tradeOrder.tradeStatus, // 订单状态
					};

					// 创建退款单（提示功能在方法内部完成）
					let res = await refundApi.createSellbackOrder(sellBackInfo);
					if (!res) {
						// 关闭“提交中”弹窗
						loading && loading.close();
						return;
					}

					// 订单退款成功，返回订单列表
					this.__gotoTradeListPage();

					// 关闭退款弹窗
					this.dialogVisible = true;
					// 关闭“提交中”弹窗
					loading.close();
				}).catch((e) => {
					if (e == "cancel") {
						return;
					}
					this.$message.error("退款申请创建异常");
					// 关闭“提交中”弹窗
					loading && loading.close();
					console.log(e);
				});
			}),

			/**
			 * 获取并检测吉客云订单详情
			 * @param {*} refundType 
			 */
			async _getGetAndCheckOmsTradeInfo(refundType) {
				// 获取销售单详情
				let omsTradeInfo = await tradeApi.getOmsTradeDetail(this.tradeNo);
				if (!omsTradeInfo.tradeId) {
					this.$message.error("未检测到销售单信息，请刷新重试");
					this.__gotoTradeListPage();
					return null;
				}

				// 退款检测订单状态
				if (refundType != await this.__getCanRefundTypeByJackyunTradeStatus(omsTradeInfo.tradeOrder.tradeStatus, omsTradeInfo.tradeOrder.sysFlagIds)) {
					this.$message.error("订单状态已变更");
					setTimeout(function() {
						// 刷新当前页面
						location.reload();
					}, 1200);
					return null;
				}

				// 订单信息解密
				await tradeApi.tradeDecrypt(omsTradeInfo);

				return omsTradeInfo;
			},

			/**
			 * 跳转到订单列表
			 */
			__gotoTradeListPage(time = 1200) {
				let _this = this;
				setTimeout(function() {
					_this.$router.push({
						name: 'tradelist',
						query: {}
					});
				}, time);
			},

			/**
			 * 获取原始单列表
			 * @param {*} sourceTradeNos 
			 */
			async _getOriginalTradeList(sourceTradeNos) {
				// 获取当前页面上已有的原始单列表
				let originalTradeList = this.originalTradeList || [];
				if (originalTradeList.length > 0) {
					return originalTradeList;
				}
				// 获取原始单列表
				originalTradeList = await tradeApi.getJmallTradeDetailList(sourceTradeNos);
				this.originalTradeList = originalTradeList || [];
				return originalTradeList;
			},

			/**
			 * 获取原始单商品集合（并附加一些订单信息到商品上）
			 * @param {*} originalTradeList 
			 */
			_getOriginalTradeGoodsList(originalTradeList) {
				// 生成原始单货品列表（多个订单的货品合并到一起）
				let originalTradeGoodsList = [];
				// 遍历原始单，生成原始单货品，并前置计算退货所需数据
				originalTradeList.forEach(originalTrade => {
					// 生成原始单货品列表
					originalTrade.tradeGoodsList.forEach(tradeGoods => {
						tradeGoods.tradeId = originalTrade.tradeId;
						tradeGoods.tradeNo = originalTrade.tradeNo;
						tradeGoods.jackyunTradeId = originalTrade.jackyunTradeId;
						tradeGoods.jackyunTradeNo = originalTrade.jackyunTradeNo;
						tradeGoods.couponCode = originalTrade.couponCode; // 卡券编号
						tradeGoods.tradeCouponValue = originalTrade.couponTotal; // 订单上卡券抵扣金额
						tradeGoods.singleGoodsCouponDeductionAmount = commonUtils.floatKeepDigit(tradeGoods.couponDeductionAmount / tradeGoods.sellCount, 4); // 卡券抵扣金额均摊
						tradeGoods.singleGoodsScoreDeductionAmount = commonUtils.floatKeepDigit(tradeGoods.scoreDeductionAmount / tradeGoods.sellCount, 4); // 积分抵扣金额均摊
						tradeGoods.singleGoodsFavourableDeductionAmount = commonUtils.floatKeepDigit(tradeGoods.favourableDeductionAmount / tradeGoods.sellCount, 4); // 改价优惠金额均摊
						tradeGoods.singleGoodsDiscountDeductionAmount = commonUtils.floatKeepDigit(tradeGoods.discountDeductionAmount / tradeGoods.sellCount, 4); // 折扣优惠金额均摊
						tradeGoods.singleGoodsDeductionScore = commonUtils.floatKeepDigit(tradeGoods.sellPrice / originalTrade.goodsTotal * (originalTrade.deductionScore || 0), 4); // 积分抵扣的积分均摊到单个货品
						tradeGoods.shopId = originalTrade.shopId;
						tradeGoods.shopName = originalTrade.shopName;
					});
					originalTradeGoodsList = originalTradeGoodsList.concat(originalTrade.tradeGoodsList);
				});
				return originalTradeGoodsList;
			},

			/**
			 * 生成退款货品列表
			 * @param {*} needReturnGoodsList 
			 * @param {*} originalTradeGoodsList 
			 * @param {*} tradeOrderGoodsRelateOnlineArr 
			 */
			_getSellBackGoodsList(needReturnGoodsList, originalTradeGoodsList, tradeOrderGoodsRelateOnlineArr, sellbackType) {
				// 遍历销售单货品列表，生成退款货品列表（和网店信息通过subTradeId关联）
				let sellbackGoodsList = [];
				needReturnGoodsList.forEach(returnGoods => {
					// 筛选出退款货品关联的原始单货品数据
					let curSourceGoodsList = originalTradeGoodsList.filter(sourceGoods => sourceGoods.tradeNo == returnGoods.sourceTradeNo &&
						sourceGoods.goodsId == returnGoods.goodsId && sourceGoods.specId == returnGoods.specId && sourceGoods.unit == returnGoods.unit);

					// 补一条当前不在原始单情况的补齐数据
					curSourceGoodsList.push({
						goodsId: returnGoods.goodsId,
						specId: returnGoods.skuId,
						unit: returnGoods.unit,
						tradeId: 0,
						tradeNo: "",
						sellScore: 0,
						sellCount: 99999999,
						singleGoodsDeductionScore: 0
					});

					// 剩余待退货品数量
					returnGoods.remainingRefundCount = returnGoods.sellCount;

					// 将退款货品分配到原始单上（原始单没有的则不需要处理）
					curSourceGoodsList.forEach(sourceGoods => {
						if (returnGoods.remainingRefundCount > 0) {
							// 计算退货数量
							let returnCount = 0;
							if (returnGoods.remainingRefundCount <= sourceGoods.sellCount) {
								returnCount = returnGoods.remainingRefundCount;
								returnGoods.remainingRefundCount = 0;
							} else {
								returnCount = sourceGoods.sellCount;
								returnGoods.remainingRefundCount -= sourceGoods.sellCount;
							}

							// 退款/退货商品单价（退款需要去除邮资）
							let sellPrice = sellbackType == 1 ? returnGoods.realSellPrice : (returnGoods.realSellPrice - returnGoods.sellPostage);

							// 生成退货货品
							sellbackGoodsList.push({
								goodsId: returnGoods.goodsId,
								specId: returnGoods.skuId,
								unit: returnGoods.unit,
								sellCount: returnCount,
								returnCount: returnCount,
								specName: returnGoods.skuName,
								goodsName: returnGoods.goodsName,
								goodsNo: returnGoods.goodsNo,
								sellPrice: sellPrice,
								sellTotal: sellPrice * returnCount,
								sellScore: (sourceGoods.sellScore || 0) + Math.round(sourceGoods.singleGoodsDeductionScore),
								scoreTotal: (sourceGoods.sellScore || 0) * returnCount + Math.round(sourceGoods.singleGoodsDeductionScore * returnCount),
								sourceTradeId: sourceGoods.tradeId || 0,
								sourceOrderNo: sourceGoods.tradeNo || "",
								jkySubTradeId: returnGoods.subTradeId,
								// 以下参数为内部传值使用，后端不要求
								couponCode: sourceGoods.couponCode || "",
								tradeCouponValue: sourceGoods.tradeCouponValue || 0,
								goodsCouponDeductionAmount: Math.min(sourceGoods.couponDeductionAmount || 0, commonUtils.floatKeepDigit((sourceGoods.singleGoodsCouponDeductionAmount || 0) * returnCount)),
								// goodsScoreDeductionAmount: Math.min(sourceGoods.scoreDeductionAmount || 0, commonUtils.floatKeepDigit((sourceGoods.singleGoodsScoreDeductionAmount || 0) * returnCount)),
								// goodsFavourableDeductionAmount: Math.min(sourceGoods.favourableDeductionAmount || 0, commonUtils.floatKeepDigit((sourceGoods.singleGoodsFavourableDeductionAmount || 0) * returnCount)),
								// goodsDiscountDeductionAmount: Math.min(sourceGoods.discountDeductionAmount || 0, commonUtils.floatKeepDigit((sourceGoods.singleGoodsDiscountDeductionAmount || 0) * returnCount)),
								// goodsDeductionScore: commonUtils.floatKeepDigit(sourceGoods.singleGoodsDeductionScore * returnCount, 4),
								jackyunTradeId: sourceGoods.jackyunTradeId || 0,
								jackyunTradeNo: sourceGoods.jackyunTradeNo || "",
								shopId: sourceGoods.shopId || 0,
								shopName: sourceGoods.shopName || "",
								sourceSellCount: sourceGoods.sellCount,
							});
						}
					});
				});

				// 所有商品售价
				let allSellTotal = commonUtils.sumArr(sellbackGoodsList, "sellTotal");

				// 遍历退款货品，补充原始单的sourceSubTradeNo
				sellbackGoodsList.forEach(goods => {
					// 子单号补充
					let onlineGoodsInfo = tradeOrderGoodsRelateOnlineArr.find(t => t.subTradeId == goods.jkySubTradeId);
					goods.sourceSubOrderNo = onlineGoodsInfo ? onlineGoodsInfo.sourceSubtradeNo : "";
					goods.returnTotal = sellbackType == 1 ? goods.sellTotal : commonUtils.floatKeepDigit(goods.sellTotal / allSellTotal * this.refundLogistics.sellBackApplyAmount);
				});

				return sellbackGoodsList;
			},

			/**
			 * 生成退卡数据
			 * @param {*} sellbackGoodsList 
			 */
			_getReturnCardTicketInfoList(sellbackGoodsList) {
				// 返还卡券信息（用作自动审核）
				let returnCardTicketInfoList = [];
				sellbackGoodsList.forEach(goods => {
					// 添加卡券返还信息
					if (goods.couponCode) {
						// 获取已经存在的卡券返还信息
						let returnCardInfo = returnCardTicketInfoList.find(t => t.returnOriginalNo == goods.sourceOrderNo && t.ticketCode == goods.couponCode);

						// 不存在则新增，存在则累加
						if (!returnCardInfo) {
							returnCardTicketInfoList.push({
								tradeId: goods.jackyunTradeId.toString(), // 吉客云订单Id
								tradeNo: goods.jackyunTradeNo, // 吉客云订单编号
								returnOriginalNo: goods.sourceOrderNo, // 吉商城原始单号
								ticketCode: goods.couponCode, // 卡券编号
								sourceFrom: "jmall", // 请求来源
								ticketCodeList: [{
									ticketCode: goods.couponCode, // 卡券编号
									deductCost: goods.goodsCouponDeductionAmount, // 卡券返还金额
									originalDeductCost: goods.tradeCouponValue, // 原始单上的抵扣金额
									returnOrderGoodList: [{
										goodsId: goods.goodsId.toString(),
										specId: goods.specId.toString(),
										unit: goods.unit,
										sellCount: goods.sellCount,
										goodsName: goods.goodsName,
										specName: goods.specName,
									}]
								}],
								returnTradeOrder: {
									tradeId: goods.jackyunTradeId.toString(), // 吉客云订单Id
									tradeNo: goods.jackyunTradeNo, // 吉客云订单编号
									shopId: goods.shopId.toString(), // 下单渠道Id
									shopName: goods.shopName, // 下单渠道名称
								}
							});
						} else {
							returnCardInfo.ticketCodeList[0].deductCost = commonUtils.floatKeepDigit(returnCardInfo.ticketCodeList[0].deductCost + goods.goodsCouponDeductionAmount);
							returnCardInfo.ticketCodeList[0].returnOrderGoodList.push({
								goodsId: goods.goodsId.toString(),
								specId: goods.specId.toString(),
								unit: goods.unit,
								sellCount: goods.sellCount,
								goodsName: goods.goodsName,
								specName: goods.specName,
							});
						}
					}
				});
				return returnCardTicketInfoList;
			},


			/**
			 * 生成退卡数据New
			 * @param {*} sellbackGoodsList
			 * @param {*} omsTradeInfo
			 * @param {*} originalTradeGoodsList
			 * @param {*} originalTradeList
			 * @param {*} couponUseDetail
			 * @param {*} isPackage
			 */
			_getReturnCardTicketInfoListNew(sellbackGoodsList, omsTradeInfo, originalTradeGoodsList, originalTradeList, couponUseDetail, isPackage = false) {
				// 接口需要的卡券数据
				let returnCardTicketInfoList = [];

				// 循环需要SpecIdList数据
				let goodsSpecIdListOfCouponUseDetail = [...new Set(couponUseDetail.map(item => {
					return {
						specId: item.specId
					}
				}))];

				// 非组合装情况根据sellbackGoodsList进行循环
				let tempList = !isPackage ? sellbackGoodsList : goodsSpecIdListOfCouponUseDetail;

				tempList.forEach(goods => {
					if (!isPackage && !goods.couponCode) {
						return
					}
					if (isPackage) {
						// 是组合装的话需要补充数据
						goods = originalTradeGoodsList.find(originalGoods => originalGoods.specId == goods.specId);
						goods.jackyunTradeId = originalTradeList[0].jackyunTradeId.toString();
						goods.jackyunTradeNo = originalTradeList[0].jackyunTradeNo;
						goods.shopId = originalTradeList[0].shopId.toString();
						goods.shopName = originalTradeList[0].shopName;
						goods.sourceOrderNo = goods.tradeNo;
						goods.tradeCouponValue = couponUseDetail.reduce((accumulator, currentValue) => (accumulator || 0) / 1 + currentValue.deductionAmount / 1, 0);
					}

					let couponMatchList = couponUseDetail.filter(item => item.specId == goods.specId);

					couponMatchList.forEach(couponUseItem => {
						// 获取已经存在的卡券返还信息
						let returnCardInfo = returnCardTicketInfoList.find(t => t.returnOriginalNo == goods.sourceOrderNo && t.ticketCode == couponUseItem.ticketCode);

						// 不存在则新增，存在则累加
						if (!returnCardInfo) {
							returnCardTicketInfoList.push({
								tradeId: goods.jackyunTradeId.toString(), // 吉客云订单Id
								tradeNo: goods.jackyunTradeNo, // 吉客云订单编号
								returnOriginalNo: couponUseItem.tradeNo, // 吉商城原始单号
								ticketCode: couponUseItem.ticketCode, // 卡券编号
								sourceFrom: "jmall", // 请求来源
								ticketCodeList: [{
									ticketCode: couponUseItem.ticketCode, // 卡券编号
									deductCost: couponUseItem.deductionAmount * (goods.sellCount / (goods.sourceSellCount || goods.sellCount)), // 卡券返还金额(需要按数量比例计算抵扣金额)
									originalDeductCost: goods.tradeCouponValue, // 原始单上的抵扣金额
									returnOrderGoodList: [{
										goodsId: goods.goodsId.toString(),
										specId: goods.specId.toString(),
										unit: goods.unit,
										sellCount: goods.sellCount,
										goodsName: goods.goodsName,
										specName: goods.specName,
									}],
								}],
								returnTradeOrder: {
									tradeId: goods.jackyunTradeId.toString(), // 吉客云订单Id
									tradeNo: goods.jackyunTradeNo, // 吉客云订单编号
									shopId: goods.shopId.toString(), // 下单渠道Id
									shopName: goods.shopName, // 下单渠道名称
								}
							})
						} else {
							returnCardInfo.ticketCodeList[0].deductCost = floatKeepDigit(returnCardInfo.ticketCodeList[0].deductCost + couponUseItem.deductionAmount * (goods.sellCount / (goods.sourceSellCount || goods.sellCount)));
							returnCardInfo.ticketCodeList[0].returnOrderGoodList.push({
								goodsId: goods.goodsId.toString(),
								specId: goods.specId.toString(),
								unit: goods.unit,
								sellCount: goods.sellCount,
								goodsName: goods.goodsName,
								specName: goods.specName,
							});
						}
					})
				})
				return returnCardTicketInfoList;
			},

			/**
			 * 获取退款所需额外数据（金额合计、积分合计、卡券合计等）
			 * @param {*} originalTradeList 
			 * @param {*} sellbackGoodsList 
			 * @param {*} omsTradeInfo 
			 */
			_getSellbackExtendedInfo(originalTradeList, sellbackGoodsList, omsTradeInfo) {
				// 原单总金额
				let originalTotalAmount = 0;
				// 原订单积分抵扣金额所使用积分
				let originalScoreDeductionTotal = 0;
				// 支付方式
				let payTypeList = [];
				// 遍历原始单，生成原始单货品，并前置计算退货所需数据
				originalTradeList.forEach(originalTrade => {
					// 计算原单总金额
					originalTotalAmount += originalTrade.rcvTotal || 0;
					// 计算原订单积分抵扣金额所使用积分
					originalScoreDeductionTotal += originalTrade.scoreDeductionTotal || 0;
					// 记录支付方式
					if (originalTrade.combinePayData && originalTrade.combinePayData.length > 0) {
						payTypeList = payTypeList.concat(originalTrade.combinePayData.map(t => t.payType));
					} else {
						payTypeList.push(originalTrade.chargeType);
					}
				});

				// 卡券抵扣金额
				let couponDeductAmount = 0;
				// 应退总积分
				let shouldReturnScoreTotal = 0;
				// 商品总数量
				let allReturnGoodsCount = 0;
				// 应退总金额
				let shouldRefundAmount = 0;
				// 遍历退款货品，补充原始单的sourceSubTradeNo，并计算退款单金额相关数据
				sellbackGoodsList.forEach(goods => {
					// 金额计算
					couponDeductAmount += goods.goodsCouponDeductionAmount;
					shouldReturnScoreTotal += goods.scoreTotal;
					allReturnGoodsCount += goods.returnCount;
					shouldRefundAmount += goods.returnTotal;
				});

				// 卡券返还金额校正
				couponDeductAmount = commonUtils.floatKeepDigit(couponDeductAmount);
				if (originalTradeList.length == 1) {
					if (Math.abs(couponDeductAmount - originalTradeList[0].couponTotal) < 0.02) {
						couponDeductAmount = originalTradeList[0].couponTotal;
					}
				}
				// 退款申请金额校正
				shouldRefundAmount = commonUtils.floatKeepDigit(shouldRefundAmount);
				if (shouldRefundAmount > omsTradeInfo.tradeOrder.payment ||
					(shouldRefundAmount > 0.02 && Math.abs(shouldRefundAmount - omsTradeInfo.tradeOrder.payment) < 0.02)) {
					shouldRefundAmount = omsTradeInfo.tradeOrder.payment;
				}

				return {
					originalTotalAmount: commonUtils.floatKeepDigit(originalTotalAmount),
					payTypes: commonUtils.distinctArr(payTypeList).join(","),
					couponDeductAmount,
					shouldReturnScoreTotal: Math.round(shouldReturnScoreTotal),
					allReturnGoodsCount: Math.round(allReturnGoodsCount),
					shouldRefundAmount,
				}
			},

			/**
			 * 处理组装下单情况数据
			 * @param {*} omsTradeInfo 
			 * @param {*} originalTradeList 
			 * @param {*} originalTradeGoodsList 
			 * @param {*} returnCardTicketInfoList 
			 * @param {*} refundExtendedInfo 
			 * @param {*} isReturnAllGoods 
			 */
			async _packageGoodsRefundDataHandling(omsTradeInfo, originalTradeList, originalTradeGoodsList, returnCardTicketInfoList, refundExtendedInfo, isReturnAllGoods, couponUseDetail) {
				// 订单如果有过拆合情况，则不需要处理
				// if (omsTradeInfo.sourceTradeNo.includes(",") || originalTradeList.length != 1) {
				// 	return;
				// }
				// if (originalTradeList[0].jackyunTradeNo != omsTradeInfo.tradeNo) {
				// 	return;
				// }
				if (originalTradeList.length != 1) {
					return;
				}

				// 如果不包含组合装，则不需要处理（仅组合装拆成单品的情况有问题）
				// let goodsIds = originalTradeGoodsList.map(t => t.goodsId).join(",");
				// if (!await goodsUtils.checkIsIncludesPackageGoods(goodsIds)) {
				// 	return;
				// }

				// 检测商品是否全退，不全退则不需要处理
				if (!isReturnAllGoods) {
					return;
				}

				// 获取原始单对应的有效订单数量，如果只有一条，则退还卡券（20240702产品提缺陷，统一按照剩余唯一有效单处理，其他组合拆分、组合装等情况不考虑）
				let validTradeCount = await tradeApi.getOmsTradeCountBySourceTradeNos(omsTradeInfo.sourceTradeNo, ['1010', '1020', '1030', '1050', '2000', '2010', '2020', '2030', '2040', '3010', '4110', '4111', '4112', '4113', '4121', '4122', '4123', '4130', '4040', '4041', '4042', '4050', '4051', '4052', '6000', '9090']);
				if (validTradeCount > 1) {
					return;
				}

				// 重新生成卡券信息（未拆合且是组合装下单的，需要返还全部卡券）
				if (couponUseDetail.length > 0) {
					this.returnCardTicketInfoList = this._getReturnCardTicketInfoListNew([], omsTradeInfo, originalTradeGoodsList, originalTradeList, couponUseDetail, true)
				}

				// 处理退款积分等扩展信息
				refundExtendedInfo.couponDeductAmount = originalTradeList[0].couponTotal; // 卡券抵扣金额
				refundExtendedInfo.shouldReturnScoreTotal = originalTradeList[0].scoreTotal + originalTradeList[0].deductionScore; // 应退总积分
			},

			/**
			 * 显示退货选货弹窗
			 */
			async showReturnGoodsDialog() {
				// 检测退货时限
				if (!await this.checkCanRefund()) {
					this.$message('已超出退货时限');
					return;
				}

				// 显示“提交中”弹窗
				let loading = this.$loading({
					text: '加载中',
				});

				// 获取检测oms订单详情
				let omsTradeInfo = await this._getGetAndCheckOmsTradeInfo(2);

				// 未获取到订单信息则关闭退款弹窗（提示窗在检测中已有）
				if (!omsTradeInfo) {
					// 关闭“提交中”弹窗
					loading.close();
					return;
				}

				// 过滤掉当前订单已经申请了退款的货品数据
				let goodsList = omsTradeInfo.tradeOrderGoodsArr;
				goodsList = await this._filterRefundedGoods(goodsList);
				if (!goodsList || goodsList.length <= 0) {
					this.$message.error("所有商品都已经申请退货");
					// 关闭“提交中”弹窗
					loading.close();
					return;
				}

				// 处理商品数据
				goodsList.forEach((item, index) => {
					item.goodsIndex = index;
					try {
						let picInfo = JSON.parse(item.skuImgUrl);
						item.picUrl = picInfo.pic400x400;
					} catch (e) {
						item.picUrl = "";
					}
				});

				// 渲染可退货商品列表
				this.canReturnGoodsList = goodsList;

				// 显示选货弹窗
				loading.close();
				this.isShowReturnGoodsDialog = true;
			},

			/**
			 * 过滤掉当前订单已经申请了退款的货品数据
			 * @param {*} goodsList 
			 */
			async _filterRefundedGoods(goodsList) {
				// 获取当前订单已经申请退款的商品列表
				let refundGoodsList = await refundApi.getRefundedGoodsList(this.tradeNo);

				goodsList.forEach(goods => {
					// 获取已退商品
					let refundGoods = refundGoodsList.find(t => t.goodsId == goods.goodsId && t.specId == goods.skuId && t.unit == goods.unit);
					// 初始化最大可退数量以及选中状态等数据
					if (refundGoods && refundGoods.sellCount > 0) {
						// 已退货数量大于当前商品数量，则当前商品的已退数量就等于销售数量，已退货品数也做响应扣减（合并订单会有同品多行情况）
						if (refundGoods.sellCount > goods.sellCount) {
							goods.refundedCount = goods.sellCount;
							refundGoods.sellCount -= goods.sellCount;
						} else {
							goods.refundedCount = refundGoods.sellCount;
							refundGoods.sellCount = 0;
						}
					} else {
						goods.refundedCount = 0;
					}
					goods.maxReturnCount = goods.sellCount - goods.refundedCount;
					goods.returnCount = goods.maxReturnCount;
					goods.isSelected = false;
				});

				// 过滤掉不可退货的商品
				goodsList = goodsList.filter(t => t.maxReturnCount > 0);
				return goodsList;
			},

			/**
			 * 退货数量变更
			 * @param {Object} index
			 * @param {Object} value
			 */
			changeReturnCount(index, value) {
				// 修改退货数量
				this.canReturnGoodsList[index].returnCount = value;
				// 计算总数量
				this._calcSelectedGoodsCount();
			},

			/**
			 * 选中单个退货商品
			 * @param {Object} selectedGoodsList
			 */
			selectReturnGoods(selectedGoodsList) {
				// 同步变更"全选"状态
				this.isSelectAllReturnGoods = selectedGoodsList.length >= this.canReturnGoodsList.length;

				// 记录选中数据
				let selectedIndexList = selectedGoodsList.map(t => t.goodsIndex);

				// 将可选商品列表中的商品置为选中
				this.canReturnGoodsList.forEach(t => {
					t.isSelected = selectedIndexList.includes(t.goodsIndex);
				});

				// 生成选中的退货商品列表
				this.selectedReturnGoodsList = this.canReturnGoodsList.filter(t => t.isSelected);

				// 计算总数量
				this._calcSelectedGoodsCount();
			},

			/**
			 * 计算选中货品中数量
			 */
			_calcSelectedGoodsCount() {
				let sumCount = 0;
				this.canReturnGoodsList.filter(t => t.isSelected).forEach(t => {
					if (t.isSelected) {
						sumCount += parseInt(t.returnCount);
					}
				});

				this.sumCount = sumCount;
			},

			/**
			 * 退货商品全选
			 */
			selectAllReturnGoods() {
				// 所有商品都选中
				this.canReturnGoodsList.forEach(t => {
					t.isSelected = this.isSelectAllReturnGoods;
				});

				// 生成选中的退货商品列表
				this.$refs.selectedReturnGoodsList.toggleAllSelection();
			},

			/**
			 * 校验是否能够退款
			 */
			async checkCanRefund() {
				try {
					// 未开启拦截则不限制
					if (!this.config.isOpenCannotReturnTime) {
						return true;
					}

					// 完成时间（没有完成时间的不做限制）
					let arriveTime = (this.tradeDetail && this.tradeDetail.arriveTime) || "";
					if (!arriveTime) {
						return true;
					}

					let arriveTimeStamp = new Date(arriveTime).getTime();
					let delayTime = this.config.cannotReturnDays * 60 * 24 * 60 * 1000;

					// 异常数据判断，异常数据统一不做限制
					if (isNaN(delayTime) || !(arriveTimeStamp > 0)) {
						return true;
					}

					let sysTime = await system.getSysTime();
					console.log(sysTime, "sysTime")
					return sysTime < arriveTimeStamp + delayTime;
				} catch (e) {
					return true;
				}
			},

			/**
			 * 展示退货主弹窗
			 */
			showReturnMainDialog() {
				// 获取选中的退款商品，并检测是存在数据
				this.selectedReturnGoodsList = this.canReturnGoodsList.filter(t => t.isSelected);
				if (!this.selectedReturnGoodsList || this.selectedReturnGoodsList.length <= 0) {
					this.$message.warning('请选择退货商品');
					return;
				}

				// 关闭选货弹窗，显示退货主弹窗
				this.isShowReturnGoodsDialog = false;
				this.isShowReturnMainDialog = true;

				// 加载物流方式
				this.getLogisticsWays();

				// 计算最大可退额度
				let maxReturnAmount = 0;
				this.selectedReturnGoodsList.forEach(t => {
					maxReturnAmount += t.sellPrice * t.returnCount;
				});

				// 渲染页面数据
				this.maxReturnAmount = commonUtils.floatKeepDigit(maxReturnAmount);
				this.returnPlaceHolder = `最大可退金额：${this.maxReturnAmount.toFixed(2)}`;
				this.refundLogistics.sellBackApplyAmount = this.maxReturnAmount.toFixed(2);
			},

			// 退货物流表单确认
			returnBtn: _.debounce(function() {
				// 参数校验
				if (!this.refundLogistics.sellBackApplyAmount && this.refundLogistics.sellBackApplyAmount != 0) {
					this.$message.warning("请输入退款金额");
					return;
				}
				if (isNaN(this.refundLogistics.sellBackApplyAmount)) {
					this.$message.warning("请输入正确的退款金额");
					return;
				}
				if (this.refundLogistics.sellBackApplyAmount > 0 && this.refundLogistics.sellBackApplyAmount > this.maxReturnAmount) {
					toast("最大可退金额为：" + this.maxReturnAmount.toFixed(2));
					return;
				}
				if (!this.refundLogistics.rcv_post_id && this.refundGoodsRules.postId[0].required) {
					this.$message.warning("请输入物流单号");
					return;
				}

				let loading;
				this.$confirm('确定退货?', '提示').then(async () => {
					// 显示“提交中”弹窗
					loading = this.$loading({
						text: '提交中',
					});

					// 获取检测oms订单详情
					let omsTradeInfo = await this._getGetAndCheckOmsTradeInfo(2);
					console.log(omsTradeInfo);
					// 未获取到订单信息则关闭退款弹窗（提示窗在检测中已有）
					if (!omsTradeInfo) {
						// 关闭退款弹窗
						this.isShowReturnMainDialog = false;
						// 关闭“提交中”弹窗支付宝
						loading.close();
						return;
					}

					// 获取原始单列表
					let originalTradeList = await this._getOriginalTradeList(omsTradeInfo.sourceTradeNo);

					// 生成原始单货品列表（多个订单的货品合并到一起，并附加一些订单信息到商品上）
					let originalTradeGoodsList = this._getOriginalTradeGoodsList(originalTradeList);

					// 生成退款商品列表
					let sellbackGoodsList = this._getSellBackGoodsList(this.selectedReturnGoodsList, originalTradeGoodsList, omsTradeInfo.tradeOrderGoodsRelateOnlineArr, 2);

					// 获取卡券使用明细
					let couponUseDetail = await tradeApi.getCouponUseDetail(omsTradeInfo.sourceTradeNo.split(","));

					// 生成卡券返还信息（用作自动审核）
					this.returnCardTicketInfoList = this._getReturnCardTicketInfoListNew(sellbackGoodsList, omsTradeInfo, originalTradeGoodsList, originalTradeList, couponUseDetail);

					// 获取退款所需额外数据（金额合计、积分合计、卡券合计等）
					let extendedInfo = this._getSellbackExtendedInfo(originalTradeList, sellbackGoodsList, omsTradeInfo);

					// 组合装拆成单件退款情况兼容
					await this._packageGoodsRefundDataHandling(omsTradeInfo, originalTradeList, originalTradeGoodsList, this.returnCardTicketInfoList, extendedInfo, this.isSelectAllReturnGoods, couponUseDetail);

					// 获取客户信息
					let customerInfo = await getCustomerInfo()

					// 退款单信息
					let sellBackInfo = {
						sellbackType: 2, // 退货类型（1退款 2退货）
						tradeNo: omsTradeInfo.sourceTradeNo, // 原关联订单号（多个）
						jackyunTradeId: omsTradeInfo.tradeId, // 吉客云订单ID
						jackyunTradeNo: omsTradeInfo.tradeNo, // 吉客云订单号
						customerId: customerInfo.customerId, // 客户id
						originalTotalAmount: extendedInfo.originalTotalAmount, // 原单总金额
						couponDeductAmount: extendedInfo.couponDeductAmount, // 卡券抵扣金额
						// shouldRefundAmount: extendedInfo.shouldRefundAmount, // 应退金额
						shouldRefundAmount: this.refundLogistics.sellBackApplyAmount, // 应退金额
						shouldReturnScoreTotal: extendedInfo.shouldReturnScoreTotal, // 应退总积分
						sellbackCount: extendedInfo.allReturnGoodsCount, // 退货总数量
						shopId: omsTradeInfo.tradeOrder.shopId, // 渠道ID（吉客云订单）
						shopName: omsTradeInfo.tradeOrder.shopName, // 渠道名称（吉客云订单）
						accountId: originalTradeList[0].accountId, // 原单下单结算账户
						chargeType: omsTradeInfo.tradeOrder.chargeTypeExplain, // 结算方式（无效字段）
						currencyCode: omsTradeInfo.tradeOrder.chargeCurrencyCode, // 币种符号
						currencyName: omsTradeInfo.tradeOrder.chargeCurrency, // 币种名称
						payType: extendedInfo.payTypes, // 支付方式
						sellerId: omsTradeInfo.tradeOrder.sellerId, // 业务员Id
						sellerName: omsTradeInfo.tradeOrder.seller, // 业务员名称
						logisticId: this.refundLogistics.logisticId, // 物流公司Id
						logisticName: this.refundLogistics.logisticName, // 物流公司名称
						rcvPostId: this.refundLogistics.rcv_post_id, // 物流单号
						reason: this.refundLogistics.reason, // 退换原因
						remark: this.refundLogistics.remark, // 退款备注
						sellBackGoodsList: sellbackGoodsList, // 退款商品信息
						returnCardTicketInfoList: this.returnCardTicketInfoList, // 返还卡券信息
						// 记录原始单信息，无实际作用，仅后台展示
						tradeNick: omsTradeInfo.tradeOrderCustomer.customerName,
						sendTo: omsTradeInfo.tradeOrderReceiver.receiverName,
						tel: omsTradeInfo.tradeOrderReceiver.mobile,
						country: omsTradeInfo.tradeOrderReceiver.country,
						province: omsTradeInfo.tradeOrderReceiver.state,
						city: omsTradeInfo.tradeOrderReceiver.city,
						town: omsTradeInfo.tradeOrderReceiver.district,
						address: omsTradeInfo.tradeOrderReceiver.address.replace('  ', ' '),
					};

					// 创建退款单（提示功能在方法内部完成）
					let res = await refundApi.createSellbackOrder(sellBackInfo);
					if (!res) {
						return;
					}

					// 订单退款成功，返回订单列表
					this.__gotoTradeListPage();

					// 关闭退款弹窗
					this.isShowReturnMainDialog = false;
					// 关闭“提交中”弹窗
					loading.close();
				}).catch((e) => {
					this.$message.error("退货申请创建异常");
					// 关闭“提交中”弹窗
					loading.close();
					console.log(e);
				});
			}),

		},
	}
</script>

<style scoped="scoped">
	.right-box {
		background-color: #fff;
		padding: 10px 30px 30px 30px !important;
		font-size: 18px;
	}

	.dialogClass .el-dialog__body {
		padding: 20px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
		margin-left: 20px !important;
	}

	.back-box {
		align-items: center;
		margin-left: -20px;
		height: 80px;
	}

	.back-btn {
		font-size: 42px;
		margin-right: 15px;
	}

	.status-box {
		height: 40px;
		background-color: #000;
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 24px 20px 24px 30px;
	}

	.trade-status {
		font-weight: bold;
		font-size: 21px;
	}

	.info-box {
		margin: 20px;
		border-bottom: 1px solid #dedede;
		font-size: 16px;
		min-height: 130px;
	}

	.small-title {
		font-size: 16px;
		font-weight: 800;
	}

	.base-info {
		margin: 10px;
		line-height: 30px;
	}

	.base-item {
		color: #999;
		width: 80px;
		margin-right: 10px;
		display: inline-block;
		text-align: right;
		flex-shrink: 0;
	}

	.copy-btn {
		padding: 2px 10px;
		font-size: 13px;
		background-color: #F2F2F2;
		margin-left: 10px;
		color: #666;
		cursor: pointer;
	}

	.total-box {
		display: flex;
		justify-content: flex-end;
		border: none !important;
	}

	.total-box /deep/.el-form-item__label,
	.total-box /deep/.el-form-item__content {
		font-size: 17px !important;
		color: #999 !important;
	}

	.total-box /deep/.el-form-item {
		margin-bottom: 0px !important;
	}

	.total {
		color: #000;
		font-size: 18px;
		font-weight: bold;
	}

	.money-text {
		color: #333;
		text-align: right;
		margin-left: 50px;
		line-height: 40px;
	}

	.score-text {
		position: relative;
		bottom: 1px;
		left: 5px;
		font-size: 15px;
	}

	.handle-box {
		margin-bottom: 60px;
		justify-content: flex-end;
	}

	.cancel-btn {
		color: #333;
		background-color: #F2F2F2;
	}

	.handle-btn {
		width: 140px;
		height: 60px;
		font-size: 16px;
		border: none;
		margin-left: 25px !important;
	}

	.log-btn {
		border: 1px solid #fff;
		border-radius: 6px;
		padding: 2px 6px;
		font-size: 15px;
		cursor: pointer;
		width: 100px;
		text-align: center;
	}

	.simple-info {
		margin-right: 20px;
		font-size: 16px;
		align-items: center;
		/* white-space: nowrap; */
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
		max-width: 500px;
	}

	::v-deep .el-dialog .el-dialog__body {
		display: flex;
		align-items: center;
		margin: 0px !important;
	}

	.number {
		margin-top: 40px;
	}


	.info_footer {
		display: flex;
		display: -webkit-flex;
		line-height: 50px;
	}

	.allCheck {
		margin-left: 14.5px;
	}

	.span_footer {
		margin: 0 50px 0 50px;
	}

	.custom-top {
		height: 100rpx;
		font-size: 100rpx;
		text-align: center;
	}

	.tradeId-top {
		font-size: 80rpx;
		height: 100rpx;
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.refund-button-div {
		text-align: right;
	}

	.el-select {
		width: 300px;
	}

	.el-select-dropdown__item {
		width: 200px !important;
	}

	.detailDialog /deep/ .el-dialog {
		overflow: hidden;
	}

	.dialogBox /deep/ .el-dialog__wrapper {
		overflow: hidden !important;
	}

	.detailDialog /deep/ .el-dialog__body {
		width: 100%;
		padding-right: 0 !important;
		padding-top: 0 !important;
		padding-left: 0 !important;
		overflow-y: auto !important;
	}

	.el-form-item {
		margin-bottom: 8px;
	}

	/deep/ .el-form-item__content {
		line-height: 28px;
	}

	/deep/ .return-goods-box {
		max-height: 500px;
		overflow-y: auto;
	}
</style>