<!-- 登录弹窗 -->
<template>
	<div>
		<!-- 登录表单 -->
		<el-dialog width="450px" :modal-append-to-body='false' :close-on-click-modal="false" :destroy-on-close="true" :show-close="true" :visible.sync="isShowDialog">
			<div class="title-text" v-if="isNeedTitle">提示</div>
			<!-- 顶部文字 -->
			<div class='check-title-text-novalid' v-if="isNoValidGoods">
				{{noValidGoodsTip}}
			</div>
			<div class='check-title-text' v-else>
				{{hasValidGoodsTip}}
			</div>
			<!-- 商品列表 -->
			<div class='check-goods-list' v-if="goodsList.length>0 && !isNoValidGoods">
				<div class='check-goods-item' style="display: flex;" v-for="(item,index) in goodsList" :key="index">
					<div class='check-goods-img'>
						<img :src="$utils.set400Img(item.imgUrl ||item.picUrl||item.coverPic)  " />
					</div>

					<!-- 多规格单位库存超卖弹窗 -->
					<div class='check-goods-info flex-alignCenter' v-if="isMultiUnitOverStock">
						<div class='check-goods-name'>{{item.goodsName}}</div>
						<div>
							<div class=' check-goods-spec'>{{item.specName}}</div>
							<div class='check-goods-spec'>单位：{{item.unitName}}　　超出数量：{{item.overStock}}</div>
						</div>
					</div>

					<!-- 其他模式弹窗 -->
					<div class='check-goods-info flex-alignCenter' v-else>
						<div class='check-goods-name'>{{item.goodsName}}</div>
						<div v-if="modalStyle == 'shopcart' ">
							<div class='check-goods-spec space-between'>
								<div>{{item.specName}}</div>
								<div v-show="!isBlockUpGoodsCheck">库存：{{item.stock}}</div>
							</div>
						</div>
						<div v-else>
							<div class=' check-goods-spec'>{{item.specName}}</div>
							<div class='check-goods-spec' v-show="!isBlockUpGoodsCheck">单位：{{item.unitName}}　　超出数量：{{item.overStock || item.goodsCount}}</div>
						</div>
					</div>

				</div>
			</div>
			<!-- 多规格单位库存超卖底部按钮 -->
			<div class='btn-box'  v-if="isMultiUnitOverStock">
				<el-button @click.stop="cancel" v-if="!isMultiUnitOverStockInPay">返回</el-button>
				<el-button @click.stop="cancel" v-else>回到购物车</el-button>
			</div>
			<!-- 其他模式底部按钮 -->
			<div class='btn-box' v-else>
				<el-button @click.stop="confirm" v-if="!isNoValidGoods">{{confirmText}}</el-button>
				<el-button @click.stop="closeDialog" v-if="isShowCancelBtn&&!isNoValidGoods">{{cancelText}}</el-button>
				<el-button @click.stop="goHome" v-else>回到首页</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'check-goods-dialog',
		components: {},
		props: {
			// 是否展示弹窗
			isShowDialog: {
				type: Boolean,
				default: false
			},
			// 待展示商品列表
			goodsList: {
				type: Array,
				default: function() {
					return [];
				}
			},
			// 是否是下架商品检测
			isBlockUpGoodsCheck: {
				type: Boolean,
				default: false
			},
			// 是否展示取消按钮
			isShowCancelBtn: {
				type: Boolean,
				default: true
			},
			// 是否没有有效商品
			isNoValidGoods: {
				type: Boolean,
				default: false
			},
			isNeedTitle: {
				type: Boolean,
				default: false
			},
			noValidGoodsTip: {
				type: String,
				default: "库存已发生变动，本订单不存在可购买的商品，请重新下单"
			},
			hasValidGoodsTip: {
				type: String,
				default: "库存已发生变动，继续下单将会移除库存不足的商品，确定继续下单？"
			},
			modalStyle: {
				type: String,
				default: ""
			},
			confirmText: {
				type: String,
				default: "移除无货商品"
			},
			cancelText: {
				type: String,
				default: "关闭"
			},
			// 是否是多规格单位库存超卖弹窗
			isMultiUnitOverStock: {
				type: Boolean,
				default: false
			},
			//是否是收银台打开的多规格单位库存超卖弹窗
			isMultiUnitOverStockInPay: {
				type: Boolean,
				value: false
			},
		},
		data() {
			return {
				// isShowDialog: this.isShow, // 是否展示弹窗（避免直接修改props）
			}
		},
		mounted() {
			// //监听登录弹窗是否显示,是否可关闭
			// this.$eventBus.$on("showLoginDialog", (showLogin, showClose) => {
			// 	this.loginTel = "";
			// 	this.loginCode = "";
			// 	clearInterval(interval);
			// 	this.vcodeBtnTxt = "获取验证码";
			// 	this.vcodeIntervalTime = 60;
			// })
		},
		methods: {
			/**
			 * 关闭弹窗
			 */
			closeDialog() {
				if (this.isBlockUpGoodsCheck) {
					return this.goHome()
				}
				// 关闭弹窗
				this.$emit('update:isShowDialog', false);
				this.$emit('cancel');
			},

			/**
			 * 确认回调
			 */
			confirm() {
				// 调用父级确认方法
				this.$emit('confirm');
			},
			
			/**
			 * 多规格单位库存按钮回调
			 */
			cancel() {
				// 调用父级确认方法
				this.$emit('cancel');
			},

			/**
			 * 跳转到首页
			 */
			goHome() {
				// 跳转到首页
				this.$router.push({
					name: 'home'
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.check-title-text {
		font-size: 17px;
		margin-top: -8px;
		margin-bottom: 8px;
		color: #999;
	}

	.check-title-text-novalid {
		font-size: 17px;
		margin-top: 20px;
		margin-bottom: 50px;
		text-align: center;
		color: #999;
	}

	.check-goods-list {
		max-height: 500px;
		overflow: auto;
	}

	.check-goods-item {
		padding: 10px 2px 10px 6px;
	}

	.check-goods-name {
		line-height: 16px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: #555;
		margin: 5px 0;
	}

	.check-goods-img {
		display: inline-block;
		width: 80px;
		height: 80px;
	}

	.check-goods-img img {
		width: 100%;
		height: 100%;
		vertical-align: top;
		border-radius: 3px;
	}

	.check-goods-info {
		display: inline-block;
		width: calc(100% - 94px);
		vertical-align: top;
		padding-left: 10px;
		word-break: break-all;
	}

	.check-goods-spec {
		color: #999;
		font-size: 13px;
		line-height: 18px;
	}

	.btn-box {
		text-align: center;
		margin-top: 12px;
		margin-bottom: -8px;
	}

	.btn-box button {
		margin: 0 20px;
	}

	.no-goods-msg {
		margin-top: 10px;
	}

	.title-text {
		text-align: center;
		font-size: 20px;
		padding-bottom: 22px;
	}

	/deep/ .el-dialog__header {
		padding: 0 20px 10px;
	}

	/deep/ .el-dialog__body {
		width: 380px;
		margin: auto;
	}

	.flex-alignCenter {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.space-between {
		display: flex;
		justify-content: space-between;
	}
</style>