<template>
	<div class="address-edit" @click="analysisFocus = false">
		<el-dialog :title="title" :visible.sync="isShow" v-if="isCreate" :before-close="onClose" :close-on-click-modal="false" center width="630px" :append-to-body="true">
			<el-form ref="addrobj" :model="addrobj" @submit.native.prevent>
				<el-form-item>
					<div @click.stop="analysisFocus = true" class="analysis-row" :class="{'analysis-focus': analysisFocus}">
						<label>智能填写</label>
						<el-input type="textarea" class="analysis-address" v-model="analysisAddress" autosize placeholder="粘贴地址信息，可自动识别如：王XX，188*********，浙江省杭州市xx区xx街道xxxx"></el-input>
						<div class="analysis-btn" v-show="analysisFocus">
							<el-button @click.stop="analysisAddress = ''" size="small" plain>清 空</el-button>
							<el-button type="danger" @click="confirmAnalysis" size="small" plain>解 析</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<el-row :gutter="20">
						<el-col :span="12">
							<el-input v-model="addrobj.name" autocomplete="off" placeholder="请输入姓名" maxlength="50" show-word-limit @input="inputChange">
							</el-input>
						</el-col>
						<el-col :span="12">
							<el-input v-model="addrobj.tel" autocomplete="off" placeholder="请输入电话" @input="inputChange">
							</el-input>
						</el-col>
					</el-row>
				</el-form-item>
				<el-form-item class='adr-sel-form'>
					<el-cascader v-if='!isForeignAdr' style='width: 100%;' id="addrselect" class='cascaderText' size="large" :options="options" v-model="selectedOptions" @change="handleChange" placeholder="请选择 省 / 市 / 区">
					</el-cascader>
					<el-cascader v-else size="large" :options="countryList" v-model="selectedCountry" placeholder="请选择 国家">
					</el-cascader>
					<div class='adr-type-btn' @click="changeAdrType">{{isForeignAdr?'国内地址':'国外地址'}}</div>
				</el-form-item>
				<el-form-item>
					<el-input v-model="addrobj.address" autocomplete="off" placeholder="请输入详细地址" maxlength="250" show-word-limit @input="inputChange"></el-input>
				</el-form-item>
				<el-form-item>
					<el-checkbox v-model="isDefault" @change="checkboxChange">默认地址</el-checkbox>
				</el-form-item>
				<div v-if="isMustIDCard">
					<el-form-item>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-select v-model="addrobj.idCardType" placeholder="请选择">
									<el-option v-for='(item,index) in ["身份证", "学生证", "军官证", "护照", "港澳通行证"]' :key="item" :label="item" :value="item" @change="handleCardtypeChange">
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="12">
								<el-input v-model="addrobj.idCardName" autocomplete="off" placeholder="请输入证件姓名" @input="inputChange">
								</el-input>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item>
						<el-input v-model="addrobj.idCard" autocomplete="off" placeholder="请输入证件编号" maxlength="250" @input="inputChange"></el-input>
					</el-form-item>
				</div>
				<div v-if="isMustIDCard" class="flex-row" style="justify-content: space-around;">
					<div>
						<el-upload accept=".jpg,.jpeg,.png" :action="config.uploadFileUrl" :show-file-list="false" :data="frontImgUploadData" :on-preview="_handlePictureCardPreview" :on-success="_handleFrondSuccess">
							<el-image v-if="frontSideImageUrl" :src="frontSideImageUrl" class="upload-img">
								<div slot="error" class="image-slot" style="margin-top: 35px">
									<i class="el-icon-camera gray"></i>
									<div class="gray img-text">证件正面</div>
								</div>
							</el-image>
							<div v-else>
								<i class="el-icon-camera gray"></i>
								<div class="gray img-text">证件正面</div>
							</div>
						</el-upload>
						<div class="upload-text">
							选填
						</div>
					</div>

					<div>
						<el-upload accept=".jpg,.jpeg,.png" :action="config.uploadFileUrl" :data="endImgUploadData" :show-file-list="false" :on-preview="_handlePictureCardPreview" :on-success="_handleReverseSuccess">
							<el-image v-if="reverseSideImageUrl" :src="reverseSideImageUrl" class="upload-img">
								<div slot="error" class="image-slot" style="margin-top: 35px">
									<i class="el-icon-camera gray"></i>
									<div class="gray img-text">证件反面</div>
								</div>
							</el-image>
							<div v-else>
								<i class="el-icon-camera gray"></i>
								<div class="gray img-text">证件反面</div>
							</div>
						</el-upload>
						<div class="upload-text">
							选填
						</div>
					</div>
				</div>


			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="onClose">取 消</el-button>
				<el-button type="danger" @click="onSave">保 存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { config } from '../../config.js'
	import adressUtils from '../../utils/api-utils/api-address.js'
	import { getParam } from '../../utils/api-utils/api-params.js'
	import addressUtils from '../../utils/api-utils/api-address.js'

	// 身份证号验证正则
	const regIdNo = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
	// 身份证姓名正则
	const regInputName = /[\u4E00-\u9FA5]{1,10}(·[\u4E00-\u9FA5]{2,10}){0,2}/;
	const regSaveName = /[\u4E00-\u9FA5]{2,10}(·[\u4E00-\u9FA5]{2,10}){0,2}/;
	const specialfilter = /((?!女士|先生|男士).)*/;

	import {
		// regionData,
		// provinceAn,
		regionDataPlus,
		dCityDataPlus,
		CodeToText,
		TextToCode
	} from 'element-china-area-data'
	export default {
		name: "address-edit",
		props: {
			title: {
				type: String,
				default: ''
			},
			addressinfo: {
				type: Object,
				default: function() {
					return {};
				}
			},
			isCreate: {
				type: Boolean,
				default: false
			},
			isShow: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			//监听父组件传值
			addressinfo(newValue, oldValue) {
				// 新增情况，文本框输入会异常监听，排除异常监听
				let keys = Object.keys(newValue);
				if (keys.length > 0 && !keys.includes('addressId')) {
					return;
				}

				// 初始化地址对象
				this.addrobj = newValue;

				// 渲染地址编辑数据
				if (this.addrobj.addressId) {
					this._getRegionCode();
					this.isDefault = this.addrobj.isDefault;

					// 检测是否为海外地址
					if (this.addrobj.country && this.addrobj.country != '中国') {
						this.isForeignAdr = true;
						this.selectedCountry = [this.addrobj.country];
					} else {
						// 回显解析得到的地址
						this._showAnalysisAdr(this.addrobj.province, this.addrobj.city, this.addrobj.district);
					}
				} else {
					this.selectedOptions = [];
					this.isDefault = false;
					this.isForeignAdr = false;
					this.selectedCountry = [];
				}

				if (!this.addrobj.idCardType) {
					this.addrobj.idCardType = "身份证"
				}

				// 时间戳
				let d = new Date().getTime();
				// 正面图判断
				if (this.addrobj.idCard) {
					this.frontSideImageUrl = `https://jkyun--jmall.oss-cn-hangzhou.aliyuncs.com/jmc/jmallFile/${this.userName}/idCardImg/${this.addrobj.idCard}-0.png?v=${d}`;
					this.reverseSideImageUrl = `https://jkyun--jmall.oss-cn-hangzhou.aliyuncs.com/jmc/jmallFile/${this.userName}/idCardImg/${this.addrobj.idCard}-1.png?v=${d}`;
				} else {
					this.frontSideImageUrl = "";
					this.reverseSideImageUrl = "";
				}

				this.analysisAddress = '';
			},

			/**
			 * 地址选择监听
			 * @param {Object} newValue
			 * @param {Object} oldValue
			 */
			selectedOptions(newValue, oldValue) {
				let province = CodeToText[newValue[0]];
				let city = CodeToText[newValue[1]];
				let district = CodeToText[newValue[2]];
				city = this._getCityText(province, city);

				this.addrobj.province = province;
				this.addrobj.city = city;
				this.addrobj.district = district;
			},
		},
		data() {
			return {
				addrobj: this.addressinfo,
				options: regionDataPlus,
				selectedOptions: [],
				isDefault: false,
				analysisAddress: '', //待解析地址
				analysisFocus: false, //解析地址获得焦点
				isForeignAdr: false, // 是否展示国外地址
				countryList: [],
				selectedCountry: [], // 当前国家
				config: config, //获取url参数
				reverseSideImageUrl: "", // 证件正面url
				frontSideImageUrl: "", // 证件反面url
				isMustIDCard: false, // 是否必传身份证件
			}
		},
		mounted() {
			// 加载国家列表
			this._loadCountryList();
			this._getParam();
		},
		computed: {
			userName() {
				return localStorage.getItem('SubMemberName') || localStorage.getItem('JackyunUserName')
			},
			dateTime() {
				return new Date().getTime();
			},
			frontImgUploadData() {
				return { userName: this.userName, fileClass: 'idCardImg', fileName: this.addrobj.idCard + "-0.png" }
			},
			endImgUploadData() {
				return { userName: this.userName, fileClass: 'idCardImg', fileName: this.addrobj.idCard + "-1.png" }
			}
		},
		methods: {
			onClose() {
				this.$emit('close');
			},
			/**
			 * 地址选择切换
			 * @param {Object} value
			 */
			handleChange(value) {
				this.selectedOptions = value;
			},
			/**
			 * 证件选择切换
			 * @param {Object} value
			 */
			handleCardtypeChange(value) {
				this.addrobj.cardtpye = value;
			},
			inputChange() {
				//解决因输入框输入导致区域选择值消失的问题 		
				let tmpStr = "";
				if (this.selectedOptions.length > 0) {
					tmpStr = this.selectedOptions.join(',');
				}
				setTimeout(() => {
					if (tmpStr) {
						this.selectedOptions = tmpStr.split(',');
					}
				}, 80);
			},

			checkboxChange(value) {
				this.isDefault = value;
			},
			async onSave() {
				if (!this._checkData()) {
					return;
				}

				// 计算当前地址的经纬度
				let receiverLocation = await this._getLocationByAddress();

				let country = '中国';
				// 海外地址需要清空省市区
				if (this.isForeignAdr) {
					country = this.selectedCountry[0];
					this.addrobj.province = '';
					this.addrobj.city = '';
					this.addrobj.district = '';
				}

				this.customerId = localStorage.getItem("pc_customerId");
				let obj = {
					customerId: this.customerId,
					addressId: this.addrobj.addressId,
					name: this.addrobj.name,
					tel: this.addrobj.tel,
					country: country,
					province: this.addrobj.province,
					city: this.addrobj.city,
					district: this.addrobj.district,
					address: this.addrobj.address,
					isDefault: this.isDefault ? 1 : 0,
					latitude: receiverLocation.latitude,
					longitude: receiverLocation.longitude,
					idCardType: this.addrobj.idCardType,
					idCardName: this.addrobj.idCardName,
					idCard: this.addrobj.idCard,
				};

				this.$request.jackyunPost({
					method: "jmall.address.setaddressinfo",
					jsonStr: JSON.stringify(obj)
				}, (res) => {
					if (res.code == 200) {
						this.$message({
							message: "保存成功",
							type: 'success'
						});
						// 补充地址Id
						if (!obj.addressId) {
							obj.addressId = res.result.data;
						}
						this.$emit('success', obj);
					} else {
						this.$message.error('地址保存失败');
					}
				});
			},

			//校验数据
			_checkData() {
				//关闭所有警告
				this.$message.closeAll();
				if (!this.addrobj.name) {
					this.$message.error("姓名不能为空");
					return false;
				}
				if (!this.addrobj.tel) {
					this.$message.error("电话不能为空");
					return false;
				}

				if (!this.isForeignAdr) {
					// let reg1 = /^1[0-9][0-9]{9}$/; //11位手机号
					// let reg2 = /^0\d{2,3}-?\d{7,8}$/; //座机号码
					// if (!(reg1.test(this.addrobj.tel) || reg2.test(this.addrobj.tel))) {
					// 	this.$message.error("电话格式不正确");
					// 	return false;
					// }

					if (!this.addrobj.province || this.addrobj.province == '全部') {
						this.$message.error("请选择省");
						return false;
					}
					if (!this.addrobj.city || this.addrobj.city == '全部') {
						this.$message.error("请选择市");
						return false;
					}
					if (!this.addrobj.district || this.addrobj.district == '全部') {
						this.$message.error("请选择区县");
						return false;
					}
				} else {
					if (this.selectedCountry.length == 0) {
						this.$message.error("国家不能为空");
						return false;
					}
				}
				if (!this.addrobj.address) {
					this.$message.error("详细地址不能为空");
					return false;
				}

				// 是否开启证件号必填检测
				if (this.isMustIDCard) {
					// 证件名称检测
					if (!this.addrobj.idCardName) {
						this.$message.error("请输入证件姓名");
						return false;
					}

					if (!regSaveName.test(this.addrobj.idCardName)) {
						this.$message.error("请检查证件姓名");
						return false;
					}
					// 证件号检测
					if (!this.addrobj.idCard) {
						this.$message.error("请先输入证件编号");
						return false;
					}

					// 证件格式检测
					if (this.addrobj.idCardType == '身份证' && !regIdNo.test(this.addrobj.idCard)) {
						this.$message.error("身份证号码格式不正确");
						return false;
					}
				}
				return true;
			},

			//读取配置参数
			async _getParam() {
				let paramList = await getParam("isMustIDCard");
				this.isMustIDCard = paramList.isMustIDCard == "1";
			},

			/**
			 * 获取经纬度
			 */
			async _getLocationByAddress() {
				// 获取收件地址完整地址
				let fullAddress = "";
				if (this.isForeignAdr) {
					fullAddress = this.selectedCountry[0] + this.addrobj.address;
				} else {
					fullAddress = this.addrobj.province + this.addrobj.city + this.addrobj.district + this.addrobj
						.address;
				}

				// 计算收货地址的经纬度
				let receiverLocation = await adressUtils.getLocationByAddress(fullAddress);
				return receiverLocation;
			},

			//获取对应的地址编码
			_getRegionCode() {
				let address = this.addrobj;
				let cityName = address.city;
				let specialCity = "北京市，天津市，上海市，重庆市"; //直辖市
				if (address.province && specialCity.indexOf(address.province) > -1) {
					cityName = '市辖区';
					if (address.province == "重庆市" && address.city == "县") {
						cityName = '县';
					}
				}
				let province = "";
				let city = "";
				let district = "";
				try {
					province = TextToCode[address.province].code;
					city = TextToCode[address.province][cityName].code;
					district = TextToCode[address.province][cityName][address.district].code;
				} catch (e) {}
				this.selectedOptions = [province, city, district];
			},
			//编码转文字
			_getCityText(province, city) {
				let cityName = city;
				let specialCity = "北京市，天津市，上海市，重庆市"; //直辖市
				if (province && specialCity.indexOf(province) > -1) {
					cityName = province;
					if (province == "重庆市" && city == "县") {
						cityName = '县';
					}
				}
				return cityName;
			},
			//地址解析
			async confirmAnalysis() {
				if (!this.analysisAddress) {
					this.$message.error("请填写需要解析的地址");
					return;
				}

				this.selectedOptions = [];
				this.addrobj.tel = "";
				this.addrobj.name = "";
				this.addrobj.province = "";
				this.addrobj.city = "";
				this.addrobj.district = "";
				this.addrobj.address = "";

				let address = await addressUtils.addressrAnalyze(this.analysisAddress);
				this.addrobj.tel = address.mobile || '';
				this.addrobj.name = address.receiverName || '';
				this.addrobj.province = address.state || '';
				this.addrobj.city = address.city || '';
				this.addrobj.district = address.district || '';
				this.addrobj.address = (address.town || '') + (address.address || '');
				// 回显解析得到的地址
				this._showAnalysisAdr(this.addrobj.province, this.addrobj.city, this.addrobj.district);
				// 省市区回显
				this._getRegionCode();
				this.analysisFocus = false;
				this.isForeignAdr = false;
			},
			/**
			 * 切换地址
			 */
			changeAdrType() {
				this.isForeignAdr = !this.isForeignAdr;
			},

			/**
			 * 加载国家列表
			 */
			async _loadCountryList() {
				// 优先缓存中数据
				let countryList = localStorage.getItem("countryList");
				if (countryList && countryList != '[]') {
					return this.countryList = JSON.parse(countryList);
				}

				countryList = await addressUtils.getCountryList();
				// 渲染到页面并添加到缓存
				this.countryList = countryList;
			},

			/**
			 * 附件上传
			 */
			_handlePictureCardPreview(response, file, fileList) {
				let aa = file
			},

			_handleFrondSuccess(response, file, fileList) {
				if (response.error_code == "0") {
					this.frontSideImageUrl = response.error_info;
				}
			},
			_handleReverseSuccess(response, file, fileList) {
				if (response.error_code == "0") {
					this.reverseSideImageUrl = response.error_info;
				}
			},

			/**
			 * 省市区完整回显
			 * @param {Object} province
			 * @param {Object} city
			 * @param {Object} district
			 */
			_showAnalysisAdr(province, city, district) {
				// 直接回传解析结果，不做处理，部分地址选项中没有
				let _this = this;
				setTimeout(function() {
					_this.addrobj.province = province;
					_this.addrobj.city = city;
					_this.addrobj.district = district;
					try {
						if (document.getElementsByClassName('cascaderText')[0].getElementsByClassName(
								'el-input__inner')[0].placeholder != "请输入姓名") {
							document.getElementsByClassName('cascaderText')[0].getElementsByClassName(
									'el-input__inner')[0].value =
								`${province} / ${city} / ${district}`;
						}
					} catch {

					}
				}, 100);
			},
		}
	}
</script>

<style scoped="scoped">
	.analysis-row {
		border: solid 1px #dcdfe6;
		background-color: #f9f9f9;
		border-radius: 5px;
		padding: 15px 15px 15px 0;
		line-height: 25px;
	}

	.analysis-row label {
		margin-left: 15px;
		color: #f56b6b;
	}

	/deep/.analysis-address textarea {
		background-color: #f9f9f9;
		border: none;
		resize: none;
	}

	.analysis-row.analysis-focus {
		background-color: #fff;
	}

	/deep/.analysis-row.analysis-focus .analysis-address textarea {
		background-color: #fff;
	}

	.analysis-btn {
		display: flex;
		justify-content: flex-end;
		margin: 10px 0 0 10px;
	}

	.el-cascader {
		width: 100%;
	}

	.el-select {
		width: 280px;
	}

	.adr-sel-form {
		padding-right: 60px;
		position: relative;
	}

	.adr-type-btn {
		font-size: 12px;
		position: absolute;
		top: 0;
		right: -58px;
		cursor: pointer;
	}

	.upload-img {
		height: 100px;
		width: 150px;
	}

	.el-icon-camera {
		font-size: 30px;
	}

	.upload-text {
		text-align: center;
		color: #ccc;
	}

	.image-slot {
		margin-top: 38px;
	}

	.gray {
		color: #ccc;
	}

	.img-text {
		margin-top: 5px;
	}
</style>