<template>
	<el-dialog title="我的地址" :visible.sync="addressListVisible" width="700px" :before-close="onClose">
		<div class="address-list">
			<div class="flex-between" style="margin-bottom: 10px;">
				<div class="flex-row">
					<el-button icon="el-icon-circle-plus" plain @click="addAddress">
						添加
					</el-button>
					<!-- <div class="add-box flex-row flex-center" @click="editAddress">
						<div class="add-icon"> <i class="el-icon-edit-outline"></i></div>
						<div>编辑</div>
					</div> -->
					<!-- <div class="add-box flex-row flex-center" @click="deleteAddress">
						<div class="add-icon"> <i i class="el-icon-delete"></i></div>
						<div>删除</div>
					</div> -->
				</div>
				<div class="address-search">
					<el-input clearable @clear="clearFn" placeholder="请输入姓名/电话/地址" v-model="addressSearchValue" @keyup.13.native="getAddressList">
						<el-button type="danger" slot="append" icon="el-icon-search" @click="getAddressList">
						</el-button>
					</el-input>
				</div>
			</div>
			<div class="address-box-list">
				<el-table :data="addressList" ref="singleTable" :row-class-name="tableRowClassName" highlight-current-row height="280" @current-change="addressChange" border style="width: 100%">
					<el-table-column fixed prop="name" label="姓名" width="110">
					</el-table-column>
					<el-table-column prop="tel" label="电话" width="120">
					</el-table-column>
					<el-table-column prop="showaddress" label="地址" width="280">
					</el-table-column>
					<el-table-column label="操作" width="149">
						<template slot-scope="scope">
							<el-button size="mini" @click="editAddress(scope.$index, scope.row)">编辑</el-button>
							<el-button size="mini" type="danger" @click="deleteAddress(scope.$index, scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next" :total="currentTotal">
			</el-pagination>
			<address-edit :addressinfo="currentAddrEdit" :title="dialogTitle" :isCreate="createEditDialog" :isShow="showEditDialog" @close="closeEditDialog" @success="editSuccess"></address-edit>
			<div class="center-div">
				<el-button type="primary" @click="submitAddress">确定</el-button>
			</div>

		</div>
	</el-dialog>
</template>

<script>
	import addressUtils from '../../utils/api-utils/api-address.js';

	import addressEdit from './AddressEdit.vue'
	export default {
		name: "address-select",
		components: {
			addressEdit
		},
		props: {
			selectAddressid: {
				type: String,
				default: '',
			},
			addressListVisible: {
				type: Boolean,
				default: false
			},
		},
		created() {
			this.selectid = this.selectAddressid;
			//获取用户地址
			this.getAddressList();
		},
		watch: {

		},
		data() {
			return {
				dialogTitle: '',
				currentAddr: {},
				currentAddrEdit: {},
				createEditDialog: false,
				showEditDialog: false,
				pageSize: 10, // 当前页大小
				currentPage: 1, // 当前index
				addressSearchValue: "", //搜索值
				currentTotal: 10,
				addressList: [],
				currentRow: null,
				selectid: '',
			}
		},
		methods: {
			tableRowClassName({
				row,
				rowIndex
			}) {
				row.index = rowIndex;
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getAddressList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getAddressList();
			},

			//获取用户地址
			async getAddressList() {
				// 获取用户地址列表
				let addressList = await addressUtils.getAddressList(this.addressSearchValue, this.pageSize, this.currentPage - 1);
				addressList.forEach((item, index) => {
					if (item.country == '中国') {
						item.showaddress = item.province + item.city + item.district + item.town + item.address
					} else {
						item.showaddress = item.country + item.address;
					}
					if (this.selectid == item.addressId) {
						this.currentRow = index + 1;
						this.currentAddr = item;
					}
				});
				this.addressList = addressList;
				
				try {
					// 设置默认选择项
					this.$nextTick(() => {
						this.$refs.singleTable.setCurrentRow(this.currentAddr)
					});
				} catch { }

				// 获取用户地址列表 
				addressUtils.getAddressListCount(this.addressSearchValue).then((count) => {
					this.currentTotal = count;
				});

				//关闭加载遮罩层
				this.addressLoading = false;
			},
			addAddress() {
				this.dialogTitle = "添加地址";
				this.createEditDialog = true;
				setTimeout(() => {
					this.showEditDialog = true;
					this.currentAddrEdit = {};
				}, 100);
			},
			editAddress(index, row) {
				this.dialogTitle = "修改地址";
				this.createEditDialog = true;
				this.selectid = row.addressId;
				//为了避免页面抖动，添加延时操作
				setTimeout(() => {
					this.showEditDialog = true;
					this.currentAddrEdit = row;
				}, 100);
			},
			deleteAddress(index, row) {
				let that = this;
				let list = that.addressList;
				var addressId = row.addressId;
				that.$confirm("确定删除地址吗？", {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					
					addressUtils.deleteAddress(addressId).then((deleteRes)=>{
						if(deleteRes)
						{
							this.currentAddr = {};
							setTimeout(() => {
								let index = list.findIndex(val => val.addressId == addressId);
								index !== -1 && list.splice(index, 1);
								that.$message.success("删除成功");
							}, 500);
						}
					});
				});
			},
			addressChange(val) {
				if (!val || !val.addressId) {
					return;
				}
				try {
					this.selectid = val.addressId;
					this.currentAddr = val;
					this.currentRow = val.index;
					// 设置默认选择项
					this.$nextTick(() => {
						this.$refs.singleTable.setCurrentRow(this.currentAddr)
					})
				} catch {

				}

			},
			closeEditDialog() {
				this.currentAddr = {};

				this.createEditDialog = false;
				this.showEditDialog = false;
			},
			editSuccess(obj) {
				this.closeEditDialog();
				if (obj.country == '中国') {
					obj.showaddress = (obj.province || "") + (obj.city || "") + (obj.district || "") + (obj.town || "") +
						obj
						.address
				} else {
					obj.showaddress = obj.country + obj.address;
				}
				this.selectid = obj.addressId;
				this.addressSearchValue = "";
				this.currentPage = 1;
				// 刷新列表（修改与新增）
				this.getAddressList();
				// this.$emit('val-change', this.currentAddr,true);
			},
			submitAddress() {
				if (!!this.currentAddr.addressId) {
					this.$emit('val-change', this.currentAddr);
				} else {
					this.$message({
						type: 'error',
						message: '选择一条地址'
					});
				}

			},
			// 关闭订单确认我的地址弹窗
			onClose() {
				this.$emit('cancel');
			},
			// 清空
			clearFn() {
				this.addressSearchValue = "";
			},
		}
	}
</script>

<style>
	.address-selectbox {
		height: 25px;
		border: 1px solid #dedede;
		padding: 5px;
		color: #999;
		font-size: 16px;
		margin-bottom: 5px;
		font-size: 14px;
	}

	.dialog-width {
		width: 700px;
	}

	.center-div {
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.address-box-list {
		height: 300px;
		/* overflow: hidden; */
		/* overflow-y: scroll; */
	}

	.add-box {
		margin-right: 10px;
		line-height: 14px;
	}

	.danger-button {
		color: #FFF !important;
		background-color: #F56C6C !important;
		border-color: #F56C6C !important;
		/* border-radius: 0px !important; */
	}

	.address-list {
		max-height: 420px;
	}

	.address-list::-webkit-scrollbar {
		display: none;
	}

	.addrselected {
		border: 1px solid #FF5757 !important;
	}

	.el-dialog__body {
		padding-top: 10px !important;
	}

	.address-search .el-input-group__append {
		background-color: #f56c6b;
		border-color: #f56c6b;
		color: #fff;
		font-size: 20px;
	}
</style>