<!-- 个人中心菜单母版 -->
<template>
	<div class="container personal-container">
		<el-container>
			<el-aside width="150px">
				<el-menu v-if="activeMenu" :default-active="activeMenu" :router="true">
					<el-menu-item index="tradelist">
						<span slot="title">我的订单</span>
					</el-menu-item>
					<el-menu-item index="addresslist">
						<span slot="title">收货地址</span>
					</el-menu-item>
					<!-- <el-menu-item index="my-coupon">
						<span slot="title">优惠券</span>
					</el-menu-item>
					<el-menu-item index="my-account">
						<span slot="title">账号管理</span>
					</el-menu-item> -->
					<el-menu-item index="contactservice">
						<span slot="title">售后服务</span>
					</el-menu-item>
				</el-menu>
			</el-aside>
			<el-main>
				<div class="personal-main">
					<slot></slot>
				</div>
				<slot name="personalFooter"></slot>
			</el-main>
			 
		</el-container>
		
	</div>
</template>

<script>
	export default {
		name: 'personal-layout',
		props: {
			curMenu: {
				type: String,
				default: "tradelist"
			} // 当前选中菜单，默认第一个
		},		
		data(){
			return {
				activeMenu: this.curMenu,
			}
		},
		activated(){			
			this.activeMenu = this.curMenu;			
		},
		deactivated(){
			this.activeMenu = undefined; // 设置为undefined重新渲染界面
		}
	}
</script>

<style scoped="scoped">
	.personal-container {
		margin-top: 20px;
	}

	.personal-menu {
		margin: 20px 0;
	}

	.personal-main {
		background-color: #fff;
		height: calc(100vh - 110px);
		overflow: hidden;
		overflow-y: scroll;
	}

	.personal-main::-webkit-scrollbar {
		display: none;
	}

	.el-aside {
		margin-right: 20px;
	}

	.el-menu {
		border: none;
	}

	.el-menu-item {
		padding-left: 40px !important;
	}
</style>
